import { useRef } from 'react';
import { useGetUserQuery } from '../userSlice';
import Stats from './Stats';
import StudentDescription from './StudentDescription';
import StudentEducation from './StudentEducation';
import StudentExperience from './StudentExperience';
import { Element } from 'react-scroll';
import Loading from 'components/global/layout/Loading';
// import { PorfileNavigation } from 'features/profile/types';
import { PDFExport } from '@progress/kendo-react-pdf';
import { FaDownload } from 'react-icons/fa';

const Index = ({
  // item,
  handleSwitch,
  setShowFullProfile,
}: {
  // item: PorfileNavigation;
  handleSwitch: (value: number) => void;
  setShowFullProfile: any;
}) => {
  const { isLoading, isSuccess, data } = useGetUserQuery();

  const pdfExportComponent = useRef<any>(null);
  const contentArea = useRef(null);
  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <>
          <div className='flex justify-end p-2 -mb-4 mr-4'>
            <button
              onClick={handleExportWithMethod}
              className='rounded mt-3 text-xs text-gray-100 font-bold md:text-sm px-3 md:px-6 py-2 bg-primary hover:bg-primary-light hover:text-gray-700  transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'
            >
              <FaDownload />
            </button>
            <div className='flex ml-3 mt-4'>
              <button
                onClick={() => {
                  setShowFullProfile(false);
                  handleSwitch(1);
                }}
              >
                <svg
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M14.7583 3.36875C15.0833 3.04375 15.0833 2.50208 14.7583 2.19375L12.8083 0.24375C12.5 -0.08125 11.9583 -0.08125 11.6333 0.24375L10.1 1.76875L13.225 4.89375M0 11.8771V15.0021H3.125L12.3417 5.77708L9.21667 2.65208L0 11.8771Z'
                    fill='#767676'
                  />
                </svg>
              </button>
            </div>
          </div>{' '}
          <div ref={contentArea} className='-mx-8 text-neutral-900'>
            <PDFExport
              paperSize='auto'
              // landscape={true}
              margin={40}
              ref={pdfExportComponent}
            >
              <StudentDescription
                // item={item}
                handleSwitch={handleSwitch}
                data={data}
                setShowFullProfile={setShowFullProfile}
              />
              <Element name='education-section'>
                {data?.education?.length ? (
                  <StudentEducation data={data} />
                ) : null}
              </Element>
              <Element name='experience-section'>
                {data?.workExperience?.length ? (
                  <StudentExperience data={data} />
                ) : null}
              </Element>
              <Stats data={data} />
            </PDFExport>
          </div>
        </>
      )}
    </>
  );
};

export default Index;
