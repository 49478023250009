import React from 'react';
import { CompanyMentorType } from '../../types';
import MentorContent from './MentorContent';

const CompanyMentorSection = ({
  mentorContent,
  mentorDesignation,
  mentorImage,
  mentorLinkedin,
  mentorName,
}: CompanyMentorType) => {
  return (
    <div className='border rounded p-4 md:px-6'>
      <div className='flex space-x-4 md:space-x-6'>
        <img
          src={mentorImage}
          alt='mentor'
          className='h-20 w-20 lg:h-24 lg:w-24 rounded-full object-cover'
        />
        <div className='my-auto'>
          <p className='font-semibold text-textColor-light'>Company Mentor</p>
          <div className='flex space-x-2 items-center'>
            <p className='font-semibold text-primary'>{mentorName}</p>
            <div>
              <a
                href={mentorLinkedin}
                target='_blank'
                rel='noreferrer noopener'
              >
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1670235654/new%20b2b/Vector_3_cbmzi2.svg'
                  alt='in-logo'
                />
              </a>
            </div>
          </div>
          <p className='text-primary'>{mentorDesignation}</p>
        </div>
      </div>
      <div>
        <MentorContent mentorContent={mentorContent} />
      </div>
    </div>
  );
};

export default CompanyMentorSection;
