import { stringify } from 'query-string';

import {
  useCheckIncompleteVgaQuery,
  useGetVgaQuizQuery,
  useStartVgaTestMutation,
} from '../VgaQuizSlice';
import classNames from 'classnames';

const StartTestInstructions = ({ domainId, skills, batchId }: any) => {
  const { refetch } = useCheckIncompleteVgaQuery({
    batchId,
    source: 'B2B',
  });

  const paramObj = {
    areaId: domainId,
    // type: 'B2C DOMAIN',
    skillIds: skills,
    questionCountForEachSkill: 8,
  };

  const params = stringify(paramObj, { arrayFormat: 'index' });

  const { isLoading, isSuccess, isError, data } = useGetVgaQuizQuery(params);

  const startTestObject = {
    domain: domainId,
    skills: skills,
    responses: data,
    batchId,
    source: 'B2B',
  };

  const [startTest, { isLoading: isProcessing, isError: startQuizError }] =
    useStartVgaTestMutation();

  return (
    <div>
      <p>{(isLoading || isProcessing) && 'Loading...'}</p>
      <p>{(isError || startQuizError) && 'Error'}</p>
      <div className='m-1 sm:m-8 bg-gray-50 p-2 sm:p-8'>
        <div className='mb-2 sm:mb-6'>
          The Domain Test evaluates your domain specific concepts and skill sets
        </div>
        <div className='ml-2'>
          <div className='text-primary font-semibold mb-4'>Test Format</div>
          <div className='mb-2 sm:mb-6'>
            Total Questions: 8 MCQs/ skill * no. of skills
          </div>
          <div className='text-primary font-semibold mb-4'>
            General Instructions
          </div>
          <ul className='list-disc'>
            <li>Click on "Get Started" to begin the assessment</li>
            <li>
              You can navigate through questions using Previous/ Next Buttons
            </li>
            <li>
              Remember to finish on time and click on the submit button. If the
              timer runs out while you're still finishing the assessment, your
              answer will be auto-submitted
            </li>
            <li>
              You can preview and edit your answers at the end of the all the
              questions if you are able to finish all your sections before a
              total time of 20 mins
            </li>
            <li>
              After submitting your assessment, you will be able to see your
              scores
            </li>
          </ul>
          <div className='mt-2 sm:mt-6 ml-1'>Note:</div>
          <ul className='list-disc'>
            <li>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            <li>
              In case of any network issues, please raise a query to the Belong
              Team
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classNames('flex justify-center mt-4 sm:mt-12', {
          hidden: !(isSuccess && data),
        })}
      >
        <button
          disabled={isProcessing}
          onClick={() => {
            startTest(startTestObject)
              .unwrap()
              .then((res) => {
                refetch();
              });
          }}
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'
        >
          Start Test
        </button>
      </div>
    </div>
  );
};

export default StartTestInstructions;
