import React, { useState } from 'react';
import FeedbackForm from './FeedbackForm';
import ThankyouPage from './ThankyouPage';

const RightSection = (props) => {
  const [isCompleted, setIsCompleted] = useState(false);
  if (props.feedbackStatus) {
    props.setIsVisible(false);
    return (
      <ThankyouPage
        showThankPage={props.showThankPage}
        setShowThankPage={props.setShowThankPage}
        completionStatus={props.completionStatus}
        batchLogo={props.batchLogo}
      />
    );
  } else {
    return (
      <div className='col-span-6 lg:col-span-4 bg-white border-2 border-l-0 border-b-0'>
        <FeedbackForm
          isVisible={props.isVisible}
          setIsVisible={props.setIsVisible}
          isCompleted={isCompleted}
          setIsCompleted={setIsCompleted}
          name={props.name}
          email={props.email}
          completionStatus={props.completionStatus}
          mentorSign={props.mentorSign}
          mentorName={props.mentorName}
          batchId={props.batchId}
          userId={props.userId}
        />
      </div>
    );
  }
};

export default RightSection;
