import React from 'react';
import { useCheckIncompleteAptitudeQuery } from './AptitudeQuizSlice';
import GetAptitudeQuestion from './GetAptitudeQuestion';
import StartAptitudeTestScreen from './StartAptitudeTestScreen';
import Loading from 'components/global/layout/Loading';

const CheckQuizStatus = () => {
  const { isLoading, isSuccess, data } = useCheckIncompleteAptitudeQuery();
  const condition = isSuccess && Boolean(data.responses);
  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : condition ? (
        <div>
          <StartAptitudeTestScreen data={data} />
        </div>
      ) : (
        <GetAptitudeQuestion />
      )}
    </div>
  );
};

export default CheckQuizStatus;
