import React from 'react';
import { formatDistance } from 'date-fns';
import IndividualTicket from './IndividualTicket';

const MyTickets = ({
  myTicket,
  setTicketDetails,
  setTicketNo,
  ticketStatus,
  isCareereraUser,
}) => {
  const tickets = myTicket.filter((ticket) => {
    return ticket.status === ticketStatus;
  });

  return (
    <>
      <div className='md:px-10'>
        {tickets.map((ticket, index) => {
          const ticketDate = formatDistance(
            new Date(ticket.createdAt),
            new Date(),
            {
              addSuffix: true,
            }
          );
          const comment = ticket.activity.filter((act) => {
            return act.comment;
          });
          return (
            <IndividualTicket
              ticket={ticket}
              setTicketDetails={setTicketDetails}
              setTicketNo={setTicketNo}
              index={index}
              ticketDate={ticketDate}
              comment={comment}
              clickCheck={true}
              isCareereraUser={isCareereraUser}
            />
          );
        })}
      </div>
    </>
  );
};

export default MyTickets;
