import React from 'react';
import htmlparser from 'html-react-parser';

interface allProps {
  taskData: any;
}
const TaskDetail = (props: allProps) => {
  const taskData = props.taskData;

  return (
    <div className='mt-2'>
      <div className='flex flex-wrap justify-between'>
        <p className='font-semibold text-lg'>{taskData.title}</p>
        <div className='flex flex-wrap gap-2 '>
          {taskData.skillId.map((skill: any) => {
            return (
              <div
                key={skill._id}
                className='border mb-2 md:mb-0 bg-primary-lightest font-semibold rounded-2xl py-1 px-2 text-sm'>
                {skill.title}
              </div>
            );
          })}
        </div>
      </div>
      {taskData.backgroundDescription && (
        <div className='prose prose-sm max-w-none lg:text-lg mt-6'>
          {htmlparser(taskData.backgroundDescription)}
        </div>
      )}
      {taskData.taskInstructions && (
        <div className='prose prose-sm max-w-none lg:text-lg'>
          {htmlparser(taskData.taskInstructions)}
        </div>
      )}
      {taskData.resources && (
        <>
          <div className='mt-2 font-semibold '>Resources</div>
          <div className='prose prose-sm max-w-none lg:text-lg'>
            {htmlparser(taskData.resources)}
          </div>
        </>
      )}
    </div>
  );
};

export default TaskDetail;
