import React, { useState } from 'react';
import { QueryTabType } from '../../types';
import CreateQuery from './CreateQuery';
import SelectQuery from './SelectQuery';

const NewQuery = ({ batchId, coachId, mentorId }: QueryTabType) => {
  const [isCreateNewQuery, SetIsCreateNewQuery] = useState('');
  return (
    <>
      {isCreateNewQuery ? (
        <CreateQuery
          SetIsCreateNewQuery={SetIsCreateNewQuery}
          department={isCreateNewQuery}
          batchId={batchId}
          coachId={coachId}
          mentorId={mentorId}
        />
      ) : (
        <div>
          <SelectQuery SetIsCreateNewQuery={SetIsCreateNewQuery} />
        </div>
      )}
    </>
  );
};

export default NewQuery;
