import React, { useState } from 'react';
import TextEditorQuill from './TextEditorQuill';
import htmlparser from 'html-react-parser';
import { Dialog } from '@headlessui/react';
import classNames from 'classnames';
import { useUpdateSubmissionsMutation } from 'components/capstone/capstoneSlice';
import { FiLock } from 'react-icons/fi';
import Tooltip from 'components/global/layout/Tooltip';

interface allProps {
  taskData: any;
  enrollData: any;
  setSelectedTask: Function;
}

const TaskSolution = (props: allProps) => {
  const [loading, setLoading] = useState(false);

  const [userSoution, setUserSolution] = useState('');
  const enrollmentId = props.enrollData._id;
  const taskId = props.taskData?._id;
  const idealSolution = props.taskData?.idealSolution;
  const enrollCurrentTask = props.enrollData?.capstoneSubmissions?.filter(
    (sub: any) => {
      return sub.taskId === taskId;
    }
  );
  const initialtext =
    enrollCurrentTask && enrollCurrentTask.length
      ? enrollCurrentTask[0].solution
      : '';

  const newSubmission =
    enrollCurrentTask && enrollCurrentTask.length ? false : true;

  const [submit] = useUpdateSubmissionsMutation();
  const submitSolution = () => {
    setLoading(true);
    submit({
      enrollmentId,
      solution: userSoution,
      taskId,
      newSubmission,
    })
      .unwrap()
      .then(() => {
        props.setSelectedTask(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='mt-4'>
      {initialtext ? (
        <>
          <div className='bg-white p-1 sm:p-4'>
            <div className='font-semibold text-lg'>Your Answer</div>
            <div className='prose prose-sm max-w-none lg:text-lg mt-6'>
              {htmlparser(initialtext)}
            </div>
          </div>
          {idealSolution && (
            <div className='p-1 sm:p-4'>
              <div className='font-semibold text-lg'>Ideal Answer</div>
              <div className='prose prose-sm max-w-none lg:text-lg mt-6'>
                {htmlparser(idealSolution)}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {idealSolution && (
            <div className=' flex pb-6'>
              <div className='font-semibold text-lg'>Ideal Solution</div>
              <Tooltip text='Submit the task to view the ideal solution'>
                <FiLock className='h-6 w-6 text-primary ml-4' />
              </Tooltip>
            </div>
          )}
          <div className='font-semibold text-lg'>Your Solution</div>
          <TextEditorQuill
            initialtext={initialtext}
            setUserSolution={setUserSolution}
          />
          <div className='text-center  mt-16'>
            <button
              onClick={() => setIsOpen(true)}
              disabled={loading}
              className={classNames(
                'px-8 py-1 rounded-lg mt-4',
                { 'bg-gray-300 pointer-events-none': userSoution?.length < 12 },
                { ' bg-primary-light': userSoution?.length >= 12 }
              )}
            >
              {loading ? 'Loading' : 'Submit'}
            </button>
          </div>
        </>
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        as='div'
        className='relative z-10'
      >
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <div>
              <Dialog.Panel className='rounded bg-white m-4 p-4'>
                <div className='text-left w-64 sm:w-96'>
                  <div className='font-bold'>
                    Are you sure you want to submit the task?
                  </div>
                  <div className='mt-4'>
                    You can not make any changes in your task once you have
                    submitted the task
                  </div>
                  <div className='mt-6 px-4 py-3 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-primary-light px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        submitSolution();
                        setIsOpen(false);
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default TaskSolution;
