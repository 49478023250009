import { Formik, Field, Form } from 'formik';
import { useAddFeedbackMutation } from '../capstoneSlice';
import Navigation from './Navigation';

const Questions = (props) => {
  const [addFeedback] = useAddFeedbackMutation();

  const question = props.database[props.currentQuestion - 1];

  // NEW (deviare radio input)
  const deviareRadioInputs = (
    <div className='flex flex-wrap space-x-4'>
      <div className='space-x-1'>
        <Field
          type='radio'
          id='Data Science (Python, ML, Deep Learning, NLP)'
          name={question.question}
          value='Data Science (Python, ML, Deep Learning, NLP)'
        />
        <label htmlFor='Data Science (Python, ML, Deep Learning, NLP)'>
          Data Science (Python, ML, Deep Learning, NLP)
        </label>
      </div>
      <div className='space-x-1'>
        <Field
          type='radio'
          id='Data Analytics (Tableau, Power BI)'
          name={question.question}
          value='Data Analytics (Tableau, Power BI)'
        />
        <label htmlFor='Data Analytics (Tableau, Power BI)'>
          Data Analytics (Tableau, Power BI)
        </label>
      </div>
    </div>
  );
  // NEW (deviare checkbox input)
  const deviareCheckboxInputs = (
    <div className='md:flex flex-wrap md:space-x-4 text-sm'>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Python'
          name={question.question}
          value='Python'
        />
        <label htmlFor='Python'>Python</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Data Cleaning'
          name={question.question}
          value='Data Cleaning'
        />
        <label htmlFor='Data Cleaning'>Data Cleaning</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Visualization'
          name={question.question}
          value='Visualization'
        />
        <label htmlFor='Visualization'>Visualization</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Data Scraping'
          name={question.question}
          value='Data Scraping'
        />
        <label htmlFor='Data Scraping'>Data Scraping</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Statistics'
          name={question.question}
          value='Statistics'
        />
        <label htmlFor='Statistics'>Statistics</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Machine Learning'
          name={question.question}
          value='Machine Learning'
        />
        <label htmlFor='Machine Learning'>Machine Learning</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Deep Learning'
          name={question.question}
          value='Deep Learning'
        />
        <label htmlFor='Deep Learning'>Deep Learning</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='NLP '
          name={question.question}
          value='NLP '
        />
        <label htmlFor='NLP '>NLP</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='SQL'
          name={question.question}
          value='SQL'
        />
        <label htmlFor='SQL'>SQL</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='EDA'
          name={question.question}
          value='EDA'
        />
        <label htmlFor='EDA'>EDA</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Power BI'
          name={question.question}
          value='Power BI'
        />
        <label htmlFor='Power BI'>Power BI</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Tableau'
          name={question.question}
          value='Tableau'
        />
        <label htmlFor='Tableau'>Tableau</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='Data Analysis'
          name={question.question}
          value='Data Analysis'
        />
        <label htmlFor='Data Analysis'>Data Analysis</label>
      </div>
    </div>
  );

  const radioInputs = (
    <>
      <div className='md:flex flex-wrap md:space-x-6 text-sm'>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='1'
            name={question.question}
            value='1'
          />
          <label htmlFor='1'>1</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='2'
            name={question.question}
            value='2'
          />
          <label htmlFor='2'>2</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='3'
            name={question.question}
            value='3'
          />
          <label htmlFor='3'>3</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='4'
            name={question.question}
            value='4'
          />
          <label htmlFor='4'>4</label>
        </div>
        <div className='space-x-1'>
          <Field
            type={question.questionType}
            id='5'
            name={question.question}
            value='5'
          />
          <label htmlFor='5'>5</label>
        </div>
      </div>
      <div className='mt-3 text-primary text-sm'>
        Rating Scale Range- 1: Lowest to 5: Highest
      </div>
    </>
  );
  const textInputs = (
    <Field
      as='textarea'
      type={question.questionType}
      name={question.question}
      className='border rounded-md p-4 h-36 w-[90%] '
    />
  );
  const booleanInputs = (
    <div className='flex flex-wrap space-x-4'>
      <div className='space-x-1'>
        <Field type='radio' id='yes' name={question.question} value='YES' />
        <label htmlFor='yes'>Yes</label>
      </div>
      <div className='space-x-1'>
        <Field type='radio' id='no' name={question.question} value='NO' />
        <label htmlFor='no'>No</label>
      </div>
    </div>
  );

  return (
    <Formik
      initialValues={{}}
      onSubmit={(values) => {
        const feedback = {};
        Object.keys(values).forEach((key) => {
          feedback[key] = +values[key];
        });

        if (values.image) {
          feedback.image = values.image;
        }
        const data = { feedback, enrollmentId: props.enrollmentId };
        addFeedback(data)
          .unwrap()
          .then((res) => {
            props.setShowFeedback(false);
          });
      }}>
      {({ values, setFieldValue }) => (
        <Form>
          <div>
            <div key={question.questionNum} className='space-y-6'>
              <p>{question.question}</p>
              <div>
                {/* New (deviareRadioInputs*/}
                {question.questionType === 'deviareRadio'
                  ? deviareRadioInputs
                  : null}
                {/* New (deviareCheckboxInputs) */}
                {question.questionType === 'checkbox'
                  ? deviareCheckboxInputs
                  : null}
                {question.questionType === 'radio' ? radioInputs : null}
                {question.questionType === 'text' ? textInputs : null}
                {question.questionType === 'yesNo' ? booleanInputs : null}
              </div>
            </div>
          </div>
          <Navigation
            values={values}
            questionName={question.question}
            database={props.database}
            currentQuestion={props.currentQuestion}
            setCurrentQuestion={props.setCurrentQuestion}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Questions;
