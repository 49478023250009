import React from 'react';
import { AiFillClockCircle } from 'react-icons/ai';
import { AiFillLock } from 'react-icons/ai';

const LockInstruction = ({ isCareereraUser }) => {
  return (
    <div>
      <div className='flex flex-col'>
        <div className='flex justify-between'>
          <p className='font-medium text-gray-600 text-lg py-1'>Assessment</p>
          <div className='flex text-lg items-center bg-gray-50 px-4 py-1 text-primary'>
            <AiFillClockCircle />
            <p className='ml-1 text-gray-600 text-base'> : 20:00</p>
          </div>
        </div>

        <div className='bg-gray-50 p-3 mt-6'>
          <p className='text-primary font-semibold'>General Instuctions - </p>
          <ul className='list-disc px-3 sm:px-6 text-primary font-semibold mt-6'>
            <li className='py-1'>
              <span className='text-gray-400'>Total Questions : </span>
              <span className='text-gray-600'> 20 </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>Total Duration : </span>
              <span className='text-gray-600'>20 min</span>
            </li>

            <li className='py-1'>
              <span className='text-gray-400'>
                Click on "Start Assessment" to begin the assessment
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                You can navigate through questions using Previous/ Next Buttons
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                Remember to finish on time and click on the submit button. If
                the timer runs out while you're still finishing the assessment,
                your answer will be auto-submitted
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                You can preview and edit your answers at the end of the all the
                sections if you are able to finish all your sections before a
                total time of 20 mins
              </span>
            </li>
            <li className='py-1'>
              <span className='text-gray-400'>
                After submitting your assessment, you will be able to see your
                scores
              </span>
            </li>
          </ul>
          <p className='text-primary font-semibold mt-4'>Note - </p>
          <ul className='list-disc px-6 text-gray-400 font-semibold mt-6'>
            <li className='py-1'>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            {!isCareereraUser && (
              <li className='py-1'>
                In case of any network issues,
                <span className='text-primary'>
                  {' '}
                  please raise a query to Belong Team
                </span>
              </li>
            )}
          </ul>
        </div>
        <div className='flex justify-end mr-4'>
          <div className=' mt-10 bg-primary-light hover:bg-primary text-primary hover:text-white font-semibold px-8 py-2 text-base md:text-lg rounded-xl'>
            <button>
              <AiFillLock className='text-primary inline text-3xl' />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LockInstruction;
