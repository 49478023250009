import React from 'react';
import TicketTypeBox from './TicketTypeBox';

const TicketType = (props) => {
  return (
    <div>
      <p className='text-gray-600 mt-8 ml-6 md:ml-12 font-semibold text-lg mb-6'>
        Select the type of your query
      </p>
      <TicketTypeBox
        setTicketType={props.setTicketType}
        setTicketAssignee={props.setTicketAssignee}
        userDetails={props.userDetails}
        partner={props.partner}
        isVGE={props.isVGE}
        isFiibBatch={props.isFiibBatch}
        isCareereraUser={props.isCareereraUser}
      />
    </div>
  );
};

export default TicketType;
