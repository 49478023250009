import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import Loading from 'components/global/layout/Loading';
import CheckUserStatus from './CheckUserStatus';
import { useGetSuperCapstoneQuery } from '../superCapstoneSlice';

const Index = () => {
  const { superCapstoneId } = useParams();
  const { isLoading, isSuccess, isError, data } = useGetSuperCapstoneQuery({
    superCapstoneId,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      {isError && <div className=''>Error fetching data</div>}
      {isSuccess && <CheckUserStatus data={data[0]} />}
    </React.Fragment>
  );
};

export default Index;
