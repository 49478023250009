import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
// import { useTimeout } from 'hooks/useTimeout';
import React, { useEffect, useReducer, useState } from 'react';
import { CapstoneTestResponsesContext } from 'hooks/useCapstoneTestResponsesContext';

import Questions from './questions';
import { reducer } from './utils/helper';
import { initialState } from './utils/types';
import {
  useFinishCapstoneTestMutation,
  useUpdateCapstoneResponsesMutation,
} from '../CapstoneQuizSlice';

const Index: React.FC<{ data: any; setShowScores: any }> = ({
  data,
  setShowScores,
}) => {
  const [updateResponse] = useUpdateCapstoneResponsesMutation();
  const [finishTest] = useFinishCapstoneTestMutation();
  const [timeLeft, setTimeLeft] = useState(data.timeLeft);

  const domainResponsesInitialState: initialState = {
    capstone: '',
    minutesLeft: 0,
    secondsLeft: 0,
    currentQuestion: 0,
    responses: data.responses,
    totalQuestions: data.totalQuestions,
    enrollmentId: data._id,
  };

  const [state, dispatch] = useReducer(reducer, domainResponsesInitialState);

  function finishTestFn() {
    finishTest({ enrollmentId: state.enrollmentId })
      .unwrap()
      .then((res: any) => {
        setShowScores(true);
      });
  }

  useEffect(() => {
    updateResponse({
      responses: state.responses,
      enrollmentId: state.enrollmentId,
    });
  }, [state.responses, state.enrollmentId, updateResponse]);

  // Set timeout to finish test
  useTimeout(() => {
    finishTestFn();
  }, data.timeLeft * 1000);
  //Update timer
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    dispatch({
      type: 'UPDATE_REMAINING_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return (
    <CapstoneTestResponsesContext.Provider value={{ state, dispatch }}>
      <div>
        <Questions setShowScores={setShowScores} />
      </div>
    </CapstoneTestResponsesContext.Provider>
  );
};

export default Index;
