import { DocumentArrowUpIcon } from '@heroicons/react/24/outline';
import { format } from 'date-fns';
import HTMLReactParser from 'html-react-parser';

const NotificationBody = ({ notification }: { notification: any }) => {
  return (
    <div className='mb-2 border-b'>
      <div className='flex justify-between gap-4 mb-2'>
        <h2 className='font-medium text-gray-900'>{notification.title}</h2>
        <p className='text-sm text-gray-400'>
          {format(new Date(notification.createdAt), 'do LLLL yyyy')}
        </p>
      </div>

      {notification.fileUrl && (
        <a href={notification.fileUrl}>
          <DocumentArrowUpIcon
            className='h-6 w-6 text-primary mt-2'
            target='_blank'
          />
        </a>
      )}

      <div className='prose prose-sm text-sm max-w-none text-gray-900'>
        {HTMLReactParser(notification.message)}
      </div>
    </div>
  );
};

export default NotificationBody;
