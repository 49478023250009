import React from 'react';
import { AboutDataType } from '../../types';
import CompanyContent from './CompanyContent';
// import CompanyContent from './CompanyContent';

const CompanyTextSection = ({
  companyName,
  companyCountry,
  companyWebsite,
  companyContent,
}: AboutDataType) => {
  return (
    <div className='space-y-4 text-sm'>
      <p className='font-semibold text-lg text-textColor-light'>
        {companyName}
      </p>
      <div className='flex flex-wrap gap-2 md:gap-4'>
        <div className='flex space-x-2'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670234241/new%20b2b/Vector_cituxk.svg'
            alt='map-logo'
          />
          <p>{companyCountry}</p>
        </div>
        <div className='flex space-x-2'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670234241/new%20b2b/Vector_1_fs3zpm.svg'
            alt='logo'
          />
          <p>Hospitality</p>
        </div>
        <div className='flex space-x-2'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/Vector_2_b3ebwk.svg'
            alt='globe'
          />
          <a href={companyWebsite} target='_blank' rel='noreferrer noopener'>
            {companyWebsite}
          </a>
        </div>
      </div>
      <div>
        <CompanyContent companyProps={companyContent} />
      </div>
    </div>
  );
};

export default CompanyTextSection;
