import { useEffect, useState } from 'react';
// import Dashboard from './Dashboard';
import OldVga from '../../partner';
import { useNavigate } from 'react-router-dom';
import SuperDashboard from 'components/super-vga/SuperDashboard';

const MultipleBatch = ({ data }: { data: any }) => {
  const [userData, setUserData] = useState(data);
  useEffect(() => {
    setUserData(data);
  }, [data]);
  function onlyOneBatch(batchId: any) {
    const myBatch = data.batches.filter((batch: any) => {
      return batch._id === batchId;
    });
    const newObj = { ...data, batches: myBatch };
    setUserData(newObj);
  }

  const isOldVga = (startDate: any) => {
    return new Date('2023-04-30T00:30:00.000Z') > new Date(startDate);
  };

  const navigate = useNavigate();

  return (
    <div>
      {userData.batches && userData.batches.length === 1 ? (
        <>
          {data?.batches?.length > 1 && (
            <div className='flex justify-end'>
              <button
                className='text-sm text-primary mr-2'
                onClick={() => setUserData(data)}>
                Change Batch
              </button>
            </div>
          )}
          {isOldVga(userData.batches[0].startDate) ? (
            <OldVga data={userData} />
          ) : (
            // <Dashboard data={userData} />
            <SuperDashboard data={userData} />
          )}
        </>
      ) : userData?.batches?.length > 1 ? (
        <div>
          <div className='m-4 font-bold text-center'>Please select a Batch</div>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-around'>
            {userData.batches.map((batch: any) => {
              return (
                <div
                  key={batch._id}
                  className='flex flex-col justify-between border border-gray-200 shadow rounded p-4 mb-4 md:mb-0'>
                  <div
                    className='h-24 mb-4 bg-contain bg-center bg-no-repeat'
                    style={{
                      backgroundImage: `url(${batch.logoUrl})`,
                    }}></div>
                  <p className=' font-bold text-center'>{batch.name}</p>

                  <div>
                    <p className='text-center uppercase text-sm font-bold my-2'>
                      {batch.status}
                    </p>
                  </div>
                  <div className='flex justify-around pt-2'>
                    <button
                      onClick={() => onlyOneBatch(batch._id)}
                      className='bg-primary-light hover:bg-primary rounded-full text-xs md:text-sm px-4 md:px-8 py-2 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'>
                      Start
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div>
          <p className='mb-4'>You are not enrolled in any batch yet</p>
          <button
            className='bg-primary-light hover:bg-primary rounded-full text-xs md:text-sm px-4 md:px-8 py-2 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'
            onClick={() => [navigate('/')]}>
            Back to Home
          </button>
        </div>
      )}
    </div>
  );
};

export default MultipleBatch;
