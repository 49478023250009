import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getindustry: builder.query<void, void>({
      query: () => {
        return { url: '/industries', params: { limit: 0 } };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Industry'],
    }),
  }),
});

export const { useGetindustryQuery } = extendedApiSlice;
