import React from 'react';
import CapstoneData from './CapstoneData';
import Card from './Card';
import { Cap } from './types';

const Ongoing = ({ data }: { data: any }) => {
  return (
    <div className=' grid grid-cols-1  gap-3 my-6 md:my-12'>
      {data?.map(
        (
          {
            capstone,
            capstoneSubmissions,
            createdAt,
            capstoneAssessments,
          }: any,
          index: number
        ) => {
          const cardData: Cap = {
            _id: capstone._id,
            title: capstone.title,
            level: capstone.level,
            logo: capstone.companyId.logoUrl,
            country: capstone.companyId.country,
            industry: capstone.companyId?.industryId?.title,
            skills: capstone.skillId,
            domain: capstone.domainId?.title,
            tasks: capstone.tasks || [],
          };

          return (
            <div
              key={capstone._id}
              className='grid grid-cols-1 md:grid-cols-2 gap-4  bg-white cursor-pointer hover:bg-primary-lightest transition-all ease-in-out duration-300 rounded-3xl shadow-md p-4'
            >
              <Card {...cardData} />
              <CapstoneData
                capstoneSubmissions={capstoneSubmissions}
                tasks={capstone.tasks}
                _id={capstone._id}
                createdAt={createdAt}
                capstone={capstone}
                quizGiven={Boolean(capstoneAssessments)}
                capstoneAssessments={capstoneAssessments}
              />
            </div>
          );
        }
      )}
    </div>
  );
};

export default Ongoing;
//border border-primary-light
