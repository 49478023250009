import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { stringify } from 'query-string';

import useDomainTestContext from '../../../../../hooks/useDomainTestContext';

import classNames from 'classnames';
import {
  useCheckIncompleteQuery,
  useGetQuizQuery,
  useStartTestMutation,
} from '../DomainQuizSlice';

const StartTestInstructions = () => {
  const navigate = useNavigate();
  const { state } = useDomainTestContext();
  const { refetch } = useCheckIncompleteQuery();

  useEffect(() => {
    //If no domain,less than 3 skills has been selected before coming to this screen
    if (!(state.areaId && state.skillIds.length >= 2)) {
      navigate('../select-skills');
    }
  }, [state.areaId, state.skillIds, navigate]);

  const params = stringify(state, { arrayFormat: 'index' });

  const { isLoading, isSuccess, isError, data } = useGetQuizQuery(params);

  const startTestObject = {
    domain: state.areaId,
    skills: state.skillIds,
    responses: data,
  };
  const totalQuestions = data?.length;

  const [startTest, { isLoading: isProcessing, isError: startQuizError }] =
    useStartTestMutation();

  return (
    <div>
      <p>{(isLoading || isProcessing) && 'Loading...'}</p>
      <p>{(isError || startQuizError) && 'Error'}</p>
      <div className='m-1 sm:m-8 bg-gray-50 p-2 sm:p-8'>
        <div className='mb-2 sm:mb-6'>
          The Domain Test evaluates your domain knowledge and skill expertise
          level
        </div>
        <div className='ml-2'>
          <div className='text-primary font-semibold mb-4'>Test Format</div>
          <div className='mb-2 sm:mb-6'>Total Questions: {totalQuestions}</div>
          <div className='text-primary font-semibold mb-4'>
            General Instructions
          </div>
          <ul className='list-disc'>
            <li>Click on "Start Quiz" to begin</li>
            <li>
              You can navigate through questions using Previous/ Next Buttons or
              by clicking on the question number in the question navigation{' '}
            </li>
            <li>
              Remember to finish on time and click on the submit button. If the
              timer runs out while you're still finishing the assessment, your
              answer will be auto-submitted
            </li>
            <li>
              You can preview and edit your answers at any time from the
              question navigation section
            </li>
            <li>
              After submitting the quiz, you will be able to see your score
            </li>
          </ul>
          <div className='mt-2 sm:mt-6 ml-1 font-bold'>Note:</div>
          <ul className='list-disc'>
            <li>
              You can not close this tab once the assessment has started,
              otherwise your assessment will be auto-submitted
            </li>
            <li>
              In case of any network issues, please write to the Belong Team at{' '}
              <span className='text-primary underline'>
                connect@belong.education
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div
        className={classNames('flex justify-end mt-4 mr-8 sm:mt-12', {
          hidden: !(isSuccess && data),
        })}>
        <button
          disabled={isProcessing}
          onClick={() => {
            startTest(startTestObject)
              .unwrap()
              .then((res) => {
                refetch();
              });
          }}
          className='px-12 py-2 font-semibold text-xl text-white bg-primary border rounded-xl shadow'>
          Start Quiz
        </button>
      </div>
    </div>
  );
};

export default StartTestInstructions;
