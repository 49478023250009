import React from 'react';

const QuickTipsSection = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div>
      <div className='px-4 sm:px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>
            Please seek support on your assignments from the assigned coach via
            scheduled discussions or written query
          </li>
          <li>
            Please make sure to plan your work and schedule to avoid missing out
            on deadlines
          </li>
          <li>
            All tasks need to be submitted to complete the VGA & earn the
            certificate
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickTipsSection;
