import React, { useState } from 'react';
import Quiz from './Quiz';
import { allQuestions } from './QuestionData';

function getRandomItems<T>(arr: T[], numItems: number): T[] {
  const shuffledArray = [...arr].sort(() => 0.5 - Math.random());

  return shuffledArray.slice(0, numItems);
}

const Instructions = ({ userData, batchId }: any) => {
  const [startTest, setStartTest] = useState(false);

  const domainAllQuestion =
    allQuestions.find((el: any) => {
      return el.domainId === userData?.area?._id;
    })?.questions || [];

  const activeQuestion: any = getRandomItems(domainAllQuestion, 25);

  return (
    <div>
      {startTest ? (
        <Quiz activeQuestion={activeQuestion} batchId={batchId} />
      ) : (
        <div>
          <div className='px-4 sm:px-4  mt-2 '>
            <div className='font-semibold'>
              Welcome to your final assessment in the VGA journey!
            </div>
            <div className='my-3'>
              This is the final quiz of your VGA journey, designed to evaluate
              your knowledge in your domain. Please read the instructions
              carefully before you begin:
            </div>
            <ul className='list-disc ml-6'>
              <li>
                This quiz contains 25 multiple-choice questions specific to your
                domain.
              </li>
              <li>
                You have a time limit of 25 minutes, so manage your time
                carefully.
              </li>
              <li>
                Ensure you are in a quiet environment with a stable internet
                connection, as interruptions may impact your performance.
              </li>
              <li>
                Note: Reattempt requests will not be entertained, so please be
                prepared before you start.
              </li>
            </ul>
            <div className='mt-2'>Wishing you all the best for the quiz!</div>
          </div>

          <div className='my-10'>
            {' '}
            <button
              className='text-white bg-primary py-1 px-4 rounded'
              onClick={() => {
                setStartTest(true);
              }}>
              {' '}
              Start Quiz{' '}
            </button>{' '}
          </div>
        </div>
      )}
    </div>
  );
};

export default Instructions;
