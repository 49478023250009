import classNames from 'classnames';
import { GoDotFill } from 'react-icons/go';

import { Link } from 'react-router-dom';
import {
  useGetCapstoneInfoQuery,
  useGetSuperCapstoneInfoQuery,
} from './panelSlice';
import { partnerIds } from 'config/common';

const MilestoneCard = ({
  userData,
  onbordingData,
}: {
  userData: any;
  onbordingData: any;
}) => {
  const isNirmanUser = userData?.partnerId?._id === partnerIds.nirman;
  const aptitudeGiven = Boolean(onbordingData?.aptitude?.totalAttempts);
  const aptitudePercentage = onbordingData?.aptitude?.percentage.toFixed(2);
  const domainPercentage = onbordingData?.domain?.percentage.toFixed(2);

  const domainGiven = Boolean(onbordingData?.domain?.totalAttempts);
  const { data, isSuccess } = useGetCapstoneInfoQuery();
  const { data: supercapstoneData, isSuccess: SuperIsSuccess } =
    useGetSuperCapstoneInfoQuery();

  return (
    <>
      <div
        className={
          'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4 '
        }>
        <Link to='/profile' className='w-full'>
          {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
          <div className='flex justify-between'>
            <div className='flex gap-x-1 items-center '>
              <GoDotFill className='text-primary' />
              <p className='font-semibold text-xs md:text-sm'>
                Employment Profile
              </p>
            </div>
            <div>
              <p className='text-xs text-primary  text-center font-semibold'>
                {onbordingData?.profileStrength.toFixed(1)} / 15
              </p>
            </div>
          </div>
        </Link>
      </div>

      <div
        className={
          'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4'
        }>
        <Link
          to={aptitudeGiven ? '/quiz/aptitude/scorecard' : '/quiz/aptitude'}
          className={classNames('w-full')}>
          {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
          <div className='flex justify-between'>
            <div className='flex gap-x-1 items-center '>
              <GoDotFill className='text-primary' />
              <p className='font-semibold text-xs md:text-sm'>Aptitude Quiz</p>
            </div>
            <div>
              <p className='text-xs text-primary  text-center font-semibold'>
                {aptitudeGiven ? `${aptitudePercentage} %` : 'Continue'}{' '}
              </p>
            </div>
          </div>
        </Link>
      </div>
      {isNirmanUser && (
        <div
          className={classNames(
            'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4',
            {
              'pointer-events-none bg-gray-600': !aptitudeGiven,
            }
          )}>
          <Link
            to={
              domainGiven
                ? '/quiz/domain/scorecard'
                : '/quiz/domain/steps/select-domain'
            }
            className={classNames('w-full')}>
            {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
            <div className='flex justify-between'>
              <div className='flex gap-x-1 items-center '>
                <GoDotFill className='text-primary' />
                <p className='font-semibold text-xs md:text-sm'>Domain Quiz</p>
              </div>
              <div>
                <p className='text-xs text-primary  text-center font-semibold'>
                  {domainGiven ? `${domainPercentage} %` : 'Continue'}{' '}
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
      {isNirmanUser && (
        <div
          className={classNames(
            'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4',
            {
              'pointer-events-none bg-gray-600': !domainGiven,
            }
          )}>
          <Link to={'/supercapstone'} className={classNames('w-full')}>
            {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
            <div className='flex justify-between'>
              <div className='flex gap-x-1 items-center '>
                <GoDotFill className='text-primary' />
                <p className='font-semibold text-xs md:text-sm'>Capstones</p>
              </div>
              <div>
                <p className='text-xs text-primary  text-center font-semibold'>
                  {SuperIsSuccess &&
                    `${supercapstoneData?.completed} / ${supercapstoneData?.total}`}
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
      {userData?.partnerId?.sidebarTab?.capstone && (
        <div
          className={classNames(
            'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4',
            {
              'pointer-events-none bg-gray-600': !domainGiven,
            }
          )}>
          <Link to={'/capstone'} className={classNames('w-full')}>
            {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
            <div className='flex justify-between'>
              <div className='flex gap-x-1 items-center '>
                <GoDotFill className='text-primary' />
                <p className='font-semibold text-xs md:text-sm'>Capstones</p>
              </div>
              <div>
                <p className='text-xs text-primary  text-center font-semibold'>
                  {isSuccess && `${data?.completed} / ${data?.total}`}
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}

      {userData?.partnerId?.sidebarTab?.vga && (
        <div
          className={
            'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4'
          }>
          <Link to={'/dashboard'} className={classNames('w-full')}>
            {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
            <div className='flex justify-between'>
              <div className='flex gap-x-1 items-center '>
                <GoDotFill className='text-primary' />
                <p className='font-semibold text-xs md:text-sm'>
                  Apprenticeship
                </p>
              </div>
              <div>
                <p className='text-xs text-primary  text-center font-semibold'>
                  Ongoing
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
      {userData?.partnerId?.sidebarTab?.job && (
        <div
          className={
            'shadow-md flex relative border rounded my-4 space-y-2 bg-primary-lightest px-4 pt-2 pb-4'
          }>
          <Link to={'/jobs'} className={classNames('w-full')}>
            {/* <div className='absolute bg-primary px-2 py-1 top-1/4 -left-3 md:-left-6 rounded-2xl'></div> */}
            <div className='flex justify-between'>
              <div className='flex gap-x-1 items-center '>
                <GoDotFill className='text-primary' />
                <p className='font-semibold text-xs md:text-sm'>Job</p>
              </div>
              <div>
                <p className='text-xs text-primary  text-center font-semibold hidden'>
                  {/* Add job applications data */}
                </p>
              </div>
            </div>
          </Link>
        </div>
      )}
    </>
  );
};

export default MilestoneCard;
