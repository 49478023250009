import React from 'react';
import Dropzone from 'react-dropzone';
import { uploadFile } from '../helper/Helper';

const classNames = require('classnames');

const NotSubmitted = (props) => {
  return (
    <div
      className={classNames(
        ' bg-white p-2 mt-3 lg:-ml-8 border-l-2 border-gray-200',
        {
          'animate-pulse': props.loading,
        }
      )}
    >
      <div className='mb-2'>
        <div className='flex justify-center items-center'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354201/uploaded_resources/pdf_icon_mq9ixc.png'
            className='m-2 h-6 w-6 object-cover md:w-12 md:h-12'
            alt='Attachment'
          />
          <p className='text-xs md:text-sm flex justify-center items-center'>
            OR
          </p>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354174/uploaded_resources/docx_icon_hmzrhi.png'
            className=' m-2 h-6 w-6 object-cover md:w-12 md:h-12'
            alt='Attachment'
          />
        </div>
        <p className='mb-2 text-xs text-center'>Max File Size 2 MB</p>
      </div>
      <div className='text-center text-xs'>
        <div>
          <Dropzone
            disabled={props.loading}
            onDrop={(acceptedFiles) =>
              uploadFile(
                acceptedFiles,
                props.fieldToUpdate,
                props.fileFormat,
                props.assignmentId,
                props.uniqueUserId,
                props.refetch,
                props.setLoading,
                props.week,
                props.batchId,
                props.userId
              )
            }
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <p className='cursor-pointer border-4 border-dashed  text-center text-lg my-4'>
                    <span className='text-primary'> click </span> to select
                    files
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      </div>
    </div>
  );
};

export default NotSubmitted;
