import React from 'react';
import { differenceInHours } from 'date-fns';
const classNames = require('classnames');

function Schedules(props) {
  const HOURS_AFTER_EVENT_IS_BLOCKED = 3;
  const isPastEvent =
    differenceInHours(new Date(), new Date(props.dateTime)) >=
    HOURS_AFTER_EVENT_IS_BLOCKED;
  return (
    <div>
      <div className='bg-gray-100 p-4'>
        <div className='grid grid-cols-1 md:grid-cols-4 mb-10'>
          <div className='bg-white '>
            <img src={props.imgsrc} className=' m-2 h-32' alt='Meeting' />
          </div>
          <div className='col-span-2 p-2 bg-white md:-ml-6 pt-4'>
            <h2 className='text-sm text-gray-700'>
              <b>{props.heading}</b>
            </h2>
            <p className='text-xs mt-1 text-gray-700'> {props.about}</p>
          </div>
          <div className='p-2  bg-white flex flex-col items-center sm:justify-center'>
            <h2 className='text-xs md:text-sm mb-1'>
              <b>{props.date}</b>
            </h2>
            <p className='text-xs md:text-sm m-2 mb-4'>{props.time} </p>
            <a
              href={props.recordingUrl || (isPastEvent ? '' : props.joinlink)}
              target='_blank'
              rel='noreferrer noopener'
              className={classNames(
                'rounded-full text-xs md:text-sm px-4 md:px-8 py-2 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105',
                {
                  'bg-gray-300 pointer-events-none':
                    isPastEvent && !props.recordingUrl,
                },
                {
                  'bg-primary-light hover:bg-primary':
                    isPastEvent && props.recordingUrl,
                },
                {
                  'bg-primary-light hover:bg-primary':
                    props.joinlink && !isPastEvent,
                }
              )}
            >
              {props.recordingUrl ? 'View' : 'Join'}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schedules;
