import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
// import { Link } from 'react-scroll';

import {
  useEnrollCapstoneMutation,
  useGetUserSuperCapstonesQuery,
} from '../superCapstoneSlice';
import { useGetUserQuery } from 'components/profile/userSlice';
import { partnerIds } from 'config/common';

interface AllProps {
  data: any;
  // When the user has already enrolled
  enrollData: any;
  setUserProgress: any;
}

const SuperEnrolNow = (props: AllProps) => {
  const { isFetching: isFetchingUserData, data: userData } = useGetUserQuery();
  //   if (userData) {
  //     setUserParterId(userData.partnerId._id);
  //   }

  const { isFetching: isFetchingUserCapData, data: userCapData } =
    useGetUserSuperCapstonesQuery({});

  const setUserProgress = props.setUserProgress;
  const [enrolLoading, setEnrolLoading] = useState(false);
  const capstoneId = props.data._id;

  const [enroll] = useEnrollCapstoneMutation();
  // const { data: enrollments = [] } = useGetMyCapstonesQuery({
  //   enrollmentType: 'Capstone',
  // });
  const enrollCapstone = () => {
    if (userData.partnerId._id === partnerIds.nirman) {
      // popup
      setIsOpen(true);
      return;
    }

    setEnrolLoading(true);
    enroll({ superCapstoneId: capstoneId })
      .unwrap()
      .finally(() => {
        setEnrolLoading(false);
      });
  };
  const [showPopup, setShowPopup] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // Assuming every task will always have 1 quiz
  const totalTasks = props.data?.tasks?.length;
  const totalQuiz = props.data?.tasks?.length;
  const tasksDone = props.enrollData?.tasks?.length;
  let quizDone = 0;
  if (props.enrollData !== null) {
    for (const quiz of props.enrollData?.quiz) {
      if (quiz.percentage >= 40 || quiz.totalAttempt > 2) {
        quizDone += 1;
      }
    }
  }
  const progress = Math.min(
    Math.ceil(((tasksDone + quizDone) / (totalTasks + totalQuiz)) * 100),
    100
  );

  const enrollmentDisabled = (): boolean => {
    if (userData.partnerId._id === partnerIds.nirman) {
      if (userCapData.length !== 0) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    setUserProgress(progress);
  }, [progress, setUserProgress]);

  let buttonText = 'Enrol Now';
  let buttonIsDisabled = false;

  if (!isFetchingUserCapData) {
    if (enrollmentDisabled()) {
      //   buttonText = 'Cannot Enrol';
      buttonIsDisabled = true;
    }
  }

  if (isFetchingUserCapData || isFetchingUserData || enrolLoading) {
    buttonIsDisabled = true;
    buttonText = 'Loading...';
  }

  //   const taskDone = props.enrollData?.capstoneSubmissions?.length || 0;
  //   const totalTask = props.data?.tasks?.length || 4;
  //   const progress = Math.min(Math.ceil((taskDone * 100) / totalTask), 100);

  return (
    <div className='mt-2 py-5 px-6 border-t-2 border-gray-200'>
      <div className='md:flex justify-end'>
        <div className='w-full flex justify-end'>
          {isFetchingUserCapData ||
          isFetchingUserData ||
          props.enrollData === null ? (
            <button
              onClick={() => {
                enrollCapstone();
              }}
              className={`bg-primary text-white text-lg px-2 md:px-12 py-2 rounded-lg ${
                enrollmentDisabled()
                  ? 'bg-stone-600 cursor-pointer select-none relative group'
                  : 'pointer-events-auto'
              }`}
              disabled={buttonIsDisabled}>
              <span className='absolute transform -translate-x-20 w-52 -translate-y-16 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-sm rounded py-1 px-2 transition-opacity duration-300'>
                The maximum enrollment limit for the capstone reached.
              </span>
              {buttonText}
            </button>
          ) : (
            <div className='relative w-1/4 flex rounded h-10 text-center bg-gray-700 -z-20'>
              {progress > 0 && (
                <div
                  className={`absolute rounded bg-primary -z-10 h-full`}
                  style={{ width: `${progress}%` }}></div>
              )}
              <span className='z-10 font-medium flex items-center justify-center w-full text-white'>
                {progress}%
              </span>
            </div>
          )}
        </div>
      </div>
      <Transition appear show={showPopup} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => {
            setShowPopup(false);
          }}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='rounded-md bg-blue-50 p-4'>
                    <div className='flex'>
                      <div className='flex-shrink-0'>
                        <InformationCircleIcon
                          className='h-5 w-5 text-blue-400'
                          aria-hidden='true'
                        />
                      </div>
                      <div className='ml-3 flex-1 md:flex md:justify-between'>
                        <p className='text-sm text-blue-700'>
                          You have already enrolled in a capstone
                        </p>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        as='div'
        className='relative z-10'>
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <div>
              <Dialog.Panel className='rounded-lg bg-white m-4 p-4 border-2 border-gray-200 shadow-md'>
                <div className='text-left w-64 sm:w-[32rem]'>
                  <div className='font-bold'>
                    {`Are you sure you want to enroll in the '${props.data.companyId?.name} : ${props.data.title}' capstone?`}
                  </div>
                  <p className='mt-4'>
                    Please note that you can enroll in only one capstone
                    project.
                  </p>
                  <div className='mt-6 px-4 py-3 flex justify-end'>
                    <button
                      type='button'
                      className='w-full justify-center rounded-md bg-white px-4 py-2 text-base font-medium text-primary border-2 border-primary shadow-sm hover:bg-gray-200 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setIsOpen(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='w-full justify-center rounded-md bg-primary text-white px-4 py-2 text-base font-medium shadow-sm hover:bg-primary-light focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setEnrolLoading(true);
                        enroll({ superCapstoneId: capstoneId })
                          .unwrap()
                          .finally(() => {
                            setEnrolLoading(false);
                          });
                        setIsOpen(false);
                      }}>
                      Confirm Enrollment
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default SuperEnrolNow;
