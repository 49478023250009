import React from 'react';
import VideoArea from './VideoArea';
import { FaLinkedin } from 'react-icons/fa';

interface allProps {
  data: any;
}

const VideoSection = (props: allProps) => {
  const mentor = props.data.mentorDetail;
  return (
    <>
      {mentor?.name && props.data.introductoryVideo && (
        <div className='p-4'>
          <div className='grid grid-cols-6'>
            <div className='col-span-6 sm:col-span-4'>
              <div className='md:mx-6 lg:mx-12 xl:mx-20'>
                <VideoArea
                  videoUrl={props.data.introductoryVideo}
                  videoBackground={props.data.videoImage}
                />
              </div>
            </div>
            <div className='col-span-6 sm:col-span-2'>
              <div className='sm:border-l-2 sm:border-primary'>
                <div className='font-semibold ml-2 mt-2'>
                  Hear From the Company
                </div>
                <div className='flex'>
                  <img
                    className='inline object-cover w-16 h-16 my-3 mx-2 rounded-full '
                    alt='avatar'
                    src={mentor?.image}
                  />
                  <div className='mt-5'>
                    <div className='flex text-primary'>
                      <div className='mx-2'>{mentor?.name}</div>
                      <a
                        href={mentor?.linkedin}
                        target='_blank'
                        rel='noreferrer noopener'
                      >
                        <FaLinkedin className='mt-1' />
                      </a>
                    </div>
                    <div className='text-primary text-sm mx-2'>
                      {mentor?.designation}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VideoSection;
