import React from 'react';
const cardData = [
  {
    id: 1,
    type: 'C',
    department: 'Coach',
    heading: 'Write to your Coach',
    text: (
      <div className='px-8   mt-2 text-sm '>
        <ul className='list-disc'>
          <li>Questions regarding assignments & tech project tasks</li>
          <li>Questions regarding coaching sessions</li>
          <li>Assistance to solve challenges</li>
        </ul>
      </div>
    ),
  },
  {
    id: 2,
    type: 'M',
    department: 'Mentor',
    heading: 'Write to your Mentor',
    text: (
      <div className='px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>Company Related Questions</li>
          <li>Company Information Requirements</li>
          <li>Questions to the Mentor</li>
        </ul>
      </div>
    ),
  },
  {
    id: 3,
    type: 'B',
    department: 'Belong',
    heading: 'Write to Belong',
    text: (
      <div className='px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>Event Recordings & Schedules</li>
          <li>Assignment Timeline Related Queries</li>
          <li>Admin Related Doubts</li>
        </ul>
      </div>
    ),
  },
];
const SelectQuery = ({ SetIsCreateNewQuery }: { SetIsCreateNewQuery: any }) => {
  return (
    <div className='mt-6 md:mt-10'>
      <p className='font-semibold'>Select the type of your Query</p>
      <div className='mt-4 md:mt-8'>
        <div className='grid grid-cols-1 lg:grid-cols-3 gap-4 md:w-3/4  lg:w-[95%] mx-auto lg:gap-8 mt-6'>
          {cardData.map((data) => (
            <div
              key={data.id}
              onClick={() => SetIsCreateNewQuery(data.department)}
              className='border rounded p-2 md:p-4 bg-gray-50 cursor-pointer '
            >
              <div className='flex space-x-2 md:space-x-4 items-center'>
                <div className='flex-none font-semibold bg-primary-light text-lg md:text-2xl text-white h-12  w-12   flex items-center my-auto justify-center rounded-full'>
                  {data.type}
                </div>
                <p className='font-semibold'>{data.heading}</p>
              </div>
              <div className='mt-4 text-textColor-lighter'>{data.text}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SelectQuery;
