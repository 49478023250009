import { useQuery } from 'react-query';
import { axiosApi } from '../config/Axios';

function fetchSkills(arg: any) {
  const { queryKey } = arg;
  const areaId = queryKey[1];
  const params = {};
  if (areaId) {
    Object.assign(params, { areaId });
  }
  return axiosApi.get('/skills/eligible-skills', { params });
}
const useEligibleSkillsData = (areaId: any) => {
  return useQuery(['fetchEligibleSkills', areaId], fetchSkills, {
    select: (data: any) => {
      const transformedData = data.data.data.map((skill: any) => {
        return { id: skill._id, title: skill.title };
      });
      return transformedData;
    },
  });
};
export default useEligibleSkillsData;
