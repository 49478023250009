import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import {
  eachDayOfInterval,
  endOfMonth,
  startOfMonth,
  format,
  endOfWeek,
  isToday,
  isSameMonth,
  startOfToday,
  isEqual,
  isSameWeek,
  parse,
  add,
  getDay,
  startOfWeek,
  isSameDay,
} from 'date-fns';
import { useState } from 'react';
import {
  getMonthEvents,
  getMonthTasks,
  isEventOnSameDay,
  isTaskOnSameDay,
} from '../helper';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function Calendar({
  startDate,
  endDate,
  allEvents,
  allTasks,
}: {
  startDate: string;
  endDate: string;
  allEvents: any;
  allTasks: any;
}) {
  let today = startOfToday();
  const start = startOfMonth(new Date(startDate));

  const [selectedDay, setSelectedDay] = useState(today);
  const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'));
  let firstDayOfCurrentMonth = parse(currentMonth, 'MMM-yyyy', new Date());

  const allDays = eachDayOfInterval({
    start: startOfWeek(firstDayOfCurrentMonth),
    end: endOfWeek(endOfMonth(firstDayOfCurrentMonth)),
  });

  function previousMonth() {
    let firstDayOfNextMonth = add(firstDayOfCurrentMonth, { months: -1 });
    setCurrentMonth(format(firstDayOfNextMonth, 'MMM-yyyy'));
  }

  function nextMonth() {
    let firstDayOfNextMonth = add(firstDayOfCurrentMonth, { months: 1 });
    setCurrentMonth(format(firstDayOfNextMonth, 'MMM-yyyy'));
  }

  const eventsOfMonth = getMonthEvents(allEvents, firstDayOfCurrentMonth);

  const tasksOfMonth = getMonthTasks(allTasks, firstDayOfCurrentMonth);

  let colStartClasses = [
    '',
    'col-start-2',
    'col-start-3',
    'col-start-4',
    'col-start-5',
    'col-start-6',
    'col-start-7',
  ];
  return (
    <div className=' md:divide-x md:divide-gray-200'>
      <div className=''>
        <div className='flex items-center'>
          <h2 className='ml-4 flex-auto font-semibold text-primary'>
            {format(firstDayOfCurrentMonth, 'MMMM yyyy')}
          </h2>
          <button
            type='button'
            className='-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <span className='sr-only'>Previous month</span>

            <ChevronLeftIcon
              className='h-5 w-5'
              aria-hidden='true'
              onClick={previousMonth}
            />
          </button>
          <button
            type='button'
            className='-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500'
          >
            <span className='sr-only'>Next month</span>
            <ChevronRightIcon
              className='h-5 w-5'
              aria-hidden='true'
              onClick={nextMonth}
            />
          </button>
        </div>
        <div className='mt-4 grid grid-cols-7 font-bold text-center text-xs leading-6 '>
          <div>Su</div>
          <div>Mo</div>
          <div>Tu</div>
          <div>We</div>
          <div>Th</div>
          <div>Fr</div>
          <div>Sa</div>
        </div>
        <div className='mt-2 grid grid-cols-7 text-sm  border-r border-t'>
          {allDays.map((day, dayIdx) => (
            <>
              <div
                key={day.toString()}
                className={classNames(
                  dayIdx === 0 && colStartClasses[getDay(day)],
                  'pt-2  border-l',
                  isSameWeek(day, today) && 'bg-primary-lighter',
                  isEqual(day, selectedDay) && isToday(day) && 'bg-blue-600'
                )}
              >
                <button
                  type='button'
                  onClick={() => setSelectedDay(day)}
                  className={classNames(
                    isEqual(day, selectedDay) && 'text-white',
                    !isEqual(day, selectedDay) &&
                      isToday(day) &&
                      'text-indigo-600',
                    !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      isSameMonth(day, today) &&
                      'text-gray-900',
                    !isEqual(day, selectedDay) &&
                      !isToday(day) &&
                      !isSameMonth(day, today) &&
                      'text-gray-400 bg-gray-100',
                    // isEqual(day, selectedDay) && isToday(day) && 'bg-blue-600',
                    isEqual(day, selectedDay) && !isToday(day) && 'bg-gray-900',
                    !isEqual(day, selectedDay) && 'hover:bg-gray-200',
                    (isEqual(day, selectedDay) || isToday(day)) &&
                      'font-semibold',
                    // isSameWeek(day, today) && 'bg-primary-lighter',
                    'mx-auto flex h-8 w-8 items-center justify-center',
                    isEventOnSameDay(eventsOfMonth, day) && 'bg-red-300',
                    isTaskOnSameDay(tasksOfMonth, day) && 'bg-yellow-400 '

                    // day.isEvent && 'bg-red-300 rounded-full',
                    // day.isTask && 'bg-yellow-400 rounded-full'
                  )}
                >
                  {isSameDay(new Date(startDate), day) && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-3 h-3 text-primary'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z'
                      />
                    </svg>
                  )}
                  {isSameDay(new Date(endDate), day) && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth='1.5'
                      stroke='currentColor'
                      className='w-3 h-3 text-primary'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M4.26 10.147a60.436 60.436 0 00-.491 6.347A48.627 48.627 0 0112 20.904a48.627 48.627 0 018.232-4.41 60.46 60.46 0 00-.491-6.347m-15.482 0a50.57 50.57 0 00-2.658-.813A59.905 59.905 0 0112 3.493a59.902 59.902 0 0110.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.697 50.697 0 0112 13.489a50.702 50.702 0 017.74-3.342M6.75 15a.75.75 0 100-1.5.75.75 0 000 1.5zm0 0v-3.675A55.378 55.378 0 0112 8.443m-7.007 11.55A5.981 5.981 0 006.75 15.75v-1.5'
                      />
                    </svg>
                  )}
                  <time dateTime={format(start, 'yyyy-MM-dd')}>
                    {format(day, 'd')}
                  </time>
                </button>
                <hr />
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
