import { useState } from 'react';
import classNames from 'classnames';
import AllQueries from './all-query/AllQueries';
import NewQuery from './new-query/NewQuery';

const NavigationBar = ({ data, batchId, coachId, mentorId }: any) => {
  const [isAllQuery, SetIsAllQuery] = useState(true);
  return (
    <>
      <div className='flex justify-between items-baseline mt-6 md:mt-10 '>
        <div className='flex space-x-6 sm:space-x-10 border-b-2 font-semibold text-textColor-light'>
          <p
            onClick={() => SetIsAllQuery(true)}
            className={classNames('cursor-pointer', {
              'underline  underline-offset-4 text-primary': isAllQuery,
            })}
          >
            All Queries
          </p>
          <p
            onClick={() => SetIsAllQuery(false)}
            className={classNames('cursor-pointer', {
              'underline underline-offset-4 text-primary': !isAllQuery,
            })}
          >
            New Query
          </p>
        </div>
      </div>
      {isAllQuery ? (
        <AllQueries data={data} />
      ) : (
        <NewQuery batchId={batchId} coachId={coachId} mentorId={mentorId} />
      )}
    </>
  );
};

export default NavigationBar;
