import { useNavigate } from 'react-router-dom';

interface allProps {
  isLocked: boolean;
  isCompleted: boolean;
  setSelectedTask: Function;
  taskDetail: any;
  setCount: Function;
  superCapstoneId: any;
}

const Task = (props: allProps) => {
  const taskDetail = props.taskDetail;
  const navigate = useNavigate();

  return (
    <tr
      onClick={() => {
        props.setSelectedTask(taskDetail);
        props.setCount();
        navigate(
          `/supercapstone/${
            props.superCapstoneId
          }/${taskDetail.taskNo.toString()}`
        );
      }}
      className={`border-b border-slate-300 items-center cursor-pointer font-medium 
        ${props.isLocked && 'pointer-events-none'}`}>
      <td className={`border-l border-slate-300 md:px-4 py-2`}>
        {taskDetail.title}
      </td>
      <td className='border-l border-r border-slate-300 md:px-4 text-center py-2'>
        {taskDetail.time}
      </td>
      <td className=' flex flex-wrap gap-2 lg:gap-4 items-center  md:px-4 py-3'>
        {taskDetail.skillId.map((skill: any) => skill.title).join(', ')}
      </td>
      <td className='border-l border-r border-slate-300 text-left md:px-6 py-2'>
        {!props.isLocked ? (
          !props.isCompleted ? (
            <p className='bg-yellow-200 w-fit py-1 px-3 rounded-full font-medium'>
              Ongoing
            </p>
          ) : (
            <p className='bg-green-200 w-fit py-1 px-3 rounded-full font-medium'>
              Completed
            </p>
          )
        ) : (
          <p className='bg-red-200 w-fit py-1 px-3 rounded-full font-medium'>
            Locked
          </p>
        )}
      </td>
    </tr>
  );
};

export default Task;
