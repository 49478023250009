import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import React from 'react';

const EventPopUp = ({ eventLink }: any) => {
  const { basicDetails } = useBasicDetailsContext();
  //   console.log(basicDetails);
  return (
    <div className='text-center mb-4'>
      <div className='w-80 mb-8 '>
        Please note that to receive attendance for the session, you must join
        the Zoom session using your registered email ID {'('}
        {basicDetails?.useremail}
        {')'} with us.
      </div>
      <a
        href={eventLink}
        target='_blank'
        rel='noreferrer noopener'
        className='border border-primary bg-white rounded-lg px-6 py-2 w-1/2 md:w-full mx-auto'>
        Continue to the link
      </a>
    </div>
  );
};

export default EventPopUp;
