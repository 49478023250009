const DomainQuizHeader = ({ domainScore }: any) => {
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670997004/new%20b2b/mingcute_science-line_doydfj.svg'
          alt='logo'
        />
        <p className='text-sm font-semibold'>
          Your Domain and Skill Expertise Evaluation
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Domain Knowledge{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Skill Level Score{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Quiz Performance-{' '}
          <span className='text-primary'>{domainScore} %</span>
        </p>
      </div>
    </div>
  );
};

export default DomainQuizHeader;
