import { createContext, useContext } from 'react';

const basicDetailsState: any = {};
const BasicDetailsContextObject: {
  basicDetails: any;
  dispatch: Function;
} = {
  basicDetails: basicDetailsState,
  dispatch: () => {},
};

export const BasicDetailsContext = createContext(BasicDetailsContextObject);

const useBasicDetailsContext = () => {
  return useContext(BasicDetailsContext);
};

export default useBasicDetailsContext;
