import React from 'react';
import SuperCard from './SuperCard';

interface allProps {
  data: any;
  isFetching: any;
}

const AllSuperCapstone: React.FC<allProps> = ({ data, isFetching }) => {
  // const isOngoing = (cap: any): boolean => {

  // }

  return (
    <div className='space-y-4'>
      {data?.map((cap: any, idx: number) => (
        <SuperCard
          key={cap._id}
          _id={cap._id}
          title={cap.title}
          level={cap.level}
          logo={cap.companyId?.logoUrl}
          country={cap.companyId?.country}
          industry={cap.companyId?.industryId?.title}
          skills={cap.skillId}
          domain={cap.domainId?.title}
          tasks={cap.tasks || []}
          capstoneSubmissions={null}
          createdAt={cap.createdAt}
          capstoneAssessments={cap.capstoneAssessments || null}
          buttonText='View Details'
          capStatus='all'
          buttonData={{
            date: new Date(cap.createdAt),
            dayCount: 0,
            progress: 0,
            tasksDoneStr: '0/4 tasks',
          }}
        />
      ))}
    </div>
  );
};

export default AllSuperCapstone;
