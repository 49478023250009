import React from 'react';
import { AboutDataType, CompanyMentorType } from '../types';
import CompanyMentorSection from './layout/CompanyMentorSection';
import CompanyTextSection from './layout/CompanyTextSection';

type CompanyTextMentor = {
  aboutData: AboutDataType;
  mentorData: CompanyMentorType;
};
const AboutCompany = ({ aboutData, mentorData }: CompanyTextMentor) => {
  return (
    <div className='grid grid-cols-12 gap-6  mt-4 lg:mt-10'>
      <div className='col-span-12 xl:col-span-6'>
        <CompanyTextSection {...aboutData} />
      </div>
      <div className='col-span-12 xl:col-span-6 lg:w-3/4 md:mx-auto my-auto'>
        <CompanyMentorSection {...mentorData} />
      </div>
    </div>
  );
};

export default AboutCompany;
