import React, { useState } from 'react';
import { EventCardType } from '../types';
import classNames from 'classnames';
import { useAddPollingMutation } from '../userBatchSlice';
import { format } from 'date-fns';

type CoachEventCardData = {
  eventData: EventCardType;
  setisAlreadyRegistered: any;
  setIsOpen: any;
};
const Registeration = (props: CoachEventCardData) => {
  const event = props.eventData;
  const [yesForEvent, setYesForEvent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const [submitPoll] = useAddPollingMutation();
  function submitPollkFn() {
    setLoading(true);
    setError('');
    submitPoll({
      eventId: event.id,
    })
      .unwrap()
      .then((res: any) => {
        props.setisAlreadyRegistered(true);
        props.setIsOpen(false);
      })
      .catch((res: any) => {
        setLoading(false);
        setError('Please Try after some Time');
      });
  }

  return (
    <div key={event.id} className='bg-white border rounded p-4 md:p-6'>
      <p className='font-semibold text-sm sm:text-base text-black text-start'>
        Reserve Your Seat For the Live Event
      </p>
      <div className='border rounded mt-6 p-4 md:flex md:space-x-12'>
        <div className='space-y-2 text-start'>
          <p className='font-semibold text-sm '>{event.title}</p>
          <p>
            Schedule:{' '}
            {event.dateTime &&
              format(new Date(event.dateTime), 'do LLLL yyyy p')}
          </p>
        </div>
        <div>
          {/* <div className='text-white w-3/4 sm:w-auto mt-2 sm:mt-0 mx-auto bg-primary rounded-2xl px-2 md:px-4 py-1'>
            <p>Seats Left 10/20</p>
          </div> */}
        </div>
      </div>
      <p className='text-start text-black my-4 font-normal'>
        Please confirm your participation
      </p>
      <div className='flex space-x-4 mt-0 text-black'>
        <div className='flex items-center space-x-2'>
          <input
            id='yes-btn'
            type='radio'
            checked={yesForEvent}
            name='option'
            onChange={() => setYesForEvent(true)}
          />
          <label htmlFor='yes-btn'>Yes</label>
        </div>
        <div className='flex items-center space-x-2'>
          <input
            id='no-btn'
            type='radio'
            checked={!yesForEvent}
            name='option'
            onChange={() => setYesForEvent(false)}
          />
          <label htmlFor='no-btn'>No</label>
        </div>
      </div>
      <button
        onClick={() => submitPollkFn()}
        disabled={!yesForEvent || loading}
        className={classNames(
          'mt-4 md:mt-6 flex justify-start bg-primary text-white px-4 md:px-6 py-2 rounded-lg',
          {
            'bg-gray-400 ': !yesForEvent,
          }
        )}
      >
        {loading ? 'Loading' : 'Register Now'}
      </button>
      {error && <p>{error} </p>}
    </div>
  );
};

export default Registeration;
