import React from 'react';
const cardData = [
  {
    id: 1,
    question: 'What?',
    text: 'Domain- Skill based quiz to validate your skill level and domain knowledge',
  },
  {
    id: 2,
    question: 'Why?',
    text: 'It forms a part of your Belong Score which helps you build your industry credibility with the employers',
  },
  {
    id: 3,
    question: 'How?',
    text: 'Choose 4 to 8 skills from the recommendations based on your domain and take the test',
  },
];

const DomainQuizDiscussions = () => {
  return (
    <div className='mt-4 md:mt-10'>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4  xl:gap-12  mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2  border rounded-lg p-2 md:p-4 '
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center justify-center my-auto  rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2'>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DomainQuizDiscussions;
