import { useState } from 'react';

import MyCapToggleBar from './MyCapToggleBar';
import Ongoing from './Ongoing';
import Completed from './Completed';
import { useGetMyCapstonesQuery } from './capstoneSlice';
import Loading from 'components/global/layout/Loading';

const MyCapstone = () => {
  const [isCapOngoing, setIsCapOngoing] = useState(true);

  const { isSuccess, isLoading, isError, data } = useGetMyCapstonesQuery({
    enrollmentType: 'Capstone',
  });

  let completedCapstones = [];
  let onGoingCapstones = [];
  if (data) {
    const completed = data.filter((cap: any) => {
      const condition1 =
        cap.capstone.tasks.length === cap.capstoneSubmissions.length;
      const condition2 = cap?.capstoneAssessments?.percentage >= 50;
      return condition1 && condition2;
    });

    const ongoing = data.filter((cap: any) => {
      const condition1 =
        cap.capstone.tasks.length === cap.capstoneSubmissions.length;
      const condition2 = cap?.capstoneAssessments?.percentage >= 50;
      return !(condition1 && condition2);
    });

    completedCapstones = completed;
    onGoingCapstones = ongoing;
  }

  return (
    <>
      {isLoading && (
        <div>
          <Loading />
        </div>
      )}
      {isError && <div>Error fetching data</div>}
      {isSuccess && (
        <>
          <MyCapToggleBar
            isCapOngoing={isCapOngoing}
            setIsCapOngoing={setIsCapOngoing}
            completed={completedCapstones.length}
            ongoing={onGoingCapstones.length}
          />
          {isCapOngoing ? (
            <Ongoing data={onGoingCapstones} />
          ) : (
            <Completed data={completedCapstones} />
          )}
        </>
      )}{' '}
    </>
  );
};

export default MyCapstone;
