import React from 'react';
import { Element } from 'react-scroll';
import Task from './Task';

interface allProps {
  data: any;
  setSelectedTask: Function;
  enrollData: any;
  setCount: Function;
}

const Tasks = (props: allProps) => {
  const tasks = props.data.tasks || [];
  return (
    <Element name='tasks-section' className='element'>
      {tasks.length > 0 && (
        <div className='p-1 sm:p-4 mb-8'>
          <div className='font-semibold lg:text-xl'>Tasks & Skills</div>
          <div className='mt-4 relative'>
            {tasks.map((task: any, index: number) => {
              return (
                <Task
                  data={props.data}
                  taskDetail={task}
                  key={index}
                  index={index}
                  setSelectedTask={props.setSelectedTask}
                  enrollData={props.enrollData}
                  setCount={props.setCount}
                />
              );
            })}
            {props.data.taskDependency && (
              <div className='absolute  border-r-4  border-primary border-dashed h-3/4 md:h-4/5 top-10 left-6 sm:left-8'></div>
            )}
          </div>
        </div>
      )}
    </Element>
  );
};

export default Tasks;
// Element name='tasks-section' or id='tasks-section'  className='element'
