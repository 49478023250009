import React from 'react';

const TaskDetails = (props) => {
  return (
    <div className='col-span-3'>
      <div className='bg-white p-4 mt-3 border-b-2 border-gray-200'>
        <h2>
          <b>{props.heading} </b>
        </h2>
        <p className='text-xs pt-2'>{props.about}</p>
      </div>
      <div className='grid grid-cols-2'>
        <div className='flex bg-white flex-col md:flex-row sm:flex-wrap md:flex-nowrap p-2 border-r-2 border-gray-200 items-center'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354234/uploaded_resources/Attachment_Icon_dvpubr.png'
            className=' m-2 h-6 w-6 object-cover mx-auto md:mx-2 md:w-12 md:h-12'
            alt='Attachment'
          />
          <a href={props.fileLink} target='_blank' rel='noreferrer noopener'>
            <p className='text-primary flex justify-center md:items-center items-center text-xs md:text-sm'>
              {props.aboutpdf}
            </p>
          </a>
        </div>
        <div className='flex bg-white flex-col md:flex-row sm:flex-wrap md:flex-nowrap'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354261/uploaded_resources/Deadline_icon_b9flwe.png'
            className='m-2 h-6 w-6 object-cover mx-auto md:mx-2 md:w-12 md:h-12 mt-4 md:mt-3'
            alt='Attachment'
          />
          <p className='text-primary flex justify-center md:items-center items-center text-xs md:text-sm'>
            Due Date:
          </p>
          <h2 className=' m-2 text-xs flex justify-center md:items-center items-center md:text-sm'>
            <b>{props.duedate} </b>
          </h2>
        </div>
      </div>
    </div>
  );
};

export default TaskDetails;
