import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { format } from 'date-fns';

const Projects = ({ data }: { data: any }) => {
  const projectPortfolio = data?.projectPortfolio || [];
  return (
    <div className='px-6 lg:pr-16 py-6  border-b-2 lg:border-r border-primary min-h-[270px]'>
      <div className='flex space-x-2 items-center'>
        <div className='border-r-2 pr-4 font-semibold text-xl'>
          Portfolios & Projects
        </div>
        <p className='text-sm'>
          {projectPortfolio.length > 0 && projectPortfolio.length}{' '}
        </p>
      </div>

      {projectPortfolio.map((project: any, index: any) => {
        return (
          <div
            key={index}
            className='relative h-32 flex mx-2 sm:mx-0 md:mr-auto space-x-3 lg:space-x-8 items-center px-4 py-2 my-6 border border-textColor-landing rounded-xl'
          >
            <svg
              width='51'
              height='52'
              viewBox='0 0 51 52'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M0.0958252 51.0254V45.3879L4.77187 40.7816V51.0254H0.0958252ZM11.6114 51.0254V34.3879L16.3573 29.7816V51.0254H11.6114ZM23.1271 51.0254V29.7816L27.8729 34.4566V51.0254H23.1271ZM34.7125 51.0254V34.3879L39.3885 29.7816V51.0254H34.7125ZM46.2281 51.0254V23.3191L50.9042 18.7129V51.0254H46.2281ZM0.0958252 35.1441V28.4754L19.9167 9.08789L31.0833 20.0879L50.9042 0.494141V7.09414L31.0833 26.7566L19.9167 15.7566L0.0958252 35.1441Z'
                fill='#10ABB7'
              />
            </svg>
            <div>
              <p className='font-semibold  mb-2'>{project.projectName} </p>
              <p className='text-sm mb-1'>{project.client}</p>
              <p className='text-xs  mb-2'>
                {project.startDate &&
                  format(new Date(project.startDate), 'MMM u')}
                -{project.endDate && format(new Date(project.endDate), 'MMM u')}
              </p>
              <div className='flex gap-8'>
                {project.projectUrl && (
                  <a
                    href={project.projectUrl}
                    target='_blank'
                    rel='noreferrer noopener'
                    className='px-2 py-1 font-semibold text-xs underline text-blue-500'
                  >
                    View Details
                  </a>
                )}

                <Popover>
                  {({ open }) => (
                    <>
                      <Popover.Button className='underline text-gray-500 hover:text-gray-900 text-sm'>
                        <span> Show Credentials</span>
                      </Popover.Button>
                      <Transition
                        as={Fragment}
                        enter='transition ease-out duration-200'
                        enterFrom='opacity-0 translate-y-1'
                        enterTo='opacity-100 translate-y-0'
                        leave='transition ease-in duration-150'
                        leaveFrom='opacity-100 translate-y-0'
                        leaveTo='opacity-0 translate-y-1'
                      >
                        <Popover.Panel className='absolute -top-[10%] left-1/2 z-10 mt-3  max-w-sm -translate-x-1/2 transform lg:max-w-3xl'>
                          <p className='rounded-lg shadow-lg ring-1 ring-black ring-opacity-10 p-2 md:p-4 bg-white'>
                            {project.description}
                          </p>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Projects;
