import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { TextArea, TextInput } from './FormElements';
import * as Yup from 'yup';
import { useCreateTicketMutation } from '../querySlice';
import { axiosUpload } from 'config/Axios';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';

const CreateQuery = ({
  SetIsCreateNewQuery,
  batchId,
  coachId,
  mentorId,
  department,
}: any) => {
  function getAssignee() {
    if (department === 'Coach') {
      return coachId;
    }
    if (department === 'Mentor') {
      return mentorId;
    }
    return null;
  }
  const [submitTicket] = useCreateTicketMutation();
  const [attachments, setAttachments] = useState([]);
  const [fileloading, setFileloading] = useState('');

  async function uploadFile(files: any) {
    setFileloading('file uploading');
    if (attachments.length + files.length > 3) {
      alert('you can upload maximum 3 file');
    } else {
      const fileExtension = files.name
        .substring(files.name.lastIndexOf('.') + 1)
        .toLowerCase();
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        setFileloading('file uploaded');
        setAttachments((arr) => arr.concat(result.data.publicUrl));
      }
    }
  }

  return (
    <div className='mt-6 md:mt-10'>
      <div className='border rounded p-4 md:p-6 space-y-4 md:space-y-10'>
        <div className='flex justify-between'>
          <p className='font-semibold'>Create the Query</p>
          <p onClick={() => SetIsCreateNewQuery('')} className='cursor-pointer'>
            X
          </p>
        </div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            department: department,
            assignee: getAssignee(),
            batch: batchId,
            attachments: [],
            subject: '',
            description: '',
          }}
          validationSchema={Yup.object({
            subject: Yup.string()
              .min(2, 'Must be 3 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required('Please enter a Subject'),
            description: Yup.string()
              .min(2, 'Must be 3 characters or more')
              .max(1000, 'Must be 1000 characters or less')
              .required('Please enter a Description'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.attachments = attachments;
            submitTicket(values)
              .unwrap()
              .then((res: any) => {
                SetIsCreateNewQuery('');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className=' rounded-xl'>
              <div className=''>
                <div className='mb-4'>
                  <TextInput
                    label='Subject'
                    name='subject'
                    type='text'
                    placeholder='Type the Subject of the message'
                  />
                </div>
                <div className='mb-4'>
                  <TextArea
                    label='Message'
                    name='description'
                    type='text'
                    placeholder='Type the Body of the message (max 1000 characters)'
                  />
                </div>
                <div className='grid grid-cols-12 flex items-center mb-4'>
                  <label className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'>
                    Assigned to
                  </label>
                  <p className='col-span-10 bg-white rounded shadow border focus:outline-none w-full px-4 py-1 text-gray-600 text-sm font-semibold'>
                    {department}
                  </p>
                </div>
                <div className='grid grid-cols-12 space-x-4 flex'>
                  <label className='col-span-2 flex flex-col text-gray-600 font-semibold text-xs md:text-base'>
                    Attachment
                    <span>(if any)</span>
                  </label>
                  <div className='col-span-10 flex flex-col'>
                    <div className='rounded-lg overflow-hidden'>
                      <div className='md:flex'>
                        <div className='w-full'>
                          <div className='relative border-dotted h-20 rounded-lg border-dashed font-semibold text-gray-600 border-2 border-primary bg-white flex justify-center items-center hover:bg-primary hover:text-white'>
                            <div className='absolute'>
                              <div className='flex flex-col items-center'>
                                {' '}
                                <i className='fa fa-folder-open fa-4x text-blue-700'></i>{' '}
                                <span className='block'>
                                  Attach you image(jpeg/png) files here
                                </span>{' '}
                              </div>
                            </div>{' '}
                            <input
                              type='file'
                              accept='image/png, image/jpeg'
                              onChange={(e: any) => {
                                const file = e.target.files[0];
                                uploadFile(file);
                              }}
                              className='h-full w-full opacity-0'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-4'>
                {attachments.map((imageUrl, index) => {
                  return (
                    <div
                      key={index}
                      className='flex flex-col items-center mt-8'
                    >
                      <img
                        src={`${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`}
                        alt='img'
                        className='rounded object-cover'
                      />
                      <button
                        type='button'
                        onClick={() =>
                          setAttachments((arr) =>
                            arr.filter((image) => {
                              return image !== imageUrl;
                            })
                          )
                        }
                        className='text-sm bg-red-400 px-4 py-1 rounded text-gray-50 hover:bg-red-300 mt-2'
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className='flex justify-end text-center mt-8'>
                {fileloading !== 'file uploading' && (
                  <button
                    type='submit'
                    className='bg-primary font-semibold text-sm px-4 py-2 rounded text-gray-50 hover:text-gray-700 hover:bg-primary-light'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Processing...' : 'Submit'}
                  </button>
                )}
              </div>
              {/* {serverResponse && <div className='m-4'>{serverResponse}</div>} */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateQuery;
