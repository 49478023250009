import React from 'react';
import htmlparser from 'html-react-parser';
import { FaRegFilePdf } from 'react-icons/fa';

interface allProps {
  data: any;
}

const Dataset = (props: allProps) => {
  const data = props.data?.dataset;
  return (
    <>
      {(data?.description || data?.link) && (
        <div className='p-4'>
          <div className='flex justify-between'>
            <div className='font-semibold lg:text-xl'>Dataset</div>
            <div>
              <a
                className='text-primary flex'
                href={data?.link}
                target='_blank'
                rel='noreferrer noopener'
              >
                Download <FaRegFilePdf className='ml-2 mt-1' />
              </a>
            </div>
          </div>
          {data?.description && (
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(data?.description)}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dataset;
