import React from 'react';
import htmlparser from 'html-react-parser';

interface allProps {
  taskData: any;
}
const TaskDetail = (props: allProps) => {
  const taskData = props.taskData;

  return (
    <div className='space-y-4 '>
      <p className='font-medium text-2xl'>{taskData.title}</p>

      <div className='overflow-y-auto md:max-h-[74vh] xl:max-h-[76vh] scrollbar flex flex-col gap-4 pr-2'>
        {taskData.backgroundDescription && (
          <div className='p-2 md:p-4 rounded-md bg-gray-100 space-y-2'>
            <p className='font-medium'>Background Information</p>
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(taskData.backgroundDescription)}
            </div>
          </div>
        )}
        {taskData.taskInstructions && (
          <div className='p-2 md:p-4 rounded-md bg-gray-100 space-y-2'>
            <p className='font-medium '>Task Information</p>
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(taskData.taskInstructions)}
            </div>
          </div>
        )}
        {taskData.resources && (
          <div className='p-2 md:p-4 rounded-md bg-gray-100 space-y-2'>
            <p className='font-medium text-lg'>Resources</p>
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(taskData.resources)}
            </div>
          </div>
        )}
        {taskData.hints && (
          <div className='p-2 md:p-4 rounded-md bg-gray-100 space-y-2'>
            <p className='font-medium text-lg'>Hints</p>
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(taskData.hints)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDetail;
