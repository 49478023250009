import React, { useState, useRef } from 'react';
import Assessment from './Assessment';
import Instruction from './Instruction';
import { AiFillClockCircle } from 'react-icons/ai';

const AssessmentTimer = ({ assessmentQuestion, batchId, isCareereraUser }) => {
  const [instruction, setInstruction] = useState(true);
  const [time, setTime] = useState({});
  const [autoSubmit, setAutoSubmit] = useState(false);

  let vgaQuestion = [];

  const localVgaQuestion = JSON.parse(
    localStorage.getItem('localVgaQuestion') || '[]'
  );

  if (localVgaQuestion.length) {
    vgaQuestion = localVgaQuestion;
  } else {
    localStorage.setItem(
      'localVgaQuestion',
      JSON.stringify(assessmentQuestion)
    );
    vgaQuestion = assessmentQuestion;
  }

  let interval = useRef(null);

  const localCountDownTime = localStorage.getItem('localVgaCountDownTime');
  const startTimer = () => {
    let countDownTime = Date.now() + 20 * 60 * 1000;

    if (localCountDownTime) {
      countDownTime = parseInt(localCountDownTime) + 20 * 60 * 1000;
    } else {
      localStorage.setItem('localVgaCountDownTime', +Date.now());
    }
    interval = setInterval(() => {
      const now = new Date();
      const distance = countDownTime - now;
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setAutoSubmit(true);
        setTime({ minutes: 0, seconds: 0 });
        if (distance < -10000) {
          setTimeout(true);
          clearInterval(interval);
        }
      } else {
        setTime({ minutes: minutes, seconds: seconds });
      }
    }, 1000);
  };
  return (
    <div>
      {instruction ? (
        <Instruction
          setInstruction={setInstruction}
          startTimer={startTimer}
          assessmentQuestion={vgaQuestion}
          isCareereraUser={isCareereraUser}
        />
      ) : (
        <div>
          <div className='flex justify-end text-lg items-center bg-gray-50 px-4 py-1 text-primary mb-2 z-30 top-44 md:top-36 sticky'>
            <AiFillClockCircle />
            <p className='ml-1 text-gray-600 text-base'>
              {' '}
              : {time.minutes} : {time.seconds}
            </p>
          </div>
          <Assessment
            autoSubmit={autoSubmit}
            assessmentQuestion={vgaQuestion}
            batchId={batchId}
          />
        </div>
      )}
    </div>
  );
};

export default AssessmentTimer;
