import React, { useState } from 'react';
import TestBar from './TestBar';
import Instructions from './Instructions';
import {
  useCurrentCapstoneQuery,
  useGetCapstoneQuizQuery,
  useStartCapstoneTestMutation,
} from './CapstoneQuizSlice';
import Assessment from './assessment';
import ShowScore from './ShowScore';

interface allProps {
  setShowQuiz: any;
  enrollmentId: String;
  capstoneId: String;
}

const Index = (props: allProps) => {
  const [showScores, setShowScores] = useState(false);
  const { data } = useGetCapstoneQuizQuery({
    capstoneId: props.capstoneId,
  });

  const { data: enrollData, refetch } = useCurrentCapstoneQuery({
    capstoneId: props.capstoneId,
  });

  const [startTest] = useStartCapstoneTestMutation();

  const startTestObject = {
    enrollmentId: props.enrollmentId,
    responses: data,
  };
  const [loading, setLoading] = useState(false);
  return (
    <div className='bg-white'>
      {showScores ? (
        <ShowScore
          capstoneId={props.capstoneId}
          setShowQuiz={props.setShowQuiz}
        />
      ) : (
        <>
          {' '}
          {enrollData &&
          enrollData.capstoneAssessments?.responses &&
          !enrollData.capstoneAssessments?.timeTaken ? (
            <Assessment data={enrollData} setShowScores={setShowScores} />
          ) : (
            <>
              <button
                className='text-primary m-2'
                onClick={() => props.setShowQuiz(false)}
              >
                {'<'} Back
              </button>
              <TestBar totalQuestion={data && data.length} />
              <Instructions totalQuestion={data && data.length} />
              {data && data.length && (
                <div className='flex justify-end mr-4'>
                  <button
                    className='px-4 py-2 bg-primary-light rounded'
                    disabled={loading}
                    onClick={() => {
                      setLoading(true);
                      startTest(startTestObject)
                        .unwrap()
                        .then((res) => {
                          refetch();
                        });
                    }}
                  >
                    {loading ? 'Loading...' : 'Start Test'}
                  </button>
                </div>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
