import StartScreenCard from '../layout/StartScreenCard';
import { useNavigate } from 'react-router-dom';

const StartTest = () => {
  const navigate = useNavigate();

  const aptitudeCardProps = {
    stepNo: 'STEP 1',
    stepColor: 'text-gray-400',
    bgColor: 'bg-gray-400',
    heading: 'Aptitude Test',
    score: '0 / 40',
    time: ' : 20 Minutes',
    text: 'Communication, Analytical Ability, Reasoning and Self-Awareness & Learnability',
    color: 'to-bgIndigo',
  };
  const domainCardProps = {
    stepNo: 'STEP 2',
    stepColor: 'text-black',
    bgColor: 'bg-secondary',
    heading: 'Domain Test',
    score: ' 0 / 50',
    time: ' : 30 to 60 Minutes',
    text: 'Industry, Domain and Skills ( Minimum 3 and Maximum 6)',
    color: 'to-bgCyan',
  };

  const content = (
    <div className='grid grid-cols-12'>
      <div className='lg:col-span-5 col-span-12 -mx-2'>
        <div className='p-4 bg-gradient-to-b from-[#193257] to-[#0A1626] '>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1661327845/b2c/Domain_Test_Image_a3dzxn.png'
            alt='Login'
            className='mx-auto mt-8 h-96'
          />
          <p className='text-white text-center text-lg md:text-2xl font-bold mb-16 md:w-3/5 mx-auto mt-8'>
            Showcase your skills and get hired with
            <span className='text-primary'> Belong Score </span>
          </p>
        </div>
      </div>
      <div className='lg:col-span-6 col-span-12 mx-auto'>
        <div className='grid md:grid-cols-2 grid-cols-1'>
          <StartScreenCard {...aptitudeCardProps} />
          <StartScreenCard {...domainCardProps} />
        </div>
        <div className='text-center'>
          <button
            onClick={() => {
              navigate('steps/select-domain');
            }}
            className='font-manrope mt-6 lg:mt-10  text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-10 px-6 py-2 rounded-xl '
          >
            Start Now
          </button>
        </div>
      </div>
    </div>
  );

  return content;
};

export default StartTest;
