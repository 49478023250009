import SuperCard from './SuperCard';

const CompletedSuperCapstone = ({ data }: { data: any }) => {
  const getTasksDoneStr = (cap: any): string => {
    const totalTasks = cap.superCapstoneId.tasks.length || 0;
    let total_quiz_done = 0;

    for (const quiz of cap.quiz!) {
      if (quiz.percentage >= 40) {
        total_quiz_done += 1;
      }
    }

    const tasks_quizes_done: number =
      total_quiz_done + (cap.tasks?.length || 0);

    // Since 1 task + 1 quiz = 1 capstone task
    const totalTasksDone = Math.floor(tasks_quizes_done / 2);
    return `${totalTasksDone}/${totalTasks}`;
  };

  // Final avg quiz perc
  const getFinalPercentage = (cap: any): number => {
    let total_quiz_done = 0;

    for (const quiz of cap.quiz!) {
      if (quiz.percentage >= 40) {
        total_quiz_done += 1;
      }
    }

    const perc = 100 * (total_quiz_done / cap.quiz?.length);

    return perc;
  };

  const getDayCount = (cap: any): number => {
    const olderDate = new Date(cap.createdAt);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - olderDate.getTime();
    const daysPassed = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysPassed;
  };

  return (
    <div className='space-y-4'>
      {data?.map((cap: any, idx: number) => (
        <SuperCard
          key={cap.superCapstoneId._id}
          _id={cap.superCapstoneId._id}
          title={cap?.superCapstoneId?.title}
          level={cap?.superCapstoneId?.level}
          logo={cap.superCapstoneId?.companyId?.logoUrl}
          country={cap.superCapstoneId?.companyId?.country}
          industry={cap.superCapstoneId?.companyId?.industryId?.title}
          skills={cap?.superCapstoneId?.skillId}
          domain={cap.superCapstoneId?.domainId?.title}
          tasks={cap.superCapstoneId?.tasks || []}
          capstoneSubmissions={cap?.capstoneSubmissions || null}
          createdAt={cap?.superCapstoneId?.createdAt}
          capstoneAssessments={cap?.capstoneAssessments || null}
          buttonText='Download Certificate'
          capStatus='completed'
          buttonData={{
            date: new Date(cap.createdAt),
            dayCount: getDayCount(cap),
            progress: getFinalPercentage(cap),
            tasksDoneStr: getTasksDoneStr(cap),
          }}
        />
      ))}
    </div>
  );
};

export default CompletedSuperCapstone;
