import React from 'react';
import { AboutDataType } from '../../types';

const CompanyHighlights = ({
  companyCountry,
  companyWebsite,
  industryId,
}: AboutDataType) => {
  return (
    <div className='border rounded p-4 md:px-6 space-y-4'>
      <p className='font-semibold'>Company Highlights</p>
      <div className='flex space-x-2 text-sm'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234241/new%20b2b/Vector_cituxk.svg'
          alt=''
        />
        <p>{companyCountry} </p>
      </div>
      {industryId && (
        <div className='flex space-x-2 text-sm'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670234241/new%20b2b/Vector_1_fs3zpm.svg'
            alt=''
          />
          <p>{industryId?.title}</p>
        </div>
      )}
      <div className='flex space-x-2 text-sm'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/Vector_2_b3ebwk.svg'
          alt=''
        />
        <a href={companyWebsite} className='break-all'>
          {companyWebsite}{' '}
        </a>
      </div>
    </div>
  );
};

export default CompanyHighlights;
