import { useState } from 'react';
import TextEditorQuill from './TextEditorQuill';
import htmlparser from 'html-react-parser';
import { Dialog } from '@headlessui/react';
import { useAddTaskSolutionMutation } from 'components/superCapstone/superCapstoneSlice';

interface allProps {
  taskData: any;
  enrollData: any;
  setSelectedTask: Function;
  disableQuiz: (val: boolean) => void;
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}

const TaskSolution = (props: allProps) => {
  const [loading, setLoading] = useState(false);

  const [userSoution, setUserSolution] = useState('');
  const enrollmentId = props.enrollData?._id;
  //   const taskId = props.taskData?._id;
  //   const idealSolution = props.taskData?.idealSolution;
  const enrollCurrentTask = props.enrollData?.tasks.find(
    (elm: any) => elm.taskNo === props.taskData.taskNo
  );
  let initialtext =
    enrollCurrentTask && enrollCurrentTask.solution
      ? enrollCurrentTask.solution
      : '';

  if (initialtext === '') {
    props.disableQuiz(true);
  }

  const [submit] = useAddTaskSolutionMutation();
  const submitSolution = () => {
    setLoading(true);
    submit({
      enrollId: enrollmentId,
      solution: userSoution,
      taskNo: props.taskData.taskNo,
    })
      .unwrap()
      .then(() => {
        // props.setSelectedTask(false);
        // Adding setTimeout because disableQuiz takes some time to change the state.
        setTimeout(() => {
          props.disableQuiz(false);
          props.setActiveTab('Quiz & Review');
        }, 200);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='h-full flex flex-col justify-between'>
      {initialtext ? (
        <>
          <div className='bg-white px-1 sm:px-4 space-y-3 text-justify'>
            <div className='font-semibold text-lg'>Your Answer</div>
            <div className='overflow-y-auto md:max-h-[74vh] xl:max-h-[64vh] bg-primary-lightest px-2 md:p-4 rounded-md text-justify prose prose-sm max-w-none text-base'>
              {htmlparser(initialtext)}
            </div>
          </div>
        </>
      ) : (
        <div className='flex flex-col justify-between h-full'>
          <div className='px-2 md:px-6 flex flex-col gap-3'>
            <div className='font-semibold text-lg'>Your Solution</div>
            <TextEditorQuill
              initialtext={initialtext}
              setUserSolution={setUserSolution}
            />
          </div>
        </div>
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        as='div'
        className='relative z-10'>
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <div>
              <Dialog.Panel className='rounded-lg bg-white m-4 p-4 border-2 border-gray-200'>
                <div className='text-left w-64 sm:w-[32rem]'>
                  <div className='font-bold'>
                    Are you sure you want to submit this task?
                  </div>
                  <p className='mt-4'>
                    Please review your work carefully before confirming your
                    submission. <br /> Once you submit, you will not be able to
                    make any changes.
                  </p>
                  <div className='mt-6 px-4 py-3 flex justify-end'>
                    <button
                      type='button'
                      className='inline-flex w-full justify-center rounded-md bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none  sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        setIsOpen(false);
                      }}>
                      Cancel
                    </button>
                    <button
                      type='button'
                      className='mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-primary-light px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm'
                      onClick={() => {
                        submitSolution();
                        setIsOpen(false);
                      }}>
                      Submit
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
      <div className='text-center py-5 px-6 border-t-2 border-gray-200 flex justify-end'>
        <button
          onClick={() => {
            if (!initialtext) {
              setIsOpen(true);
              return;
            } else {
              props.setActiveTab('Quiz & Review');
            }
          }}
          disabled={loading}
          className={`px-5 py-2 rounded-lg text-lg text-white min-w-40
            ${
              userSoution?.length < 12 && !initialtext
                ? 'bg-stone-600 pointer-events-none'
                : 'bg-primary'
            }`}>
          {!initialtext
            ? loading
              ? 'Loading'
              : 'Submit Solution'
            : 'Continue'}
        </button>
      </div>
    </div>
  );
};

export default TaskSolution;
