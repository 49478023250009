import { addMinutes, differenceInSeconds } from 'date-fns';
import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVgaQuiz: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/question/domain/random',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['NewVgaQuiz'],
    }),
    checkIncompleteVga: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/test/domain-test/incomplete',
          params: arg,
        };
      },
      transformResponse: (res: any) => {
        //Start Time
        //Total minutes = total questions
        //End time = current time + total questions (mins)
        //Remaining time = End time - current time
        if (!res.data) {
          return null;
        }
        const totalQuestions = res.data.responses.length;
        const startTime = res.data.startTime;
        const endTime = addMinutes(new Date(startTime), totalQuestions);
        const currentTime = new Date();
        const timeLeft = differenceInSeconds(endTime, currentTime);
        const allSkills = res.data.skills
          .map((skill: { title: string }) => skill.title)
          .toString();
        // const groupedResponses = res.data.responses.map((skillSpecific:any)=> {return skillSpecific.questions.map((question:any) => {num++;return {...question,num}})}).flat()
        return { totalQuestions, allSkills, ...res.data, timeLeft };
      },
      providesTags: ['CurrentVgaQuiz'],
    }),
    vgaTestScore: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/test/domain-test/vga-quiz',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['VgaTestScore'],
    }),
    startVgaTest: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/test/domain-test/start`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CurrentVgaQuiz'],
    }),
    updateVgaResponses: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/test/domain-test/update-responses`,
          method: 'POST',
          body,
        };
      },
    }),
    finishVgaTest: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/test/domain-test/finish`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CurrentVgaQuiz', 'VgaTestScore', 'UserBatch'],
    }),
    getVgaQuizSkill: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/skills/vga-quiz-skills',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      // providesTags: ['NewVgaQuiz'],
    }),
  }),
});

export const {
  useGetVgaQuizQuery,
  useCheckIncompleteVgaQuery,
  useVgaTestScoreQuery,
  useStartVgaTestMutation,
  useUpdateVgaResponsesMutation,
  useFinishVgaTestMutation,
  useGetVgaQuizSkillQuery,
} = extendedApiSlice;
