
import React from 'react';

const AboutTimeSheet = () => {

  const cardData = [
    {
      id: 1,
      question: 'What?',
      text: 'A time sheet is a record-keeping document used to log internship hours on a weekly basis',
    },
    {
      id: 2,
      question: 'Why?',
      text: `Crucial to ensure transparency & accountability in tracking work hours and performance`,
    },
    {
      id: 3,
      question: 'How?',
      text: 'Submit your time sheet on weekly basis using the panel on your dashboard',
    },
  ];
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold'>Time Sheet</p>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4  xl:gap-12  mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2 items-center lg:items-start border rounded-lg p-2 md:p-4 '
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center justify-center my-auto  rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2'>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutTimeSheet;
