import React from 'react';
import QuizScoreChart from 'components/dashboard/quiz/layout/QuizScoreChart';
import { Link } from 'react-router-dom';

type scoreProps = {
  data: any;
};

const ShowFinalScore = (props: scoreProps) => {
  function getCategoryPercentage(cat: any) {
    const currentCategory = props.data.finalScore.filter((category: any) => {
      return category.category === cat;
    })[0];
    const catPercentage = Math.ceil(
      (currentCategory.obtained * 100) / currentCategory.max
    );
    return catPercentage;
  }

  const percentage = Math.ceil(props.data.percentage);

  const color = percentage > 25 ? '#9BBCEC' : '#EC9B9B';

  // new representation of score
  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
  ];

  function showImage(percentage: number) {
    let imageToShow = images[0]; //gold 80 and above
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1]; //silver 65-80
    }
    if (percentage < 65) {
      imageToShow = images[2]; //bronze 45-65
    }
    return imageToShow;
  }

  // Competency content data
  const competencyContent = {
    COMMUNICATION: {
      growth:
        'Strengthen your basic communication skills with practice in active listening and structured writing.',
      improvement:
        'Refine your communication by joining advanced workshops and seeking constructive feedback.',
      strength:
        'Leverage your communication strengths by leading projects and mentoring others.',
    },
    CREATIVITY: {
      growth:
        'Boost your creativity with brainstorming exercises and introductory creative thinking courses.',
      improvement:
        'Apply creativity consistently in your work with advanced courses and collaborative projects.',
      strength:
        'Leverage your creativity by leading innovation initiatives and exploring new creative domains.',
    },
    ANALYTICAL_SKILLS: {
      growth:
        'Build your foundational analytical skills with basic courses in math and data interpretation.',
      improvement:
        'Enhance your analytical abilities with intermediate courses and real-life case studies.',
      strength:
        'Maximize your analytical skills by leading projects and learning advanced tools like Python.',
    },
    SELF_AWARENESS: {
      growth:
        'Develop self-awareness through journaling, mindfulness, and personal assessments.',
      improvement:
        'Enhance self-awareness with emotional intelligence training and reflective exercises.',
      strength:
        'Leverage your self-awareness by mentoring others and exploring advanced personal development topics.',
    },
  };

  const competencyHeadings = [
    {
      id: 1,
      heading: 'Areas of Growth (0% to 30%)',
      text: 'These are areas where foundational skills need development; focus on building strong basics.',
    },
    {
      id: 2,
      heading: 'Areas of Improvement (30% to 80%)',
      text: 'These areas show moderate proficiency; continue refining and practicing to achieve excellence.',
    },

    {
      id: 3,
      heading: 'Areas of Strength (80% and above) ',
      text: 'These are your strong suits; apply these skills with real-world projects and gain experience.',
    },
  ];

  // Trophy text for different score ranges
  const trophyText = {
    gold: {
      title: 'Gold (You are in the top 90%)',
      message:
        "Outstanding performance! You've demonstrated exceptional mastery in this area.",
    },
    silver: {
      title: 'Silver (You are in the top 80%)',
      message: (
        <span>
          Strong performance! You&apos;re highly proficient and nearing the top
          tier.
        </span>
      ),
    },
    bronze: {
      title: 'Bronze (You are in the top 70%)',
      message:
        'Good performance! You have a solid understanding, with room for further growth.',
    },
  };
  // Determine which trophy text to show based on the score
  function getTrophyText(percentage: number) {
    if (percentage > 80) {
      return trophyText.gold;
    } else if (percentage > 65 && percentage <= 80) {
      return trophyText.silver;
    } else if (percentage > 45 && percentage <= 65) {
      return trophyText.bronze;
    }
    return null; // For scores below 70%, no trophy text is shown
  }

  type Category =
    | 'COMMUNICATION'
    | 'CREATIVITY'
    | 'ANALYTICAL_SKILLS'
    | 'SELF_AWARENESS';

  // Determine category and the respective content based on percentage 0-30, 30-80, 80 and above
  function getCompetencyText(category: Category, percentage: number) {
    if (percentage <= 30) {
      return competencyContent[category].growth;
    } else if (percentage < 80) {
      return competencyContent[category].improvement;
    } else {
      return competencyContent[category].strength;
    }
  }

  function getCompetencyHeading(percentage: number) {
    if (percentage <= 30) {
      return competencyHeadings[0]; // Areas of Growth
    } else if (percentage < 80) {
      return competencyHeadings[1]; // Areas of Improvement
    } else {
      return competencyHeadings[2]; // Areas of Strength
    }
  }

  const selectedHeading = getCompetencyHeading(percentage);

  // Categorize each competency
  const categorizedCompetencies = [
    {
      name: 'Business Communication',
      category: 'COMMUNICATION' as Category,
      percentage: getCategoryPercentage('COMMUNICATION'),
    },
    {
      name: 'Reasoning & Creativity',
      category: 'CREATIVITY' as Category,
      percentage: getCategoryPercentage('CREATIVITY'),
    },
    {
      name: 'Observation & Analytics',
      category: 'ANALYTICAL_SKILLS' as Category,
      percentage: getCategoryPercentage('ANALYTICAL_SKILLS'),
    },
    {
      name: 'Self Awareness',
      category: 'SELF_AWARENESS' as Category,
      percentage: getCategoryPercentage('SELF_AWARENESS'),
    },
  ];

  // Display categorized text based on the score
  const displayCategorizedText = (comp: {
    name: string;
    category: Category;
    percentage: number;
  }) => {
    const { name, category, percentage } = comp;
    if (percentage <= 30) {
      return (
        <p>
          <span className='font-bold'> {name}</span> -{' '}
          {getCompetencyText(category, percentage)}
        </p>
      );
    } else if (percentage < 80) {
      return (
        <p>
          <span className='font-bold'>{name}</span> -{' '}
          {getCompetencyText(category, percentage)}
        </p>
      );
    } else {
      return (
        <p>
          <span className='font-bold'>{name}</span> -{' '}
          {getCompetencyText(category, percentage)}
        </p>
      );
    }
  };

  const trophyMessage = getTrophyText(percentage);

  return (
    <div className='m-1 sm:m-4'>
      <div className='hidden text-gray-500 font-semibold m-2'>
        Congratualtions! You can start Applying to Oppotunitues
      </div>
      <div className='bg-gray-50 p-4 mt-8'>
        <div className='sm:flex text-gray-500 font-semibold'>
          <div>
            <div className='mb-6 text-center '>Your Score Card</div>
            <div className='flex justify-center'>
              <QuizScoreChart percentage={percentage} color={color} />
            </div>
          </div>
          <div className='mt-12 sm:ml-16'>
            <div className='mb-4'>
              The Assessment assesses on the 4 essential workplace readiness
              skills :{' '}
              <span className='text-primary'>
                Communication, Analytics, Creativity & Self- Awareness
              </span>
            </div>
            <div className='grid grid-cols-2 gap-2'>
              {categorizedCompetencies.map((comp) => {
                return (
                  <div
                    key={comp.category}
                    className='my-2 flex items-center bg-primary-lightest col-span-2 sm:col-span-1 rounded-3xl'>
                    <div className='bg-primary rounded-full text-center flex items-center w-12 h-12 px-3 py-1 text-white'>
                      {comp.percentage}%
                    </div>
                    <div className='flex justify-between w-full '>
                      <div className='ml-3 text-sm mt-1'>{comp.name} </div>
                      {percentage > 45 && (
                        <div>
                          <img
                            alt='skill'
                            className='mr-6'
                            src={showImage(percentage)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* Insights and recommendations */}
        <div className='space-y-3 mt-4 ml-10'>
          <p className='font-bold'>
            Insights & Recommendations into your cognitive abilities:
          </p>
          <p className='font-bold text-primary'>{selectedHeading.heading}</p>
          <p>{selectedHeading.text}</p>
          {categorizedCompetencies.map((comp) => displayCategorizedText(comp))}
        </div>
        <div className='space-y-4'>
          {percentage > 70 && (
            <p className='font-bold'>Yay! You won a trophy:</p>
          )}
          {trophyMessage && (
            <div className='mb-4 text-center'>
              <div className='text-xl font-bold'>{trophyMessage.title}</div>
              <p>{trophyMessage.message}</p>
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-center mt-4 sm:mt-12'>
        <Link
          to='/'
          className='px-6 py-2 font-semibold text-lg text-primary border border-gray-400 rounded-xl shadow'>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ShowFinalScore;
