import React from 'react';
// import { GoPrimitiveDot } from 'react-icons/go';
import { format } from 'date-fns';
import { CapData } from './types';
import { AiOutlineCheckCircle } from 'react-icons/ai';
// import { Link } from 'react-router-dom';

const CapstoneDataCompleted = (props: CapData) => {
  const totalTasks = props.tasks.length;
  const submittedTasks = props.capstoneSubmissions?.length;
  const progressPercentage = (submittedTasks * 100) / totalTasks;
  // const lastItem = props?.capstoneSubmissions[submittedTasks - 1];
  const finishTime = props.capstoneAssessments.finishTime;

  return (
    <div>
      <div className='md:px-8 border-t md:border-t-0 md:border-l border-primary'>
        <div className='md:flex justify-between'>
          <div className='mt-2 md:mt-0'>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                {/* <GoPrimitiveDot /> */}
              </div>
              <p className='text-sm'>
                Capstone Progress-{' '}
                <span className='text-primary font-semibold'>
                  {progressPercentage}%
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                {/* <GoPrimitiveDot /> */}
              </div>
              <p className='text-sm'>
                Milestones Achieved-{' '}
                <span className='text-primary font-semibold'>
                  {submittedTasks}/{totalTasks}
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                {/* <GoPrimitiveDot /> */}
              </div>
              <p className='text-sm'>
                Capstone Performance-{' '}
                <span className='text-primary font-semibold'>
                  {' '}
                  {props.capstoneAssessments?.finishTime
                    ? `${props?.capstoneAssessments?.percentage}%`
                    : 'NA'}
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                {/* <GoPrimitiveDot /> */}
              </div>
              <p className='text-sm'>
                Current Milestone-{' '}
                <span className='text-primary font-semibold'>
                  Task {props?.capstoneSubmissions?.length}
                </span>
              </p>
            </div>
          </div>
          <div className='flex items-center  md:block'>
            <div
              className='text-primary font-semibold text-center  pt-12 h-20 md:h-24 w-20 md:w-24 bg-top md:-mt-7 flex place-items-center justify-center'
              style={{
                backgroundImage:
                  'url(https://res.cloudinary.com/belong/image/upload/v1666187316/capstone/ant-design_calendar-filled_ytsqip.png)',
              }}
            >
              <AiOutlineCheckCircle className='h-4 md:h-8 w-4 md:w-8' />
            </div>
            <p className='ml-4 md:mt-2 md:ml-0 text-xs text-gray-400'>
              Start Date- {format(new Date(props?.createdAt), 'do LLLL yyyy')}
            </p>
            <p className='ml-4 mt-2 md:ml-0 text-xs text-gray-400'>
              End Date- {format(new Date(finishTime), 'do LLLL yyyy')}
            </p>
          </div>
        </div>
        {/* <Link to={'/capstone/' + props._id}>
          <button className='text-white bg-primary px-8 py-1 my-4 rounded cursor-pointer'>
            Download Certificate
          </button>
        </Link> */}

        {/* <p className='font-medium md:font-semibold text-sm '>
          *Capstone quiz performance criteria to unlock the certificate is 50%
          and above
        </p> */}
      </div>
    </div>
  );
};

export default CapstoneDataCompleted;
