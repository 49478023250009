import React, { useState } from 'react';

import CapstoneInfo from './CapstoneInfo';
import EnrolNow from './EnrolNow';
import AboutCompany from './AboutCompany';
import VideoSection from './VideoSection';
import ProjectBrief from './ProjectBrief';
import BusinessObjectives from './BusinessObjectives';
import Tasks from './Tasks';
import TaskView from './taskView';
import Dataset from './Dataset';
// import { useQuery } from 'react-query';
// import { axiosApi } from '../../../api/Axios';
import QuizAndScore from './QuizAndScore';
import QuizView from './capstoneQuiz';
import {
  useCurrentCapstoneQuery,
  useGetCapstoneQuizQuery,
} from './capstoneQuiz/CapstoneQuizSlice';

interface allProps {
  data: any;
}

const CheckUserStatus = (props: allProps) => {
  const [selectedTask, setSelectedTask] = useState(null);
  const [showQuiz, setShowQuiz] = useState(false);
  const [count, setCount] = useState(1);
  const capstoneId = props.data._id;

  const { isSuccess, data: enrollData } = useCurrentCapstoneQuery({
    capstoneId,
  });

  const { data: questionData } = useGetCapstoneQuizQuery({
    capstoneId: capstoneId,
  });
  return (
    <div>
      <div className='bg-gray-50 pb-4'>
        <CapstoneInfo data={props.data} />
        {showQuiz ? (
          <QuizView
            setShowQuiz={setShowQuiz}
            capstoneId={props.data._id}
            enrollmentId={enrollData?._id}
          />
        ) : selectedTask ? (
          <TaskView
            setSelectedTask={setSelectedTask}
            selectedTask={selectedTask}
            data={props.data}
            enrollData={enrollData}
            count={count}
          />
        ) : (
          <div className='border border-primary-light mt-4 rounded-lg mx-2 sm:mx-4 px-1 sm:px-2 bg-white shadow'>
            {isSuccess && (
              <EnrolNow data={props.data} enrollData={enrollData} />
            )}
            <AboutCompany data={props.data} />
            <VideoSection data={props.data} />
            <ProjectBrief data={props.data} />
            <BusinessObjectives data={props.data} />
            <Dataset data={props.data} />
            <Tasks
              data={props.data}
              setSelectedTask={setSelectedTask}
              enrollData={enrollData}
              setCount={setCount}
            />
            {questionData && questionData.length && (
              <QuizAndScore
                setShowQuiz={setShowQuiz}
                enrollData={enrollData}
                capstoneData={props.data}
                totalQuestions={questionData.length}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckUserStatus;
