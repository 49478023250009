import classNames from 'classnames';
import React from 'react';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';

const Card = (props) => {
  const markOption = (optionSelectedByUser, indexOfOptionSelectedByUser) => {
    const data = props.finalSubmission[props.currentQuestion];
    const { maxSelect } = data;
    const selectedOptionsCount = data.options.filter(
      (el) => el.selected
    ).length;

    if (data.options[indexOfOptionSelectedByUser].hasOwnProperty('selected')) {
      delete data.options[indexOfOptionSelectedByUser].selected;
    } else if (selectedOptionsCount < maxSelect) {
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    } else if (selectedOptionsCount === maxSelect) {
      data.options.forEach((option) => {
        if (option.hasOwnProperty('selected')) {
          delete option.selected;
        }
      });
      data.options[indexOfOptionSelectedByUser]['selected'] = true;
    }

    let tempObj = [...props.finalSubmission];
    tempObj[props.currentQuestion] = data;
    props.setFinalSubmation(tempObj);
    localStorage.setItem('localVgaSubmission', JSON.stringify(tempObj));
  };

  return (
    <div>
      <p className='mb-6 font-semibold text-gray-500 text-lg'>
        {props.currentQuestion + 1}. {props.question}
      </p>
      <div className='grid grid-flow-col auto-cols-fr gap-4'>
        {props.image.map((imageName) => {
          return (
            <img
              key={imageName}
              className='rounded shadow h-full'
              src={imageName}
              alt='Question'
            />
          );
        })}
      </div>
      <div className='space-y-4 bg-gray-50 p-6'>
        {props.options.map((option, index) => {
          return (
            <div
              key={option._id}
              onClick={() => {
                markOption(option._id, index);
              }}
              className={classNames(
                {
                  'text-blue-500': option.selected === true,
                },
                'cursor-pointer p-2 flex items-center font-semibold text-gray-500'
              )}
            >
              {option.selected ? (
                <span className='pr-2'>
                  <BiRadioCircleMarked />
                </span>
              ) : (
                <span className='pr-2'>
                  <BiRadioCircle />
                </span>
              )}

              {option.option}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Card;
