import { useState, Fragment } from 'react';
// import { FaLock } from 'react-icons/fa';
// import CapstoneCertificate from './CapstoneCertificate';
import { Dialog } from '@headlessui/react';
import { useGetUserQuery } from 'components/profile/userSlice';
import CompleteCertificate from 'components/capstone/capstoneView/CompleteCetificate';
import { useAddCompletedMutation } from '../superCapstoneSlice';
import { useNavigate } from 'react-router-dom';
// import FeedBackView from './FeedBackView';

interface allProps {
  //   setShowQuiz: any;
  enrollData: any;
  capstoneData: any;
  userProgress: any;
  setShowFeedback: any;
}

// function diff_hours(dt2: any, dt1: any) {
//   let diff = (dt2.getTime() - dt1.getTime()) / 1000;
//   diff /= 60 * 60;
//   return Math.round(diff);
// }

const SuperFeedbackCertificate = (props: allProps) => {
  const enrollData = props.enrollData;
  const capstoneData = props.capstoneData;
  const setShowFeedback = props.setShowFeedback;
  console.log(enrollData);
  // confirm data query
  const { data } = useGetUserQuery();
  const partnerLogo = data?.partnerId?.logoUrl;
  const partnerName = data?.partnerId?.name;

  const [isOpen, setIsOpen] = useState(false);
  const [addCompleted] = useAddCompletedMutation();

  let isLocked = props.userProgress < 100;
  function checkQuizConditions() {
    //   stays locked if user has not enrolled or no. of quiz submitted is less than no. of tasks
    // isLocked = props.userProgress < 100;

    const finalPercentage = props.userProgress >= 100;

    const isFeedbackDone = enrollData?.feedback?.submitTime;

    if (enrollData?.status === 'Completed') {
      return (
        <button
          onClick={() => setIsOpen(true)}
          className='bg-green-200 w-fit py-1 px-3 rounded-full ml-4'>
          Download Again
        </button>
      );
    }

    if (isLocked) {
      return (
        <button disabled className='bg-red-200 w-fit py-1 px-3 rounded-full'>
          Locked
        </button>
      );
    }
    // isFeedbackDone
    if (isFeedbackDone) {
      return (
        <button
          onClick={() => {
            addCompleted({ enrollId: enrollData?._id });
            setIsOpen(true);
          }}
          className='bg-green-200 w-fit py-1 px-3 rounded-full'>
          Get Certificate
        </button>
      );
    }

    if (finalPercentage) {
      return (
        <>
          <button
            onClick={() => {
              //   props.onFeedbackClick();
              setShowFeedback(true);
              navigate(
                `/supercapstone/${props.enrollData.superCapstoneId._id}/feedback`
              );
            }}
            className='bg-yellow-200 w-fit py-1 px-1 rounded-full'>
            Give Feedback
          </button>
        </>
      );
    }
  }

  const navigate = useNavigate();

  return (
    <>
      <tr className=' items-center cursor-pointer font-semibold '>
        <td className='border-l border-b border-slate-300 md:px-4 py-2'>
          Feedback and Certificate
        </td>
        <td className='border-b border-slate-300'></td>
        <td className='border-b border-slate-300'></td>
        <td
          className={`border border-slate-300 text-left md:px-2 py-2 font-medium ${
            isLocked && 'md:px-6'
          }`}>
          {checkQuizConditions()}
        </td>
      </tr>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='relative z-50'>
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex items-center justify-center w-auto bg-gray-100'>
            <div>
              <Dialog.Panel className='mx-auto rounded bg-white'>
                <CompleteCertificate
                  partnerName={partnerName}
                  partnerLogo={partnerLogo}
                  user={data}
                  capstoneData={capstoneData}
                  setIsOpen={setIsOpen}
                />
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SuperFeedbackCertificate;
