import React, { useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { BiLeftArrow, BiRightArrow } from 'react-icons/bi';
import { format } from 'date-fns';

const belongLogo =
  'https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png';

const Certificate = (props) => {
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);
  const batchId = props.batchDetails._id;
  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const certificateText = 'CERTIFICATE OF ' + props.status.toUpperCase();
  const areaText =
    batchId === '63467ebedaf0a00016aae73f'
      ? 'Data Analytics and Power BI'
      : props.userDetails.area.title;
  const companyText = props.userDetails.company.name;
  const companyLogo = props.userDetails.company.logoUrl;
  const userBatch = props.data.batches[0];
  const topRightLogo =
    userBatch.type === 'University' ? belongLogo : userBatch.logoUrl;
  const leftLogo = userBatch.type === 'University' ? companyLogo : belongLogo;

  const durationText = `FOR THE DURATION OF ${format(
    new Date(userBatch.startDate),
    'do LLLL y'
  )} to ${format(new Date(userBatch.endDate), 'do LLLL y')}.`;

  const titleText = props.isVGE
    ? 'Virtual Global Entrepreneurship Bootcamp'
    : props.showEtp
    ? 'Virtual Global Apprenticeship Program'
    : 'VIRTUAL INTERNSHIP';

  const detailsText = props.isVGE
    ? `SUCCESSFULLY CONDUCTING HIS/HER RESPONSIBILITIES AS AN INCUBATEE `
    : props.showEtp
    ? `SUCCESSFULLY CONDUCTING HIS/HER RESPONSIBILITIES AS AN APPRENTICE IN ${areaText} `
    : `SUCCESSFULLY CONDUCTING HIS/HER RESPONSIBILITIES AS AN INTERN IN ${areaText} WITH ${companyText}`;

  return (
    <>
      <div className='flex flex-col items-center p-2 border-2 border-b-0 bg-gray-50'>
        <button
          onClick={handleExportWithMethod}
          className='rounded mt-3 text-xs text-gray-100 font-bold md:text-sm px-4 md:px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700  transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'
        >
          Download Certificate
        </button>
      </div>
      <div
        style={{ minWidth: '950px', maxWidth: '1000px' }}
        className='md:w-3/4 md:mx-auto px-4 border-2 border-t-0 border-b-0 pb-4 bg-gray-50'
      >
        <PDFExport paperSize='auto' landscape={true} ref={pdfExportComponent}>
          <div ref={contentArea} className='flex justify-center'>
            <div
              className='bg-no-repeat'
              style={{
                backgroundImage:
                  "url('https://res.cloudinary.com/belong/image/upload/v1643358849/uploaded_resources/borders_svhcan.png')",
              }}
            >
              <div className='p-6 my-3 bg-transparent'>
                <div className='flex justify-end mb-6 pr-12 md:pt-2'>
                  <img src={topRightLogo} alt='Logo' className='h-8 md:h-12' />
                </div>
                <div className='flex justify-center mb-6 sm:mb-2 md:mb-1 md:-mt-20 -mt-16'>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1643358778/uploaded_resources/badge_fec7cf.png'
                    alt='Logo'
                    className='h-12 md:h-24'
                  />
                </div>
                <div className='flex flex-col justify-center px-12 '>
                  <p className='font-extrabold text-lg lg:text-2xl mb-2 sm:mb-1 lg:mb-4 text-center text-primary-darkblue tracking-widest'>
                    {certificateText}
                  </p>
                  <span className='px-96'>{''}</span>
                  <p className=' font-semibold text-center text-lg mb-3 sm:mb-2 lg:mb-4 tracking-widest text-primary-darkblue'>
                    THIS CERTIFICATE IS PRESENTED TO
                  </p>
                  <div className='flex justify-center items-center '>
                    <div className='flex space-x-16 border-b-2'>
                      <BiLeftArrow className='text-primary-mediumblue' />
                      <p className='text-primary-mediumblue tracking-widest text-2xl font-bold font-mono'>
                        {props.userDetails.name.toUpperCase()}
                      </p>
                      <BiRightArrow className='text-primary-mediumblue' />
                    </div>
                  </div>
                  <p className='text-2xl text-center font-extrabold tracking-wider mt-3 text-gray-500  mb-2 text-primary-darkblue lg:tracking-widest'>
                    {titleText.toUpperCase()}
                  </p>
                  <p className='text-lg md:text-xl text-center font-extrabold text-primary-darkblue mb-3 sm:mb-1 md:mb-4 font-mono tracking-widest'>
                    FOR
                  </p>
                  <p className='text-center w-4/5 mx-auto text-xs md:text-base text-gray-500 font-semibold text-primary-darkblue'>
                    {`${detailsText.toUpperCase()}  ${durationText.toUpperCase()}`}
                  </p>
                </div>
                <div className='flex justify-between px-16 mt-12'>
                  {userBatch.partner === '610a7ca8d3c51a1458d005ce' && (
                    <div className='flex flex-col items-center'>
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1643353725/uploaded_resources/Deviare_sign_g7kcav.jpg'
                        alt='Logo'
                        className='h-10'
                      />
                      <p className='border-2 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-sm mr-2'>
                        DEVIARE DIRECTOR
                      </p>
                    </div>
                  )}
                  <div className='flex flex-col items-center'>
                    <img
                      src={props.userDetails.mentor.mentorSign}
                      alt='Logo'
                      className='h-10'
                    />
                    <p className='border-2 md:border-2 md:border-b-0 md:border-l-0 md:border-r-0 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-xs md:text-sm mr-2 md:mr-0'>
                      COMPANY MENTOR
                    </p>
                  </div>
                  {userBatch.type !== 'University' && (
                    <div className='flex flex-col items-center'>
                      <div className='flex flex-col sm:flex-row'>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                          alt='Logo'
                          className='h-10'
                        />
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                          alt='Logo'
                          className='h-10'
                        />
                      </div>
                      <p className='border-2 md:border-2  md:border-b-0 md:border-l-0 md:border-r-0 border-b-0 border-l-0 border-r-0 border-gray-600 font-bold tracking-wider text-gray-600 pt-2 text-center text-xs md:text-sm'>
                        BELONG FOUNDERS
                      </p>
                    </div>
                  )}
                </div>
                <div className='flex mt-8 pl-6 mb-4'>
                  <img src={leftLogo} alt='Logo' className='h-8' />
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
    </>
  );
};

export default Certificate;
