import htmlparser from 'html-react-parser';
interface PrerequisitesProps {
  data: any;
}

const Prerequisites = (props: PrerequisitesProps) => {
  return (
    <div className=' flex flex-col gap-3'>
      {props.data.prerequisites && (
        <div className=' flex flex-col gap-3'>
          <div className='font-medium'>Prerequisites</div>

          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.prerequisites)}
          </div>
        </div>
      )}
      {props.data.tools && (
        <div className='  bg-opacity-60 rounded-lg flex flex-col gap-2'>
          <p className='font-medium'>Platform/Tools</p>
          <div className='prose prose-sm max-w-none text-base'>
            {props.data.tools}
          </div>
        </div>
      )}
      {!props.data.prerequisites && !props.data.tools && (
        <span className='  bg-opacity-60 font-medium rounded-lg'>
          Prerequisites are currently unavailable for this capstone.
        </span>
      )}
    </div>
  );
};

export default Prerequisites;
