import axios from 'axios';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../config/API_LINKS';

export async function deleteFile(
  fileKey,
  fieldToUpdate,
  uniqueUserId,
  refetch,
  setLoading
) {
  if (
    window.confirm('Are you sure you want to permanently delete this file?')
  ) {
    setLoading(true);
    const result = await axios.delete(
      `${UPLOAD_OR_DOWNLOAD_URL}/${fileKey}/${fieldToUpdate}`
    );
    if (result.data === 'success') {
      refetch();
      setLoading(false);
    } else {
      alert('Could not delete file, please try again.');
      setLoading(false);
    }
  }
}

export async function uploadFile(
  files,
  fieldToUpdate,
  fileFormat,
  assignmentId,
  uniqueUserId,
  refetch,
  setLoading,
  week,
  batchId,
  userId
) {
  if (uniqueUserId) {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('id', uniqueUserId);
    formData.append('fieldToUpdate', fieldToUpdate);
    formData.append('fileFormat', fileFormat);
    formData.append('assignmentId', assignmentId);
    formData.append('week', week);
    formData.append('batchId', batchId);
    formData.append('userId', userId);

    const result = await axios.post(UPLOAD_OR_DOWNLOAD_URL, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (result.data.status === 'success') {
      refetch();
      setLoading(false);
    }
    if (result.data.status === 'error') {
      alert(result.data.message);
      setLoading(false);
    }
  } else {
    alert('Could not upload file, please try again.');
  }
}
