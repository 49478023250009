import React from 'react';
import { FaRegCommentAlt, FaAngleLeft } from 'react-icons/fa';

const IndividualTicket = ({
  ticket,
  setTicketDetails,
  setTicketNo,
  index,
  ticketDate,
  comment,
  clickCheck,
  ticketDetails,
  isCareereraUser,
}) => {
  const assigneeButtonStyle =
    ' border px-4 py-1 rounded-full text-xs  text-gray-50 mr-4 text-center';
  return (
    <div
      className='border w-full p-4 rounded-xl mb-3 cursor-pointer bg-white'
      key={ticket._id}
      onClick={() => {
        if (clickCheck) {
          setTicketDetails(true);
          setTicketNo(index);
        }
      }}
    >
      <div className='grid col-span-12 md:grid-cols-2 mb-3 flex items-center'>
        <div className='col-span-12 md:col-span-1 grid grid-cols-8 flex items-center'>
          <div className='col-span-2 md:col-span-4 text-gray-600 text-sm font-semibold mr-4 flex items-center'>
            {ticketDetails && (
              <FaAngleLeft
                onClick={() => setTicketDetails(false)}
                className='mr-2'
              />
            )}
            <span>{ticket.subject}</span>
          </div>
          <p
            className={`${ticket.assigneeRef === 'Coach' && 'bg-yellow-300'}
            ${ticket.assigneeRef === 'Mentor' && 'bg-red-300'} ${
              !ticket.assigneeRef && 'bg-primary'
            }${assigneeButtonStyle} col-span-3 md:col-span-2`}
          >
            {ticket.assigneeRef
              ? ticket.assigneeRef
              : isCareereraUser
              ? 'Careerera'
              : 'Belong'}
          </p>
          <p className='col-span-3 md:col-span-2 text-center text-gray-600 font-semibold border px-4 py-1 rounded-full text-xs'>
            {ticketDate}
          </p>
        </div>

        <div className='col-span-12 md:col-span-1 flex justify-end'>
          {comment.length > 0 && (
            <>
              <FaRegCommentAlt className='text-primary' />
              <p className='text-xs text-gray-600 ml-2 font-semibold'>
                {comment.length}
              </p>
            </>
          )}
        </div>
      </div>
      <p className='text-xs text-gray-500 mb-3'>{ticket.description}</p>
    </div>
  );
};

export default IndividualTicket;
