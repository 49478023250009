import Loading from 'components/global/layout/Loading';
import { useGetUserBatchQuery } from './userBatchSlice';
import MultipleBatch from './MultipleBatch';

const Index: React.FC = () => {
  const { data, isSuccess, isLoading } = useGetUserBatchQuery({});
  return isLoading ? (
    <Loading />
  ) : isSuccess ? (
    <MultipleBatch data={data} />
  ) : null;
};

export default Index;
