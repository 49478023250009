import React, { useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useNavigate } from 'react-router-dom';

import useDomainTestContext from '../../../../../hooks/useDomainTestContext';
import useEligibleSkillsData from '../../../../../hooks/useEligibleSkillsData';
import Loading from '../../../../global/layout/Loading';
import classNames from 'classnames';

const style = {
  chips: {
    background: '#10adb91a',
    color: '#13253A',
  },
};

const SelectSkills: React.FC = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useDomainTestContext();

  useEffect(() => {
    //If no domain has been selected before coming to this screen
    !state.areaId && navigate('../select-domain');
  }, [state.areaId, navigate]);

  const { isLoading, isError, data } = useEligibleSkillsData(state.areaId);

  function onChange(selectedList: []) {
    const selectedSkills = selectedList.map((skill: any) => skill.id);
    dispatch({
      type: 'UPDATE_SKILL_IDS',
      payload: selectedSkills,
    });
  }

  return (
    <div>
      {isLoading && <Loading />}
      {isError && 'Error...'}
      {data && (
        <>
          <p className='text-primary font-manrope lg:font-semibold font-normal lg:text-xl text-base text-center lg:mt-12 mt-6'>
            Pick your Skill
          </p>
          <div className='font-manrope font-normal text-center lg:leading-9 leading-6'>
            <p>
              Select at least 3 and up to 6 skills that align with your
              professional goals.
            </p>
            <p>You can select at least three and atmost six skills.</p>
          </div>
          <div className='w-1/2 mx-auto my-8'>
            <Multiselect
              options={data}
              displayValue='title'
              placeholder='Select Skills'
              selectionLimit={6}
              loading={isLoading}
              onSelect={onChange}
              onRemove={onChange}
              style={style}
              customCloseIcon={<div className='ml-3 font-bold'>X</div>}
            />
            <div
              className={classNames('text-center', {
                hidden: state.skillIds.length < 3,
              })}>
              <button
                onClick={() => {
                  navigate('../start-test');
                }}
                className='font-manrope lg:mt-10 mt-4 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-14 px-6 py-2 rounded-xl '>
                Next
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SelectSkills;
