export const tourConfig = [
  {
    selector: '[data-tut="belong_logo"]',
    content: () => (
      <div>
        <b>Welcome to your Employability Dashboard!</b>
        <div className=''>
          We are excited to have you on board, let's take a quick tour to get
          you started.
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Belong-Score"]',
    content: () => (
      <div>
        <div className=''>
          Your learning journey consist of{' '}
          <span className='font-bold'> three elements </span> that combine
          together to become your Employability Score (Belong Score).
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Belong-Score"]',
    content: () => (
      <div>
        <div className=''>
          Employability Score (100) = Cognitive Acumen(40) + Skill Competency
          (45) + Profile Strength (15)
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Belong-Score"]',
    content: () => (
      <div>
        <div className=''>
          It's powerful tool to gauge your readiness for the job market.
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Cognitive-Acumen"]',
    content: () => (
      <div>
        <div className=''>
          Cognitive Acumen (out of 40) consists of an{' '}
          <span className='font-bold'> Aptitude Quiz </span> that evaluates your
          workplace readiness.
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Skill-Competency"]',
    content: () => (
      <div>
        <div className=''>
          Skill Competency (out of 45) consists of the
          <span className='font-bold'>
            {' '}
            Domain Quiz and Capstones & VGA performance (if applicable).{' '}
          </span>{' '}
          It assess your skill expertise.
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Profile-Strength"]',
    content: () => (
      <div>
        <div className=''>
          Profile Strength (out of 15) assesses the robustness of your
          <span className='font-bold'>Employment Profile.</span> Build a strong
          profile to stand out to potential employers!
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="Milestones"]',
    content: () => (
      <div>
        <div className=''>
          <span className='font-bold'>Milestones to Completion </span> mentions
          all the steps that you need to achieve in your learning journey and
          build your employability score.
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="profile-icon"]',
    content: () => (
      <div>
        <div className=''>
          You can directly access, complete and update your profile using
          <span className='font-bold'> the profile icon. </span>
        </div>
      </div>
    ),
  },
  {
    selector: '[data-tut="logout-icon"]',
    content: () => (
      <div>
        <div className=''>
          You can logout from your dashboard by clicking on the logout icon.
        </div>
      </div>
    ),
  },
];
