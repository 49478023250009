import React from 'react';
import DomainQuizHeader from './layout/DomainQuizHeader';
import Report from './layout/Report';
import { useCheckIncompleteVgaQuery } from './VgaQuizSlice';
import Loading from 'components/global/layout/Loading';
import Steps from './steps';
import Assessment from './assessment';

const DomainQuizPanel = ({ userData, batchId, batchDomain, openQuiz }: any) => {
  const domainScore = userData?.report?.quiz || userData?.report?.quiz === 0;
  const domain = batchDomain || userData?.area;
  const { isLoading, isSuccess, data } = useCheckIncompleteVgaQuery({
    batchId,
    source: 'B2B',
  });
  const condition = isSuccess && Boolean(data);

  return (
    <div className='md:border rounded-b-lg text-textColor-lighter'>
      <DomainQuizHeader domainScore={domainScore} />
      <div className='px-4 md:px-8 space-y-14'>
        {domainScore ? (
          <Report batchId={batchId} />
        ) : isLoading ? (
          <Loading />
        ) : condition ? (
          <Assessment data={data} batchId={batchId} />
        ) : (
          <Steps domain={domain} batchId={batchId} openQuiz={openQuiz} />
        )}
      </div>
    </div>
  );
};

export default DomainQuizPanel;
