import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';

import { axiosApi } from '../../config/Axios';
import { buttonStyle, errorStyle, inputStyle, linkStyle } from './authStyles';
import Logo from './Logo';
import { useGetPartnerQuery } from './partnerSlice';
import Loading from 'components/global/layout/Loading';
import CheckLink from './CheckLink';
import GoacbSignup from './GoacbSignup';

const SignUp = () => {
  const [serverResponse, setServerResponse] = useState('');
  const location: any = useLocation();
  const from = location.state?.from?.pathname || '/';
  const navigate = useNavigate();
  const { slug } = useParams();
  //If already logged in
  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      navigate(from, { replace: true });
    });
  }, [from, navigate]);
  const url = window.location.hostname;
  const isEdRed = slug === 'edred' || url === 'capstone.edred.in';

  const { isLoading, data } = useGetPartnerQuery({
    code: isEdRed ? 'edred' : slug,
  });

  if (!isLoading && !data) {
    return <CheckLink />;
  }
  return isLoading ? (
    <Loading />
  ) : (
    <div className='grid md:grid-cols-2 grid-cols-1'>
      <div className='md:flex md:flex-col p-4 justify-between bg-gradient-to-b from-[#193257] to-[#0A1626] md:min-h-screen'>
        <Link to='/'>
          <Logo partnerLogo={data?.logoUrl} partnerCode={slug} />
        </Link>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1661165411/b2c/loginImage_uvscv9.png'
          alt='SignUp'
          className='mx-auto mt-8'
        />
        {isEdRed ? (
          <p className='text-white text-center text-lg md:text-2xl font-bold mb-32 md:w-3/5 mx-auto'>
            Build proof of work with capstones and apprenticeships. Get
            recognised by 500+ global companies
          </p>
        ) : (
          <p className='text-white text-center text-lg md:text-2xl font-bold mb-32 md:w-3/5 mx-auto'>
            Build <span className='text-primary'>Belong Score </span>and get
            hired by 500+ global companies
          </p>
        )}
      </div>

      <div className='grid place-items-center p-4 md:h-4/5'>
        <div className='md:w-3/4 w-full'>
          <h1 className='my-8 text-lg font-semibold'>
            Sign up to create your{' '}
            {isEdRed ? 'Edred account' : 'Belong account'}
          </h1>
          {slug === 'goacb' ? (
            <GoacbSignup data={data} />
          ) : (
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                confirmPassword: '',
                partnerId: data._id,
                source: 'B2B',
              }}
              validationSchema={Yup.object().shape({
                firstName: Yup.string()
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!')
                  .required('Required'),
                lastName: Yup.string()
                  .min(2, 'Too Short!')
                  .max(50, 'Too Long!')
                  .required('Required'),
                email: Yup.string().email('Invalid email').required('Required'),
                password: Yup.string()
                  .required('Please enter your password')
                  .matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
                  ),
                confirmPassword: Yup.string()
                  .required('Please confirm your password')
                  .when('password', {
                    is: (password: string) =>
                      password && password.length > 0 ? true : false,
                    then: Yup.string().oneOf(
                      [Yup.ref('password')],
                      "Password doesn't match"
                    ),
                  }),
              })}
              onSubmit={(values, { setSubmitting }) => {
                setServerResponse('');
                axiosApi
                  .post('/user/b2b-signup', values)
                  .then(async (res) => {
                    await Auth.signIn(values.email, values.password);
                  })
                  .then((res) => {
                    navigate(from, { replace: true });
                  })
                  .catch((err) => {
                    setServerResponse(err?.response?.data?.message);
                  })
                  .finally(() => {
                    setSubmitting(false);
                  });
              }}
            >
              {({ isSubmitting }) => (
                <Form className='space-y-8'>
                  <div className='flex gap-4'>
                    <div className='flex-1'>
                      <Field
                        type='text'
                        name='firstName'
                        placeholder='First Name'
                        className={inputStyle}
                      />
                      <ErrorMessage
                        name='firstName'
                        component='div'
                        className={errorStyle}
                      />
                    </div>
                    <div className='flex-1'>
                      <Field
                        type='text'
                        name='lastName'
                        placeholder='Last Name'
                        className={inputStyle}
                      />
                      <ErrorMessage
                        name='lastName'
                        component='div'
                        className={errorStyle}
                      />
                    </div>
                  </div>
                  <div>
                    <Field
                      type='email'
                      name='email'
                      placeholder='Email'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='email'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div>
                    <Field
                      type='password'
                      name='password'
                      placeholder='Password'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='password'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div>
                    <Field
                      type='password'
                      name='confirmPassword'
                      placeholder='Confirm Password'
                      className={inputStyle}
                    />
                    <ErrorMessage
                      name='confirmPassword'
                      component='div'
                      className={errorStyle}
                    />
                  </div>
                  <div className='text-center'>
                    <button
                      type='submit'
                      disabled={isSubmitting}
                      className={buttonStyle}
                    >
                      {isSubmitting ? 'Submitting' : 'Signup'}
                    </button>
                    <div className='my-4'>
                      <p className={errorStyle}>{serverResponse}</p>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>

        <Link to={`/login/${slug}`} className={linkStyle}>
          Login Instead?
        </Link>
      </div>
    </div>
  );
};

export default SignUp;
