import React, { useRef, useState } from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FaAngleLeft } from 'react-icons/fa';
import {
  API_URL,
  UPLOAD_OR_DOWNLOAD_URL,
} from '../../../../../config/API_LINKS';

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className='grid grid-cols-12 flex items-center'>
      <label
        className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        className='col-span-10 rounded shadow border focus:outline-none w-full px-4 py-1 text-gray-600 text-sm font-semibold'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs ml-2'>{meta.error}</div>
      ) : null}
    </div>
  );
};
const TextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className='grid grid-cols-12 flex'>
      <label
        className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <textarea
        className='col-span-10 rounded shadow border focus:outline-none w-full h-32 px-4 mr-6 py-1 text-gray-600 text-sm font-semibold'
        {...field}
        {...props}
      ></textarea>
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs ml-2'>{meta.error}</div>
      ) : null}
    </div>
  );
};

const TicketForm = (props) => {
  const [serverResponse, setServerResponse] = useState('');
  const formikRef = useRef();
  const [attachments, setAttachments] = useState([]);
  const [fileloading, setFileloading] = useState('');

  async function uploadFile(files, cognitoId = props.cognitoId) {
    if (attachments.length + files.length > 3) {
      alert('you can upload maximum 3 file');
    } else {
      setFileloading('file uploading');
      const formData = new FormData();
      if (files.length !== 0) {
        for (const single_file of files) {
          formData.append('file', single_file);
        }
      }
      formData.append('cognitoId', cognitoId);

      const result = await axios.post(
        API_URL + '/aws-upload/multiple',
        formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        }
      );
      if (result.status === 200) {
        setFileloading('file uploaded');
        setAttachments((arr) => arr.concat(result.data.publicUrl));
      }
    }
  }

  async function deleteFile(key) {
    axios({
      method: 'DELETE',
      url: API_URL + '/aws-upload/delete',
      data: { key: key },
    })
      .then(function (response) {
        setFileloading('file removed');
        setAttachments((arr) =>
          arr.filter((image) => {
            return image !== key;
          })
        );
      })
      .catch(function (response) {
        console.log('Error');
      });
  }

  return (
    <div>
      <div className='flex items-center ml-8 mt-8'>
        <FaAngleLeft onClick={() => props.setTicketType(null)} />
        <p className='ml-3 text-gray-600 font-bold text-xl'>Create the query</p>
      </div>
      <div className='border border-gray-300 p-2 md:p-8 mx-3 md:mx-8 mt-8 rounded'>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            user: props.userId,
            department: props.ticketType,
            assignee: props.ticketAssignee,
            batch: props.batchId,
            attachments: [],
            subject: '',
            description: '',
          }}
          validationSchema={Yup.object({
            subject: Yup.string()
              .min(2, 'Must be 3 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required('Please enter a Subject'),
            description: Yup.string()
              .min(2, 'Must be 3 characters or more')
              .max(1000, 'Must be 1000 characters or less')
              .required('Please enter a Description'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            values.attachments = attachments;
            axios({
              method: 'POST',
              url: API_URL + '/ticket',
              data: values,
            })
              .then(function (response) {
                setServerResponse(response.data);
                setSubmitting(false);
                response.status === 200 && resetForm();
                props.setShowTicket(false);
                props.setUpdated((prev) => !prev);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form className=' rounded-xl'>
              <div className=''>
                <div className='mb-4'>
                  <TextInput
                    label='Subject'
                    name='subject'
                    type='text'
                    placeholder='Type the Subject of the message'
                  />
                </div>
                <div className='mb-4'>
                  <TextArea
                    label='Message'
                    name='description'
                    type='text'
                    placeholder='Type the Body of the message (max 1000 characters)'
                  />
                </div>
                <div className='grid grid-cols-12 flex items-center mb-4'>
                  <label className='col-span-2 text-gray-600 font-semibold text-xs md:text-base'>
                    Assigned to
                  </label>
                  <p className='col-span-10 bg-white rounded shadow border focus:outline-none w-full px-4 py-1 text-gray-600 text-sm font-semibold'>
                    {props.ticketType === 'Belong' && props.isCareereraUser
                      ? 'Careerera'
                      : props.ticketType}
                  </p>
                </div>
                <div className='grid grid-cols-12 space-x-4 flex'>
                  <label className='col-span-2 flex flex-col text-gray-600 font-semibold text-xs md:text-base'>
                    Attachment
                    <span>(if any)</span>
                  </label>
                  <div className='col-span-10 flex flex-col'>
                    <div className='rounded-lg overflow-hidden'>
                      <div className='md:flex'>
                        <div className='w-full'>
                          <div className='relative h-20 rounded-lg border-dashed font-semibold text-gray-600 border-2 border-primary bg-white flex justify-center items-center hover:bg-primary hover:text-white'>
                            <div className='absolute'>
                              <div className='flex flex-col items-center'>
                                {' '}
                                <i className='fa fa-folder-open fa-4x text-blue-700'></i>{' '}
                                <span className='block'>
                                  Attach you files here
                                </span>{' '}
                              </div>
                            </div>{' '}
                            <input
                              type='file'
                              multiple
                              onChange={(e) => uploadFile(e.target.files)}
                              className='h-full w-full opacity-0'
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-3 gap-4'>
                {attachments.map((imageUrl, index) => {
                  return (
                    <div
                      key={index}
                      className='flex flex-col items-center mt-8'
                    >
                      <img
                        src={`${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`}
                        alt='img'
                        className='rounded object-cover'
                      />
                      <button
                        type='button'
                        onClick={() => deleteFile(imageUrl)}
                        className='text-sm bg-red-400 px-4 py-1 rounded text-gray-50 hover:bg-red-300 mt-2'
                      >
                        Remove
                      </button>
                    </div>
                  );
                })}
              </div>

              <div className='flex justify-end text-center mt-8'>
                {fileloading !== 'file uploading' && (
                  <button
                    type='submit'
                    className='bg-primary font-semibold text-sm px-4 py-2 rounded text-gray-50 hover:text-gray-700 hover:bg-primary-light'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Processing...' : 'Submit'}
                  </button>
                )}
              </div>
              {serverResponse && <div className='m-4'>{serverResponse}</div>}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TicketForm;
