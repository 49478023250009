import React from 'react';

import Main from './main/main';

const Index = ({ data }) => {
  return (
    <div>
      <Main data={data} />
    </div>
  );
};

export default Index;
