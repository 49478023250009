import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';

const WeekBar = ({
  totalWeeks,
  weekNumber,
  setCurrentWeek,
}: {
  totalWeeks: number;
  weekNumber: number;
  setCurrentWeek: (value: number) => void;
}) => {
  return (
    <div className='space-y-2'>
      <div className='flex'>
        <div className='text-primary border rounded grid place-items-center  font-light'>
          <ChevronLeftIcon
            className={classNames('w-8 h-8 cursor-pointer', {
              'text-gray-400': weekNumber === 1,
            })}
            onClick={() => {
              if (weekNumber >= 2) setCurrentWeek(weekNumber - 1);
            }}
          />
        </div>
        <div className='rounded border text-center text-primary font-semibold flex-1 items-center flex justify-center'>
          <p>Week {weekNumber}</p>
        </div>
        <div className='text-primary border rounded grid place-items-center'>
          <ChevronRightIcon
            className={classNames('w-8 h-8 cursor-pointer', {
              'text-gray-400': weekNumber === totalWeeks,
            })}
            onClick={() => {
              if (weekNumber < totalWeeks) setCurrentWeek(weekNumber + 1);
            }}
          />
        </div>
      </div>

      <div className='flex justify-around p-1 rounded border text-primary'>
        <p className='text-center border-r-2 flex-1'>Events</p>
        <p className='text-center flex-1'>Tasks</p>
      </div>
    </div>
  );
};

export default WeekBar;
