import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import { axiosApi } from 'config/Axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { buttonStyle, errorStyle, inputStyle } from './authStyles';

const GoacbSignup = ({ data }: any) => {
  const [serverResponse, setServerResponse] = useState('');
  const navigate = useNavigate();
  const location: any = useLocation();
  const from = location.state?.from?.pathname || '/';
  const courses = data?.courses || [];
  const collage = [
    'Agnel Institute of Technology and Design, Assagao',
    "Dempo Charities Trust's Dhempe College of Arts & Science",
    'Don Bosco College, Panjim',
    "Dynanprassarak Mandal's College & Research Centre",
    'Fr. Agnel College of Arts & Commerce',
    'Government College of Arts, Science & Commerce, Khandola, Marcela – Goa',
    'Government College of Arts, Science and Commerce, Sanquelim - Goa',
    'Ganpat Parsekar College of Education',
    'Mandre College of Commerce, Economics & Management',
    'Narayan Zantye College of Commerce',
    'S.S. Dempo College of Commerce & Economics',
    "S.V's Sridora Caculo College of Commerce & Management Studies, Khorlim, Mapusa",
    'Sant Sohirobanath Ambiye Government College of Arts & Commerce, Pernem',
    'St. Xavier’s College of Arts, Science & Commerce, Mapusa',
    'Vidya Prabodhini College of Commerce, Education, Computer and Management',
    'Carmel College of Arts, Science & Commerce',
    'Cuncolim Education Society’s College of Arts & Commerce, Cuncolim',
    'Don Bosco College of Engineering, Fatorda',
    "DPM's SHREE MALLIKARJUN and Shri Chetan Manju Desai College",
    'Government College of Arts, Science and Commerce, Quepem- Goa.',
    'Government College of Commerce & Economics Borda, Margao',
    'MES’s Vasant Joshi College of Arts & Commerce',
    'GVM’s College of Commerce & Economics, Farmagudi, Ponda -Goa.',
    "P.E.S.'s Ravi S. Naik College of Arts & Science",
    'Padre Conceicao College of Engineering, Verna',
    'Parvatibai Chowgule College of Arts & Science',
    'Rosary College of Arts & Commerce, Navelim',
    'Sateri Pisani Education Society’s Goa Multi Faculty College, Ponda.',
    'Shree Rayeshwar Institute of Engineering & Information Technology, Shiroda',
    'St. Joseph Vaz College, Cortalim',
    'Swami Vivekanand Vidya Prasarak Mandal’s College of Commerce,Bori, Ponda',
    "VVM's Shree Damodar College of Commerce & Economics",
  ];

  return (
    <div>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          partnerId: data._id,
          source: 'B2B',
          course: '',
          university: '',
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
          lastName: Yup.string()
            .min(2, 'Too Short!')
            .max(50, 'Too Long!')
            .required('Required'),
          email: Yup.string().email('Invalid email').required('Required'),
          password: Yup.string()
            .required('Please enter your password')
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character'
            ),
          confirmPassword: Yup.string()
            .required('Please confirm your password')
            .when('password', {
              is: (password: string) =>
                password && password.length > 0 ? true : false,
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                "Password doesn't match"
              ),
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setServerResponse('');
          axiosApi
            .post('/user/b2b-signup', values)
            .then(async (res) => {
              await Auth.signIn(values.email, values.password);
            })
            .then((res) => {
              navigate(from, { replace: true });
            })
            .catch((err) => {
              setServerResponse(err?.response?.data?.message);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form className='space-y-8'>
            <div className='flex gap-4'>
              <div className='flex-1'>
                <Field
                  type='text'
                  name='firstName'
                  placeholder='First Name'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='firstName'
                  component='div'
                  className={errorStyle}
                />
              </div>
              <div className='flex-1'>
                <Field
                  type='text'
                  name='lastName'
                  placeholder='Last Name'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='lastName'
                  component='div'
                  className={errorStyle}
                />
              </div>
            </div>
            <div>
              <Field
                type='email'
                name='email'
                placeholder='Email'
                className={inputStyle}
              />
              <ErrorMessage
                name='email'
                component='div'
                className={errorStyle}
              />
            </div>

            <div>
              <Field
                as='select'
                name='university'
                placeholder='university'
                className={inputStyle}
              >
                <option value=''>Select University</option>
                {collage.map((el: string) => {
                  return (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                name='course'
                component='div'
                className={errorStyle}
              />
            </div>
            <div>
              <Field
                as='select'
                name='course'
                placeholder='course'
                className={inputStyle}
              >
                <option value=''>Select Course</option>
                {courses.map((el: string) => {
                  return (
                    <option key={el} value={el}>
                      {el}
                    </option>
                  );
                })}
              </Field>
              <ErrorMessage
                name='course'
                component='div'
                className={errorStyle}
              />
            </div>
            <div>
              <Field
                type='password'
                name='password'
                placeholder='Password'
                className={inputStyle}
              />
              <ErrorMessage
                name='password'
                component='div'
                className={errorStyle}
              />
            </div>
            <div>
              <Field
                type='password'
                name='confirmPassword'
                placeholder='Confirm Password'
                className={inputStyle}
              />
              <ErrorMessage
                name='confirmPassword'
                component='div'
                className={errorStyle}
              />
            </div>
            <div className='text-center'>
              <button
                type='submit'
                disabled={isSubmitting}
                className={buttonStyle}
              >
                {isSubmitting ? 'Submitting' : 'Signup'}
              </button>
              <div className='my-4'>
                <p className={errorStyle}>{serverResponse}</p>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GoacbSignup;
