import React from 'react';
import Result from './Result';
import Instructions from './Instructions';

const Index = ({ userData, batchId }: any) => {
  return (
    <div className='m-4 lg:m-10'>
      {userData?.vgaQuizData ? (
        <Result userData={userData} />
      ) : (
        <Instructions userData={userData} batchId={batchId} />
      )}
    </div>
  );
};

export default Index;
