import React from 'react';
import { format } from 'date-fns';

import OverviewCard from '../../layout/OverviewCard';
import AboutSection from '../../layout/AboutSection';
import Tasks from '../../layout/Tasks';

function Project(props) {
  const userSpecificTasks = props.userSpecificTasks;
  const uniqueUserId = props.data.cognitoId;
  const isCareereraUser = props.isCareereraUser;

  const companyTasks = userSpecificTasks
    .map((task) => ({
      ...task,
    }))
    .filter(
      (task) =>
        task.assignmentId.type === 'Company Tasks' &&
        task.assignmentId.status === 'publish'
    );

  return (
    <div className='bg-gray-100 py-4'>
      <OverviewCard
        imageUrl='https://res.cloudinary.com/belong/image/upload/v1643360201/uploaded_resources/Project_Task_Banner_Logo_nrcc4x.png'
        overviewHeader={`Your Tasks From The ${
          isCareereraUser ? 'Internship' : 'Apprenticeship'
        }  Company`}
        list1='Entrepreneurial'
        list2='Communication'
        list3='Industry Insights'
      />
      <AboutSection heading='What You Need To Achieve?'>
        <p>
          You will be allocated tasks by your{' '}
          {isCareereraUser ? ' Internship ' : ' apprenticeship '} company here
          as per your calendar. These tasks will pertain to the stream you have
          been trained for.
        </p>
        <p>
          You can seek support on your tasks from the coach assigned to you and
          submit your completed work in this section.
        </p>
        <p>
          Please make sure to plan your work and schedule to avoid missing out
          on deadlines.
        </p>
      </AboutSection>

      {companyTasks.length !== 0 && (
        <div className='flex mt-2  p-4'>
          <div className='h-2 w-2 m-2 bg-primary mb-2 '></div>
          <div className='text-sm'>
            <h2>
              <b>Task Schedule</b>
            </h2>
          </div>
        </div>
      )}

      {companyTasks.map((task) => {
        return (
          <Tasks
            key={task.assignmentId._id}
            taskSubmissions={props.data.taskSubmissions}
            assignmentId={task.assignmentId._id}
            uniqueUserId={uniqueUserId}
            heading={task.assignmentId.title}
            about={task.assignmentId.description}
            aboutpdf={task.assignmentId.fileName}
            fileLink={task.assignmentId.assignmentFileUrl}
            duedate={format(
              new Date(task.assignmentId.dueDateTime),
              'do LLLL p'
            )}
            publishAt={task.assignmentId.publishAt}
          />
        );
      })}
    </div>
  );
}

export default Project;
