import { initialState } from './types';
import { addMinutes, differenceInSeconds } from 'date-fns';

export function reducer(draft: initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_REMAINING_TIME':
      draft.overallTimeLeft.minutes = action.payload.minutesLeft;
      draft.overallTimeLeft.seconds = action.payload.secondsLeft;
      return;
    case 'UPDATE_REMAINING_CATEGORY_TIME':
      draft.categoryTimeLeft.minutes = action.payload.minutesLeft;
      draft.categoryTimeLeft.seconds = action.payload.secondsLeft;
      return;
    case 'UPDATE_CURRENT_QUESTION':
      //Payload is the index of the question
      //Updating status as visited if not answered already and current question as payload
      draft.currentQuestion = action.payload;
      const targetQuestion =
        draft.responses[draft.currentCategoryNo].questions[action.payload];
      if (!targetQuestion.status) {
        targetQuestion.status = 'visited';
      }
      return;
    case 'UPDATE_OPTIONS':
      //To update status and options of a question based on option selected or not
      const maxSelect = action.payload.maxSelect;
      const selectedOption = action.payload.option;

      //All current selected options object
      const allSelectedOptionsObjects = draft.responses[
        draft.currentCategoryNo
      ].questions[draft.currentQuestion].options.filter(
        (option: any) => option.selected
      );
      //All current selected ooptions id
      let allSelectedOptions = new Set(
        allSelectedOptionsObjects.map((option: any) => option._id)
      );
      //If option is already selected remove it
      if (allSelectedOptions.has(selectedOption)) {
        allSelectedOptions.delete(selectedOption);
      } else {
        //If max num are not selected, select otherwise remove last and then add
        if (allSelectedOptions.size < maxSelect) {
          allSelectedOptions.add(selectedOption);
        } else {
          const tempArray = Array.from(allSelectedOptions);
          tempArray.pop();
          allSelectedOptions = new Set(tempArray);
          allSelectedOptions.add(selectedOption);
        }
      }
      //Update all the options selected
      draft.responses[draft.currentCategoryNo].questions[
        draft.currentQuestion
      ].options.forEach((option: any) => {
        if (allSelectedOptions.has(option._id)) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
      //If maxSelect options are selected change question status to answered otherwise visited
      draft.responses[draft.currentCategoryNo].questions[
        draft.currentQuestion
      ].status = allSelectedOptions.size === maxSelect ? 'answered' : 'visited';
      return;
    case 'UPDATE_INPUT':
      //To update status and options of a question based on option selected or not
      const inputesponsesCopy = [
        ...draft.responses.map((res: any) => {
          return res;
        }),
      ];

      const inputCategoryResponsesCopy = inputesponsesCopy[
        draft.currentCategoryNo
      ].questions.map((qu: any) => {
        if (qu.responseType === 'text') {
          return {
            ...qu,
            status: qu.status || '',
            userInput: qu.userInput || '',
          };
        }
        return { ...qu, status: qu.status || '' };
      });
      inputCategoryResponsesCopy[draft.currentQuestion].userInput =
        action.payload.value;
      //Update the question in responses copy
      inputCategoryResponsesCopy[draft.currentQuestion] = {
        ...inputCategoryResponsesCopy[draft.currentQuestion],

        status: action.payload.value ? 'answered' : 'visited',
      };
      inputesponsesCopy[draft.currentCategoryNo] = {
        ...inputesponsesCopy[draft.currentCategoryNo],
        questions: inputCategoryResponsesCopy,
      };
      //return new state
      return {
        ...draft,
        responses: inputesponsesCopy,
      };
      case 'UPDATE_CATEGORY':
        const newCategoryNo = draft.currentCategoryNo + 1;
        draft.currentCategoryNo = newCategoryNo;
        draft.currentQuestion = 0;
        draft.totalQuestions = draft.responses[newCategoryNo].questions.length;
        draft.responses[newCategoryNo]["startTime"] = new Date();
        return; 
      default:
        return draft;
    }
}

export function getRemainingTime(startTime: string, timeInMinutes: number) {
  //Function to return remaining time in seconds based on start time, current time and the time in minutes given as parameter
  const currentTime = new Date();
  const endTime = addMinutes(new Date(startTime), timeInMinutes);
  const timeLeft = differenceInSeconds(endTime, currentTime);
  return timeLeft;
}
