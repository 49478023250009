import React from 'react';
import { formatDistance } from 'date-fns';

const IndividualQuery = ({ ticket, SetIsReplySelected }: any) => {
  const ticketDate = formatDistance(new Date(ticket.createdAt), new Date(), {
    addSuffix: true,
  });
  const comment = ticket.activity.filter((act: any) => {
    return act.comment;
  });
  return (
    <div
      onClick={() => SetIsReplySelected(ticket)}
      key={ticket._id}
      className='border rounded cursor-pointer p-2 md:p-4 bg-gray-50'
    >
      <div className='lg:flex lg:justify-between'>
        <div className='flex space-x-2  items-center'>
          <div className='flex-none font-semibold bg-primary-light text-lg md:text-2xl text-white h-12  w-12   flex items-center my-auto justify-center rounded-full'>
            {ticket.department[0]}
          </div>
          <div className='space-y-2'>
            <p className='font-semibold'>{ticket.subject}</p>
          </div>
        </div>
        <div className='flex space-x-2 mt-4 lg:mt-0 items-center'>
          <div>
            <p className='w-28 text-center px-2 py-1 font-medium md:font-semibold border border-primary rounded-2xl text-sm  bg-white text-primary'>
              {ticketDate}
            </p>
          </div>
          {comment.length > 0 && (
            <div className='px-3'>
              <strong className='relative inline-flex items-center rounded border border-gray-200 px-2.5 py-1.5 text-xs font-medium'>
                <span className='absolute -top-2 -right-2 h-5 w-5 rounded-full bg-yellow-300 flex justify-center items-center items'>
                  <span>{comment.length}</span>
                </span>
                <span className='ml-1.5 text-green-700'>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1670586381/new%20b2b/clarity_bell-solid_ym220q.svg'
                    alt='bell'
                    className='h-6 lg:h-8'
                  />
                </span>
              </strong>
            </div>
          )}
        </div>
      </div>
      <div className='text-sm text-left text-textColor-lighter mt-1'>
        {ticket.description}
      </div>
    </div>
  );
};

export default IndividualQuery;
