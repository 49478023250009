import { useState, Fragment } from 'react';
import { FaLock } from 'react-icons/fa';
// import CapstoneCertificate from './CapstoneCertificate';
import { Dialog, Transition } from '@headlessui/react';
import Feedback from 'components/capstone/feedback';
import { useGetUserQuery } from 'components/profile/userSlice';
import CompleteCertificate from './CompleteCetificate';

interface allProps {
  setShowQuiz: any;
  enrollData: any;
  capstoneData: any;
}

function diff_hours(dt2: any, dt1: any) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.round(diff);
}

const QuizStatus = (props: allProps) => {
  const enrollData = props.enrollData;
  const capstoneData = props.capstoneData;
  const { data } = useGetUserQuery();
  const partnerLogo = data?.partnerId?.logoUrl;
  const partnerName = data?.partnerId?.name;

  const [isOpen, setIsOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);

  function checkQuizConditions() {
    const isLocked =
      !enrollData ||
      capstoneData?.tasks?.length > enrollData?.capstoneSubmissions?.length;
    const isQuizDone = enrollData?.capstoneAssessments?.timeTaken;
    const isPercentage = enrollData?.capstoneAssessments?.percentage >= 60;

    const isFeedbackDone = enrollData?.feedback?.submitTime;

    const totalAttempts = enrollData?.totalAttempt || 0;

    if (isLocked) {
      return (
        <>
          <button
            disabled
            className='rounded font-semibold border text-white bg-primary px-10 py-2'
          >
            <FaLock className='text-lg' />
          </button>
          <p className='text-sm text-primary mt-1'>
            Unlocks post task submissions
          </p>
        </>
      );
    }
    if (!isQuizDone) {
      return (
        <>
          <button
            onClick={() => props.setShowQuiz(true)}
            className='rounded  font-semibold border text-white bg-primary  px-10 py-1'
          >
            {`Start Quiz`}
          </button>
          <div className='text-xs mt-1'>
            Total Attempts: {totalAttempts + 1} / 3
          </div>
        </>
      );
    }
    //Should be isFeedbackDone
    if (isFeedbackDone) {
      return (
        <>
          <button
            onClick={() => setIsOpen(true)}
            className='text-white bg-primary px-8 py-1 my-4 rounded cursor-pointer'
          >
            Download Certificate
          </button>
        </>
      );
    }

    if (isPercentage) {
      return (
        <>
          <button
            onClick={() => {
              setShowFeedback(true);
            }}
            className='text-white bg-primary px-8 py-1 my-4 rounded cursor-pointer'
          >
            Get Certificate
          </button>
        </>
      );
    } else {
      if (totalAttempts > 2) {
        return (
          <div>
            <button
              disabled
              className='rounded font-semibold border text-white bg-primary px-10 py-2'
            >
              <FaLock className='text-lg' />
            </button>
            <p className='text-sm text-red-400 mt-1'>
              You have exhausted your free attempts limits, please contact the
              Belong administrator at <b>connect@belong.education</b>
            </p>
          </div>
        );
      }

      const dt1 = new Date(enrollData?.capstoneAssessments?.finishTime);
      const dt2 = new Date(Date.now());
      const timeLeft = diff_hours(dt1, dt2) + 48;

      if (timeLeft > 0) {
        return (
          <>
            <button
              disabled
              className='rounded font-semibold border text-white bg-primary px-10 py-2'
            >
              <FaLock className='text-lg' />
            </button>
            <p className='text-sm text-primary mt-1'>
              You can re-attempt the test after {timeLeft} hours
            </p>
          </>
        );
      } else {
        return (
          <>
            <button
              onClick={() => props.setShowQuiz(true)}
              className='rounded  font-semibold border text-white bg-primary  px-10 py-1'
            >
              {`Try Again`}
            </button>
            <div className='text-xs mt-1'>
              Total Attempts: {totalAttempts + 1} / 3
            </div>
          </>
        );
      }
    }
  }

  return (
    <>
      {checkQuizConditions()}

      <Transition appear show={showFeedback} as={Fragment}>
        <Dialog
          as='div'
          className='relative z-10'
          onClose={() => {
            setShowFeedback(false);
          }}
        >
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-full max-w-fit transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <div className='mt-2'>
                    <Feedback
                      enrollmentId={enrollData?._id}
                      setShowFeedback={setShowFeedback}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='relative z-50'
      >
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex items-center justify-center w-auto bg-gray-100'>
            <div>
              <Dialog.Panel className='mx-auto rounded bg-white'>
                {/* <CapstoneCertificate
                  partnerLogo={partnerLogo}
                  user={data}
                  capstoneData={capstoneData}
                  setIsOpen={setIsOpen}
                /> */}
                <CompleteCertificate
                  partnerName={partnerName}
                  partnerLogo={partnerLogo}
                  user={data}
                  capstoneData={capstoneData}
                  setIsOpen={setIsOpen}
                />
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default QuizStatus;
