import { useEffect, useState } from 'react';
import CompanyProject from './layout/CompanyProject';
import DatasetText from './layout/DatasetText';
import MilestonesCard from './layout/MilestonesCard';
import Progress from './layout/Progress';
import QuickTipsSection from './layout/QuickTipsSection';
import Overview from './Overview';
import SecondTaskView from './second-task-view/index';
import { ProjectTaskType } from '../types';
import { useGetUserProjectQuery } from '../userBatchSlice';
import ProjectDataHeader from './layout/ProjectDataHeader';

const ProjectTasksPanel = ({
  aboutCompanyData,
  projectData,
}: ProjectTaskType) => {
  const [isTaskView, setIsTaskView] = useState(null);
  const projectId = projectData?._id;
  const batchId = projectData?.batchId;
  const { data, isSuccess } = useGetUserProjectQuery({
    projectId,
    batchId,
  });
  let tasks = [];
  if (isSuccess) {
    const tasksubmission = data?.tasksubmission || [];
    tasks = projectData?.tasks.map((task: any) => {
      const userTaskSubmission = tasksubmission.filter((userTask: any) => {
        return userTask.taskId === task._id;
      })[0];
      if (userTaskSubmission) {
        return {
          ...task,
          solution: userTaskSubmission?.solution,
          score: userTaskSubmission?.score,
          coachNote: userTaskSubmission?.coachNote,
          isLocked: new Date() < new Date(task.publishAt),
        };
      } else {
        return { ...task, isLocked: new Date() < new Date(task.publishAt) };
      }
    });
  }
  const myProjectData = { ...projectData, tasks };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isTaskView]);

  return (
    <div className='md:border rounded-b-lg text-textColor-lighter'>
      <ProjectDataHeader projectData={myProjectData} />
      {isTaskView ? (
        <SecondTaskView
          setIsTaskView={setIsTaskView}
          batchId={batchId}
          projectId={projectId}
          taskData={isTaskView}
        />
      ) : (
        <div className='px-4 md:px-8 space-y-14'>
          <CompanyProject />
          <QuickTipsSection />
          <Overview aboutCompanyData={aboutCompanyData} />
          <DatasetText projectData={myProjectData} />
          <Progress projectData={myProjectData} />
          <MilestonesCard
            setIsTaskView={setIsTaskView}
            projectData={myProjectData}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectTasksPanel;
