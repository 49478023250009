import React from 'react';
import { FaArrowCircleRight } from 'react-icons/fa';
import classNames from 'classnames';

interface allProps {
  data: any;
  index: number;
  isTaskDone: any;
  isCurrentTask: any;
}

const TaskIcon = (props: allProps) => {
  const taskDependency = props.data.taskDependency;
  const isTaskDone = props.isTaskDone;
  const isCurrentTask = props.isCurrentTask;

  return (
    <div
      className={classNames(
        'border-2 sm:border-4 border-primary rounded-full h-8 sm:h-12 w-8 sm:w-12 text-primary text-center',
        {
          'bg-primary': isTaskDone,
        },
        {
          'bg-white': !isTaskDone,
        }
      )}
    >
      {isCurrentTask ? (
        <div className='h-4 sm:h-6 w-4 sm:w-6 bg-primary ml-1.5 sm:ml-2 mt-1.5 sm:mt-2 rounded-full'></div>
      ) : taskDependency ? (
        <div
          className={classNames('mt-1 sm:mt-2 font-semibold', {
            'text-white': isTaskDone,
          })}
        >
          0{props.index + 1}
        </div>
      ) : (
        <FaArrowCircleRight
          className={classNames('ml-1.5 sm:ml-2 mt-1.5 sm:mt-2  sm:text-2xl', {
            'text-white': isTaskDone,
          })}
        />
      )}
    </div>
  );
};

export default TaskIcon;
