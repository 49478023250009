import React from 'react';

const Progress = ({ projectData }: { projectData: any }) => {
  const scores = projectData.tasks
    ?.filter((task: any) => {
      return task?.score?.total;
    })
    .map((el: any) => {
      return el?.score?.total;
    });

  const avgScore = scores.length
    ? scores.reduce((a: any, b: any) => a + b) / scores.length
    : 0;
  return (
    <div className=' mt-4 md:mt-10'>
      <div className='sm:flex justify-between '>
        <p className='font-semibold text-lg'>Your Project Tasks</p>
        {avgScore > 0 && (
          <p className='text-white text-sm px-4 py-1 mt-2 sm:mt-0 max-w-fit rounded-2xl bg-primary-light font-medium '>
            Project Performance - {avgScore.toFixed()}%
          </p>
        )}
      </div>
    </div>
  );
};

export default Progress;
