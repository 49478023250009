import React, { useState } from 'react';

import Timeline from './Timeline';
import OverviewCard from '../../layout/OverviewCard';
import WeekButtons from './WeekButtons';

const Calendar = (props) => {
  const currentWeek = props.currentWeek;
  const userSpecificTasks = props.userSpecificTasks;
  const userSpecificEvents = props.userSpecificEvents;
  const isCareereraUser = props.isCareereraUser;

  const batchWeek = props.data.batches[0].week;
  const [selectedWeek, setSelectedWeek] = useState(
    Math.min(currentWeek, batchWeek)
  );
  const startDate = props.startDate;
  let etpdueDateData = [];
  if (props.showEtp) {
    for (let i = 1; i < 5; i++) {
      let etpDate = new Date(startDate);
      etpDate.setDate(etpDate.getDate() + (7 * i - 1));
      etpdueDateData.push({
        assignmentId: {
          week: i,
          title: `Company project task- ${i}`,
        },
        _id: i,
        dateTime: etpDate,
      });
    }
  }

  const batchId = props.data.batches[0]._id;

  const _getTimelineData = () => {
    const eventsData = userSpecificEvents
      .map((event) => ({
        ...event,
        dateTime: event.eventId.dateTime,
      }))
      .filter(
        (event) =>
          event.eventId.week === selectedWeek &&
          event.eventId.status === 'publish'
      );
    const etpWeek = etpdueDateData.filter((etp) => {
      return etp.assignmentId.week === selectedWeek;
    });
    const assignmentsData = userSpecificTasks
      .map((assignment) => ({
        ...assignment,
        dateTime: assignment.assignmentId.dueDateTime,
      }))
      .filter(
        (assignment) =>
          assignment.assignmentId.week === selectedWeek &&
          assignment.assignmentId.status === 'publish'
      );

    etpWeek.length && assignmentsData.push(etpWeek[0]);
    const combinedData = [...eventsData, ...assignmentsData];

    combinedData.sort(
      (eventsData, assignmentsData) =>
        new Date(eventsData.dateTime) - new Date(assignmentsData.dateTime)
    );

    return combinedData;
  };
  return (
    <div className=' bg-gray-100 py-4'>
      {props.isVGE ? (
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1643358929/uploaded_resources/Calendar-Banner_logo_i9gkux.png'
          overviewHeader='Your VGE Schedule In One Place'
          list1='Expert Sessions'
          list2='Assignments'
          list3='Deadlines'
        />
      ) : (
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1643358929/uploaded_resources/Calendar-Banner_logo_i9gkux.png'
          overviewHeader={`Your ${
            isCareereraUser ? 'Internship' : 'Apprenticeship'
          }  Schedule In One Place`}
          list1='Classes'
          list2='Connects'
          list3='Deadlines'
        />
      )}

      <div className='sticky z-10 p-3 bg-gray-100 text-xs text-center'>
        <WeekButtons
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          batchId={batchId}
          batchWeek={batchWeek}
        />
        <Timeline data={props.data} _getTimelineData={_getTimelineData} />
      </div>
    </div>
  );
};

export default Calendar;
