import React from 'react';

const SkillText = () => {
  return (
    <div className=' mt-4 md:mt-10'>
      <p className='font-semibold text-lg'>Tasks & Skill Milestones</p>
    </div>
  );
};

export default SkillText;
