const CompanyText = ({
  contentType,
  contentToPublish,
}: {
  contentType: string;
  contentToPublish: string;
}) => {
  //Cases: Text, List, Tab, Link, Image, File
  return (
    <>
      {contentType === 'Text' && <p className='mt-4'>{contentToPublish}</p>}
      {contentType === 'List' && (
        <ul className='list-disc mt-4 ml-4'>
          <li>{contentToPublish}</li>
        </ul>
      )}
      {contentType === 'Tab' && (
        <div className='mr-4 mt-4'>
          <span className='border border-primary-light rounded-lg px-3 py-1'>
            {contentToPublish}
          </span>
        </div>
      )}
      {contentType === 'Link' && (
        <div className='mt-4'>
          <a href={contentToPublish} className='text-primary font-semibold'>
            {contentToPublish}
          </a>
        </div>
      )}
      {contentType === 'Image' && (
        <div className='mt-4'>
          <img src={contentToPublish} className='h-24' alt='company' />
        </div>
      )}
      {contentType === 'File' && (
        <div className='mt-4'>
          <a
            href={contentToPublish}
            download
            className='text-primary font-semibold'
          >
            {contentToPublish}
          </a>
        </div>
      )}
    </>
  );
};

export default CompanyText;
