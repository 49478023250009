import React, { useState } from 'react';
import htmlparser from 'html-react-parser';
import { useAddViewDatasetMutation } from '../../userBatchSlice';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import classNames from 'classnames';

const DatasetText = ({ projectData }: any) => {
  const { basicDetails } = useBasicDetailsContext();
  const userId = basicDetails?.userId;
  const {
    dataset,
    businessObjectives,
    problemStatement,
    viewDatasetUsers = [],
  } = projectData;

  const isAllreadyView = viewDatasetUsers.find(
    (el: any) => el.userId === userId
  );
  const [viewed, setViewed] = useState(Boolean(isAllreadyView));
  const [submitView] = useAddViewDatasetMutation();
  function submitFn() {
    if (!viewed) {
      submitView({
        projectId: projectData._id,
      })
        .unwrap()
        .then((res: any) => {
          setViewed(true);
        })
        .catch((res: any) => {});
    }
  }
  return (
    <div className='space-y-14  text-sm mt-4 md:mt-10'>
      {businessObjectives && (
        <div className='space-y-4 mt-4 md:mt-10 text-sm'>
          <p className='font-semibold text-lg'>Business Objectives</p>
          <div className='px-4 prose prose-sm max-w-none text-base'>
            {htmlparser(businessObjectives)}
          </div>
        </div>
      )}
      {problemStatement && (
        <div className='space-y-4 mt-4 md:mt-10 text-sm'>
          <p className='font-semibold text-lg'>Problem Statement</p>
          <div className='px-4 prose prose-sm max-w-none text-base'>
            {htmlparser(problemStatement)}
          </div>
        </div>
      )}

      {dataset?.link && (
        <>
          <div className=''>
            <p className='font-semibold text-lg'>Dataset</p>
            {dataset?.link && (
              <button
                onClick={() => submitFn()}
                className={classNames(
                  'bg-primary text-white rounded-xl px-6 py-1 mt-4'
                )}>
                <a
                  href={dataset?.link}
                  target='_blank'
                  rel='noreferrer noopener'>
                  <span className='font-medium'>Download</span>
                </a>
              </button>
            )}
          </div>
          {dataset?.description && (
            <div className='px-4 prose prose-sm max-w-none text-base'>
              {htmlparser(dataset?.description)}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DatasetText;
