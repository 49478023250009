import React, { useReducer } from 'react';
import CoachSupportPanel from '../../dashboard/vga/coach-support/CoachSupportPanel';
// import CompanyConnectPanel from '../../dashboard/vga/company-connect/CompanyConnectPanel';
import {
  getCoachConnectData,
  // getCompanyConnectData,
  getMasterclassConnectData,
  getTabsData,
  getProjectTaskData,
  // getCenturyLabData,
  getQueryTabData,
  getCompanyConnectData,
  getCenturyLabData,
} from '../../dashboard/vga/helper';
import JourneyPanel from '../../dashboard/vga/journey/JourneyPanel';
import MasterclassPanel from '../../dashboard/vga/masterclass/MasterclassPanel';
// COMMENT: replaced by SuperProjectTaskPanel
// import ProjectTasksPanel from '../../dashboard/vga/project-tasks/ProjectTasksPanel';
import QueryPanel from '../../dashboard/vga/query/QueryPanel';
// import CenturyLabPanel from '../../dashboard/vga/21st-century-lab/CenturyLabPanel';
import DomainQuizPanel from '../../dashboard/vga/domain-quiz/DomainQuizPanel';
import {
  CoachConnectType,
  // CompanyConnectType,
  InitialTab,
  MasterclassConnectType,
  TabType,
  QueryTabType,
  CompanyConnectType,
} from '../../dashboard/vga/types';
import ScoreCertificationPanel from '../../dashboard/vga/score-certification/ScoreCertificationPanel';
import { finalQuizBatches, vgeBatch } from 'config/common';
// import SuperProjectTaskPanel from '../super-project-tasks/SuperProjectTaskPanel';
import CompanyConnectPanel from 'components/dashboard/vga/company-connect/CompanyConnectPanel';
import ProjectTasksPanel from 'components/dashboard/vga/project-tasks/ProjectTasksPanel';
import TimeSheetPanel from 'components/dashboard/vga/time-sheet/TimeSheetPanel';
import CenturyLabPanel from 'components/dashboard/vga/21st-century-lab/CenturyLabPanel';
// import TimeSheetPanel from '../../dashboard/vga/time-sheet/TimeSheetPanel';
import FinalQuiz from 'components/dashboard/vga/finalQuiz';

export default function useSuperTabs(data: any) {
  const batchData = data?.batches[0];
  const batchDomain = batchData?.domainId;
  const userId = data?._id;
  const tabProps: TabType = getTabsData(batchData);
  const userData = batchData.users[0];
  const isVge = vgeBatch.includes(batchData._id);
  const isFinalQuiz = finalQuizBatches.includes(batchData._id);
  console.log(isFinalQuiz);
  const dt1 = new Date(batchData.startDate);
  const dt2 = new Date();

  const totalDays = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  const currentWeek =
    Math.ceil((totalDays + 1) / 7) > 0 ? Math.ceil((totalDays + 1) / 7) : 1;

  const isFullJourney =
    batchData.timeSheet &&
    batchData.timeSheet.startsAt &&
    currentWeek >= batchData.timeSheet.startsAt;

  const feedbackGiven = userData.vgaFeedback;
  // const companyConnectProps: CompanyConnectType = getCompanyConnectData(
  //   batchData.users[0],
  //   batchData.events,
  //   userId,
  //   data?.email
  // );
  const coachConnectProps: CoachConnectType = getCoachConnectData(
    batchData.users[0],
    batchData.events,
    userId,
    data?.email
  );
  const masterclassConnectProps: MasterclassConnectType =
    getMasterclassConnectData(
      batchData.users[0],
      batchData.events,
      userId,
      data?.email
    );

  const initialState: { basicDetails: any; tabs: InitialTab[] } = {
    basicDetails: {},
    tabs: [],
  };

  //Add basic details that can be accessed via the state
  initialState.basicDetails = {
    userId,
    useremail: data?.email,
    batchId: batchData?._id,
    partnerId: data?.partnerId,
    coachNotifications: 0,
    isVge,
    isFullJourney,
  };
  const projectTaskProps = getProjectTaskData({
    userData,
    projects: batchData.projects,
  });
  // const centuryLabProps = getCenturyLabData({
  //   projects: batchData.projects,
  // });

  const queryPanelProps: QueryTabType = getQueryTabData(batchData);

  const addInitialState: (
    id: number,
    text: string,
    component: React.ReactElement,
    tabStatus?: 'ACTIVE' | 'INACTIVE'
  ) => void = (id, text, component, tabStatus = 'INACTIVE') => {
    initialState.tabs.push({
      id: id,
      text: text,
      status: tabStatus,
      component: component,
    });
  };

  const companyConnectProps: CompanyConnectType = getCompanyConnectData(
    batchData.users[0],
    batchData.events,
    userId,
    data?.email
  );

  const centuryLabProps = getCenturyLabData({
    projects: batchData.projects,
  });

  //First screen
  //The order of the screen matters, please check the files where tab id is being used
  addInitialState(1, 'Journey', <JourneyPanel data={data} />, 'ACTIVE');

  tabProps.companyConnect &&
    addInitialState(
      2,
      isVge ? 'Industry Connect' : 'Company Connect',
      <CompanyConnectPanel {...companyConnectProps} />
    );

  tabProps.projectTask &&
    addInitialState(
      3,
      'Company Project',
      <ProjectTasksPanel {...projectTaskProps} />
      // <SuperProjectTaskPanel {...projectTaskProps} />
    );
  isFullJourney &&
    addInitialState(
      10,
      'Time Sheet',
      <TimeSheetPanel batchDetails={batchData} />
    );
  tabProps.coachSupport &&
    addInitialState(
      4,
      'Coach Support',
      <CoachSupportPanel {...coachConnectProps} batchId={batchData._id} />
    );

  tabProps.masterclass &&
    addInitialState(
      5,
      'Masterclass',
      <MasterclassPanel {...masterclassConnectProps} />
    );
  tabProps.centuryLab &&
    addInitialState(6, 'Assignments', <CenturyLabPanel {...centuryLabProps} />);
  addInitialState(
    7,
    'Domain Quiz',
    <DomainQuizPanel
      userData={userData}
      batchId={batchData._id}
      batchDomain={batchDomain}
      openQuiz={tabProps.domainQuiz}
    />
  );
  isFinalQuiz &&
    addInitialState(
      11,
      'Final Quiz',
      <FinalQuiz
        userData={userData}
        batchId={batchData._id}
        batchDomain={batchDomain}
        openQuiz={tabProps.domainQuiz}
      />
    );
  tabProps.enableQueryManagement &&
    addInitialState(8, 'Query', <QueryPanel {...queryPanelProps} />);
  addInitialState(
    9,
    'Score & Certification',
    <ScoreCertificationPanel
      batchData={batchData}
      data={data}
      {...projectTaskProps}
      feedbackGiven={feedbackGiven}
    />
  );

  const reducer = (state: any, action: { type: string; payload: number }) => {
    switch (action.type) {
      case 'CHANGE_TAB':
        const changedTabs = state.tabs.map((tab: InitialTab) => {
          if (tab.id === action.payload) {
            return { ...tab, status: 'ACTIVE' };
          } else {
            return { ...tab, status: 'INACTIVE' };
          }
        });
        return { ...state, tabs: changedTabs };

      case 'UPDATE_COACH_NOTIFICATIONS':
        return {
          ...state,
          basicDetails: {
            ...state.basicDetails,
            coachNotifications: action.payload,
          },
        };
      default:
        return state;
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return [state, dispatch];
}
