import React, { useEffect } from 'react';
import CheckUserStatus from './CheckUserStatus';
import Loading from 'components/global/layout/Loading';
import { useGetCapstoneQuery } from '../capstoneSlice';
import { useParams } from 'react-router-dom';

const Index = (props: any) => {
  const { capstoneId } = useParams();
  const { isLoading, isSuccess, isError, data } = useGetCapstoneQuery({
    capstoneId,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className='grid grid-cols-12 lg:grid-cols-10'>
      <div className='col-span-12 lg:col-span-10'>
        <div className='bg-gray-100 px-2 mx-2 rounded-xl space-y-4 pb-6'>
          {isLoading && (
            <div>
              <Loading />
            </div>
          )}
          {isError && <div>Error fetching data</div>}
          {isSuccess && (
            <div>
              <CheckUserStatus data={data[0]} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
