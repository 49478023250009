import React from 'react';
import CardDetail from './CardDetail';

const LabCard = ({
  setIsLabView,
  centuryData,
}: {
  setIsLabView: any;
  centuryData: any;
}) => {
  //Sort by date
  const compareDates = (a: any, b: any) => {
    const dateA = new Date(a.tasks[0].dueDateTime);
    const dateB = new Date(b.tasks[0].dueDateTime);

    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  };

  centuryData.sort(compareDates);

  return (
    <>
      <div className='mt-4 md:mt-6 grid grid-cols-12 gap-4 xl:gap-8'>
        <div className='col-span-12 xl:col-span-6'>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Upcoming</p>
          </div>
        </div>
        <div className='col-span-12 xl:col-span-6'>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Completed</p>
          </div>
        </div>
      </div>
      <div>
        {centuryData.map((data: any) => (
          <CardDetail
            key={data.id}
            taskData={data}
            setIsLabView={setIsLabView}
          />
        ))}
      </div>
    </>
  );
};

export default LabCard;
