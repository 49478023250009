import React from 'react';

const RecentQueryButtons = ({
  ticketStatus,
  setTicketStatus,
  setTicketDetails,
}) => {
  const buttonStyle =
    'w-1/2 font-semibold text-sm py-2 hover:bg-primary-light text-gray-600';

  return (
    <div>
      <p className='text-gray-600 mt-8 px-4 md:px-10 font-bold mb-6 text-lg'>
        My Recent Queries
      </p>
      <div className='w-full flex px-10 text-sm font-semibold mb-6'>
        <button
          className={`${
            ticketStatus === 'open' ? 'bg-primary-light' : 'bg-white'
          } ${buttonStyle} border-r border-gray-700`}
          onClick={() => {setTicketStatus('open')
          setTicketDetails(false)}}
        >
          Ongoing
        </button>
        <button
          className={`${
            ticketStatus === 'closed' ? 'bg-primary-light' : 'bg-white'
          } ${buttonStyle}`}
          onClick={() => {
            setTicketStatus('closed');
            setTicketDetails(false);
          }}
        >
          Resolved
        </button>
      </div>
    </div>
  );
};

export default RecentQueryButtons;
