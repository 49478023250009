// import React, { useState } from 'react';
import { GoDotFill } from 'react-icons/go';
// import Modal from 'react-modal';
// import EngagementPopup from './EngagementPopup';

const engagementdata: any = [
  {
    id: 1,
    title: 'Golden Circle',
    type: 'Masterclass',
    remaining: '',
    task: 'Join Now',
    date: '5th September',
    time: '1: 30 PM ',
  },
  {
    id: 2,
    title: 'Discussion with Shubham',
    type: 'Coach Support',
    remaining: '10 Left',
    task: 'Register Now',
    date: '7th September',
    time: '1: 30 PM ',
  },
  {
    id: 3,
    title: 'Company SWOT Analysis',
    type: '21st Century Lab',
    remaining: '',
    task: '',
    date: '5th September',
    time: '1: 30 PM ',
  },
];
export const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const EngagementsCard = () => {
  // const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }

  return engagementdata.map((data: any) => {
    return (
      <div key={data.id}>
        <div
          // onClick={() => openModal()}
          className='flex border-l-4 border-l-red-500 border rounded my-4 space-y-2 bg-white  px-2 pt-2 pb-4'
        >
          <div className='grid grid-cols-2 w-full grid-flow-col items-center cursor-pointer '>
            <div className='flex gap-x-1 items-center  '>
              <GoDotFill className='text-primary' />
              <div>
                <p className='font-medium text-xs md:text-sm'>{data.title}</p>
                <p className='text-xs text-gray-500'>{data.type}</p>
              </div>
            </div>
            <div className='flex justify-between text-center text-primary text-xs md:text-sm'>
              <div>
                <p>{data.remaining}</p>
                <p>{data.task}</p>
              </div>
              <div>
                <p>{data.date}</p>
                <p>{data.time}</p>
              </div>
            </div>
          </div>
        </div>
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          ariaHideApp={false}
          contentLabel='Example Modal'
          data={{ background: 'gray' }}
        >
          <div>
            <EngagementPopup closeModal={closeModal} />
          </div>
        </Modal> */}
      </div>
    );
  });
};

export default EngagementsCard;
