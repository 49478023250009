import React from 'react';

const classNames = require('classnames');

const ButtonEtpVga = ({
  selectedScreen,
  setSelectedScreen,
  showCertificate,
  isVGE,
  showTicket,
  showEtp,
  isSunstone,
}) => {
  return (
    <div className='p-3 bg-gray-100 text-xs'>
      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 0,
        })}
        onClick={() => {
          setSelectedScreen(0);
        }}
      >
        Calendar
      </button>
      {isSunstone && (
        <>
          <button
            type='button'
            className={classNames('bg-white p-2 hover:bg-primary-light', {
              'bg-primary-light': selectedScreen === 1,
            })}
            onClick={() => {
              setSelectedScreen(1);
            }}
          >
            Company Connect
          </button>
          <button
            type='button'
            className={classNames('bg-white p-2 hover:bg-primary-light', {
              'bg-primary-light': selectedScreen === 2,
            })}
            onClick={() => {
              setSelectedScreen(2);
            }}
          >
            Project Tasks
          </button>
        </>
      )}
      {showEtp && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 9,
          })}
          onClick={() => {
            setSelectedScreen(9);
          }}
        >
          Company Project
        </button>
      )}
      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 3,
        })}
        onClick={() => {
          setSelectedScreen(3);
        }}
      >
        {isVGE ? 'Entrepreneurial sessions' : 'MasterClass'}
      </button>

      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 4,
        })}
        onClick={() => {
          setSelectedScreen(4);
        }}
      >
        {isVGE ? 'Startup Assignments' : '21st Century Lab'}
      </button>
      {!isSunstone && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 5,
          })}
          onClick={() => {
            setSelectedScreen(5);
          }}
        >
          Coach Support
        </button>
      )}

      {showTicket && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 8,
          })}
          onClick={() => {
            setSelectedScreen(8);
          }}
        >
          Query
        </button>
      )}

      {showCertificate && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 6,
          })}
          onClick={() => {
            setSelectedScreen(6);
          }}
        >
          Feedback & Certification
        </button>
      )}
    </div>
  );
};

export default ButtonEtpVga;
