import React from 'react';

const ThankyouPage = (props) => {
  const certificatePart = () => {
    return (
      <div>
        <p className='text-sm text-center'>
          You can now proceed to download your certificate from below.
        </p>
        <p className='text-sm text-center'>
          In case you have any issues with the certificate you can write to us
          at <span className='text-primary'>connect@belong.education</span>
        </p>
        <div className='flex justify-center mt-10'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643353948/uploaded_resources/certificate_icon_vv4jxs.png'
            alt='certificate'
            className='h-12 md:h-16'
          />
          <button
            className='bg-primary-light p-2 md:p-4 rounded border-2 shadow-xl ml-4 hover:bg-primary hover:text-gray-100'
            onClick={() => props.setShowThankPage(false)}
          >
            VGA Certificate
          </button>
        </div>
      </div>
    );
  };
  return (
    <div className='col-span-6 border-2 flex justify-center'>
      <div className='flex p-3 md:p-0 '>
        <div className='flex items-center '>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643353857/uploaded_resources/Thank_You_illustration_qgt2jh.jpg'
            alt='Illustration'
            className='h-0 md:h-56'
          />
        </div>
        <div className=' bg-gray-100 flex flex-col p-10 '>
          <p className='text-primary text-2xl font-bold mb-8 text-center'>
            Thank you for submitting your feedback.
          </p>
          {['Merit', 'Completion'].includes(props.completionStatus) ? (
            certificatePart()
          ) : (
            <div className='text-center'>
              Please submit all the company project tasks and 21st century lab
              assignments to access your VGA Certificate.
            </div>
          )}
          <div className='flex items-center justify-center mt-12'>
            <img src={props.batchLogo} alt='Batch-Logo' className='h-12 ' />
            <p className='font-black text-gray-600 text-center mx-10'>X</p>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
              alt='Logo'
              className='h-8'
            />
          </div>
        </div>
        <div className='flex items-center'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643353857/uploaded_resources/Thank_You_illustration_qgt2jh.jpg'
            alt='Illustration'
            className='h-0 md:h-56'
          />
        </div>
      </div>
    </div>
  );
};

export default ThankyouPage;
