import React from 'react';
import { Amplify } from 'aws-amplify';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// import DashboardLayoutNew from 'components/global/layout/DashboardLayout';
import Capstone from 'components/capstone';
import CapstoneDetails from 'components/capstone/capstoneView';

import SuperDashboardLayout from 'components/global/layout/SuperDashboardLayout';
import SuperCapstoneDetails from 'components/superCapstone/superCapstoneView';
import SuperCapstone from 'components/superCapstone';

import Dashboard from 'components/dashboard/panel';
import Vga from 'components/dashboard/vga';
import Login from 'components/auth/Login';
import SignUp from 'components/auth/SignUp';
import CheckLink from 'components/auth/CheckLink';
import AMPLIFY_CONFIG from 'config/AmplifyConfig';
import Jobs from 'components/jobs';
import JobDetails from 'components/jobs/details';
import Profile from 'features/profile';
import AptitudeAssessment from 'components/dashboard/quiz/aptitude';
import CheckAptitudeAttempt from 'components/dashboard/quiz/aptitude/CheckAptitudeAttempt';
import AptitudeScoreCard from 'components/dashboard/quiz/aptitude/layout/AptitudeScoreCard';
import DomainAssessment from 'components/dashboard/quiz/domain';
import StartSteps from 'components/dashboard/quiz/domain/StartSteps';
import Steps from 'components/dashboard/quiz/domain/steps';
import SelectDomain from 'components/dashboard/quiz/domain/steps/SelectDomain';
import SelectSkills from 'components/dashboard/quiz/domain/steps/SelectSkills';
import StartTestInstructions from 'components/dashboard/quiz/domain/steps/StartTestInstructions';
import DomainScoreCard from 'components/dashboard/quiz/layout/DomainScoreCard';
import NoSidebarLayout from 'components/global/layout/NoSidebarLayout';
import ForgotPassword from 'components/auth/forgotPassword/ForgotPassword';
import NotFound from 'components/global/layout/NotFound';
import PartnerLogin from 'components/auth/PartnerLogin';

const Index = () => {
  Amplify.configure(AMPLIFY_CONFIG);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login'>
          <Route index element={<Login />} />
          <Route path=':slug' element={<PartnerLogin />} />
        </Route>
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='/signup'>
          <Route index element={<CheckLink />} />
          <Route path=':slug' element={<SignUp />} />
        </Route>
        <Route element={<PrivateRoute />}>
          {/* <Route path='/' element={<DashboardLayoutNew />}> */}
          <Route path='/' element={<SuperDashboardLayout />}>
            <Route index element={<Dashboard />} />
            <Route path='deviare' element={<Dashboard />} />
            <Route path='dashboard' element={<Vga />} />
            <Route path='jobs'>
              <Route index element={<Jobs />} />
              <Route path=':slug' element={<JobDetails />} />
            </Route>
            <Route path='profile' element={<Profile />} />
            <Route path='supercapstone'>
              {/* <Route index element={<Capstone />} /> */}
              <Route index element={<SuperCapstone />} />
              <Route
                path=':superCapstoneId'
                element={<SuperCapstoneDetails />}
              />
              <Route
                path=':superCapstoneId/:taskno'
                element={<SuperCapstoneDetails />}
              />
            </Route>
            <Route path='capstone'>
              <Route index element={<Capstone />} />
              {/* <Route index element={<SuperCapstone />} /> */}
              <Route path=':capstoneId' element={<CapstoneDetails />} />
            </Route>
            <Route path='quiz'>
              <Route path='aptitude'>
                <Route path='scorecard' element={<AptitudeScoreCard />} />
              </Route>
              <Route path='domain'>
                <Route path='scorecard'>
                  <Route index element={<DomainScoreCard />} />
                  <Route path=':areaId' element={<DomainScoreCard />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route path='quiz' element={<NoSidebarLayout />}>
            <Route path='aptitude'>
              <Route index element={<AptitudeAssessment />} />
              <Route path='aptitude-quiz' element={<CheckAptitudeAttempt />} />
            </Route>
            <Route path='domain' element={<DomainAssessment />}>
              <Route index element={<StartSteps />} />
              <Route path='steps' element={<Steps />}>
                <Route path='select-domain' element={<SelectDomain />} />
                <Route path='select-skills' element={<SelectSkills />} />
                <Route path='start-test' element={<StartTestInstructions />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Index;
