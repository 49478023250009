import React from 'react';
import { format } from 'date-fns';
import Mentor from './Mentor';
import OverviewCard from '../../layout/OverviewCard';
import AboutSection from '../../layout/AboutSection';
import Schedules from '../../layout/Schedules';
import MentorMeetings from './MentorMeetings';

function Company(props) {
  const userSpecificEvents = props.userSpecificEvents;
  const isFullJourney = props.isFullJourney;
  const batchDetails = props.data.batches[0];
  const isCareereraUser = props.isCareereraUser;

  const userDetails = batchDetails.users.filter(
    (user) => user.email === props.data.email
  )[0];
  const mentorDetails = userDetails.mentor;
  const mentorDetails2 = userDetails.mentor2;
  const companyDetails = userDetails.company;
  const companyDetails2 = userDetails.company2;
  const fullJourneyStart =
    batchDetails.timeSheet && batchDetails.timeSheet.startsAt;

  const mentorEvents = userSpecificEvents
    .map((event) => ({
      ...event,
    }))
    .filter(
      (event) =>
        event.eventId.type === 'Mentor Connect' &&
        event.eventId.status === 'publish'
    );

  const mentorEvent1 = mentorEvents.filter((event) => {
    return event.eventId.week < fullJourneyStart;
  });
  const mentorEvent2 = mentorEvents.filter((event) => {
    return event.eventId.week >= fullJourneyStart;
  });

  return (
    <div>
      <div className='bg-gray-100 py-4'>
        <OverviewCard
          imageUrl={
            companyDetails2 ? companyDetails2.logoUrl : companyDetails.logoUrl
          }
          overviewHeader={`Your ${
            isCareereraUser ? 'Internship' : 'Apprenticeship'
          } Company`}
          list1='Background'
          list2='Mentorship'
          list3='Mettings'
        />
        {companyDetails2 && isFullJourney ? (
          <AboutSection heading={companyDetails2.sectionTitle}>
            {companyDetails2.sectionContent.map((content, index) => {
              return <p key={index}>{content.contentToPublish}</p>;
            })}
          </AboutSection>
        ) : (
          <AboutSection heading={companyDetails.sectionTitle}>
            {companyDetails.sectionContent.map((content, index) => {
              return <p key={index}>{content.contentToPublish}</p>;
            })}
          </AboutSection>
        )}
        {isFullJourney && mentorDetails2 ? (
          <>
            <Mentor
              heading='Your Full-time Internship Mentor'
              mentor={mentorDetails2}
            />
            <MentorMeetings isCareereraUser={isCareereraUser} />
            {mentorEvent2.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-2 md:mb-4'></div>
                <h2>
                  <b>Schedule</b>
                </h2>
              </div>
            )}
            {mentorEvent2.map((event) => {
              return (
                <Schedules
                  key={event._id}
                  schedule='Meeting'
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
            <Mentor
              heading='Your Previous Mentor Engagement'
              mentor={mentorDetails}
            />
            {mentorEvent1.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-2 md:mb-4'></div>
                <h2>
                  <b>Schedule</b>
                </h2>
              </div>
            )}
            {mentorEvent1.map((event) => {
              return (
                <Schedules
                  key={event._id}
                  schedule='Meeting'
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
          </>
        ) : (
          <>
            <Mentor heading='Your Mentor' mentor={mentorDetails} />
            <MentorMeetings isCareereraUser={isCareereraUser} />
            {mentorEvents.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-2 md:mb-4'></div>
                <h2>
                  <b>Schedule</b>
                </h2>
              </div>
            )}
            {mentorEvents.map((event) => {
              return (
                <Schedules
                  key={event._id}
                  schedule='Meeting'
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default Company;
