import React, { useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import ReactPlayer from 'react-player';

interface allProps {
  videoBackground: string;
  videoUrl: any;
}

const VideoArea = (props: allProps) => {
  const [startVideo, setStartVideo] = useState(false);
  return (
    <div>
      <div
        className={`h-64 rounded-xl relative bg-primary bg-cover bg-center`}
        style={{
          backgroundImage: startVideo ? '' : `url(${props.videoBackground})`,
        }}
      >
        {startVideo && (
          <ReactPlayer
            url={props.videoUrl}
            controls={true}
            width='100%'
            height='100%'
          />
        )}
        {startVideo ? (
          <button
            className='absolute right-0 -top-6 font-bold text-gray-50 bg-primary px-3 cursor-pointer'
            onClick={() => setStartVideo(false)}
          >
            Close
          </button>
        ) : null}
        {!startVideo && (
          <div className='text-white bottom-0 left-16 p-4'>
            <FaPlayCircle
              className='mb-4 text-4xl cursor-pointer'
              onClick={() => setStartVideo(true)}
            />
            {/* <p className='text-lg mb-2'>{props.videoTitle}</p> */}
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoArea;
