import classNames from 'classnames';

const MyCapToggleBar: React.FC<{
  setIsCapOngoing: Function;
  isCapOngoing: Boolean;
  completed: number;
  ongoing: number;
}> = ({ setIsCapOngoing, isCapOngoing, completed, ongoing }) => {
  return (
    <div className='flex w-full items-baseline border rounded'>
      <button
        onClick={() => {
          setIsCapOngoing(true);
        }}
        className={classNames(
          'text-sm md:text-base px-2 md:px-6 py-1 font-semibold rounded   w-1/2',
          {
            'text-white bg-primary ': isCapOngoing,
          },
          {
            'text-primary bg-white': !isCapOngoing,
          }
        )}
      >
        {`Ongoing (${ongoing})`}
      </button>
      <button
        onClick={() => {
          setIsCapOngoing(false);
        }}
        className={classNames(
          'text-sm md:text-base px-2 md:px-6 py-1 font-semibold rounded  w-1/2',
          {
            'text-white bg-primary ': !isCapOngoing,
          },
          {
            'text-primary bg-white ': isCapOngoing,
          }
        )}
      >
        {`Completed (${completed})`}
      </button>
    </div>
  );
};

export default MyCapToggleBar;
