import React from 'react';
//text list tab link image file
const CoachContent: React.FC<any> = ({ coachProps }) => {
  return (
    <div className='space-y-4'>
      {coachProps.map((content: any, index: number) => {
        if (content.contentType === 'Text') {
          return (
            <p className='my-4 text-textColor-lighter text-sm' key={index}>
              {content.contentToPublish}
            </p>
          );
        }
        if (content.contentType === 'Tab') {
          return (
            <p
              className='rounded-md inline-flex items-center mb-4  border-2 border-primary p-2 mr-4'
              key={index}
            >
              {content.contentToPublish}
            </p>
          );
        }
        if (content.contentType === 'List') {
          return (
            <p className='flex items-center'>
              <span
                className='w-2 h-2 bg-black inline-block mr-2'
                key={index}
              ></span>
              {content.contentToPublish}
            </p>
          );
        }
        if (content.contentType === 'Image') {
          return (
            <img
              key={index}
              src={content.contentToPublish}
              alt='Content'
              className='mb-4'
            />
          );
        }
        if (content.contentType === 'Link') {
          return (
            <a
              key={index}
              href={content.contentToPublish}
              className='text-blue-400 mb-4'
              rel='noopener noreferrer'
              target='_blank'
            >
              {content.contentToPublish}
            </a>
          );
        }
        if (content.contentType === 'File') {
          return (
            <a key={index} href={content.contentToPublish} download>
              <button className='p-2 shadow rounded bg-primary-light hover:bg-primary mb-4'>
                Download File
              </button>
            </a>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CoachContent;
