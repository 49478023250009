import React from 'react';

const Deadline = ({ deadline, batchDetails }) => {
  const guidelines = batchDetails.timeSheet
    ? batchDetails.timeSheet.guidelines
    : '';
  const template = batchDetails.timeSheet
    ? batchDetails.timeSheet.template
    : '';
  return (
    <div className='flex px-4 py-2 text-sm'>
      <div className='flex'>
        <p className='text-primary '>Deadline:</p>
        <p>
          {deadline.day}, {deadline.date}
        </p>
      </div>
      <div className='flex justify-end  text-primary'>
        <div className='flex place-items-center px-4'>
          <a href={guidelines} rel='noreferrer noopener' target='_blank'>
            Weekly Work Report Guidelines
          </a>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354201/uploaded_resources/pdf_icon_mq9ixc.png'
            className='h-6 w-6 object-cover '
            alt='Attachment'
          />
        </div>
        <div className='flex place-items-center px-4'>
          <a href={template} rel='noreferrer noopener' target='_blank'>
            Report Template (Editable)
          </a>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1643354201/uploaded_resources/pdf_icon_mq9ixc.png'
            className='h-6 w-6 object-cover '
            alt='Attachment'
          />
        </div>
      </div>
    </div>
  );
};

export default Deadline;
