const TabBar = ({
  tabOptions,
  setActiveTab,
  activeTab,
  quizDisabled,
}: {
  tabOptions: any;
  setActiveTab: Function;
  activeTab: string;
  quizDisabled: boolean;
}) => {
  return (
    <div className='md:pt-4 flex flex-wrap md:flex-nowrap select-none'>
      {tabOptions.map((val: string, idx: number) => (
        <button
          onClick={() => {
            setActiveTab(val);
          }}
          key={idx}
          className={`text-center md:px-4 pb-2 font-medium border-b-2 duration-200 w-full ${
            idx === 1 && quizDisabled
              ? 'pointer-events-none opacity-60'
              : 'pointer-events-auto opacity-100'
          } ${
            activeTab === val
              ? 'border-b-primary text-black '
              : ' bg-white text-neutral-500 border-b-gray-200'
          }`}>
          {val}
        </button>
      ))}
    </div>
  );
};

export default TabBar;
