import React from 'react';
import { FaFileDownload, FaCheckCircle, FaTrashAlt } from 'react-icons/fa';

import { deleteFile } from '../helper/Helper';
const classNames = require('classnames');

const AlreadySubmitted = (props) => {
  return (
    <div
      className={classNames(
        'bg-white p-2  md:mt-3 mt-0 border-t-2 md:border-t-0 lg:-ml-8 border-l-2 border-gray-200 flex flex-col items-center justify-center',
        {
          'animate-pulse': props.loading,
        }
      )}
    >
      <div className='flex items-center mb-8'>
        <FaCheckCircle className='text-xl  text-green-400 mr-2' />
        <p>Submitted</p>
      </div>
      <div className='flex space-x-16'>
        <a
          href={`${props.UPLOAD_OR_DOWNLOAD_URL}/${props.fileName}`}
          target='_blank'
          rel='noreferrer noopener'
        >
          <FaFileDownload className='hover:animate-bounce text-gray-400 text-lg cursor-pointer hover:text-gray-700 transition duration-300 ease-in-out' />
        </a>

        <FaTrashAlt
          className={classNames(
            'text-gray-400 text-lg cursor-pointer  transition duration-300 ease-in-out',
            {
              'hover:text-red-700': !props.loading,
            }
          )}
          onClick={() => {
            if (!props.loading) {
              deleteFile(
                props.fileName,
                props.fieldToUpdate,
                props.uniqueUserId,
                props.refetch,
                props.setLoading
              );
            }
          }}
        />
      </div>
    </div>
  );
};

export default AlreadySubmitted;
