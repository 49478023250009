import classNames from 'classnames';
import { AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';

const Navigation = (props) => {
  const isDisabled = !props.values[props.questionName];
  const setPrevious = () => {
    props.currentQuestion > 0 &&
      props.setCurrentQuestion(props.currentQuestion - 1);
  };
  const setNext = () => {
    if (props.currentQuestion < props.database.length) {
      props.setCurrentQuestion(props.currentQuestion + 1);
    }
  };
  const btnAnimation =
    'border flex items-center font-semibold px-2 py-1 rounded text-primary border-primary hover_border-white hover:bg-primary hover:text-white transition ease-in-out duration:300';
  return (
    <div className='flex space-x-4 justify-end text-sm mt-4'>
      {props.currentQuestion - 1 ? (
        <button
          type='button'
          className={btnAnimation}
          onClick={() => {
            setPrevious();
          }}
        >
          <AiOutlineArrowLeft className='mr-2 ' />
          Previous
        </button>
      ) : null}
      {props.currentQuestion < props.database.length ? (
        <button
          disabled={isDisabled}
          type='button'
          className={classNames(btnAnimation, {
            'pointer-events-none bg-slate-300 opacity-40': isDisabled,
          })}
          onClick={() => {
            setNext();
          }}
        >
          Next
          <AiOutlineArrowRight className='ml-2' />
        </button>
      ) : (
        <button
          disabled={isDisabled}
          type='submit'
          className={classNames(btnAnimation, {
            'pointer-events-none bg-slate-300 opacity-40': isDisabled,
          })}
        >
          Submit
        </button>
      )}
    </div>
  );
};

export default Navigation;
