import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';

import OverviewCard from '../../layout/OverviewCard';
import TimeTable from './TimeTable';
import WeekButtons from './WeekButtons';
import AddTimeTable from './AddTimeTable';
import EditTimeSheet from './EditTimeSheet';
import { API_URL } from '../../../../config/API_LINKS';

const Index = ({
  batchDetails,
  userDetails,
  currentWeek,
  cognitoId,
  userId,
}) => {
  const [weekTimeSheet, setWeekTimeSheet] = useState([]);
  const [dataloading, setDataloading] = useState(false);
  const batchId = batchDetails._id;
  const timeSheetStartAt =
    batchDetails.timeSheet && batchDetails.timeSheet.startsAt;

  const dt1 = new Date(batchDetails.startDate);

  const [selectedWeek, setSelectedWeek] = useState(
    Math.min(currentWeek, batchDetails.week)
  );

  let weekDates = [];
  let mydate = '';
  for (let i = 0; i < 7; i++) {
    mydate = moment(dt1, 'DD-MM-YYYY').add(
      (selectedWeek - 1) * 7 + i,
      'days'
    )._d;
    if (mydate.getDay() > 0 && mydate.getDay() < 6) {
      weekDates.push({
        date: moment(dt1, 'DD-MM-YYYY')
          .add((selectedWeek - 1) * 7 + i, 'days')
          .format('DD/MM/YYYY'),
        day: moment(dt1, 'DD-MM-YYYY')
          .add((selectedWeek - 1) * 7 + i, 'days')
          .format('ddd'),
      });
    }
  }

  useEffect(() => {
    setWeekTimeSheet([]);
    (async () => {
      const result = await axios(API_URL + '/timesheet', {
        params: {
          week: selectedWeek,
          userId: userId,
          batchId: batchId,
        },
      });
      setWeekTimeSheet(result.data);
    })();
  }, [selectedWeek, dataloading, userId, batchId]);

  return (
    <div>
      <div className='bg-gray-100 py-4'>
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1643359130/uploaded_resources/Coaching-Banner-Logo_byqkzp.png'
          overviewHeader='Your Weekly Timesheet for work Record'
          list1='Tasks'
          list2='Record'
          list3='Submit'
        />
        <WeekButtons
          selectedWeek={selectedWeek}
          setSelectedWeek={setSelectedWeek}
          length={batchDetails.week}
          timeSheetStartAt={timeSheetStartAt}
        />

        {selectedWeek <= currentWeek ? (
          weekTimeSheet.length > 0 ? (
            <EditTimeSheet
              weekDates={weekDates}
              currentTimeSheet={weekTimeSheet[0]}
              batchDetails={batchDetails}
              setDataloading={setDataloading}
              cognitoId={cognitoId}
            />
          ) : (
            <AddTimeTable
              selectedWeek={selectedWeek}
              weekDates={weekDates}
              batchDetails={batchDetails}
              userDetails={userDetails}
              cognitoId={cognitoId}
              userId={userId}
              setDataloading={setDataloading}
            />
          )
        ) : weekTimeSheet.length > 0 ? (
          <TimeTable
            weekDates={weekDates}
            currentTimeSheet={weekTimeSheet[0]}
          />
        ) : selectedWeek > currentWeek ? (
          <div className='text-center p-4'>
            Timesheet will generate at the start of the week
          </div>
        ) : (
          <div className='text-center p-4'>Time Sheet not available</div>
        )}
      </div>
    </div>
  );
};

export default Index;
