import classNames from 'classnames';
import React from 'react';
import { FiLock } from 'react-icons/fi';
import TaskIcon from './TaskIcon';

interface allProps {
  data: any;
  setSelectedTask: Function;
  taskDetail: any;
  index: number;
  enrollData: any;
  setCount: Function;
}

const Task = (props: allProps) => {
  const taskDetail = props.taskDetail;
  const taskDependency = props.data.taskDependency;

  const isLockedTask =
    (taskDependency &&
      props.index > 0 &&
      !props.enrollData?.capstoneSubmissions?.filter((sub: any) => {
        return sub.taskId === props.data.tasks[props.index - 1]._id;
      }).length) ||
    !props.enrollData;

  const enrollCurrentTask = props.enrollData?.capstoneSubmissions?.filter(
    (sub: any) => {
      return sub.taskId === props.taskDetail._id;
    }
  );
  const isTaskDone =
    enrollCurrentTask && enrollCurrentTask.length ? true : false;
  const isCurrentTask =
    !isTaskDone &&
    props.enrollData &&
    (props.index === 0 ||
      props.enrollData?.capstoneSubmissions?.filter((sub: any) => {
        return sub.taskId === props.data.tasks[props.index - 1]._id;
      }).length);

  return (
    <div>
      <div
        onClick={() => {
          props.setSelectedTask(taskDetail);
          props.setCount(props.index + 1);
        }}
        className={classNames(
          'grid grid-cols-12 bg-gray-50 py-2 mt-2 items-center cursor-pointer rounded-xl',
          { 'pointer-events-none': isLockedTask }
        )}
      >
        <div className='ml-2 z-10'>
          <TaskIcon
            data={props.data}
            index={props.index}
            isTaskDone={isTaskDone}
            isCurrentTask={isCurrentTask}
          />
        </div>
        <div
          // className='col-span-8 sm:col-span-4 ml-3'
          className={classNames(
            'col-span-8 sm:col-span-4 ml-6 sm:ml-3 lg:px-4',
            {
              'font-semibold': isCurrentTask,
            }
          )}
        >
          {taskDetail.title}
        </div>
        <div className='col-span-3 sm:col-span-2 lg:px-2'>
          {taskDetail.time} hours
        </div>
        <div className='col-span-11 sm:col-span-5 flex flex-nowrap items-center justify-between px-4 md:border-l border-primary-light '>
          <div className='flex flex-wrap gap-2 lg:gap-4 p-2 ml-4 sm:ml-2 '>
            {taskDetail.skillId.map((skill: any) => {
              return (
                <div
                  key={skill._id}
                  className='border mb-2 md:mb-0 bg-primary-lightest font-semibold rounded-2xl py-1 px-2 text-sm'
                >
                  {skill.title}
                </div>
              );
            })}
          </div>

          <FiLock
            className={classNames('h-6 w-6 flex-shrink-0 text-primary ', {
              hidden: !isLockedTask,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default Task;
