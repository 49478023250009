import React, { useState } from 'react';
import Modal from 'react-modal';

import QuestionGrid from './QuestionGrid';
import ShowQuestion from './ShowQuestion';

const Index: React.FC = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  return (
    <div className='border pb-8'>
      <div className='grid grid-cols-3'>
        <div className='flex justify-end w-screen md:hidden mt-4 pr-8 '>
          <button
            className='bg-primary/10 font-bold rounded-xl px-4 py-2 text-xs drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]'
            onClick={() => openModal()}
          >
            Questions
          </button>
        </div>
        <div className='md:col-span-2 col-span-3'>
          <ShowQuestion />
        </div>
        <div className='md:col-span-1 md:block hidden'>
          <QuestionGrid />
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          ariaHideApp={false}
          // style={customStyles}
          contentLabel='Example Modal'
          data={{ background: 'white' }}
        >
          <div className='col-span-3'>
            <QuestionGrid closeModal={closeModal} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Index;
