import React from 'react'

const Expertise = (props) => {
  return (
    <div className="flex pl-2  md:pl-5 space-x-2 mt-6 md:mt-0">
    <p className="text-xs md:text-sm">
      <b>Your Coach Has Technical Experties In :</b>
    </p>
    <p className="text-xs md:text-sm">
      {props.areas1}
    </p>
    <div className="bg-gray-500 h-8 md:h-4 w-0.5 m-1"></div>
    <p className="text-xs md:text-sm">{props.areas2}</p>
  </div>
  )
}

export default Expertise
