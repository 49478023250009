import React from 'react';
const cardData = [
  {
    id: 1,
    question: 'What?',
    text: 'The masterclasses are followed by assignments that allow for the practical application of learnings and help enhance overall employability',
  },
  {
    id: 2,
    question: 'Why?',
    text: "The assignments aid in upgrading apprentices' performance and contribute to their exposure to key entrepreneurial skills",
  },
  {
    id: 3,
    question: 'How?',
    text: 'The assignments on the dashboard progress in sync with the Masterclasses and come with deadlines',
  },
];

const LabDiscussions = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold'>Practical Tools</p>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4 xl:gap-12 mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2 items-center  border rounded-lg p-2 md:p-4'
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center lg:my-4 justify-center rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2'>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LabDiscussions;
