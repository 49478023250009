import { Auth } from 'aws-amplify';
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AllOpportunities from './AllOpportunities';
import FeaturedOpportunities from './FeaturedOpportunities';
import Filter from './filter';
import Header from './layout/Header';
import { useDispatch } from 'react-redux';
import ReactGA from 'react-ga4';
import { setToken } from 'components/auth/authSlice';

const Index = (props: any) => {
  ReactGA.send({ hitType: 'pageview', page: '/jobs' });

  const [opportunitiesSearchParameter, setOpportunitiesSearchParameter] =
    useState<string>('');
  let [searchParams] = useSearchParams();
  const [searchViewFromUrl, setSearchViewFromUrl] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [browseAll, setbrowseAll] = useState(false);
  const dispatch = useDispatch();

  Auth.currentAuthenticatedUser({
    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then((res) => {
    const token = res?.signInUserSession?.accessToken?.jwtToken;
    dispatch(setToken(token));
  });
  useEffect(() => {
    setSearchViewFromUrl(searchParams.toString());
  }, [searchViewFromUrl, searchParams]);

  useEffect(() => {
    setOpportunitiesSearchParameter(searchViewFromUrl);
  }, [searchViewFromUrl]);

  useEffect(() => {
    searchViewFromUrl && setbrowseAll(true);
  }, [searchViewFromUrl]);

  useEffect(() => {
    opportunitiesSearchParameter && setbrowseAll(true);
  }, [opportunitiesSearchParameter]);

  //Scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [browseAll]);

  return (
    <div className='grid grid-cols-12 gap-8'>
      <Header showFilters={showFilters} setShowFilters={setShowFilters} />
      <div className='col-span-12 sm:col-span-8 order-2 sm:order-1'>
        {browseAll ? (
          <AllOpportunities
            opportunitiesSearchParameter={opportunitiesSearchParameter}
          />
        ) : (
          <FeaturedOpportunities
            browseAll={browseAll}
            setbrowseAll={setbrowseAll}
          />
        )}
      </div>
      <div
        className={classNames(
          { hidden: !showFilters },
          'sm:block col-span-12 sm:col-span-4 order-1 sm:order-2'
        )}
      >
        <Filter
          opportunitiesSearchParameter={opportunitiesSearchParameter}
          setOpportunitiesSearchParameter={setOpportunitiesSearchParameter}
        />
      </div>
    </div>
  );
};

export default Index;
