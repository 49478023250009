import { createContext, useContext } from 'react';

//TO DO: Find less hacky solution

interface domainQuizType {
  areaId: string;
  type: string;
  skillIds: string[];
  questionCountForEachSkill: number;
}

const paramsObj: domainQuizType = {
  areaId: '',
  type: 'B2C DOMAIN',
  skillIds: [''],
  questionCountForEachSkill: 0,
};
const DomainTestContextObject: {
  state: domainQuizType;
  dispatch: Function;
} = {
  state: paramsObj,
  dispatch: () => {},
};

export const DomainTestContext = createContext(DomainTestContextObject);

const useDomainTestContext = () => {
  const useDomainTestContext = useContext(DomainTestContext);

  return useDomainTestContext;
};

export default useDomainTestContext;
