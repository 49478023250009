import { axiosUpload } from 'config/Axios';
import { Formik, Field, Form } from 'formik';
import Navigation from './Navigation';
import { useState } from 'react';
import { useAddVgaFeedbackMutation } from './vgaFeedbackSlice';

const Questions = (props) => {
  const [addVgaFeedback] = useAddVgaFeedbackMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const question = props.database[props.currentQuestion - 1];
  const radioInputs = (
    <div className='md:flex flex-wrap md:space-x-4 text-sm'>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='1'
          name={`data[${props.currentQuestion - 1}].value`}
          value='1'
        />
        <label htmlFor='1'>Strongly Disagree</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='2'
          name={`data[${props.currentQuestion - 1}].value`}
          value='2'
        />
        <label htmlFor='2'>Disagree</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='3'
          name={`data[${props.currentQuestion - 1}].value`}
          value='3'
        />
        <label htmlFor='3'>Neither</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='4'
          name={`data[${props.currentQuestion - 1}].value`}
          value='4'
        />
        <label htmlFor='4'>Agree</label>
      </div>
      <div className='space-x-1'>
        <Field
          type={question.questionType}
          id='5'
          name={`data[${props.currentQuestion - 1}].value`}
          value='5'
        />
        <label htmlFor='5'>Strongly Agree</label>
      </div>
    </div>
  );
  const textInputs = (
    <Field
      as='textarea'
      type={question.questionType}
      name={`data[${props.currentQuestion - 1}].value`}
      className='border rounded-md p-4 h-36 w-[90%] '
    />
  );
  const booleanInputs = (
    <div className='flex flex-wrap space-x-4'>
      <div className='space-x-1'>
        <Field
          type='radio'
          id='yes'
          name={`data[${props.currentQuestion - 1}].value`}
          value='YES'
        />
        <label htmlFor='yes'>Yes</label>
      </div>
      <div className='space-x-1'>
        <Field
          type='radio'
          id='no'
          name={`data[${props.currentQuestion - 1}].value`}
          value='NO'
        />
        <label htmlFor='no'>No</label>
      </div>
    </div>
  );

  async function uploadFile(files, setFieldValue) {
    if (files.size > 2200000) {
      setErrorMessage('file must be less then 2 MB');
    } else {
      const fileExtension = files.name.substring(
        files.name.lastIndexOf('.') + 1
      );
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axiosUpload.post('/aws-upload/single', formData);
      if (result.status === 200) {
        setFieldValue('image', result.data.publicUrl);
      } else {
        setErrorMessage('Please try after some time');
      }
    }
  }
  return (
    <Formik
      initialValues={{ data: props.database }}
      onSubmit={(values) => {
        const dataToSend = { batchId: props.batchId };
        values.data.forEach((individual) => {
          if (individual.questionType === 'radio') {
            //convert string to number
            individual.value = +individual.value;
          }
        });
        dataToSend['feedback'] = values.data;
        addVgaFeedback(dataToSend)
          .unwrap()
          .then((res) => {
            window.location.reload();
          });
      }}
    >
      {({ values, index, setFieldValue, isSubmitting }) => (
        <Form>
          <div>
            <div key={index} className='space-y-6'>
              <p>{question.question}</p>
              <div>
                {question.questionType === 'radio' ? radioInputs : null}
                {question.questionType === 'text' ? textInputs : null}
                {question.questionType === 'yesNo' ? booleanInputs : null}
                {question.questionType === 'yesNo' &&
                  values.data[props.currentQuestion - 1].value === 'YES' && (
                    <div>
                      <div>
                        <div className='flex justify-between w-full text-primary mb-4'>
                          If yes, can you upload a high resolution picture of
                          yourself to feature on our platforms?{' '}
                        </div>
                        <input
                          type='file'
                          accept='image/png, image/jpeg'
                          onChange={(e) => {
                            const file = e.target.files[0];
                            uploadFile(file, setFieldValue);
                          }}
                        />

                        <div className='text-xs mt-1'>
                          Please upload png or jpeg file, Max size: 2 MB
                        </div>
                        <div className='mt-2 text-red-600'>{errorMessage}</div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <Navigation
            values={values}
            questionName={question.question}
            database={props.database}
            currentQuestion={props.currentQuestion}
            setCurrentQuestion={props.setCurrentQuestion}
            isSubmitting={isSubmitting}
          />
        </Form>
      )}
    </Formik>
  );
};

export default Questions;
