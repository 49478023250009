import React from 'react';

const QuickTipsSection = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div>
      <div className='px-4 sm:px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>
            Please ensure your camera and microphone are functioning before the
            meeting
          </li>
          <li>
            Please do follow the formal etiquettes of dressing and conversing
            appropriately
          </li>
          <li>
            If you miss a meeting or are unable to attend please notify via
            dashboard query system
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickTipsSection;
