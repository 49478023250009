import { useRef } from 'react';
import { PDFExport } from '@progress/kendo-react-pdf';
import { format } from 'date-fns';

const CompleteCertificate = (props) => {
  const capstoneData = props.capstoneData;
  const capstoneTitle = capstoneData.title;
  const companyName = capstoneData?.companyId?.name;
  const companyLogo = capstoneData?.companyId?.logoUrl;
  const domainName = capstoneData?.domainId?.title;
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);
  const partnerLogo = props.partnerLogo;
  const partnerName = props.partnerName === 'Belong' ? '' : props.partnerName;

  //   console.log('capstone data', capstoneData);
  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
    props.setIsOpen(false);
  };
  const certificateText = 'Certificate of Completion';
  const user = props.user;

  return (
    <>
      <div className='relative flex flex-col items-center p-2 border-2 border-b-0 bg-white'>
        <button
          onClick={handleExportWithMethod}
          className='absolute top-16 visible rounded  text-gray-100  text-sm px-8 py-2 bg-primary hover:bg-primary-light hover:text-gray-700 font-bold transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105'>
          Download Certificate
        </button>
      </div>
      <div style={{ width: '1000px' }} className='bg-primary '>
        <PDFExport paperSize='auto' landscape={true} ref={pdfExportComponent}>
          <div ref={contentArea} className='flex justify-center p-6 '>
            <div className='border-2 border-primary bg-white'>
              <div className='p-6 my-1'>
                <div className='flex justify-between  mb-12'>
                  <img src={companyLogo} alt='Logo' className='h-12' />
                  <div className='flex space-x-4'>
                    <img
                      src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
                      alt='Logo'
                      className='h-12'
                    />
                    {partnerName && (
                      <img src={partnerLogo} alt='Logo' className='h-12' />
                    )}
                  </div>
                </div>
                <div>
                  <div className='w-[90%] text-lg mx-auto text-center  space-y-10'>
                    <p className='font-semibold  text-5xl  text-center tracking-widest'>
                      {certificateText}
                    </p>
                    <p>
                      This is to certify that{' '}
                      <span className='font-bold'>
                        {user?.firstName} {user?.lastName}
                      </span>{' '}
                      has successfully completed the industry titled
                      <span className='font-bold'>{capstoneTitle}</span> in
                      <span className=' font-bold'> {domainName} </span>. This
                      accomplishment is a testament to their commitment &
                      contribution to the{' '}
                      <span className=' font-bold '> {companyName} </span>
                      project for a duration of{' '}
                      <span className=' font-bold'> 4 weeks </span>.
                    </p>

                    <p className=''>Congratulations on this Achievement! </p>
                    <div className='flex flex-col '>
                      <div className=' flex space-x-4 justify-center '>
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353663/uploaded_resources/One_no4yyb.gif'
                          alt='Logo'
                          className='h-16'
                        />
                        <img
                          src='https://res.cloudinary.com/belong/image/upload/v1643353575/uploaded_resources/Two_yy5ee6.gif'
                          alt='Logo'
                          className='h-16'
                        />
                      </div>
                      <p className='w-1/2 mx-auto border-2 border-b-0 border-l-0 border-r-0  tracking-wider  pt-2 text-center'>
                        Belong Founders
                      </p>
                    </div>
                  </div>
                  <div className='mt-12 font-medium  flex justify-between '>
                    <p className='text-gray-500'>
                      Certificate issued in association with{' '}
                      {partnerName && ` ${partnerName} & `} Belong
                    </p>
                    <p className='text-primary'>
                      {format(new Date(Date.now()), 'do LLLL yyyy')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
      </div>
    </>
  );
};

export default CompleteCertificate;
