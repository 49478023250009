import React from 'react';
import {
  BiMoney,
  BiBriefcase,
  BiClipboard,
  BiLocationPlus,
} from 'react-icons/bi';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { BasicDetailsType } from '../utils/types';
import PerksIcon from '../utils/PerksIcon';

const BasicDetails: React.FC<BasicDetailsType> = (props) => {
  const pillStyle =
    'h-8 border w-full font-semibold text-gray-600 border-1 bg-primary-lightest rounded-full px-2 py-1';
  const labelStyle = 'font-semibold';
  const iconStyle = 'text-xl inline mr-2 text-primary';
  const secondaryButtonStyle =
    'bg-primary-light px-4 py-2 rounded-lg shadow hover:bg-primary transition-all ease-in-out duration-300';
  return (
    <div className='p-4 shadow rounded'>
      <div className='md:flex md:justify-between gap-8'>
        <div className='space-y-4 md:mt-6'>
          <div className='flex flex-wrap gap-4 justify-between items-center'>
            <div className='space-x-2'>
              <span className={pillStyle}>{props.jobType}</span>
              <span className={pillStyle}>{props.roleType}</span>
            </div>
            {props.jobDescriptionDoc && (
              <a
                className={secondaryButtonStyle}
                href={`${UPLOAD_OR_DOWNLOAD_URL}/${props.jobDescriptionDoc}`}
                target='_blank'
                rel='noreferrer'
              >
                View Job Description
              </a>
            )}
          </div>
          <div>
            <p>
              <span className={labelStyle}>
                Selection Process: <BiClipboard className={iconStyle} />
              </span>
              {props.selectionProcess}
            </p>
          </div>
          {props.openPositionsCount && (
            <div>
              <p>
                <span className={labelStyle}>
                  Open Positions: <BiBriefcase className={iconStyle} />
                </span>
                {props.openPositionsCount}
              </p>
            </div>
          )}
          {props.jobLocation && props.jobLocation.country && (
            <div>
              <p>
                <span className={labelStyle}>
                  Location: <BiLocationPlus className={iconStyle} />
                </span>
                <span>
                  {props.jobLocation.city.map((city: any) => {
                    return <span key={city}>{city}, </span>;
                  })}
                </span>
                <span> {props.jobLocation.country} </span>
              </p>
            </div>
          )}
          {props.payRange && props.payRange.disclosed && (
            <div>
              <p>
                <span className={labelStyle}>
                  Salary: <BiMoney className={iconStyle} />
                </span>
                <span>{props.payRange.currency} </span>
                <span>{props.payRange.minimum} </span> -
                <span>{props.payRange.maximum} </span>
              </p>
            </div>
          )}
        </div>
        <div className='md:w-1/2 text-center mt-8 md:mt-0'>
          <p className='font-semibold mb-6'>Perks and Benefits</p>
          <div className='flex flex-wrap gap-6'>
            {Object.entries(props.perks).map(([key, value]) => {
              return value ? <PerksIcon perkName={key} key={key} /> : '';
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
