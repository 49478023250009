import React from 'react';

const QuickTipsSection = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div>
      <div className='px-4 sm:px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>
            Utilise this connect to seek guidance and support wherever needed.
            No question is too big or small
          </li>
          <li>
            Get your work reviewed, ideate on actionable steps and find your way
            around challenges
          </li>
          <li>
            You must register for these discussions and reserve your seat. Only
            limited seats available
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickTipsSection;
