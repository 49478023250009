import React from 'react';

const QuizStart = ({ setcurrentStep, domain, openQuiz }: any) => {
  return (
    <div className='mt-4 md:mt-10 bg-gray-50 border rounded p-4 md:px-10 flex justify-between items-center font-semibold text-primary'>
      <p>{domain?.title} Quiz</p>

      {openQuiz ? (
        <button
          onClick={() => setcurrentStep(2)}
          className='text-white bg-primary py-1 text-sm px-4 rounded'
        >
          Start Quiz
        </button>
      ) : (
        <div>
          <div className='border  border-primary bg-white rounded py-1'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1670311998/new%20b2b/bxs_lock-alt_s9g7ds.svg'
              alt='lock'
              className='mx-10'
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuizStart;
