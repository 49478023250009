import React, { useState } from 'react';
import moment from 'moment';
import TimeSheetWeeks from './layout/TimeSheetWeeks';
import TimeTable from './layout/TimeTable';
import AddTimeTable from './layout/AddTimeTable';
import EditTimeSheet from './layout/EditTimeSheet';

const TimeSheet = ({ batchDetails, currentWeek, userTimeSheetData }) => {
  const leaveWeeks = batchDetails?._id === '64f2077ec0020b0014ccec3d' ? 7 : 0;
  const timeSheetStartAt =
    batchDetails.timeSheet && batchDetails.timeSheet.startsAt;
  const [selectedWeek, setSelectedWeek] = useState(
    Math.min(currentWeek, batchDetails.week + leaveWeeks)
  );
  const dt1 = new Date(batchDetails.startDate);
  const weekDates = [];
  //   let mydate = '';
  for (let i = 0; i < 7; i++) {
    const mydate = moment(dt1, 'DD-MM-YYYY').add(
      (selectedWeek - 1) * 7 + i,
      'days'
    )._d;
    if (mydate.getDay() > 0 && mydate.getDay() < 6) {
      weekDates.push({
        date: moment(dt1, 'DD-MM-YYYY')
          .add((selectedWeek - 1) * 7 + i, 'days')
          .format('DD/MM/YYYY'),
        day: moment(dt1, 'DD-MM-YYYY')
          .add((selectedWeek - 1) * 7 + i, 'days')
          .format('ddd'),
      });
    }
  }

  const weekTimeSheet = userTimeSheetData.filter((sheet) => {
    return sheet.week === selectedWeek;
  });
  return (
    <div>
      <TimeSheetWeeks
        selectedWeek={selectedWeek}
        setSelectedWeek={setSelectedWeek}
        length={batchDetails.week}
        timeSheetStartAt={timeSheetStartAt}
        leaveWeeks={leaveWeeks}
      />
      {selectedWeek > currentWeek ? (
        <div className='text-center h-48 mt-8'>
          Timesheet will generate at the start of the week
        </div>
      ) : selectedWeek < currentWeek ? (
        weekTimeSheet.length ? (
          <TimeTable
            weekDates={weekDates}
            currentTimeSheet={weekTimeSheet[0]}
          />
        ) : (
          <AddTimeTable
            batchDetails={batchDetails}
            weekDates={weekDates}
            selectedWeek={selectedWeek}
          />
        )
      ) : weekTimeSheet.length ? (
        <EditTimeSheet
          weekDates={weekDates}
          currentTimeSheet={weekTimeSheet[0]}
        />
      ) : (
        <AddTimeTable
          batchDetails={batchDetails}
          weekDates={weekDates}
          selectedWeek={selectedWeek}
        />
      )}
    </div>
  );
};

export default TimeSheet;
