import React from 'react';
import axios from 'axios';
import 'survey-react/survey.css';
import * as Survey from 'survey-react';
import { API_URL } from 'config/API_LINKS';
const FeedbackForm = (props) => {
  const onCompleteSurvey = async (feedbacks) => {
    axios({
      method: 'PUT',
      url: API_URL + '/batch/feedback',
      data: {
        batchId: props.batchId,
        email: props.email,
        feedback: feedbacks.valuesHash,
      },
    })
      .then((response) => {
        if (response.status === 201) {
          props.setIsCompleted(true);
          props.setIsVisible(false);
        }
      })
      .catch((error) => {
        alert('Something went wrong, please try again.');
      })
      .finally(() => {
        window.location.reload();
      });
  };

  Survey.StylesManager.applyTheme('custom');

  var myCss = {
    matrix: {
      root: ' text-primary font-light text-xs',
      title: 'font-bold text-lg text-gray-800 text-left',
      cell: ' text-xs md:text-sm text-gray-700 font-normal ',
      cellLabel: ' text-left font-normal',
      label: 'sv_q_m_label text-xs text-center font-normal',
      cellText: ' font-normal',
      row: 'font-normal',
    },
    rating: {
      root: 'mb-10 text-center ',
      title: 'font-bold text-lg mb-3 md:mb-6',
      description: 'font-bold text-sm md:text-normal mb-6',
      item: 'text-center sv_q_rating_item text-xs md:text-normal',
      itemText:
        'text-sm sv_q_rating_item_text p-1 md:p-2 border rounded-full text-center',
    },
    comment: {
      title: 'font-bold text-sm md:text-normal mb-6',
      description: 'mb-4',
    },
    boolean: {
      root: 'text-center mb-6',
      title: 'font-bold text-sm md:text-normal mb-6',
    },
    file: {
      title: 'font-bold mb-6 text-sm md:text-normal',
    },
    html: {
      root: 'text-lg font-bold',
    },
  };

  const json = {
    pages: [
      {
        questions: [
          {
            type: 'matrix',
            name: 'Learnability',
            title: 'Learnability',
            isAllRowRequired: true,
            columns: [
              {
                value: 1,
                text: 'Strongly Agree',
              },
              {
                value: 2,
                text: 'Agree',
              },
              {
                value: 3,
                text: 'Neither',
              },
              {
                value: 4,
                text: 'Disagree',
              },
              {
                value: 5,
                text: 'Strongly Disagree',
              },
            ],
            rows: [
              {
                value:
                  'The apprenticeship met your expectations and/or objectives you had before joining.',
                text: 'The apprenticeship met your expectations and/or objectives you had before joining.',
              },
              {
                value:
                  'I gained practical learning that the university course does not offer.',
                text: 'I gained practical learning that the university course does not offer.',
              },
              {
                value: 'Mentors provided significant support and training.',
                text: 'Mentors provided significant support and training.',
              },
              {
                value:
                  'The experince was relevant to my career choice and progression.',
                text: 'The experince was relevant to my career choice and progression.',
              },
              {
                value:
                  'The journey guide or couch support played a crucial role in the learning journey.',
                text: 'The journey guide or couch support played a crucial role in the learning journey.',
              },
            ],
          },
        ],
      },
      {
        questions: [
          {
            type: 'matrix',
            name: 'Company Tasks',
            title: 'Company Tasks',
            isAllRowRequired: true,
            columns: [
              {
                value: 1,
                text: 'Strongly Agree',
              },
              {
                value: 2,
                text: 'Agree',
              },
              {
                value: 3,
                text: 'Neither',
              },
              {
                value: 4,
                text: 'Disagree',
              },
              {
                value: 5,
                text: 'Strongly Disagree',
              },
            ],
            rows: [
              {
                value:
                  'The company project tasks were successful in upskilling your practical domain knowledge.',
                text: 'The company project tasks were successful in upskilling your practical domain knowledge.',
              },
              {
                value:
                  'The tasks were challenging enough to enhance your domain knowledge and practical skills.',
                text: 'The tasks were challenging enough to enhance your domain knowledge and practical skills.',
              },
              {
                value:
                  'I was required to work independently on the project tasks to research, strategise, implement and deploy the solution.',
                text: 'I was required to work independently on the project tasks to research, strategise, implement and deploy the solution.',
              },
              {
                value:
                  'The project tasks quality met your expectations and helped you achieve relevant experience.',
                text: 'The project tasks quality met your expectations and helped you achieve relevant experience.',
              },
            ],
          },
        ],
      },
      {
        questions: [
          {
            type: 'matrix',
            name: '21st Century Skills',
            title: '21st Century Skills',
            isAllRowRequired: true,
            columns: [
              {
                value: 1,
                text: 'Strongly Agree',
              },
              {
                value: 2,
                text: 'Agree',
              },
              {
                value: 3,
                text: 'Neither',
              },
              {
                value: 4,
                text: 'Disagree',
              },
              {
                value: 5,
                text: 'Strongly Disagree',
              },
            ],
            rows: [
              {
                value:
                  'The IF sessions were successful in upgrading my apprenticeship experience.',
                text: 'The IF sessions were successful in upgrading my apprenticeship experience.',
              },
              {
                value:
                  'The Career Cafe sessions were crucial in defining my perspective and attitude towards my career.',
                text: 'The Career Cafe sessions were crucial in defining my perspective and attitude towards my career.',
              },
              {
                value:
                  'The journey required to think out of the box and innovate in the tasks assigned to me.',
                text: 'The journey required to think out of the box and innovate in the tasks assigned to me.',
              },
              {
                value:
                  'There were enough challenges to analyse and think critically.',
                text: 'There were enough challenges to analyse and think critically.',
              },
              {
                value:
                  'I was required to work independently on the projects to ideate a relevant solution and action plan.',
                text: 'I was required to work independently on the projects to ideate a relevant solution and action plan.',
              },
            ],
          },
        ],
      },
      {
        questions: [
          {
            type: 'matrix',
            name: 'Intra & Inter Personal Skills',
            title: 'Intra & Inter Personal Skills',
            isAllRowRequired: true,
            columns: [
              {
                value: 1,
                text: 'Strongly Agree',
              },
              {
                value: 2,
                text: 'Agree',
              },
              {
                value: 3,
                text: 'Neither',
              },
              {
                value: 4,
                text: 'Disagree',
              },
              {
                value: 5,
                text: 'Strongly Disagree',
              },
            ],
            rows: [
              {
                value:
                  'The workflow required coordination and communication with multiple people.',
                text: 'The workflow required coordination and communication with multiple people.',
              },
              {
                value:
                  'I developed awareness and insights into my personality, purpose and vision in life.',
                text: 'I developed awareness and insights into my personality, purpose and vision in life.',
              },
              {
                value:
                  'The training session were effective in improving my people skills.',
                text: 'The training session were effective in improving my people skills.',
              },
            ],
          },
        ],
      },
      {
        questions: [
          {
            type: 'matrix',
            name: 'Future Readiness',
            title: 'Future Readiness',
            isAllRowRequired: true,
            columns: [
              {
                value: 1,
                text: 'Strongly Agree',
              },
              {
                value: 2,
                text: 'Agree',
              },
              {
                value: 3,
                text: 'Neither',
              },
              {
                value: 4,
                text: 'Disagree',
              },
              {
                value: 5,
                text: 'Strongly Disagree',
              },
            ],
            rows: [
              {
                value:
                  'The apprenticeship helped me improve my reasoning and analytical skills.',
                text: 'The apprenticeship helped me improve my reasoning and analytical skills.',
              },
              {
                value:
                  'There were enough learning and personal growth opportunities to utilise.',
                text: 'There were enough learning and personal growth opportunities to utilise.',
              },
              {
                value: 'I got exposure into the global ecosystem and culture.',
                text: 'I got exposure into the global ecosystem and culture.',
              },
              {
                value:
                  'The journey encouraged me to gather information and make decisions based on it.',
                text: 'The journey encouraged me to gather information and make decisions based on it.',
              },
              {
                value:
                  'After the aprrenticeship, I feel better equipped to perform in a professional setup or environment.',
                text: 'After the aprrenticeship, I feel better equipped to perform in a professional setup or environment.',
              },
            ],
          },
        ],
      },
      {
        elements: [
          {
            type: 'rating',
            name: 'Score and Comment',
            title: 'Score and Comment',
            description:
              'How likely are you to recommend this apprenticeship to someone who is looking to be more employable?',
            rateMax: 10,
            minRateDescription: 'Not Likely to Recommend',
            maxRateDescription: 'Highly Recommend',
            isRequired: true,
          },
          {
            type: 'comment',
            name: 'If and what could the apprenticeship have offered to better your experience?',
            title:
              'If and what could the apprenticeship have offered to better your experience?',
            hideNumber: true,
          },
        ],
      },
      {
        elements: [
          {
            type: 'comment',
            name: 'Could you leave a short testimonial summarising your experience and learning from the journey?',
            title: 'Testimonial',
            description:
              'Could you leave a short testimonial summerinsing your experience and learning from the journey?',
          },
          {
            type: 'boolean',
            name: 'Do you share your consent to feature your experience as a testimonial on our platform?',
            hideNumber: true,
            isRequired: true,
          },
          {
            type: 'file',
            name: 'image',
            title:
              'If yes, can you upload a high resolution picture of yourself to feature of yourself to feature on our platform?',
            storeDataAsText: false,
            showPreview: true,
            imageWidth: 150,
            maxSize: 1024000,
          },
        ],
      },
    ],
  };

  async function uploadFiles(survey, options) {
    const fileExtension = options.files[0].name.substring(
      options.files[0].name.lastIndexOf('.') + 1
    );

    const formData = new FormData();
    formData.append('file', options.files[0]);
    formData.append('fileFormat', fileExtension);
    formData.append('uploadingAs', 'TESTIMONIAL_PROFILE_IMAGE');
    formData.append('userId', props.userId);
    const result = await axios.post(API_URL + '/aws-upload/single', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (result.status === 200) {
      options.callback(
        'success',
        options.files.map(function (file) {
          return {
            file: file.name,
            content: result.data.publicUrl,
          };
        })
      );
    }
  }

  const surveyRender = !props.isCompleted ? (
    <Survey.Survey
      json={json}
      showCompletedPage={false}
      onComplete={(feedbacks) => onCompleteSurvey(feedbacks)}
      css={myCss}
      onUploadFiles={uploadFiles}
    />
  ) : null;

  return <div>{surveyRender}</div>;
};

export default FeedbackForm;
