import classNames from 'classnames';
import useCapstoneTestResponsesContext from 'hooks/useCapstoneTestResponsesContext';
import React from 'react';

const TestCardBar: React.FC = () => {
  const { state } = useCapstoneTestResponsesContext();

  return (
    <div className='flex justify-between items-center lg:font-bold font-medium  lg:text-lg text-base bg-gradient-to-b  from-white to-primary-light lg:h-20 h-10 lg:px-20 px-6'>
      <p className='font-semibold'>Capstone Quiz</p>
      <div>
        <p
          className={classNames({
            hidden: !state.minutesLeft && !state.secondsLeft,
          })}
        >
          {state.minutesLeft}:{state.secondsLeft}
        </p>
      </div>
    </div>
  );
};

export default TestCardBar;
