import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAnnouncements: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/vga-announcement/user',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['Announcements'],
    }),
    markViewed: builder.mutation<any, any>({
      query: (params) => {
        return {
          method: 'PUT',
          url: '/vga-announcement/view-announcement',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['Announcements'],
    }),
  }),
});

export const { useGetAnnouncementsQuery, useMarkViewedMutation } =
  extendedApiSlice;
