import { initialState } from './types';

export function reducer(state: initialState, action: any) {
  switch (action.type) {
    case 'UPDATE_REMAINING_TIME':
      return {
        ...state,
        minutesLeft: action.payload.minutesLeft,
        secondsLeft: action.payload.secondsLeft,
      };
    case 'UPDATE_CURRENT_QUESTION':
      //Payload is the index of the question
      //Updating status as visited if not answered already and current question as payload
      const tempResponses = [...state.responses];
      tempResponses[action.payload] = {
        ...tempResponses[action.payload],
        status: tempResponses[action.payload].status || 'visited',
      };
      return {
        ...state,
        responses: tempResponses,
        currentQuestion: action.payload,
      };
    case 'UPDATE_OPTIONS':
      //To update status and options of a question based on option selected or not
      const responsesCopy = [...state.responses];
      //make copy of options and reset all to false
      const optionsCopy = [...responsesCopy[state.currentQuestion].options].map(
        (option) => {
          return { ...option, selected: false };
        }
      );
      //find index of option to update
      const updateOption = optionsCopy.findIndex(
        (option: any) => option._id === action.payload.option
      );
      //update option
      optionsCopy[updateOption] = {
        ...optionsCopy[updateOption],
        selected: action.payload.value,
      };
      //Update the question in responses copy
      responsesCopy[state.currentQuestion] = {
        ...responsesCopy[state.currentQuestion],
        options: optionsCopy,
        status: action.payload.value ? 'answered' : 'visited',
      };
      //return new state
      return {
        ...state,
        responses: responsesCopy,
      };
    default:
      return state;
  }
}
