import React from 'react';
import Links from './Links';

type contentProps = {
  content: any;
};
const EditorContent = (props: contentProps) => {
  const headingStyle = 'mb-2 font-semibold';
  const listStyle = 'pl-4 text-sm text-justify';

  //   const [imgVisible, setImgVisible] = useState(false);
  //   const viewerIsOpen = () => {
  //     setImgVisible(true);
  //   };
  //   const viewerIsClose = () => {
  //     setImgVisible(false);
  //   };
  return (
    <div className='flex flex-col space-y-4 mt-8'>
      {props.content.map((item: any, index: number) => {
        return (
          <div key={index}>
            {item.type === 'paragraph' && item.data.text.includes('href') ? (
              <Links link={item.data.text} />
            ) : (
              item.type === 'paragraph' && (
                <p
                  className='text-sm'
                  dangerouslySetInnerHTML={{ __html: item.data.text }}
                ></p>
              )
            )}
            {item.type === 'header' && (
              <p className={headingStyle}>{item.data.text}</p>
            )}
            {item.type === 'list' && (
              <>
                <ul>
                  {item.data.items.map((i: any, index: number) => (
                    <li className={listStyle} key={index}>
                      {i.content}
                    </li>
                  ))}
                </ul>
              </>
            )}
            {item.type === 'image' && (
              <div className='flex justify-center items-center'>
                <img
                  src={item.data.url}
                  alt='img'
                  className='w-96 h-72'
                  //   onClick={viewerIsOpen}
                />
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default EditorContent;
