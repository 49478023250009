import React from 'react';
import { FaStopwatch } from 'react-icons/fa';

const AptitudeTestCardBar: React.FC<{
  minutes: number;
  seconds: number;
}> = ({ minutes, seconds }) => {
  return (
    <div className='flex justify-between items-center lg:font-bold font-medium font-manrope lg:text-lg text-base bg-gradient-to-b from-white to-bgIndigo lg:h-20 h-10 px-1 md:px-4'>
      <div>Aptitude Test</div>
      <div className='flex'>
        <FaStopwatch className='mt-1 text-primary' />
        <p className='ml-1 text-gray-600 text-base'>
          : {minutes} : {seconds}
        </p>
      </div>
    </div>
  );
};

export default AptitudeTestCardBar;
