const TimeSheetHeader = () => {
    
    return (
      <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
        <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1670568071/new%20b2b/mdi_google-classroom_x3g7ge.svg'
            alt='logo'
          />
          <p className='text-sm font-semibold'>
            Your Weekly Time Sheet Log
          </p>
        </div>
  
        <div className='mt-2 md:mt-0'>
          <p className='text-sm'>
            <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
            Log in Weekly Hours
          </p>
          <p className='text-sm'>
            <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
            Internship Work Record
          </p>
          <p className='text-sm'>
            <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
            Time Sheet Submitted-{' '}
            <span className='text-primary'>
              5/12
            </span>
          </p>
        </div>
      </div>
    );
  };
  
  export default TimeSheetHeader;
  