import React from 'react';

const classNames = require('classnames');

const Buttons = ({
  selectedScreen,
  setSelectedScreen,
  showTimeSheet,
  showCertificate,
  showTicket,
  batchId,
}) => {
  const buttonText = [
    'Calendar',
    'Company Connect',
    'Project Tasks',
    'MasterClass',
    '21st Century Lab',
    'Coach Support',
  ];
  return (
    <div className='p-3 bg-gray-100 text-xs'>
      {[...Array(buttonText.length).keys()].map((_) => {
        return (
          <button
            key={_}
            type='button'
            className={classNames('bg-white p-2 hover:bg-primary-light', {
              'bg-primary-light': selectedScreen === _,
            })}
            onClick={() => {
              setSelectedScreen(_);
            }}
          >
            {buttonText[_]}
          </button>
        );
      })}
      {showTimeSheet && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 7,
          })}
          onClick={() => {
            setSelectedScreen(7);
          }}
        >
          Timesheets
        </button>
      )}

      {showTicket && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 8,
          })}
          onClick={() => {
            setSelectedScreen(8);
          }}
        >
          Query
        </button>
      )}
      {batchId !== '62947de0554ce700162e04d8' && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 11,
          })}
          onClick={() => {
            setSelectedScreen(11);
          }}
        >
          Quiz
        </button>
      )}
      {showCertificate && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 6,
          })}
          onClick={() => {
            setSelectedScreen(6);
          }}
        >
          Feedback & Certification
        </button>
      )}
    </div>
  );
};

export default Buttons;
