import React from 'react';

const MentorMeetings = ({ isCareereraUser }) => {
  return (
    <div className='bg-gray-100'>
      <div className='flex'>
        <div className='h-2 w-2 m-2 bg-primary'></div>
        <div>
          <h2>
            <b>Mentor Meetings</b>
          </h2>
        </div>
      </div>
      <div className='ml-10 text-xs'>
        <ul className='list-disc m-1 p-3'>
          <li className='m-1'>
            You will be connected with your mentor{' '}
            {isCareereraUser ? '' : 'by Belong '} through a scheduled meeting
            through this dashboard
          </li>
          <li className='m-1'>
            In the meeting you will interact with the mentor and establish a
            communication stream on email/ text messaging
          </li>
          <li className='m-1'>
            Please do follow the formal etiquettes of dressing and conversing
            appropriately at all times with your mentor
          </li>
          <li className='m-1'>
            If you miss a meeting or are unable to attend please{' '}
            {isCareereraUser
              ? 'raise a query'
              : 'notify Belong at connect@belong.education'}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MentorMeetings;
