import React from 'react';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../../config/API_LINKS';

const TimeTable = ({ weekDates, currentTimeSheet }) => {
  const report = currentTimeSheet.report;

  const totalAmount = function (data, day) {
    const mydata = data.map((cat) => {
      return cat.timeValues[day].hours;
    });
    var totalSum = 0;
    for (var i = 0; i < mydata.length; i++) {
      if (mydata[i] !== '') {
        totalSum += mydata[i];
      }
    }
    return totalSum;
  };

  let dailyTotal = [];
  for (let i = 0; i < 5; i++) {
    dailyTotal.push(totalAmount(report, i));
  }

  return (
    <div className='p-4'>
      <div>
        <table className='table-fixed border-collapse border border-gray-400'>
          <thead>
            <tr className='bg-primary-light'>
              <th className='w-3/12 border-collapse border border-gray-300'>
                Category
              </th>
              <th className='w-4/12  border-collapse border border-gray-300'>
                Description
              </th>
              {weekDates.map((myDate) => {
                return (
                  <th
                    key={myDate.day}
                    className='w-1/12 p-2  border-collapse border border-gray-300'
                  >
                    <p>{myDate.date}</p>
                    <p>{myDate.day}</p>
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {report.map((myReport, idx) => {
              return (
                <tr className='text-center' key={idx}>
                  <td className='py-3 border-collapse border border-gray-300'>
                    {myReport.eventCode}
                  </td>
                  <td className='py-3 border-collapse border border-gray-300'>
                    {myReport.description}
                  </td>
                  {myReport.timeValues.map((hour, index) => {
                    return (
                      <td
                        key={index}
                        className='py-3 border-collapse border border-gray-300'
                      >
                        {hour.hours}
                      </td>
                    );
                  })}
                </tr>
              );
            })}

            <tr className='bg-primary-light'>
              <td></td>
              <td>
                Total Hours = {dailyTotal.reduce((sum, a) => sum + a, 0)}/40
              </td>
              {dailyTotal.map((hour, idx) => {
                return (
                  <td
                    key={idx}
                    className='py-3 text-center border-collapse border border-gray-300'
                  >
                    {hour}
                  </td>
                );
              })}
            </tr>
          </tbody>
        </table>
      </div>
      <div className='border border-gray-300 text-center grid grid-cols-3'>
        <div className='border border-gray-300 p-4'>Weekly Work Report</div>
        <div className='border border-gray-300 p-4'>
          {currentTimeSheet.fileUrl && (
            <a
              className='text-primary'
              href={UPLOAD_OR_DOWNLOAD_URL + '/' + currentTimeSheet.fileUrl}
            >
              Download Report
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default TimeTable;
