import React from 'react';
import Types from './type.json';

const TicketTypeBox = (props) => {
  const setMyTicket = (ticketType) => {
    props.setTicketType(ticketType);
    if (ticketType === 'Coach') {
      props.setTicketAssignee(props.userDetails.coach._id);
    } else if (ticketType === 'Mentor') {
      props.setTicketAssignee(props.userDetails.mentor._id);
    } else {
      props.setTicketAssignee(null);
    }
  };

  const myType = Types.filter((type) => {
    if (props.isVGE) {
      return type.type !== 'Mentor';
    } else if (props.isFiibBatch) {
      return type.type === 'Belong';
    } else return true;
  });

  return (
    <div
      className={`${
        props.partner === '610a7ca8d3c51a1458d005ce'
          ? 'grid grid-cols-4'
          : 'grid grid-cols-3'
      } flex justify-center md:justify-start ml-3 mr-3 md:mr-0 md:ml-6`}
    >
      {myType.map((type, index) => {
        return (
          <div
            key={index}
            className='flex flex-col col-span-3 md:col-span-1 mb-4 md:mb-0 md:mr-2'
          >
            <div
              className='border shadow py-2 flex flex-col cursor-pointer h-56 ml-6 bg-white'
              onClick={() => {
                setMyTicket(type.type);
              }}
            >
              <div className='flex items-center px-4 mb-3'>
                <p
                  className={`${type.id === 'C' && 'bg-yellow-300'} ${
                    type.id === 'M' && 'bg-red-300'
                  } ${
                    type.id === 'B' && 'bg-primary'
                  } flex justify-center items-center text-xl mr-3 text-center text-gray-50 rounded-full h-10 w-10`}
                >
                  {type.id}
                </p>
                <p className='text-gray-600 font-semibold' key={type.id}>
                  {type.type === 'Belong' ? (
                    <p> Write to {props.isCareereraUser ? 'us' : type.type} </p>
                  ) : (
                    <p> Write to your {type.type}</p>
                  )}
                </p>
              </div>
              <div className='text-primary text-xs w-full px-4 space-y-1 flex flex-col justify-center'>
                {type.list.map((li, i) => {
                  return (
                    <p className='flex' key={i}>
                      <span className='font-semibold mr-2'>-</span>
                      <span className='text-gray-400 font-semibold'>{li}</span>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
      {props.partner === '610a7ca8d3c51a1458d005ce' && (
        <a href='mailto:customersupport@deviare.africa?subject=[VGA]%20Query&cc=connect@belong.education;'>
          <div className='flex flex-col col-span-3 md:col-span-1 mb-4 md:mb-0 md:mr-2'>
            <div className='border shadow py-2 flex flex-col cursor-pointer h-56 ml-6 bg-white'>
              <div className='flex items-center px-4 mb-3'>
                <p className='flex bg-blue-500 justify-center items-center text-xl mr-3 text-center text-gray-50 rounded-full h-10 w-10'>
                  D
                </p>
                <p className='text-gray-600 font-semibold'>
                  <p> Write to Deviare </p>
                </p>
              </div>
              <div className='text-primary text-xs w-full px-4 space-y-1 flex flex-col justify-center'>
                <p className='flex'>
                  <span className='font-semibold mr-2'>-</span>
                  <span className='text-gray-400 font-semibold'>
                    Deviare Customer Support Team
                  </span>
                </p>
              </div>
            </div>
          </div>
        </a>
      )}
    </div>
  );
};

export default TicketTypeBox;
