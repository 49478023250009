import React, { useState } from 'react';

import Card from './Card';
import Navigation from './Navigation';
import Progress from './Progress';
import Preview from './Preview';

const Questions = (props) => {
  const [showPreview, setShowPreview] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(
    parseInt(localStorage.getItem('localVgaCurrentQuestion')) || 0
  );
  const question = props.finalSubmission[currentQuestion].question;
  const options = props.finalSubmission[currentQuestion].options;
  const image = props.finalSubmission[currentQuestion].image || [];
  const isInputType =
    props.finalSubmission[currentQuestion].responseType === 'text';

  return (
    <>
      {showPreview ? (
        <Preview
          finalSubmission={props.finalSubmission}
          autoSubmit={props.autoSubmit}
          batchId={props.batchId}
        />
      ) : (
        <>
          <Card
            question={question}
            options={options}
            currentQuestion={currentQuestion}
            image={image}
            finalSubmission={props.finalSubmission}
            setFinalSubmation={props.setFinalSubmation}
            isInputType={isInputType}
          />
          <Navigation
            setCurrentQuestion={setCurrentQuestion}
            currentQuestion={currentQuestion}
            totalQuestions={props.totalQuestions}
            finalSubmission={props.finalSubmission}
            autoSubmit={props.autoSubmit}
            setShowPreview={setShowPreview}
            batchId={props.batchId}
          />
          <Progress
            currentQuestion={currentQuestion + 1}
            totalQuestions={props.totalQuestions}
          />
        </>
      )}
    </>
  );
};

export default Questions;
