import { Link, useParams } from 'react-router-dom';
import SuperCapstoneBreadcurmb from './SuperCapstoneBreadcurmb';
import classNames from 'classnames';

const Breadcrumb = ({
  pathname,
  children,
  extendSidebar,
}: {
  pathname: any;
  children: any;
  extendSidebar: boolean;
}) => {
  const usePathname = pathname.split('/')[1];
  const titleMap: { [key: string]: string } = {
    '': 'Dashboard',
    supercapstone: 'Capstones',
    dashboard: 'Apprenticeship',
  };
  const { superCapstoneId } = useParams();

  return (
    <div
      className={classNames(
        'flex flex-wrap gap-2 justify-between p-4 sm:pl-24',
        { 'sm:pl-56': extendSidebar }
      )}>
      <nav className='flex mb-1' aria-label='Breadcrumb'>
        <ol className='flex items-center space-x-4'>
          {/* FIrst page here without chevron right */}
          <li>
            <div className='flex items-center'>
              <Link to={`/${usePathname}`} className='text-xl font-normal'>
                {titleMap[usePathname] || ''}
              </Link>
            </div>
          </li>
          {superCapstoneId && (
            <SuperCapstoneBreadcurmb superCapstoneId={superCapstoneId} />
          )}
        </ol>
      </nav>

      {children}
    </div>
  );
};

export default Breadcrumb;
