import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const ProfileCard = ({ onbordingData }: any) => {
  const navigate = useNavigate();
  const { belongScore } = onbordingData;
  const profileStrength = belongScore?.profileStrength || 0;

  return (
    <div
      onClick={() => {
        navigate('/profile');
      }}
      className='relative  mt-4 md:mx-2  pb-4 text-center border rounded bg-primary-lightest cursor-pointer'
    >
      {/* <div className='absolute text-xs bg-primary rounded text-white px-4 py-0.5  text-center right-0 top-0'>
        1/1
      </div> */}
      <div className='flex justify-center pt-4 mt-4'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1678860332/new%20b2b/image_239_lbfcqx.svg'
          alt='user'
        />
      </div>
      <p className='my-2 font-semibold'>Employment Profile</p>
      <p className='font-semibold text-sm'>Strength</p>
      <p className='text-primary font-bold text-sm mt-1'>
        {profileStrength.toFixed(1)} / 15
      </p>
      <Link to='/profile'>
        <button className='text-sm  mt-1 text-white bg-primary rounded-2xl  px-3 py-1'>
          Complete Profile
        </button>
      </Link>
    </div>
  );
};

export default ProfileCard;
