import React from 'react';
import htmlparser from 'html-react-parser';
import { FaRegFilePdf } from 'react-icons/fa';

interface allProps {
  data: any;
}

const Dataset = (props: allProps) => {
  const data = props.data?.dataset;
  return (
    <div className=' flex flex-col gap-3'>
      {data?.description || data?.link ? (
        <React.Fragment>
          <div className='flex justify-between'>
            <div className='font-medium'>Dataset Description</div>
            <div>
              <a
                className='text-primary flex'
                href={data?.link}
                target='_blank'
                rel='noreferrer noopener'>
                Download <FaRegFilePdf className='ml-2 mt-1' />
              </a>
            </div>
          </div>
          {data?.description && (
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(data?.description)}
            </div>
          )}
        </React.Fragment>
      ) : (
        <p className=' bg-neutral-100 bg-opacity-60 font-medium rounded-lg'>
          Dataset is currently unavailable for this capstone.
        </p>
      )}
    </div>
  );
};

export default Dataset;
