import React from 'react';
import { format } from 'date-fns';

const TaskListElement = ({ task }: { task: any }) => {
  return (
    <div className='flex justify-between items-center gap-4 cursor-pointer'>
      <div className='flex'>
        <p className='shrink-0 mr-2 mt-2 h-2 w-2 rounded-full bg-gray-400'></p>
        <div>
          <p>{task.title}</p>
          <p className='text-xs text-gray-400'>
            {task.type === '21st Century Skills' ? (
              <span>Assignments</span>
            ) : (
              task.type
            )}
          </p>
        </div>
      </div>
      <div className='text-xs shrink-0 text-red-400' title='Deadline'>
        <p>{format(new Date(task.dueDateTime), 'd LLL')}</p>
        <p>{format(new Date(task.dueDateTime), 'p')}</p>
      </div>
    </div>
  );
};

export default TaskListElement;
