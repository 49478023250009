import React, { useState } from 'react';
import QuizScoreChart from './QuizScoreChart';
import { Link, useParams } from 'react-router-dom';

type scoreProps = {
  data: any;
};

const ShowDomainScore = (props: scoreProps) => {
  const params = useParams();
  const areaId = params?.areaId;
  const selectedArea = props.data?.filter(
    (data: any) => data.domain._id === areaId
  )[0];
  const [currentDomainId, setCurrentData] = useState(
    selectedArea ? areaId : props.data[0].domain._id
  );

  const currentData = props.data.filter((domain: any) => {
    return domain.domain._id === currentDomainId;
  })[0];

  const percentage = currentData.percentage;

  function getSkillPercentage(skillId: any) {
    const currentSkill = currentData.finalScore.filter((skill: any) => {
      return skill.skillId === skillId;
    })[0];
    const catPercentage = Math.ceil(
      (currentSkill.obtained * 100) / currentSkill.max
    );
    return catPercentage;
  }
  const color = percentage > 25 ? '#C2FFFB' : '#EC9B9B';

  // new representation of score
  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
  ];

  function showImage(percentage: number) {
    let imageToShow = images[0];
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1];
    }
    if (percentage < 65) {
      imageToShow = images[2];
    }
    return imageToShow;
  }
  const competencyHeadings = [
    {
      id: 1,
      heading: 'Areas of Growth (0% to 30%)',
      text: 'These are areas where foundational skills need development; focus on building strong basics.',
    },
    {
      id: 2,
      heading: 'Areas of Improvement (30% to 80%)',
      text: 'These areas show moderate proficiency; continue refining and practicing to achieve excellence.',
    },

    {
      id: 3,
      heading: 'Areas of Strength (80% and above) ',
      text: 'These are your strong suits; apply these skills with real-world projects and gain experience.',
    },
  ];
  function getCompetencyHeading(percentage: number) {
    if (percentage <= 30) {
      return competencyHeadings[0]; // Areas of Growth
    } else if (percentage < 80) {
      return competencyHeadings[1]; // Areas of Improvement
    } else {
      return competencyHeadings[2]; // Areas of Strength
    }
  }

  const selectedHeading = getCompetencyHeading(percentage);

  // Categorize skills based on their percentages
  const categorizedSkills = {
    growth: currentData.skills.filter(
      (skill: any) => getSkillPercentage(skill._id) <= 30
    ),
    improvement: currentData.skills.filter(
      (skill: any) =>
        getSkillPercentage(skill._id) > 30 && getSkillPercentage(skill._id) < 80
    ),
    strength: currentData.skills.filter(
      (skill: any) => getSkillPercentage(skill._id) >= 80
    ),
  };

  // Trophy text for different score ranges
  const trophyText = {
    gold: {
      title: 'Gold (You are in the top 90%)',
      message:
        "Outstanding performance! You've demonstrated exceptional mastery in this area.",
    },
    silver: {
      title: 'Silver (You are in the top 80%)',
      message: (
        <span>
          Strong performance! You&apos;re highly proficient and nearing the top
          tier.
        </span>
      ),
    },
    bronze: {
      title: 'Bronze (You are in the top 70%)',
      message:
        'Good performance! You have a solid understanding, with room for further growth.',
    },
  };
  // Determine which trophy text to show based on the score
  function getTrophyText(percentage: number) {
    if (percentage > 80) {
      return trophyText.gold;
    } else if (percentage > 65 && percentage <= 80) {
      return trophyText.silver;
    } else if (percentage > 45 && percentage <= 65) {
      return trophyText.bronze;
    }
    return null; // For scores below 70%, no trophy text is shown
  }
  const trophyMessage = getTrophyText(percentage);

  return (
    <div className='m-1 sm:m-4'>
      <div className='flex justify-end'>
        <select
          value={currentDomainId}
          onChange={(e) => setCurrentData(e.target.value)}>
          {props.data.map((domain: any, index: number) => {
            return (
              <option key={index} value={domain.domain._id}>
                {domain.domain.title}
              </option>
            );
          })}
        </select>
      </div>
      <div className='hidden text-gray-500 font-semibold m-2'>
        Congratulations! You can start Applying to Opportunities.
      </div>
      <div className='bg-gray-50 p-4 mt-8'>
        <div className='sm:flex text-gray-500 font-semibold'>
          <div>
            <div className='mb-6 text-center '>Your Score Card</div>
            <div className='flex justify-center'>
              <QuizScoreChart color={color} percentage={percentage} />
            </div>
          </div>
          <div className='mt-12 sm:ml-16'>
            <div className='mb-4'>
              The Assessment assesses on the {currentData.skills.length} skill
              expertise as picked by you:
              <span className='text-primary'>
                {currentData.skills.map((skill: any) => {
                  return <span key={skill._id}> {skill.title}, </span>;
                })}
              </span>
            </div>
            <div className='grid grid-cols-2 gap-2'>
              {currentData.skills.map((skill: any) => {
                return (
                  <div
                    key={skill._id}
                    className='my-2 flex items-center bg-primary-lightest col-span-2 sm:col-span-1 rounded-3xl'>
                    <div className='bg-primary rounded-full text-center flex items-center w-12 h-12 px-3 py-1 text-white'>
                      {getSkillPercentage(skill._id)}%
                    </div>
                    <div className='flex justify-between w-full '>
                      <div className='ml-3 text-sm mt-1'>{skill.title} </div>
                      {percentage > 45 && (
                        <div>
                          <img
                            alt='skill'
                            className='mr-6'
                            src={showImage(percentage)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  // <li key={skill._id}>
                  //   {skill.title} -{' '}
                  //   <span className='text-primary'>
                  //     {getSkillPercentage(skill._id)} %
                  //   </span>
                  // </li>
                );
              })}
            </div>
          </div>
        </div>
        {/* text area */}
        <div className='space-y-3 mt-4 ml-10'>
          <p className='font-bold'>Your Skill Insights:</p>
          <p className='font-bold text-primary'>{selectedHeading.heading}</p>
          <p>{selectedHeading.text}</p>
          {/* Display categorized skills */}
          {categorizedSkills.growth.length > 0 && (
            <div>
              <p className='font-bold text-red-500'>Growth Areas:</p>
              <ul>
                {categorizedSkills.growth.map((skill: any) => (
                  <li key={skill._id}>{skill.title}</li>
                ))}
              </ul>
            </div>
          )}

          {categorizedSkills.improvement.length > 0 && (
            <div>
              <p className='font-bold text-yellow-500'>Improvement Areas:</p>
              <ul>
                {categorizedSkills.improvement.map((skill: any) => (
                  <li key={skill._id}>{skill.title}</li>
                ))}
              </ul>
            </div>
          )}

          {categorizedSkills.strength.length > 0 && (
            <div>
              <p className='font-bold text-green-500'>Strength Areas:</p>
              <ul>
                {categorizedSkills.strength.map((skill: any) => (
                  <li key={skill._id}>{skill.title}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className='space-y-4'>
          {percentage > 70 && (
            <p className='font-bold'>Yay! You won a trophy:</p>
          )}
          {trophyMessage && (
            <div className='mb-4 text-center'>
              <div className='text-xl font-bold'>{trophyMessage.title}</div>
              <p>{trophyMessage.message}</p>
            </div>
          )}
        </div>
      </div>
      <div className='flex justify-center mt-4 sm:mt-12'>
        <Link
          to='/'
          className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'>
          Back to Home
        </Link>
      </div>
    </div>
  );
};

export default ShowDomainScore;
