import React, { useState } from 'react';
import Pagination from '../capstone/Pagination';
import Loading from 'components/global/layout/Loading';

import {
  useGetSuperCapstonesQuery,
  useGetUserSuperCapstonesQuery,
} from 'components/superCapstone/superCapstoneSlice';
import AllSuperCapstone from './AllSuperCapstone';
import SuperToggleBar from './SuperToggleBar';
import { RiToolsFill } from 'react-icons/ri';
import OngoingSuperCapstone from './OngoingSuperCapstone';
import CompletedSuperCapstone from './CompletedSuperCapstone';

const Index = () => {
  const filterOptions = ['All', 'Ongoing', 'Completed'];
  const sortbyOptions = [
    {
      sortBy: 'Domain',
      icon: <RiToolsFill className='text-lg text-gray-400 mt-1' />,
      options: [],
    },
    {
      sortBy: 'Industry',
      icon: <RiToolsFill className='text-lg text-gray-400 mt-1' />,
      options: [],
    },
    {
      sortBy: 'Level',
      icon: <RiToolsFill className='text-lg text-gray-400 mt-1' />,
      options: [],
    },
    {
      sortBy: 'Time',
      icon: <RiToolsFill className='text-lg text-gray-400 mt-1' />,
      options: [],
    },
  ];
  const defaultFilterState = {
    filter: 'All',
    Domain: '',
    Industry: '',
    Level: '',
    Time: '',
  };
  const [activeFilter, setActiveFilter] = useState(defaultFilterState);

  const [page, setPage] = useState(0);
  // const [level, setLevel] = useState('');
  // WRITE 'level' after limit:6 below

  const { isLoading, isError, isSuccess, isFetching, data } =
    useGetSuperCapstonesQuery({ page, limit: 6 });

  // For Ongoing and Completed Capstones
  const { data: ongoingAndCompleteData } = useGetUserSuperCapstonesQuery({});

  let completedCapstones = [];
  let onGoingCapstones = [];

  const calcPercentage = (quizlen: number, taskLen: number): boolean => {
    return quizlen === taskLen;
  };

  if (ongoingAndCompleteData) {
    // data.tasks.length === data.superCapstoneId.taks.length
    // data.quiz.length === data.superCapstoneId.tasks.length
    // calcPercentage(data.tasks) >= 50

    let completed: any[] = [];
    let ongoing: any[] = [];

    for (const cap of ongoingAndCompleteData) {
      const condition1 = cap.tasks?.length === cap.superCapstoneId.tasks.length;
      const condition2 = cap.quiz?.length === cap.superCapstoneId.tasks.length;
      const condition3 = calcPercentage(cap.quiz?.length, cap.tasks?.length);

      if (condition1 && condition2 && condition3) {
        completed.push(cap);
      } else {
        ongoing.push(cap);
      }
    }

    // const completed = ongoingAndCompleteData.filter((cap: any) => {
    //   if (cap.tasks.length === 0) {
    //     return true;
    //   }

    //   const condition1 = cap.tasks?.length === cap.superCapstoneId.tasks.length;
    //   const condition2 = cap.quiz?.length === cap.superCapstoneId.tasks.length;
    //   const condition3 = calcPercentage(cap.quiz?.length, cap.tasks?.length);

    //   return condition1 && condition2 && condition3;
    // });

    // const ongoing = ongoingAndCompleteData.filter((cap: any) => {
    //   const condition1 = cap.tasks?.length === cap.capstoneSubmissions.length;
    //   const condition2 = cap?.capstoneAssessments?.percentage >= 50;
    //   return !(condition1 && condition2);
    // });

    completedCapstones = completed;
    onGoingCapstones = ongoing;
  }

  return (
    <div>
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1716291365/new%20b2b/Top_1_svf55r.webp'
        alt='banner'
        className='w-full object-cover pb-6'
      />
      <div className='grid grid-cols-12 lg:grid-cols-10'>
        <div className='col-span-12 lg:col-span-10'>
          <div className=' px-2 mx-2 space-y-6 pb-6 '>
            {/* <div className='w-full'>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1716291365/new%20b2b/Top_1_svf55r.webp'
              alt='banner'
              className='w-full object-cover'
            />
          </div> */}
            <SuperToggleBar
              filterOptions={filterOptions}
              setActiveFilter={setActiveFilter}
              sortbyOptions={sortbyOptions}
              activeFilter={activeFilter}
            />

            {isLoading && (
              <div>
                <Loading />
              </div>
            )}
            {isError && <div>Error fetching data</div>}
            {isSuccess && (
              <React.Fragment>
                {activeFilter.filter === 'All' && (
                  <React.Fragment>
                    <AllSuperCapstone
                      data={data?.data || []}
                      isFetching={isFetching}
                    />
                    <Pagination
                      data={data}
                      setPage={setPage}
                      isFetching={isFetching}
                    />
                  </React.Fragment>
                )}
                {activeFilter.filter === 'Ongoing' && (
                  <OngoingSuperCapstone data={onGoingCapstones} />
                )}
                {activeFilter.filter === 'Completed' && (
                  <CompletedSuperCapstone data={completedCapstones} />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
