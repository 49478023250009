import React from 'react';

const criterias = [
  {
    id: 1,
    heading: 'Problem Statement Comprehension and Analysis',
    text: 'Ability to construct the problem statement, identify the various challenges and structure the holistic view',
    Required: 'Analytical and Critical Thinking',
  },
  {
    id: 2,
    heading: 'Potential Solutions Evaluation',
    text: 'Ability to conduct research, identify approaches and evaluate potential solutions with insightful explanation',
    Required: 'Research and Creative Problem Solving',
  },
  {
    id: 3,
    heading: 'Solution Startegy and Implementation',
    text: 'Ability to review logic/reasoning, examines feasibility of solution and weigh impacts of solution',
    Required: 'Domain Knowledge and Skill Applicability',
  },
  {
    id: 4,
    heading: 'Challenge Addressal',
    text: 'Ability to proactively identify the challenge in the implementation and suggest & revise the solution ',
    Required: 'Commitment',
  },
  {
    id: 5,
    heading: 'Outcome Evaluation',
    text: 'Ability to conclude results relative to the problem defined with thorough considerations on need for further work',
    Required: 'Domain Expertise',
  },
];

const CriteriaSection = () => {
  return (
    <div className='mt-4 md:mt-6'>
      {/* <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div> */}
      <p className='font-semibold my-4 text-textColor-lighter'>
        Evaluation Criteria
      </p>
      <div className='my-4 lg:my-8 md:w-3/4 mr-auto '>
        {criterias.map((criteria) => {
          return (
            <div
              key={criteria.id}
              className='grid grid-cols-12 md:grid-flow-col gap-2 mb-4 sm:mb-2   text-textColor-lighter'
            >
              <div className='col-span-12 md:col-span-8 border rounded-lg  p-2 md:px-4 '>
                <p className='font-medium mb-2'>{criteria.heading}</p>
                <p className='text-sm'>{criteria.text}</p>
              </div>
              <div className='col-span-12 md:col-span-4  border rounded-lg  p-2 md:px-4'>
                <div className='flex items-center h-full '>
                  <p className='text-sm sm:text-base'>{criteria.Required}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CriteriaSection;
