import { useState } from 'react';
import Progress from './Progress';
import Questions from './Questions';
import { useGetUserQuery } from 'components/profile/userSlice';
import { allBatches } from 'config/common';

const deviareDatabase = [
  {
    questionNum: 1,
    question:
      'What aspects of data science ignite your passion? Tell us about any experience that made you more passionate and guided your focus on Data Science (150-300 words)',
    questionType: 'text',
    // value: '',
  },
  {
    questionNum: 2,
    question:
      'Can you share your past experiences with projects, what areas did you find most engaging or challenging and how did you navigate those challenges? (150-300 words)',
    questionType: 'text',
    // value: '',
  },
  {
    questionNum: 3,
    question:
      'In terms of project work, could you outline your preferred area within data science? (choose only one)',
    questionType: 'deviareRadio',
    // value: '',
  },
  {
    questionNum: 4,
    question:
      'Which specific skills from the provided list resonate most with you? (maximum 6)',
    questionType: 'checkbox',
    // value: '',
  },
];

const basicDatabase = [
  {
    questionNum: 5,
    question:
      'To what extent did the project go beyond surface-level information and provide a deeper understanding of the topic?',
    questionType: 'radio',
  },
  {
    questionNum: 6,
    question:
      'How would you rate the accuracy and sufficiency of the resources presented in addition to task explanations?',
    questionType: 'radio',
  },
  {
    questionNum: 7,
    question:
      'How well was the project presented with clear and effective visual aids, such as graphs, charts, and tables?',
    questionType: 'radio',
  },
  {
    questionNum: 8,
    question:
      'To what extent was the language used clear, concise, and easy to understand?',
    questionType: 'radio',
  },
  {
    questionNum: 9,
    question:
      'How up-to-date and relevant was the information to current industry trends and developments?',
    questionType: 'radio',
  },
  {
    questionNum: 10,
    question:
      'To what extent did the project enable your skill development and meet your experiential learning goals?',
    questionType: 'radio',
  },
  {
    questionNum: 11,
    question:
      'How easy was the layout and organization of the platform to navigate?',
    questionType: 'radio',
  },
  {
    questionNum: 12,
    question: 'Overall, how would you rate your experience of the platform?',
    questionType: 'radio',
  },
];

const Index = ({ enrollmentId, setShowFeedback }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const { data } = useGetUserQuery();
  const userBatches = data?.batches || [];
  const database = userBatches.includes(allBatches.deviareMICT)
    ? deviareDatabase.concat(basicDatabase)
    : basicDatabase;

  return (
    <div className='text-gray-900'>
      <div className='p-4 space-y-4  mx-4 md:mx-auto  border shadow rounded-xl'>
        <Progress currentQuestion={currentQuestion} database={database} />
        <Questions
          setShowFeedback={setShowFeedback}
          enrollmentId={enrollmentId}
          database={database}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
        />
      </div>
    </div>
  );
};

export default Index;
