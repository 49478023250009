import React, { useState } from 'react';
import DomainQuizDiscussions from '../layout/DomainQuizDiscussions';
import QuickTipsSection from '../layout/QuickTipsSection';
import QuizStart from '../layout/QuizStart';
import SelectSkills from './SelectSkills';
import StartTestInstructions from './StartTestInstructions';

const Index = ({ domain, batchId, openQuiz }: any) => {
  const [currentStep, setcurrentStep] = useState(1);
  const [skills, setSkills] = useState([]);
  return (
    <div>
      {currentStep === 1 && (
        <>
          <DomainQuizDiscussions />
          <QuickTipsSection />
          <QuizStart
            domain={domain}
            setcurrentStep={setcurrentStep}
            openQuiz={openQuiz}
          />
        </>
      )}
      {currentStep === 2 && (
        <SelectSkills
          domainId={domain._id}
          skills={skills}
          setSkills={setSkills}
          setcurrentStep={setcurrentStep}
        />
      )}
      {currentStep === 3 && (
        <StartTestInstructions
          domainId={domain._id}
          skills={skills}
          batchId={batchId}
        />
      )}
    </div>
  );
};

export default Index;
