import React from 'react';

const CMSection = (props) => {
  return (
    <div className='md:p-4 flex flex-col md:flex-row m-2'>
      <img
        src={props.imgUrl}
        className=' m-2 w-24 h-auto flex justify-center items-center md:h-32 md:w-auto md:object-fill shadow'
        alt='Meeting'
      />
      <div className='flex flex-col mt-4 md:mt-8 md:ml-6'>
        <h1 className='text-sm font-bold '>{props.name}</h1>
        <p className='text-sm mt-2 text-gray-700'>{props.designation}</p>
        <p className='text-sm mt-2 text-gray-700'>{props.aboutCM}</p>
      </div>
    </div>
  );
};

export default CMSection;
