import { useState, useMemo } from 'react';
import { Auth } from 'aws-amplify';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Loading from 'components/global/layout/Loading';
import { setToken } from 'components/auth/authSlice';

const PrivateRoute = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const url = window.location.hostname;
  const isEdRed = url === 'capstone.edred.in';

  const loginpath = isEdRed ? '/login/edred' : '/login';

  useMemo(() => {
    Auth.currentAuthenticatedUser({
      bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
      .then(async (res) => {
        const token = res?.signInUserSession?.accessToken?.jwtToken;
        dispatch(setToken(token));
        setLoggedIn(true);
      })
      .finally(() => setLoading(false));
  }, [dispatch]);

  const content = loggedIn ? (
    <Outlet />
  ) : (
    <Navigate to={loginpath} state={{ from: location }} replace />
  );

  return loading ? <Loading /> : content;
};

export default PrivateRoute;
