import React, { useEffect, useState } from 'react';
import { useGetUserSuperCapstonesQuery } from '../superCapstoneSlice';
import { useParams } from 'react-router-dom';

import CapstoneInfo from './CapstoneInfo';
import TabBar from './TabBar';
import Dataset from './Dataset';
import Tasks from './Tasks';
import TaskView from './taskView';
import SuperEnrolNow from './SuperEnrolNow';
import ProblemStatement from './ProblemStatement';
import Prerequisites from './Prerequisites';
import Objectives from './Objectives';
import FeedBackView from './FeedBackView';

interface allProps {
  data: any;
}

const CheckUserStatus = (props: allProps) => {
  // Determine the tab options dynamically

  const tabOptions = [
    'Problem Statement',
    'Objectives',
    ...(props.data?.dataset?.description?.trim() &&
    props.data?.dataset?.link?.trim()
      ? ['Dataset']
      : []),
    'Prerequisites',
    'Tasks',
  ];

  const [activeTab, setActiveTab] = useState('Problem Statement');
  const [selectedTask, setSelectedTask] = useState(null);
  const { taskno } = useParams();

  useEffect(() => {
    if (taskno === 'feedback') {
      setActiveTab('Tasks');
    }
    if (taskno === 'tasks') {
        setActiveTab('Tasks');
      }
    if (!taskno) {
      setActiveTab('Problem Statement');
      setSelectedTask(null);
      setShowFeedback(false);
    }
  }, [taskno]);

  const [count, setCount] = useState(1);

  const { isSuccess, data: enrollData } = useGetUserSuperCapstonesQuery({});
  const [showFeedback, setShowFeedback] = useState(false);

  // Find the enroll data for the current supercapstone from the array
  // Check whether the user has enrolled into the current viewing supercapstone
  let isEnrolled = false;
  let currentEnrollData: any = null;
  if (enrollData) {
    for (const enD of enrollData) {
      if (enD.superCapstoneId?._id === props.data?._id) {
        currentEnrollData = enD;
        isEnrolled = true;
      }
    }
  }

  const [userProgress, setUserProgress] = useState(0);

  return (
    <React.Fragment>
      {selectedTask ? (
        <TaskView
          setSelectedTask={setSelectedTask}
          selectedTask={selectedTask}
          data={props.data}
          enrollData={currentEnrollData}
          count={count}
        />
      ) : showFeedback ? (
        <div>
          <FeedBackView
            enrollmentId={currentEnrollData?._id}
            setShowFeedback={setShowFeedback}
          />
        </div>
      ) : (
        <div className='flex flex-col md:flex-row h-full md:border-t-2 text-justify'>
          <div className='md:basis-2/5 md:border-r-2 border-gray-200 h-full'>
            <CapstoneInfo data={props.data} />
          </div>
          <div className='flex flex-col h-full md:basis-3/5'>
            <TabBar
              quizDisabled={false}
              tabOptions={tabOptions}
              setActiveTab={setActiveTab}
              activeTab={activeTab}
            />
            <div className='flex flex-col justify-between h-full '>
              <div className='p-2 md:p-6'>
                {activeTab === 'Problem Statement' && (
                  <ProblemStatement data={props.data} />
                )}
                {activeTab === 'Objectives' && <Objectives data={props.data} />}
                {activeTab === 'Dataset' && <Dataset data={props.data} />}
                {activeTab === 'Prerequisites' && (
                  <Prerequisites data={props.data} />
                )}
                {activeTab === 'Tasks' && isSuccess && (
                  <Tasks
                    isEnrolled={isEnrolled}
                    data={props.data}
                    setSelectedTask={setSelectedTask}
                    enrollData={currentEnrollData}
                    setCount={setCount}
                    userProgress={userProgress}
                    setShowFeedback={setShowFeedback}
                  />
                )}
              </div>
              <div>
                {isSuccess && (
                  <SuperEnrolNow
                    data={props.data}
                    enrollData={currentEnrollData}
                    setUserProgress={setUserProgress}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default CheckUserStatus;
