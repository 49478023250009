const url = window.location.hostname;
// console.log(url);
let redirectSignIn = '';
let redirectSignOut = '';
if (url === 'dashboard.belong.education') {
  redirectSignIn = 'https://dashboard.belong.education';
  redirectSignOut = 'https://platform.deviare.co.za';
} else if (url === 'beta.belong.education') {
  redirectSignIn = 'https://beta.belong.education';
  redirectSignOut = 'https://www.consoleflare.com/logout';
} else if (url === 'launchpad.consoleflare.com') {
  redirectSignIn = 'https://beta.belong.education';
  redirectSignOut = 'https://www.consoleflare.com/logout';
} else {
  redirectSignIn = 'http://localhost:3000';
  redirectSignOut = 'http://localhost:3000';
}
// console.log(redirectSignOut);
const AMPLIFY_CONFIG = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-2',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-2_h5OexVjb0',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '20738icdfn538kieuetqrajoj2',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: false,

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: 'belong.auth.us-east-2.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      clientId: '20738icdfn538kieuetqrajoj2',
      responseType: 'token',
    },
  },
};

export default AMPLIFY_CONFIG;
