import React from 'react';
import CheckQuizStatus from './CheckQuizStatus';
import { useAptitudeAttemptQuery } from './AptitudeQuizSlice';
import { Navigate } from 'react-router-dom';
import Loading from 'components/global/layout/Loading';

const CheckAptitudeAttempt = () => {
  const { isLoading, isSuccess, isError, data } = useAptitudeAttemptQuery();

  return (
    <div>
      {isError && <div> Error... </div>}
      {isLoading && (
        <div>
          <Loading />{' '}
        </div>
      )}
      {isSuccess && data && (
        <>
          {data.attemptLeft || data.testInProgress ? (
            <CheckQuizStatus />
          ) : (
            <Navigate to='/quiz/aptitude/scorecard' replace />
          )}
        </>
      )}
    </div>
  );
};

export default CheckAptitudeAttempt;
