import classNames from 'classnames';
import useVgaTestResponsesContext from 'hooks/useVgaTestResponsesContent';
import React from 'react';

const TestCardBar: React.FC = () => {
  const { state } = useVgaTestResponsesContext();

  return (
    <div className='flex justify-between items-center lg:font-bold font-medium font-manrope lg:text-lg text-base bg-gradient-to-b  from-white/100 to-bgCyan/100 lg:h-20 h-10 lg:px-20 px-6'>
      <p>Domain Quiz</p>
      <div>
        <p
          className={classNames({
            hidden: !state.minutesLeft && !state.secondsLeft,
          })}
        >
          {state.minutesLeft}:{state.secondsLeft}
        </p>
      </div>
    </div>
  );
};

export default TestCardBar;
