import React from 'react';

const Loading = () => {
  const url = window.location.hostname;
  const isEdRed = url === 'capstone.edred.in';
  const logoUrl = isEdRed
    ? 'https://res.cloudinary.com/belong/image/upload/v1693893154/uploaded_resources/EDRED_LOGO_for_center_with_patch-09_ov7ocr.png'
    : 'https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png';
  return (
    <div className='grid place-items-center h-screen animate-pulse'>
      <img src={logoUrl} alt='logo' className='animate-spin h-16' />
    </div>
  );
};

export default Loading;
