const CoachDataHeader = (props: any) => {
  const total = props.eventData.length;
  const attended = props.eventData.filter(
    (event: any) => event.attendance === 1
  ).length;
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670486801/new%20b2b/fluent_person-support-20-regular_prfvyy.svg'
          alt='logo'
        />
        <p className='text-sm font-semibold'>
          Your Apprenticeship Journey Guide
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Domain & Industry Discussion{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Project Support & Feedback{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Discussions Attended-{' '}
          <span className='text-primary'>
            {attended}/{total}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CoachDataHeader;
