import React from 'react';
import { Link } from 'react-router-dom';
import { Cap } from './types';

const Card = (props: Cap) => {
  const allSkills: any[] = [];

  props.tasks.forEach((task: any) => {
    task.skillId.forEach((skill: any) => {
      if (
        skill &&
        !allSkills.some((someSkill: any) => someSkill._id === skill._id)
      ) {
        allSkills.push(skill);
      }
    });
  });

  const totalTime =
    props.tasks && props.tasks.length
      ? props.tasks?.map((i: any) => i.time).reduce((a: any, b: any) => a + b)
      : 0;

  return (
    <Link
      key={props._id}
      to={'/capstone/' + props._id}
      className='border border-primary-light bg-white cursor-pointer hover:bg-primary-lightest transition-all ease-in-out duration-300 rounded-3xl shadow-md p-4'
    >
      {/* image,heading and text+icons under heading start here */}
      <div className='flex flex-wrap md:flex-nowrap space-y-1 md:space-y-0 md:space-x-4'>
        {/* company logo */}
        <img
          src={props.logo}
          alt='logo'
          className='h-16 w-24  object-contain'
        />
        <div>
          {/* heading */}
          <p className='text-primary font-semibold text-sm md:text-base'>
            {props.title}
          </p>
          {/* icons and text */}
          <div className='flex flex-wrap  gap-x-3 gap-y-3  text-xs pt-2'>
            <div className='flex gap-x-1 -ml-0.5'>
              <img
                className='object-contain'
                src='https://res.cloudinary.com/belong/image/upload/v1661952973/capstone/location_upuc6e.png'
                alt='map'
              />
              <p>{props.country}</p>
            </div>
            {props.industry && (
              <div className='flex gap-x-1'>
                <img
                  className='object-contain'
                  src='https://res.cloudinary.com/belong/image/upload/v1661953024/capstone/image_284_r7k0xs.png'
                  alt='design'
                />
                <p className='flex flex-none'>{props.industry}</p>
              </div>
            )}
            {totalTime > 0 && (
              <div className='flex gap-x-1'>
                <img
                  className='object-contain'
                  src='https://res.cloudinary.com/belong/image/upload/v1661953040/capstone/clock_b4bs6q.png'
                  alt='clock'
                />
                <p>{totalTime}hours</p>
              </div>
            )}
            <div className='flex gap-x-1'>
              <img
                className='object-contain'
                src='https://res.cloudinary.com/belong/image/upload/v1661953024/capstone/image_285_cyfenz.png'
                alt='basic'
              />
              <p>{props.level}</p>
            </div>
          </div>
        </div>
      </div>
      {/* image,heading and text+icons under heading end here */}
      {/* domain part starts here */}
      <div className='flex  my-4 md:space-x-3  items-baseline '>
        <p className='text-xs  flex-none mr-2 md:mr-0'>Domain expertise</p>
        <div className='flex flex-row flex-wrap gap-2 '>
          <button className=' border border-primary rounded-2xl py-1 px-2 text-primary font-medium text-xs md:text-sm'>
            {props.domain}
          </button>
        </div>
      </div>
      {/* domain part ends here */}
      {/* skill part starts here */}
      <div className='flex flex-wrap md:flex-nowrap space-y-2 md:space-y-0  my-4 space-x-0 lg:space-x-3 items-baseline'>
        <p className='text-xs flex-none mr-3'>Skills you'll gain</p>
        <div className='flex flex-row flex-wrap gap-2 '>
          {allSkills.map((skill: any) => {
            return (
              <p
                key={skill._id}
                className='border bg-primary-lightest font-semibold rounded-2xl py-1 px-2 text-xs'
              >
                {skill.title}
              </p>
            );
          })}
        </div>
      </div>
    </Link>
  );
};

export default Card;
