import { differenceInCalendarWeeks } from 'date-fns';
import { useMemo, useState } from 'react';
import { getAllTasks, getCurrentWeekEvents, getWeekTasks } from './helper';
import Calendar from './layout/Calendar';
import CalendarLegend from './layout/CalendarLegend';
import DataHeader from './layout/DataHeader';
import EventsList from './layout/EventsList';
import TasksList from './layout/TasksList';
import WeekBar from './layout/WeekBar';

const JourneyPanel = ({ data }: { data: any }) => {
  //Calculate current week number based on current date and the end date of the batch
  const currentWeekNumber = useMemo<number>(() => {
    return Math.max(
      Math.min(
        differenceInCalendarWeeks(
          new Date(),
          new Date(data?.batches[0].startDate)
        ),
        differenceInCalendarWeeks(
          new Date(data?.batches[0].endDate),
          new Date(data?.batches[0].startDate)
        )
      ),
      1
    );
  }, [data?.batches]);

  const [currentWeek, setCurrentWeek] = useState<number>(currentWeekNumber);

  const weekEvents = getCurrentWeekEvents(data?.batches[0].events, currentWeek);

  const allTasks = getAllTasks(data?.batches[0].projects);

  const weekTasks = getWeekTasks(allTasks, currentWeek);

  return (
    <>
      <div className=' md:border rounded-b-lg text-textColor-lighter'>
        <DataHeader batchId={data?.batches[0]?._id} />
        <div className='mt-6'>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1678967833/new%20b2b/Banner-B2B-DASHBOARD-2_jvkqmo.jpg'
            alt='Banner'
            className='w-full'
          />
        </div>
        <div className='grid grid-cols-12 gap-4 my-6 p-4 md:p-8'>
          <div className='col-span-12 md:col-span-8'>
            <WeekBar
              totalWeeks={data?.batches[0].week}
              weekNumber={currentWeek}
              setCurrentWeek={setCurrentWeek}
            />
            {weekTasks.length || weekEvents.length ? (
              <div className='flex'>
                {/* Border Right */}
                <div className='flex-1'>
                  <EventsList weekEvents={weekEvents} />
                </div>
                <div className='flex-1'>
                  <TasksList weekTasks={weekTasks} />
                </div>
              </div>
            ) : (
              <div className='text-center font-semibold mt-4'>
                No events or task deadlines scheduled for this week{' '}
              </div>
            )}
          </div>
          <div className='col-span-12 md:col-span-4 '>
            <Calendar
              startDate={data?.batches[0].startDate}
              endDate={data?.batches[0].endDate}
              allEvents={data?.batches[0].events}
              allTasks={allTasks}
            />
            <CalendarLegend />
          </div>
        </div>
      </div>
    </>
  );
};

export default JourneyPanel;
