import React, { useState } from 'react';
import Questions from './layout/Questions';

const Assessment = ({ assessmentQuestion, autoSubmit, batchId }) => {
  const localfinalSubmission = JSON.parse(
    localStorage.getItem('localVgaSubmission') || '[]'
  );
  const [finalSubmission, setFinalSubmation] = useState(
    localfinalSubmission.length ? localfinalSubmission : assessmentQuestion
  );

  if (!localfinalSubmission.length) {
    localStorage.setItem('localVgaSubmission', JSON.stringify(finalSubmission));
  }
  return (
    <div>
      <Questions
        totalQuestions={finalSubmission.length}
        finalSubmission={finalSubmission}
        setFinalSubmation={setFinalSubmation}
        autoSubmit={autoSubmit}
        batchId={batchId}
      />
    </div>
  );
};

export default Assessment;
