import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { buttonStyle, inputStyle, errorStyle } from '../authStyles';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

type forgotProps = {
  setSendCode: Function;
  setUserEmail: Function;
};

const ForgotEmailEnter = (props: forgotProps) => {
  const navigate = useNavigate();
  const [serverResponse, setServerResponse] = useState('');

  //If already logged in

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      navigate('/');
    });
  }, [navigate]);

  async function handleSendCodeClick(email: string) {
    try {
      await Auth.forgotPassword(email);
    } catch (error: any) {
      setServerResponse(error.message);
    }
  }

  return (
    <div className='grid place-items-center p-4'>
      <div className='md:w-3/4 w-full'>
        <h1 className='my-8 text-lg font-semibold'>Forgot your password?</h1>
        <p className='mb-4'>
          Please enter the email address to reset your password
        </p>
        <Formik
          initialValues={{ email: '' }}
          validationSchema={Yup.object().shape({
            email: Yup.string().email('Invalid email').required('Required'),
          })}
          onSubmit={async (values, { setSubmitting }) => {
            setServerResponse('');
            props.setUserEmail(values.email);
            await handleSendCodeClick(values.email);
            setSubmitting(false);
            props.setSendCode(true);
          }}
        >
          {({ isSubmitting }) => (
            <Form className='space-y-8'>
              <div>
                <Field
                  type='email'
                  name='email'
                  placeholder='Email'
                  className={inputStyle}
                />
                <ErrorMessage
                  name='email'
                  component='div'
                  className='text-red-300'
                />
              </div>
              <div className='text-center'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className={buttonStyle}
                >
                  {isSubmitting ? 'Submitting' : 'SEND CONFIRMATION CODE'}
                </button>
                <div className='my-4'>
                  <p className={errorStyle}>{serverResponse}</p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <Link to='/login'>Back to login?</Link>
    </div>
  );
};

export default ForgotEmailEnter;
