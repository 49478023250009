import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/io';
import NavigationBar from './NavigationBar';
import TextSection from './TextSection';
import QuillSection from './QuillSection';
import FeedbackSection from './FeedbackSection';
import AdditionalFeedback from './AdditionalFeedback';
import UserSolution from './UserSolution';
import CriteriaSection from './CriteriaSection';
import { FaRegEdit } from 'react-icons/fa';
import { Element } from 'react-scroll';
import UngradedSection from './UngradedSection';

const Index = ({
  setIsTaskView,
  taskData,
  batchId,
  projectId,
}: {
  setIsTaskView: any;
  taskData: any;
  batchId: string;
  projectId: string;
}) => {
  const isPrjectLive = new Date(taskData.dueDateTime) > new Date();
  const [isEdit, setEdit] = useState(false);
  return (
    <div className='p-4 md:p-8  md:border rounded-b-lg pb-8 '>
      <div className='flex justify-between'>
        <div
          className='flex space-x-1  cursor-pointer items-center text-primary'
          onClick={() => setIsTaskView(null)}>
          <IoIosArrowBack />
          <p>Back</p>
        </div>
        {/* <p
          title='Difficulty Level'
          className='  text-xs sm:text-sm border-2 font-semibold text-center text-primary bg-white   px-2 sm:px-4 py-1 rounded-2xl'
        >
          {taskData.level}
        </p> */}
      </div>
      <NavigationBar />

      <TextSection taskData={taskData} />
      {!taskData.solution || isEdit ? (
        <>
          <Element name='solution'>
            <QuillSection
              solution={taskData.solution}
              batchId={batchId}
              projectId={projectId}
              taskId={taskData._id}
              setIsTaskView={setIsTaskView}
              dueDateTime={taskData.dueDateTime}
            />
          </Element>
          <Element name='score-feedback'>
            {' '}
            <CriteriaSection />
          </Element>
        </>
      ) : (
        <>
          {isPrjectLive && (
            <div className='flex justify-end mt-4 -mb-12 mr-2'>
              <button
                className='text-primary flex '
                onClick={() => setEdit(true)}>
                <FaRegEdit className='mt-1 mr-1' /> <p>Edit</p>
              </button>
            </div>
          )}
          <Element name='solution'>
            <UserSolution solution={taskData.solution} />
          </Element>
        </>
      )}
      {taskData?.solution && !taskData.score && (
        <Element name='score-feedback'>
          <UngradedSection />
        </Element>
      )}
      {(taskData?.score || taskData?.coachNote) && (
        <>
          <Element name='score-feedback'>
            <FeedbackSection score={taskData?.score} />
          </Element>
          <AdditionalFeedback coachNote={taskData?.coachNote} />
        </>
      )}
    </div>
  );
};

export default Index;
