import React from 'react';
import { format } from 'date-fns';
import {
  HiOutlineCurrencyRupee,
  HiOutlineCurrencyEuro,
  HiOutlineCurrencyDollar,
  HiOutlineBriefcase,
  // HiOutlineUsers,
} from 'react-icons/hi';
// import { useGetApplicationCountQuery } from './JobsSlice';

const OpportunityStat = (props: any) => {
  // const { isSuccess, data } = useGetApplicationCountQuery({
  //   opportunityId: props.opportunityId,
  // });
  const symbolStyle =
    'inline md:text-2xl text-primary mr-1 md:mr-2 align-text-bottom';
  function currencyMap(currency: string) {
    let temp: any = {
      INR: <HiOutlineCurrencyRupee className={symbolStyle} />,
      USD: <HiOutlineCurrencyDollar className={symbolStyle} />,
      EUR: <HiOutlineCurrencyEuro className={symbolStyle} />,
    };
    return temp[currency];
  }

  return (
    <>
      <div className='flex gap-2 md:gap-8 py-4 border-b-2'>
        {props.payRange?.disclosed && (
          <p className='text-primary font-semibold text-sm'>
            {currencyMap('INR')}
            {`${Math.round(Number(props.payRange.minimum) / 12)}${
              Number(props.payRange.maximum)
                ? ' - ' + Math.round(Number(props.payRange.maximum) / 12)
                : ''
            }`}{' '}
            <span className='text-gray-400 text-xs'> / month</span>
          </p>
        )}
        {/* {isSuccess && (
          <p className='text-primary font-semibold'>
            <HiOutlineUsers className={symbolStyle} />
            <span>{data}</span>
            <span className='text-gray-400 text-xs'> Applicants</span>
          </p>
        )} */}
        {props.cutOffDate && (
          <p title='Application Deadline'>
            {props.cutOffDate && <HiOutlineBriefcase className={symbolStyle} />}
            <span className='text-gray-400 text-xs'>
              {format(new Date(props.cutOffDate), 'PP')}
            </span>
          </p>
        )}
      </div>
    </>
  );
};

export default OpportunityStat;
