import React from 'react'

function AboutSection( props ) {
    return (
        <div>
        <div className="bg-gray-100 p-4">
        <div className="flex">
          <div className="h-2 w-2 m-2 bg-primary"></div>
          <div>
            <h2>
              <b>{props.heading}</b>
            </h2>
          </div>
        </div>
        <div className="md:ml-10 text-xs space-y-4 p-3">
          {props.children}
        </div>
      </div>
        </div>
    )
}

export default AboutSection;