import React from 'react';
import { useGetUserProjectQuery } from '../../userBatchSlice';
import { format } from 'date-fns';
import classNames from 'classnames';

const CardDetail = ({
  taskData,
  setIsLabView,
}: {
  taskData: any;
  setIsLabView: any;
}) => {
  const projectId = taskData._id;
  const batchId = taskData.batchId;
  const { data, isSuccess } = useGetUserProjectQuery({ projectId, batchId });

  let tasks = taskData.tasks;
  if (isSuccess) {
    const tasksubmission = data?.tasksubmission || [];
    tasks = taskData?.tasks.map((task: any) => {
      const userTaskSubmission = tasksubmission.filter((userTask: any) => {
        return userTask.taskId === task._id;
      })[0];
      if (userTaskSubmission) {
        return {
          ...task,
          solution: userTaskSubmission?.solution,
          score: userTaskSubmission?.score,
          coachNote: userTaskSubmission?.coachNote,
        };
      } else {
        return { ...task };
      }
    });
  }
  const updatedData = { ...taskData, tasks };

  const score = updatedData.tasks[0]?.score;
  const solution = updatedData.tasks[0]?.solution;
  const isLocked = new Date() < new Date(taskData.tasks[0]?.publishAt);

  return (
    <div className='grid grid-cols-12 gap-8'>
      <div className='col-span-12 xl:col-span-6'>
        {!solution ? (
          <div>
            <div
              onClick={() => setIsLabView(updatedData)}
              className={classNames(
                'col-span-12 mt-4  cursor-pointer border-2 hover:border-primary bg-white pb-4 hover:bg-primary-lightest',
                { 'pointer-events-none': isLocked }
              )}>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1670239433/new%20b2b/image_323_lt9pjm.svg'
                alt='banner'
                className='object-cover w-full border-l-4 border-yellow-400'
              />
              <div className=' p-4 grid grid-cols-12 gap-x-4 gap-y-2'>
                <div className='col-span-12 md:col-span-8'>
                  <p className='text-primary font-semibold'>
                    {' '}
                    Due Date:{' '}
                    {taskData.tasks[0]?.dueDateTime &&
                      format(
                        new Date(taskData.tasks[0].dueDateTime),
                        'do LLLL yyyy h:m b'
                      )}
                  </p>
                  <div className='flex items-center space-x-2 mt-2'>
                    <div className='h-3 w-3 bg-primary rounded-full '></div>
                    <p className='text-sm  font-medium w-[90%] md:w-full mx-auto'>
                      {taskData.title}
                    </p>
                  </div>
                  {/* <p className='text-xs mt-2 w-[90%] md:w-full mx-auto text-textColor-lightest'>
            {taskData.text}
          </p> */}
                  {taskData.tasks[0].skillId.map((skill: any) => {
                    return (
                      <p
                        key={skill.title}
                        className='text-xs mt-2 w-[90%] md:w-full mx-auto text-primary'>
                        {skill.title}
                      </p>
                    );
                  })}
                </div>

                {score ? (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      View Feedback
                    </button>
                  </div>
                ) : solution ? (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      Completed
                    </button>
                  </div>
                ) : isLocked ? (
                  <div className='col-span-12 md:col-span-4 text-primary space-y-1 text-center text-sm font-semibold mt-4 sm:mt-0 '>
                    <div className='border border-primary w-1/2 md:w-full mx-auto rounded-lg py-2 bg-white '>
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1670311998/new%20b2b/bxs_lock-alt_s9g7ds.svg'
                        alt='lock'
                        className='mx-auto'
                      />
                    </div>
                    <p>Unlocks in Week {taskData.tasks[0]?.week} </p>
                  </div>
                ) : (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2  mx-auto  text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      Complete Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div className='col-span-12 xl:col-span-6'>
        {solution ? (
          <div>
            <div
              onClick={() => setIsLabView(updatedData)}
              className={classNames(
                'col-span-12 mt-4 cursor-pointer border-2 hover:border-primary bg-white pb-4 hover:bg-primary-lightest',
                { 'pointer-events-none': isLocked, grayscale: solution }
              )}>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1670239433/new%20b2b/image_323_lt9pjm.svg'
                alt='banner'
                className='object-cover w-full border-l-4 border-yellow-400'
              />
              <div className=' p-4 grid grid-cols-12 gap-x-4 gap-y-2'>
                <div className='col-span-12 md:col-span-8'>
                  <p className='text-primary font-semibold'>
                    {' '}
                    Due Date:{' '}
                    {taskData.tasks[0]?.dueDateTime &&
                      format(
                        new Date(taskData.tasks[0].dueDateTime),
                        'do LLLL yyyy h:m b'
                      )}
                  </p>
                  <div className='flex items-center space-x-2 mt-2'>
                    <div className='h-3 w-3 bg-primary rounded-full '></div>
                    <p className='text-sm  font-medium w-[90%] md:w-full mx-auto'>
                      {taskData.title}
                    </p>
                  </div>
                  {/* <p className='text-xs mt-2 w-[90%] md:w-full mx-auto text-textColor-lightest'>
            {taskData.text}
          </p> */}
                  {taskData.tasks[0].skillId.map((skill: any) => {
                    return (
                      <p
                        key={skill.title}
                        className='text-xs mt-2 w-[90%] md:w-full mx-auto text-primary'>
                        {skill.title}
                      </p>
                    );
                  })}
                </div>

                {score ? (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      View Feedback
                    </button>
                  </div>
                ) : solution ? (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2 mx-auto text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      Completed
                    </button>
                  </div>
                ) : isLocked ? (
                  <div className='col-span-12 md:col-span-4 text-primary space-y-1 text-center text-sm font-semibold mt-4 sm:mt-0 '>
                    <div className='border border-primary w-1/2 md:w-full mx-auto rounded-lg py-2 bg-white '>
                      <img
                        src='https://res.cloudinary.com/belong/image/upload/v1670311998/new%20b2b/bxs_lock-alt_s9g7ds.svg'
                        alt='lock'
                        className='mx-auto'
                      />
                    </div>
                    <p>Unlocks in Week {taskData.tasks[0]?.week} </p>
                  </div>
                ) : (
                  <div className='col-span-12 md:col-span-4 md:text-right'>
                    <button className=' text-primary border border-primary bg-white rounded-lg px-2 py-2  mx-auto  text-center text-xs font-semibold mt-4 sm:mt-0 '>
                      Complete Now
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CardDetail;
