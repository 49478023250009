import React from 'react';

type textProps = {
  data: any;
};

const InstructionsText = (props: textProps) => {
  const assessmentQuestion = props.data;

  const CREATIVITY = assessmentQuestion.filter((ass: any) => {
    return ass.category === 'CREATIVITY';
  })[0].questions.length;

  const SELF_AWARENESS = assessmentQuestion.filter((ass: any) => {
    return ass.category === 'SELF_AWARENESS';
  })[0].questions.length;
  const ANALYTICAL_SKILLS = assessmentQuestion.filter((ass: any) => {
    return ass.category === 'ANALYTICAL_SKILLS';
  })[0].questions.length;
  const COMMUNICATION = assessmentQuestion.filter((ass: any) => {
    return ass.category === 'COMMUNICATION';
  })[0].questions.length;

  const totalQuestion =
    CREATIVITY + SELF_AWARENESS + ANALYTICAL_SKILLS + COMMUNICATION;

  const sections = [
    {
      id: 1,
      cat: 'Business Communication',
      ques: <>{COMMUNICATION}</>,
      time: '6',
    },
    {
      id: 2,
      cat: 'Reasoning & Creativity',
      ques: <>{CREATIVITY}</>,
      time: '8',
    },
    {
      id: 3,
      cat: 'Observation & Analytics',
      ques: <>{ANALYTICAL_SKILLS}</>,
      time: '3',
    },
    { id: 4, cat: 'Self Awareness', ques: <> {SELF_AWARENESS}</>, time: '3' },
  ];

  return (
    <div className='m-1 sm:m-8 bg-gray-50/50 p-2 sm:p-8'>
      <div className='mb-2 sm:mb-6'>
        The Aptitude Test evaluates your communication, reasoning & creativity,
        analytical ability and Self-Awareness & Learnability
      </div>
      <div className='ml-2'>
        <div className='text-primary font-semibold mb-4'>Test Format</div>
        <div className='mb-2 sm:mb-6'>Total Questions: {totalQuestion}</div>
        <div className='mb-4'>
          The assessment is divided into four sections with a timer for each
        </div>
        <table className='lg:w-[40vw]'>
          <thead>
            <tr className='bg-slate-100 '>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4 '>
                Section
              </th>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4'>
                No. of questions
              </th>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4'>
                Time duration (in min.)
              </th>
            </tr>
          </thead>
          <tbody>
            {sections.map((sec) => {
              return (
                <tr
                  key={sec.id}
                  className='border-b border-slate-300 items-center  font-medium'>
                  <td className={`border-l border-slate-300 md:px-4 py-2`}>
                    {sec.cat}
                  </td>
                  <td className={`border-l border-slate-300 md:px-4 py-2`}>
                    {sec.ques}
                  </td>
                  <td
                    className={`border-l border-r border-slate-300 md:px-4 py-2`}>
                    {sec.time}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div>
          - Business Communication - {COMMUNICATION} questions in 6 minutes
        </div>
        <div>
          - Reasoning & Creativity - {CREATIVITY} questions in 8 minutes
        </div>
        <div>
          - Observation & Analytics - {ANALYTICAL_SKILLS} questions in 3 minutes
        </div>
        <div>- Self Awareness - {SELF_AWARENESS} questions in 3 minutes</div> */}
        <div className='text-primary font-semibold mb-4 mt-4'>
          General Instructions
        </div>
        <ul className='list-disc ml-4 sm:ml-6'>
          <li>Click on "Start Test" to begin the assessment</li>
          <li>
            You can navigate through questions using Previous/ Next Buttons
          </li>
          <li>
            Remember to finish on time and click on the submit button. If the
            timer runs out while you're still finishing the assessment, your
            answers will be auto-submitted
          </li>
          <li>
            You can preview and edit your answers at the end of all the
            questions if you are able to finish all your sections before a total
            time of 20 mins
          </li>
          <li>
            After submitting your assessment, you will be able to see your
            score.
          </li>
        </ul>
        <div className='mt-2 sm:mt-6 ml-1 font-bold'>Note:</div>
        <ul className='list-disc ml-4 sm:ml-6'>
          <li>
            You can not close this tab once the assessment has started,
            otherwise your assessment will be auto-submitted
          </li>
          {/* <li>
            In case of any network issues,{' '}
            <span className='text-primary underline'>
              please raise a query to the Belong Team
            </span>
          </li> */}
          <li>
            In case of any network issues, please write to the Belong Team at{' '}
            <span className='text-primary underline'>
              connect@belong.education
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default InstructionsText;
