import React from 'react';

const Progress = (props) => {
  return (
    <div className='mt-4 mb-16 sm:mb-4'>
      <p className='text-primary font-semibold p-6'>
        <span className='text-gray-500'>Progress:</span> {props.currentQuestion}
        /{props.totalQuestions}
      </p>
    </div>
  );
};

export default Progress;
