import React from 'react';
import htmlparser from 'html-react-parser';

const AssigneeComment = ({ com, commentTitle, assigneeRef }) => {
  return (
    <div className='grid grid-cols-12 space-x-4 md:space-x-0 flex items-center mb-2'>
      <div className='col-span-1 mx-auto'>
        <p
          className={`${assigneeRef === 'Coach' && 'bg-yellow-300 '} ${
            assigneeRef === 'Mentor' && 'bg-red-300'
          } ${
            assigneeRef === 'Belong' && 'bg-primary'
          } text-gray-50 h-8 w-8 md:h-10 md:w-10 flex justify-center text-sm items-center font-bold text-sm rounded-full border `}
        >
          {commentTitle.toUpperCase()}
        </p>
      </div>
      <div className='col-span-11'>
        <p
          className={`${assigneeRef === 'Coach' && 'bg-yellow-100 '} ${
            assigneeRef === 'Mentor' && 'bg-red-300'
          } ${
            assigneeRef === 'Belong' && 'bg-primary'
          } text-xs text-gray-700 py-2 border rounded-xl px-4 items-center md:mr-8`}
        >
          {htmlparser(com.comment)}
        </p>
      </div>
    </div>
  );
};

export default AssigneeComment;
