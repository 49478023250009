import React, { useState } from 'react';
import ProfileCard from './ProfileCard';
import ProgressCard from './ProgressCard';
import ScoreCard from './ScoreCard';
import ScorePercentCard from './ScorePercentCard';

const AllScores = ({ userData, onbordingData }: any) => {
  const [showScreen, setShowScreen] = useState(1);
  return (
    <div>
      <div className='border  px-2 mx-2 pb-4 rounded-bl-2xl rounded-br-2xl'>
        <div data-tut='Belong-Score'>
          <div className='bg-primary w-full text-white font-semibold text-center rounded-2xl py-1'>
            Employability Journey
          </div>
          <div className='grid md:grid-flow-col gap-x-4 grid-col-1 md:grid-cols-3 '>
            <ScoreCard
              showScreen={showScreen}
              onbordingData={onbordingData}
              setShowScreen={setShowScreen}
            />
          </div>
        </div>

        <div className='grid bg-white md:grid-flow-col gap-4 grid-col-1 md:grid-cols-3 '>
          {showScreen === 1 && (
            <ScorePercentCard onbordingData={onbordingData} />
          )}
          {showScreen === 2 && (
            <ProgressCard userData={userData} onbordingData={onbordingData} />
          )}
          {showScreen === 3 && <ProfileCard onbordingData={onbordingData} />}
        </div>
      </div>
    </div>
  );
};

export default AllScores;
