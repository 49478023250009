import Banner from './Banner';
import ToggleBar from './ToggleBar';
import AllCapstone from './AllCapstone';
import MyCapstone from './MyCapstone';
import { useState } from 'react';
import Pagination from './Pagination';
import Loading from 'components/global/layout/Loading';
import { useGetCapstonesQuery } from './capstoneSlice';

const Index = () => {
  const [isAllCapstone, setIsAllCapstone] = useState(true);
  console.log('test');
  const [page, setPage] = useState(0);

  const [level, setLevel] = useState('');

  const { isLoading, isError, isSuccess, isFetching, data } = useGetCapstonesQuery({
      page,
      limit: 6,
      level,
    });
  return (
    <>
      <div className='grid grid-cols-12 lg:grid-cols-10'>
        <div className='col-span-12 lg:col-span-10'>
          <div className=' px-2 mx-2 rounded-xl space-y-4 pb-6'>
            <Banner />
            <ToggleBar
              isAllCapstone={isAllCapstone}
              setIsAllCapstone={setIsAllCapstone}
            />

            {isLoading && (
              <div>
                <Loading />
              </div>
            )}
            {isError && <div>Error fetching data</div>}

            {isSuccess && (
              <>
                {isAllCapstone ? (
                  <>
                    <AllCapstone
                      data={data.data || []}
                      isFetching={isFetching}
                      setLevel={setLevel}
                      level={level}
                    />
                    <Pagination
                      data={data}
                      setPage={setPage}
                      isFetching={isFetching}
                    />
                  </>
                ) : (
                  <MyCapstone />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
