import React from 'react';
import TimeSheetHeader from './layout/TimeSheetHeader';
import AboutTimeSheet from './layout/AboutTimeSheet';
import TimeSheet from './TimeSheet';
import { useGetUserTimeSheetQuery } from '../userBatchSlice';
import Loading from 'components/global/layout/Loading';

const TimeSheetPanel = ({ batchDetails }: any) => {
  const dt1 = new Date(batchDetails.startDate);
  const dt2 = new Date();

  const totalDays = Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
  const currentWeek =
    Math.ceil((totalDays + 1) / 7) > 0 ? Math.ceil((totalDays + 1) / 7) : 1;

  const { data, isSuccess, isLoading } = useGetUserTimeSheetQuery({
    batchId: batchDetails._id,
  });
  //   console.log(data);
  return (
    <div className='md:border rounded-b-lg text-textColor-lighter '>
      <TimeSheetHeader />
      <div className='px-4 md:px-8 space-y-14'>
        <AboutTimeSheet />
        {isLoading && <Loading />}
        {isSuccess && (
          <TimeSheet
            currentWeek={currentWeek}
            batchDetails={batchDetails}
            userTimeSheetData={data || []}
          />
        )}
      </div>
    </div>
  );
};

export default TimeSheetPanel;
