import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

const PageTitle = ({ favIcon, title }: any) => {
  useEffect(() => {
    if (favIcon) {
      let link = document.querySelector(
        "link[rel~='icon']"
      ) as HTMLLinkElement | null;
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = favIcon;
    }
  }, [favIcon]);
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default PageTitle;
