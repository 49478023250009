export function profilePercentage(data: any) {
  let percentage = 0;
  if (data?.firstName) percentage += 5;
  if (data?.lastName) percentage += 5;
  if (data?.industryId) percentage += 5;
  if (data?.graduationYear) percentage += 5;
  if (data?.course) percentage += 5;
  if (data?.department) percentage += 5;
  if (data?.currentRole) percentage += 5;
  if (data?.linkedInUrl) percentage += 5;
  if (data?.imageUrl) percentage += 5;
  if (data?.projectPortfolio && data?.projectPortfolio.length) percentage += 10;
  if (data?.education && data?.education.length) percentage += 10;
  //   if (data?.workExperience && data?.workExperience.length) percentage += 10;
  if (data?.certificates && data?.certificates.length) percentage += 5;
  if (data?.skills && data?.skills.length) percentage += 10;
  if (data?.interests && data?.interests.length) percentage += 5;
  if (data?.languages && data?.languages.length) percentage += 5;
  if (data?.references && data?.references.length) percentage += 5;
  if (data?.about) percentage += 5;

  return percentage;
}
