import React from 'react';
import { format } from 'date-fns';
import OverviewCard from '../../layout/OverviewCard';
import AboutSection from '../../layout/AboutSection';
import Schedules from '../../layout/Schedules';

function Masterclass(props) {
  const userSpecificEvents = props.userSpecificEvents;
  const isCareereraUser = props.isCareereraUser;

  const batchEvents = userSpecificEvents
    .map((event) => ({
      ...event,
    }))
    .filter(
      (event) =>
        event.eventId.type === 'Masterclass' &&
        event.eventId.status === 'publish'
    )
    .sort(
      (a, b) => new Date(a.eventId.dateTime) - new Date(b.eventId.dateTime)
    );

  return (
    <div>
      <div className='bg-gray-100 py-4'>
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1643359922/uploaded_resources/Masterclass_banner_logo_airl7f.png'
          overviewHeader={
            props.isVGE
              ? 'Your Entrepreneurial Mindset Accelerator'
              : 'Your Employability Upgrade Classroom'
          }
          list1='Entrepreneurial'
          list2='Communication'
          list3='Industry Insights'
        />
        <AboutSection heading='What you will find here?'>
          <p>
            These are live sessions focussing on enhancing various
            entrepreneurial and employability skills that aid you in your
            {isCareereraUser ? ' internship ' : ' apprenticeship '} journey. We
            conduct conceptual classes and invite industry experts to give you
            insights into the industry you are training for.
          </p>
          {props.isVGE ? (
            <p>
              These sessions leave you with assignments that provide you tools
              and knowledge to excel in the work you do to transform your idea
              into business. Learn how entrepreneurial skills can contribute to
              your growth in your daily life.
            </p>
          ) : (
            <p>
              These masterclasses leave you with upskilling assignments that
              provide you tools and knowledge to excel in the work you do with
              the apprenticeship company. Learn how entrepreneurial skills can
              contribute to your growth in your daily life.
            </p>
          )}
          <p>
            Also network with some of the best in the industry who take up
            classes as guest faculty.
          </p>
        </AboutSection>
        {batchEvents.length !== 0 && (
          <div className='flex p-4'>
            <div className='h-2 w-2 m-2 bg-primary mb-2'></div>
            <h2>
              <b>Schedule</b>
            </h2>
          </div>
        )}
        {batchEvents.map((event) => {
          return (
            <Schedules
              key={event.eventId._id}
              imgsrc={event.eventId.imageUrl}
              heading={event.eventId.title}
              about={event.eventId.description}
              dateTime={event.eventId.dateTime}
              date={format(new Date(event.eventId.dateTime), 'do LLLL yyyy')}
              time={format(new Date(event.eventId.dateTime), 'p')}
              recordingUrl={event.eventId.recordingUrl}
              joinlink={event.eventId.link}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Masterclass;
