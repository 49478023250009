import NavigationBar from './NavigationBar';
import QueryHeader from './QueryHeader';
import { useGetUserTicketQuery } from './querySlice';
import { QueryTabType } from '../types';
import Loading from 'components/global/layout/Loading';

const QueryPanel = ({ batchId, coachId, mentorId }: QueryTabType) => {
  const { data, isSuccess, isLoading } = useGetUserTicketQuery({ batchId });
  return (
    <div className='  md:border rounded-b-lg text-textColor-lighter '>
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <QueryHeader numQueries={data?.length} />
          <div className='px-4 md:px-8 space-y-14'>
            <NavigationBar
              data={data}
              batchId={batchId}
              coachId={coachId}
              mentorId={mentorId}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default QueryPanel;
