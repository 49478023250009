import React from 'react';

const UserComment = ({ com, commentTitle }: any) => {
  return (
    <div className='px-2 grid grid-cols-12 space-x-3 flex items-center mb-2'>
      <div className='col-span-11'>
        <p className='text-xs text-gray-600 py-2 border rounded-full px-4 flex items-center md:ml-4'>
          {com.comment}
        </p>
      </div>
      <div className='col-span-1 mx-auto'>
        <p className='h-8 w-8 md:h-10 md:w-10 text-xs flex justify-center items-center text-gray-600 font-bold border-gray-600 rounded-full border'>
          {commentTitle.toUpperCase()}
        </p>
      </div>
    </div>
  );
};

export default UserComment;
