import { apiSlice } from '../../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserTicket: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/ticket/user',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['UserTicket'],
    }),
    createTicket: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/ticket',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserTicket'],
    }),
    addComment: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/ticket/comment',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['UserTicket'],
    }),
  }),
});

export const {
  useGetUserTicketQuery,
  useCreateTicketMutation,
  useAddCommentMutation,
} = extendedApiSlice;
