import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../new-query/FormElements';
import { useAddCommentMutation } from '../querySlice';

const Reply = ({
  ticketId,
  status,
  SetIsReplySelected,
}: {
  ticketId: string;
  status: string;
  SetIsReplySelected: any;
}) => {
  const [serverResponse, setServerResponse] = useState('');
  const [addComment] = useAddCommentMutation();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ticketId: ticketId,
          comment: '',
          owner: 'user',
          status: status,
        }}
        validationSchema={Yup.object({
          comment: Yup.string()
            .min(2, 'Must be 3 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a comment'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          addComment(values)
            .unwrap()
            .then((res: any) => {
              SetIsReplySelected(null);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className='py-4 px-2 md:px-6 rounded-xl mt-4'>
            <div className=''>
              <div className='grid grid-cols-12  items-center'>
                <div className='col-span-12 md:col-span-10 w-full '>
                  <TextInput name='comment' type='text' placeholder='Comment' />
                </div>
                <div className='col-span-12 md:col-span-2 mt-3 md:mt-0 md:mx-0 text-center mx-auto md:ml-4'>
                  <button
                    type='submit'
                    className='bg-primary font-semibold text-sm px-4 py-1 rounded text-gray-50 hover:text-gray-700 hover:bg-primary-light'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Processing...' : 'Comment'}
                  </button>
                </div>
                {status === 'closed' && (
                  <div className='flex items-center mt-3 col-span-6'>
                    <p className='text-xs'>
                      Do you want to reopen your ticket?
                    </p>
                    <button
                      type='button'
                      onClick={() => setFieldValue('status', 'open')}
                      className={`${
                        values.status === 'open'
                          ? 'bg-primary text-gray-50'
                          : 'bg-white text-gray-600'
                      } ml-6 px-4 py-1 border-1 rounded text-xs`}
                    >
                      Yes
                    </button>
                    <button
                      type='button'
                      onClick={() => setFieldValue('status', 'closed')}
                      className={`${
                        values.status === 'closed'
                          ? 'bg-primary text-gray-50'
                          : 'bg-white text-gray-600'
                      } ml-6 px-4 py-1 border-1 rounded text-xs`}
                    >
                      No
                    </button>
                  </div>
                )}
              </div>
            </div>

            {serverResponse && (
              <div className='m-4 flex text-sm justify-center'>
                {serverResponse}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Reply;
