import React from 'react';

const QuickTipsSection = () => {
  return (
    <div className='mt-4 md:mt-10'>
      <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div>
      <div className='px-4 sm:px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>
            Improves your chance to employment via Belong recruiter network
          </li>
          <li>
            Revise the basics for the domain-skill combinations before taking
            the quiz
          </li>
          <li>
            This quiz is unlocked and administered according to the journey
            schedule
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickTipsSection;
