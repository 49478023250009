import React from 'react';

const OverviewCard = (props) => {
  return (
    <div className='p-2 flex items-center border-b-2 border-gray-200 mx-4 bg-white mb-8 flex-wrap'>
      <div className='p-2'>
        <img
          src={props.imageUrl}
          className=' m-2 h-10 w-10 md:h-16 md:w-16 object-fill'
          alt='Guidebook'
        />
      </div>
      <div className='flex flex-1 text-xs md:text-sm font-bold'>
        <p>{props.overviewHeader}</p>
      </div>
      <div className='p-2 flex justify-end text-xs md:text-sm'>
        <ul className='list-disc m-1 p-1 text-primary'>
          <li className='m-1'>
            <span className='text-black'>{props.list1}</span>
          </li>
          <li className='m-1'>
            <span className='text-black'>{props.list2}</span>
          </li>
          <li className='m-1'>
            <span className='text-black'>{props.list3}</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default OverviewCard;
