import React from 'react';
import CompanyHighlights from './layout/CompanyHighlights';
import OverviewText from './layout/OverviewText';
import { AboutDataType } from '../types';

type OverviewType = {
  aboutCompanyData: AboutDataType;
};

const Overview = ({ aboutCompanyData }: OverviewType) => {
  return (
    <>
      <div className='grid grid-cols-12 gap-6  mt-4 lg:mt-10'>
        <div className='col-span-12 xl:col-span-6'>
          <OverviewText companyContent={aboutCompanyData.companyContent} />
        </div>
        <div className='col-span-12 xl:col-span-6 md:w-1/2  lg:ml-40 lg:mt-12'>
          <CompanyHighlights {...aboutCompanyData} />
        </div>
      </div>
    </>
  );
};

export default Overview;
