import React from 'react';
import { Element } from 'react-scroll';

interface allProps {
  data: any;
}

const ProjectBrief = (props: allProps) => {
  return (
    <Element name='project-section' className='element'>
      {props.data.problemStatement && (
        <div className='p-4'>
          <div className='font-semibold lg:text-xl'>Project Brief</div>
          <div>{props.data.problemStatement}</div>
        </div>
      )}
    </Element>
  );
};

export default ProjectBrief;
