import React from 'react';
import htmlparser from 'html-react-parser';

const GuidelineText = ({ taskData }: { taskData: any }) => {
  return (
    <div className='space-y-4 mt-4 md:mt-10'>
      <p className='font-semibold sm:text-lg'>Assignment Guidelines</p>
      {taskData?.backgroundDescription && (
        <div className='px-4 prose prose-sm max-w-none text-base'>
          {htmlparser(taskData?.backgroundDescription)}
        </div>
      )}
      {taskData?.taskInstructions && (
        <div className='px-4 prose prose-sm max-w-none text-base'>
          {htmlparser(taskData?.taskInstructions)}
        </div>
      )}

      <p className='font-semibold'>Learning Outcome</p>
      {taskData?.learningOutcomes && (
        <div className='px-4 prose prose-sm max-w-none text-base'>
          {htmlparser(taskData?.learningOutcomes)}
        </div>
      )}
      <p className='font-semibold'>Resources</p>
      {taskData?.resources && (
        <div className='px-4 prose prose-sm max-w-none text-base'>
          {htmlparser(taskData?.resources)}
        </div>
      )}
    </div>
  );
};

export default GuidelineText;
