import React, { useState, useEffect } from 'react';
import axios from 'axios';
import MyTickets from './myTickets/MyTickets';
import NewTicket from './newTicket/NewTicket';
import NewTicketButton from './myTickets/NewTicketButton';
import TicketInfo from './myTickets/TicketInfo';
import OverviewCard from '../../layout/OverviewCard';
import RecentQueryButtons from './myTickets/RecentQueryButtons';
import { API_URL } from '../../../../config/API_LINKS';

const Index = ({
  cognitoId,
  userId,
  userDetails,
  batchId,
  partner,
  isVGE,
  isFiibBatch,
  isCareereraUser,
}) => {
  const [showNewTicket, setShowTicket] = useState(true);
  const [ticketDetails, setTicketDetails] = useState(false);
  const [ticketNo, setTicketNo] = useState(null);
  const [openTicket, setOpenTicket] = useState([]);
  const [closedTicket, setClosedTicket] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [ticketStatus, setTicketStatus] = useState('open');

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/ticket', {
        params: {
          user: userId,
          batch: batchId,
        },
      });
      setOpenTicket(
        result.data.filter((ticket) => {
          return ticket.status === 'open';
        })
      );
      setClosedTicket(
        result.data.filter((ticket) => {
          return ticket.status === 'closed';
        })
      );
    })();
  }, [userId, batchId, updated]);

  return (
    <div className='py-4 bg-gray-100'>
      <OverviewCard
        imageUrl='https://res.cloudinary.com/belong/image/upload/v1643359130/uploaded_resources/Coaching-Banner-Logo_byqkzp.png'
        overviewHeader='Your queries regarding the VGA Journey'
        list1='Journey Support'
        list2='Doubts & Clarification'
        list3='Tech Assistance'
      />
      <NewTicketButton
        setShowTicket={setShowTicket}
        showNewTicket={showNewTicket}
      />
      {showNewTicket ? (
        <NewTicket
          setShowTicket={setShowTicket}
          cognitoId={cognitoId}
          userId={userId}
          userDetails={userDetails}
          batchId={batchId}
          setUpdated={setUpdated}
          partner={partner}
          isVGE={isVGE}
          isFiibBatch={isFiibBatch}
          isCareereraUser={isCareereraUser}
        />
      ) : (
        <>
          <div className='px-4'>
            <RecentQueryButtons
              ticketStatus={ticketStatus}
              setTicketStatus={setTicketStatus}
              setTicketDetails={setTicketDetails}
            />
          </div>

          {!ticketDetails ? (
            <div className='px-4'>
              <MyTickets
                setTicketDetails={setTicketDetails}
                setTicketNo={setTicketNo}
                ticketStatus={ticketStatus}
                myTicket={ticketStatus === 'open' ? openTicket : closedTicket}
                isCareereraUser={isCareereraUser}
              />
            </div>
          ) : (
            <div className='md:px-10'>
              <TicketInfo
                ticketInfo={
                  ticketStatus === 'open'
                    ? openTicket[ticketNo]
                    : closedTicket[ticketNo]
                }
                ticketNo={ticketNo}
                ticketDetails={ticketDetails}
                setTicketDetails={setTicketDetails}
                setUpdated={setUpdated}
                isCareereraUser={isCareereraUser}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Index;
