import React, { useState, useEffect } from 'react';

interface QuizScoreScreenProps {
  prevAttemptCount: number;
  prevPercentage: number;
  tryAgainFunc: () => void;
  goBackFunc: () => void;
  idealSolution: string;
  prevFinishTime: Date;
}

const QuizScoreScreen = (props: QuizScoreScreenProps) => {
  const isQuizPassed = props.prevPercentage >= 40;
  const isQuizAttemptsOver = props.prevAttemptCount > 2;
  const [timeRemaining, setTimeRemaining] = useState(NaN);

  const formatTime = (milliseconds: number): string => {
    const hrs = Math.floor(milliseconds / 3600000);
    const mins = Math.floor((milliseconds % 3600000) / 60000);
    const secs = Math.floor((milliseconds % 60000) / 1000);

    return `${hrs}:${mins < 10 ? '0' : ''}${mins}:${
      secs < 10 ? '0' : ''
    }${secs}`;
  };

  useEffect(() => {
    if (!props.prevFinishTime) return;

    const currTime = new Date().getTime();
    const prevTime = new Date(props.prevFinishTime).getTime();
    const twelveHrsInMS = 12 * 60 * 60 * 1000;
    const timeDiff = currTime - prevTime;

    if (timeDiff < twelveHrsInMS) {
      setTimeRemaining(twelveHrsInMS - timeDiff);
    } else {
      setTimeRemaining(NaN);
    }

    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(intervalId);
          return NaN;
        }

        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, [props.prevFinishTime]);

  const isTimerDone = () => {
    return isNaN(timeRemaining);
  };

  return (
    <React.Fragment>
      <div className='flex flex-col px-5 gap-5'>
        <span className='font-semibold text-lg'>Final Result -</span>
        {isQuizPassed ? (
          <div className='flex flex-col bg-green-50 justify-center items-center rounded-lg py-6 px-5 text-center gap-5'>
            <div className='flex flex-col gap-2'>
              <span className='text-2xl text-green-500 font-bold'>
                {props.prevPercentage}%
              </span>
              <span className='font-medium text-base text-gray-800'>
                Final Score
              </span>
            </div>
            <div className='flex flex-col text-center'>
              <span className='font-medium text-base text-gray-800'>
                Congratulations! You have cleared the quiz.
              </span>
            </div>
          </div>
        ) : (
          <div className='flex flex-col bg-red-50 justify-center items-center rounded-lg py-6 px-5 text-center gap-5'>
            <div className='flex flex-col gap-2'>
              <span className='text-2xl text-red-500 font-bold'>
                {props.prevPercentage}%
              </span>
              <span className='font-medium text-base text-gray-800'>
                Final Score
              </span>
            </div>
            <div className='flex flex-col text-center'>
              <span className='font-medium text-base text-gray-800'>
                You have not cleared the quiz since, a minimum score of 40% is
                required.
              </span>
              {!isQuizAttemptsOver && (
                <span className='font-medium text-base text-gray-800'>
                  Don't worry, you can try again{' '}
                  {isTimerDone() || timeRemaining <= 300 ? (
                    'now'
                  ) : (
                    <React.Fragment>
                      in{' '}
                      <span className='text-primary'>
                        {formatTime(timeRemaining)}
                      </span>
                    </React.Fragment>
                  )}
                  .
                </span>
              )}
            </div>
            {!isQuizAttemptsOver && (
              <span>Remaining attempts : {3 - props.prevAttemptCount}</span>
            )}
          </div>
        )}
        {(isQuizPassed || isQuizAttemptsOver) && props.idealSolution && (
          <React.Fragment>
            <div className='flex flex-col bg-stone-50 justify-center items-center rounded-lg py-6 px-5 text-center gap-5'>
              <div className='flex flex-col gap-2'>
                <span className='text-base text-stone-600 font-medium'>
                  Solution File
                </span>
                {/* <span
                  className='font-medium text-base text-gray-800 underline cursor-pointer select-none'
                  onClick={() => {
                    setIsSolutionOpen(!isSolutionOpen);
                  }}>
                  Ideal Solution File
                </span> */}
                <a
                  href={props.idealSolution}
                  className='text-base text-stone-600 font-medium underline cursor-pointer select-none'
                  rel='noreferrer'
                  target='_blank'>
                  Ideal Solution File
                </a>
              </div>
            </div>
          </React.Fragment>
        )}
        {/* {isSolutionOpen && props.idealSolution && (
          <div className='flex flex-col bg-stone-50 justify-center items-center rounded-lg py-6 px-5 text-center gap-5'>
            {htmlparser(props.idealSolution)}
          </div>
        )} */}
      </div>
      <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
        <button
          onClick={
            isQuizAttemptsOver || props.prevPercentage >= 40 || !isTimerDone()
              ? props.goBackFunc
              : props.tryAgainFunc
          }
          className={`text-white text-lg px-12 md:px-12 py-2 rounded-lg select-none ${
            isQuizAttemptsOver || props.prevPercentage >= 40 || !isTimerDone()
              ? 'pointer-events-auto bg-primary'
              : 'pointer-events-auto bg-primary'
          }`}>
          {isQuizAttemptsOver || props.prevPercentage >= 40 || !isTimerDone()
            ? 'Go To Home'
            : 'Try Again'}
        </button>
      </div>
    </React.Fragment>
  );
};

export default QuizScoreScreen;
