import React, { useEffect, useState } from 'react';
import QuizContent from './QuizContent';
import QuizReviewScreen from './QuizReviewScreen';
import { useAddQuizMutation } from 'components/superCapstone/superCapstoneSlice';
import QuizErrorScreen from './QuizErrorScreen';
import QuizScoreScreen from './QuizScoreScreen';

interface QuizStartProps {
  enrollData: any;
  // data of the particular task from the array
  taskData: any;
  // setState
  setQuizState: React.Dispatch<React.SetStateAction<string>>;
  // instructions, ongoing, result
  quizState: string;
  quizResponses: string[][];
  setQuizResponses: React.Dispatch<React.SetStateAction<string[][]>>;
  setSelectedTask: Function;
  resetQuizResponses: () => void;

  // Previous quiz attempt by the user. Null if first attempt.
  prevAttempt: any;
  isReattempt: boolean;
}

// The initial screen that shows the information/instructions regarding the quiz.
interface InstructProps {
  startQuiz: () => void;
  prevAttemptExists: boolean;
  prevAttemptCount: number;
  prevPercentage: number;
}
const QuizInstructionScreen = (props: InstructProps) => {
  return (
    <React.Fragment>
      <div className='px-2 md:px-6 flex flex-col gap-5'>
        <span className='font-semibold text-lg'>
          Instructions for the task quiz -
        </span>
        <div className='border-2 border-gray-200 rounded-lg flex flex-col gap-12 p-6'>
          <div className='flex flex-col gap-6'>
            <span className='font-normal'>
              Please read the below given instructions carefully before starting
              the quiz.
            </span>
            <div className='grid grid-cols-5 lg:w-3/4  xl:w-1/2'>
              <span className='font-medium col-span-3'>Total Questions</span>
              <span className='col-span-2'>: 10</span>
              <span className='font-medium col-span-3'>Total Time</span>
              <span className='col-span-2'>: 10 minutes</span>
              <span className='font-medium col-span-3'>
                Overall Passing Marks
              </span>
              <span className='col-span-2'>: 40%</span>
              <span className='font-medium col-span-3'>Questions Type</span>
              <span className='col-span-2'>: MCQs</span>
              <span className='font-medium col-span-3'>Reattempts</span>
              <span className='col-span-2'>: Yes</span>
              <span className='font-medium col-span-3'>Reattempts Allowed</span>
              <span className='col-span-2'>: 3</span>
              <span className='font-medium col-span-3'>Attempts Left</span>
              <span className='col-span-2'>: {3 - props.prevAttemptCount}</span>
              <span className='font-medium col-span-3'>Previous Score</span>
              <span className='col-span-2'>
                :{' '}
                {isNaN(props.prevPercentage)
                  ? 'Yet to attempt'
                  : `${props.prevPercentage}%`}
              </span>
            </div>
          </div>
          <span className=''>Good luck for your quiz! 🍀</span>
        </div>
      </div>
      <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
        <button
          onClick={props.startQuiz}
          className={`bg-primary text-white text-lg px-5 md:px-5 py-2 rounded-lg`}>
          Start Quiz
        </button>
      </div>
    </React.Fragment>
  );
};

// Entrypoint into quiz
// Primary logic handled here.
const QuizAndScoreStart = (props: QuizStartProps) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [quizTime, setQuizTime] = useState({
    startTime: new Date(),
    endTime: new Date(),
  });
  const [isReattempt, setIsReattempt] = useState(props.isReattempt);
  const [resData, setResData] = useState({
    percentage: NaN,
    finishTime: '',
    totalAttempts: NaN,
  });

  // Used to clean transition to score screen. Loading till data is refetched
  useEffect(() => {
    setLoading(false);
  }, [props.prevAttempt]);

  const idealSolution = props.taskData?.idealSolution;

  // Disables button click events to avoid double clicks
  const [loading, setLoading] = useState(false);

  const [submit] = useAddQuizMutation();

  // User submits -> wait for the data to refetch and update -> when it changes show the endscreen using the prevAttempt data
  const useSubmitQuizData = (totalCorrect: number) => {
    const endTime = new Date();

    // Increment the totalAttempt count depending on if prevAttempt exists.
    setLoading(true);
    const attemptPercentage = Math.round(
      (totalCorrect * 100) / props.taskData.questions.length
    );

    setResData((prev) => ({
      ...prev,
      finishTime: endTime.toString(),
      percentage: attemptPercentage,
      totalAttempts:
        props.prevAttempt !== null ? props.prevAttempt.totalAttempt + 1 : 1,
    }));

    submit({
      enrollId: props.enrollData?._id,
      taskNo: props.taskData.taskNo,
      percentage: attemptPercentage,
      responses: props.quizResponses,
      startTime: quizTime.startTime.toString(),
      finishTime: endTime.toString(),
      totalAttempt:
        props.prevAttempt !== null ? props.prevAttempt.totalAttempt + 1 : 1,
      finalScore: { score: 0 },
    })
      .unwrap()
      .then(() => {
        // Only show the quiz answers if score above 40
        setIsReattempt(false);
        if (attemptPercentage < 40) {
          props.setQuizState('score');
        } else {
          props.setQuizState('review');
        }
      })
      .catch(() => {
        setErrorMessage('Could not submit.');
        props.setQuizState('error');
      })
      .finally(() => {
        // setLoading(false);
        setQuizTime((prev) => ({
          ...prev,
          startTime: new Date(),
          endTime: new Date(),
        }));
      });
  };

  // Testing the timer
  //   let currentDateTime = new Date();
  //   currentDateTime.setSeconds(currentDateTime.getSeconds() - 0);

  return (
    <div className='flex flex-col gap-2 h-full justify-between'>
      {props.quizState === 'instructions' && (
        <QuizInstructionScreen
          prevPercentage={props.prevAttempt?.percentage}
          prevAttemptCount={props.prevAttempt?.totalAttempt || 0}
          prevAttemptExists={props.prevAttempt !== null}
          startQuiz={() => {
            setQuizTime({
              endTime: new Date(),
              startTime: new Date(),
            });
            props.setQuizState('ongoing');
          }}
        />
      )}
      {props.quizState === 'ongoing' && (
        <QuizContent
          isLoading={loading}
          setQuizTime={setQuizTime}
          questionData={props.taskData.questions}
          quizResponses={props.quizResponses}
          setQuizResponses={props.setQuizResponses}
          submitQuizResponses={useSubmitQuizData}
        />
      )}
      {props.quizState === 'review' && (
        <QuizReviewScreen
          questionData={props.taskData.questions}
          quizResponses={props.quizResponses}
          nextFunc={() => {
            props.setQuizState('score');
          }}
        />
      )}
      {props.quizState === 'score' && (
        <QuizScoreScreen
          idealSolution={idealSolution}
          prevAttemptCount={
            isReattempt ? props.prevAttempt.totalAttempt : resData.totalAttempts
          }
          prevPercentage={
            isReattempt ? props.prevAttempt.percentage : resData.percentage
          }
          prevFinishTime={
            isReattempt ? props.prevAttempt.finishTime : resData.finishTime
          }
          //   prevFinishTime={currentDateTime}
          //   prevFinalTime={props.prevAttempt?.}
          goBackFunc={() => {
            props.setSelectedTask(false);
          }}
          tryAgainFunc={() => {
            props.resetQuizResponses();
            props.setQuizState('instructions');
          }}
        />
      )}
      {props.quizState === 'error' && (
        <QuizErrorScreen
          goBackFunc={() => {
            props.setSelectedTask(false);
          }}
          message={errorMessage}
        />
      )}
    </div>
  );
};

export default QuizAndScoreStart;
