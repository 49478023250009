import classNames from 'classnames';
import useDomainTestResponsesContext from 'hooks/useDomainTestResponsesContext';
import React from 'react';
import { FaStopwatch } from 'react-icons/fa';

const TestCardBar: React.FC = () => {
  const { state } = useDomainTestResponsesContext();

  return (
    <div className='flex justify-between items-center lg:font-bold font-medium font-manrope lg:text-lg text-base bg-gradient-to-b  from-white/100 to-bgCyan/100 lg:h-20 h-10 lg:px-20 px-6'>
      <p>Domain Test</p>
      <div className='flex'>
        <FaStopwatch className='mt-1 text-primary' />
        <p
          className={classNames('ml-1 text-gray-600 text-base', {
            hidden: !state.minutesLeft && !state.secondsLeft,
          })}>
          {state.minutesLeft}:{state.secondsLeft}
        </p>
      </div>
    </div>
  );
};

export default TestCardBar;
