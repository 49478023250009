import React, { useState } from 'react';
import Certificate from './Certificate';
import FeedbackSection from './FeedbackSection';
import LeftSection from './LeftSection';

const Index = (props) => {
  //Check if feedback is already submitted
  const batchDetails = props.data.batches[0];
  const feedbackStatus = props.userDetails.feedback;
  const [isVisible, setIsVisible] = useState(true);
  const [showThankPage, setShowThankPage] = useState(
    batchDetails.partner === '611e2e55c2b6580023029ed3' ? false : true
  );
  const name = props.userDetails.name;
  const email = props.userDetails.email;
  const allTaskDone = props.allTaskDone;

  const completionStatus =
    batchDetails.partner === '611e2e55c2b6580023029ed3'
      ? 'Completion'
      : props.userDetails.completionStatus === 'Not Applicable' && allTaskDone
      ? 'Completion'
      : props.userDetails.completionStatus;
  const mentorSign = props.userDetails.mentor.mentorSign;
  const mentorName = props.userDetails.mentor.name;

  return !showThankPage ? (
    batchDetails.partner === '611e2e55c2b6580023029ed3' && !allTaskDone ? (
      <div className='p-2 flex justify-around'>
        Please submit all assignments to get your certificate
      </div>
    ) : (
      <Certificate
        userDetails={props.userDetails}
        data={props.data}
        status={completionStatus}
        isVGE={props.isVGE}
        showEtp={props.showEtp}
        batchDetails={batchDetails}
      />
    )
  ) : (
    <div className='grid grid-cols-6 overflow:auto'>
      {isVisible ? <LeftSection batchLogo={batchDetails.logoUrl} /> : null}
      <FeedbackSection
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        showThankPage={showThankPage}
        setShowThankPage={setShowThankPage}
        feedbackStatus={feedbackStatus}
        name={name}
        email={email}
        completionStatus={completionStatus}
        mentorSign={mentorSign}
        mentorName={mentorName}
        batchId={batchDetails._id}
        batchLogo={batchDetails.logoUrl}
        userId={props.data._id}
      />
    </div>
  );
};

export default Index;
