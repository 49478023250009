import React, { useState } from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const classNames = require('classnames');

const WeekButtons = (props) => {
  const selectedWeek = props.selectedWeek;
  const setSelectedWeek = props.setSelectedWeek;
  const length = props.length;
  const dataLimit = 8;
  let myWeek = [];

  for (let i = props.timeSheetStartAt; i <= length; i++) {
    myWeek.push(i);
  }

  const [currentPage, setCurrentPage] = useState(
    Math.ceil((selectedWeek - myWeek[0] + 1) / dataLimit)
  );

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
    setSelectedWeek(currentPage * dataLimit + myWeek[0]);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
    setSelectedWeek((currentPage - 2) * dataLimit + myWeek[0]);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return myWeek.slice(startIndex, endIndex);
  };

  return (
    <div className='flex'>
      {getPaginatedData()[0] > myWeek[0] && (
        <div
          className='flex place-items-center cursor-pointer'
          onClick={goToPreviousPage}
        >
          <FaArrowLeft />
        </div>
      )}
      {getPaginatedData().map((x) => {
        return (
          <button
            key={x}
            type='button'
            className={classNames(
              'bg-white p-2 m-2 border rounded-lg hover:bg-primary-light',
              {
                'text-primary font-bold': selectedWeek === x,
              }
            )}
            onClick={() => {
              setSelectedWeek(x);
            }}
          >
            Week {x}
          </button>
        );
      })}
      {getPaginatedData()[getPaginatedData().length - 1] !== length && (
        <div
          className='flex place-items-center cursor-pointer'
          onClick={goToNextPage}
        >
          <FaArrowRight />
        </div>
      )}
    </div>
  );
};

export default WeekButtons;
