import React, { useState } from 'react';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const classNames = require('classnames');

const WeekButtons = ({ selectedWeek, setSelectedWeek, batchId, batchWeek }) => {
  const dataLimit = 10;
  const myWeek = Array(batchWeek)
    .fill()
    .map((x, i) => i + 1);
  const [currentPage, setCurrentPage] = useState(
    Math.ceil(selectedWeek / dataLimit)
  );

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return myWeek.slice(startIndex, endIndex);
  };

  return (
    <div className='flex'>
      {getPaginatedData()[0] > 1 && (
        <div
          className='flex place-items-center cursor-pointer'
          onClick={goToPreviousPage}
        >
          <FaArrowLeft />
        </div>
      )}
      {getPaginatedData().map((x) => {
        return (
          <button
            key={x}
            type='button'
            className={classNames(
              'bg-white p-2 m-2 border rounded-lg hover:bg-primary-light',
              {
                'text-primary font-bold': selectedWeek === x,
              }
            )}
            onClick={() => {
              setSelectedWeek(x);
            }}
          >
            Week {x}
          </button>
        );
      })}
      {getPaginatedData()[getPaginatedData().length - 1] !== batchWeek && (
        <div
          className='flex place-items-center cursor-pointer'
          onClick={goToNextPage}
        >
          <FaArrowRight />
        </div>
      )}
    </div>
  );
};

export default WeekButtons;
