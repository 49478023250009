import React from 'react';
import { AiFillFilePdf } from 'react-icons/ai';
import { RiShareForward2Fill } from 'react-icons/ri';

const Links = (props: any) => {
  const str = props.link;
  const link = str.match(/"([^]*)"/g);
  const linkTitle = str.match(/>([^]*)</g);
  if (link && link[0]) link[0] = link[0].slice(1, -1);
  if (linkTitle && linkTitle[0]) linkTitle[0] = linkTitle[0].slice(1, -1);
  return (
    <div className='flex justify-between bg-primary-light py-2 px-6 items-center'>
      <div className='flex space-x-3 text-primary font-semibold items-center'>
        <span className='text-2xl'>
          <AiFillFilePdf />
        </span>
        <p className='text-sm text-gray-400 font-semibold'>{linkTitle}</p>
      </div>
      <a href={link} target='_blank' rel='noreferrer'>
        <span className='text-2xl text-primary cursor-pointer'>
          <RiShareForward2Fill />
        </span>
      </a>
    </div>
  );
};

export default Links;
