import React from 'react';

const QuickTipsSection = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <div className='flex space-x-2'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670234242/new%20b2b/image_212_y4kdrr.svg'
          alt='bulb'
        />
        <p className='font-semibold'>Quick Tips</p>
      </div>
      <div className='px-4 sm:px-8  mt-2 text-sm '>
        <ul className='list-disc'>
          <li>
            Learn how entrepreneurial & employability skills can contribute to
            your growth in your daily life
          </li>
          <li>
            You must register for certain live sessions and reserve your seat.
            Only limited seats available
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QuickTipsSection;
