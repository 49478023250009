import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { BiUpload } from 'react-icons/bi';
import { useGetFiltersQuery } from '../JobsSlice';
import { fieldMap, makeSearchQuery } from '../utils/helper';
import FilterCategory from './FilterCategory';

const Index: React.FC<{
  opportunitiesSearchParameter: string;
  setOpportunitiesSearchParameter: Function;
}> = ({ opportunitiesSearchParameter, setOpportunitiesSearchParameter }) => {
  const [appliedFilters, setAppliedFilters] = useState({
    selectedSections: [],
    jobType: [],
    roleType: [],
    expertise: [],
    experience: [],
    city: [],
    country: [],
    industryId: [],
    companyId: [],
  });
  const [parameters, setParameters] = useState('');
  const { isError, isSuccess, data, refetch } = useGetFiltersQuery(parameters);

  useEffect(() => {
    refetch();
  }, [parameters, refetch]);

  useEffect(() => {
    const queryStringsArray = makeSearchQuery(appliedFilters);
    if (Boolean(queryStringsArray[0])) {
      setParameters(queryStringsArray[0] + '&' + queryStringsArray[1]);
      setOpportunitiesSearchParameter(queryStringsArray[0]);
    } else {
      setParameters('');
      setOpportunitiesSearchParameter('');
    }
  }, [appliedFilters, setOpportunitiesSearchParameter]);

  const content = (
    <>
      <div className='flex justify-between items-end border-b mb-4'>
        <p className='font-semibold text-center text-primary'>Apply Filters</p>
        <p
          className={classNames('cursor-pointer', {
            hidden: !parameters.length,
          })}
          title='Shareable View'
          onClick={async () => {
            await navigator.clipboard.writeText(
              'https://belong.education/jobs?' + opportunitiesSearchParameter
            );
            alert('Link Copied');
          }}
        >
          <BiUpload className='mr-4 mb-2 text-xl' />
        </p>
      </div>
      {isSuccess &&
        Object.keys(data).map((category) => {
          return (
            <FilterCategory
              key={category}
              field={fieldMap[category]}
              appliedFilters={appliedFilters}
              setAppliedFilters={setAppliedFilters}
              label={category}
              data={data[category]}
            />
          );
        })}
    </>
  );

  return (
    <div className='space-y-8 p-4 sm:p-0'>
      {isError && 'Error loading filters'}
      {isSuccess && content}
    </div>
  );
};

export default Index;
