import { useInterval } from 'hooks/useInterval';
import { useTimeout } from 'hooks/useTimeout';
import React, { useEffect, useReducer, useState } from 'react';
import { VgaTestResponsesContext } from 'hooks/useVgaTestResponsesContent';
import {
  useUpdateVgaResponsesMutation,
  useFinishVgaTestMutation,
} from '../VgaQuizSlice';
import Questions from './questions';
import { reducer } from './utils/helper';
import { initialState } from './utils/types';
import { useGetUserQuery } from 'components/profile/userSlice';

const Index: React.FC<{ data: any; batchId: any }> = ({ data, batchId }) => {
  const [updateResponse] = useUpdateVgaResponsesMutation();
  const [finishTest] = useFinishVgaTestMutation();
  const [timeLeft, setTimeLeft] = useState(data.timeLeft);

  const { data: userData } = useGetUserQuery();
  const userEmail = userData?.email || '';

  const freeUser = [
    'mukesh@ingeniousfaces.com',
    'vayongceke2@gmail.com',
    'tshepangmpho@gmail.com',
  ].includes(userEmail);

  const domainResponsesInitialState: initialState = {
    domain: data.domain.title,
    minutesLeft: 0,
    secondsLeft: 0,
    currentQuestion: 0,
    responses: data.responses,
    totalQuestions: data.totalQuestions,
    allSkills: data.allSkills,
    batchId,
  };

  const [state, dispatch] = useReducer(reducer, domainResponsesInitialState);

  function finishTestFn() {
    finishTest({ batchId, source: 'B2B' })
      .unwrap()
      .then((res: any) => {
        // navigate('/belong-score/domain/scorecard');
      });
  }

  useEffect(() => {
    updateResponse({ responses: state.responses, batchId, source: 'B2B' });
  }, [state.responses, updateResponse, batchId]);

  // Set timeout to finish test
  useTimeout(() => {
    if (data.timeLeft && !freeUser) {
      finishTestFn();
    }
  }, data.timeLeft * 1000);
  //Update timer
  useInterval(() => {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;
    dispatch({
      type: 'UPDATE_REMAINING_TIME',
      payload: { minutesLeft, secondsLeft },
    });
    setTimeLeft((timeLeft: number) => timeLeft - 1);
  }, 1000);

  return (
    <VgaTestResponsesContext.Provider value={{ state, dispatch }}>
      <div>
        <Questions />
      </div>
    </VgaTestResponsesContext.Provider>
  );
};

export default Index;
