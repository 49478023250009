import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { axiosApi } from 'config/Axios';
import { AiFillClockCircle } from 'react-icons/ai';

const Quiz = ({ activeQuestion, batchId }: any) => {
  const startTime = useMemo(() => new Date(), []);
  const [timeLeft, setTimeLeft] = useState(activeQuestion.length * 60); // minutes in seconds based on number of questions

  //   const activeQuestion: any = domainAllQuestion;

  const [userResponses, setUserResponses] = useState(
    activeQuestion.map((ques: any) => {
      let temp = ques;
      temp.response_index = -1;
      return temp;
    })
  );

  const [activeQuestionIdx, setActiveQuestionIdx] = useState(0);
  //   const [somethingSelected, setSomethingSelected] = useState(false);
  const [tabSwitchedCount, setTabSwitchedCount] = useState(0);

  // Add new state to track if time is up
  const [isTimeUp, setIsTimeUp] = useState(false);

  const submitQuizResponse = useCallback(async () => {
    let totalCorrect = 0;
    for (const resp of userResponses) {
      if (resp.options[resp.response_index]?.value === 1) {
        totalCorrect += 1;
      }
    }

    const endTime = new Date();

    const final = {
      batchId: batchId,
      startTime: startTime.toString(),
      finishTime: endTime.toString(),
      tabSwitchedCount,
      responses: userResponses,
      score: {
        totalQuestions: userResponses.length,
        totalCorrect: totalCorrect,
        percentage: 100 * (totalCorrect / userResponses.length),
      },
    };

    axiosApi
      .post('/batch/vga-quiz', final)
      .then((res) => {
        console.log(res.status);
        window.location.reload();
      })
      .catch((err) => {
        console.log('Something went wrong!');
        console.log(err);
      });
  }, [userResponses, batchId, startTime, tabSwitchedCount]);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 0) {
          clearInterval(timer);
          setIsTimeUp(true);
          submitQuizResponse();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [submitQuizResponse]);

  useEffect(() => {
    function handleVisibilityChange() {
      if (document.hidden) {
        setTabSwitchedCount((pre) => pre + 1);
      }
    }
    document.addEventListener('visibilitychange', handleVisibilityChange);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [tabSwitchedCount]);

  return (
    <div className='flex flex-col gap-3 px-5 py-5'>
      <div className='flex justify-end'>
        {/* <span>Questions</span> */}
        <div className='flex items-center gap-2'>
          <span className='font-medium'>Time Left</span>
          <AiFillClockCircle />:
          <span className='font-medium text-primary'>
            {String(Math.floor(timeLeft / 60)).padStart(2, '0')}:
            {String(timeLeft % 60).padStart(2, '0')}
          </span>
        </div>
      </div>
      <div className='flex flex-col border-2 border-gray-200 rounded-lg px-5 py-2 gap-5'>
        <div className='flex flex-row gap-2 font-medium'>
          <span>{activeQuestionIdx + 1}. </span>
          <span>{activeQuestion[activeQuestionIdx].question}</span>
        </div>
        <div className='flex flex-col px-5 gap-1'>
          {activeQuestion[activeQuestionIdx].options.map(
            (opt: any, opt_idx: number) => (
              <div key={opt_idx} className='flex flex-row gap-2 items-start'>
                <input
                  className='mt-[5px]'
                  type='radio'
                  name={`${activeQuestionIdx}`}
                  id={`${opt_idx}`}
                  checked={
                    userResponses[activeQuestionIdx].response_index === opt_idx
                  }
                  onClick={() => {
                    // setSomethingSelected(true);
                    setUserResponses((prevResponses: any) => {
                      const newResponses = [...prevResponses];
                      newResponses[activeQuestionIdx] = {
                        ...newResponses[activeQuestionIdx],
                        response_index: opt_idx,
                      }; // update the specific question's response
                      return newResponses;
                    });
                  }}
                />
                <label htmlFor={`${opt_idx}`}>{opt.option}</label>
              </div>
            )
          )}
        </div>
      </div>

      <div className='flex justify-between'>
        {activeQuestionIdx > 0 ? (
          <button
            onClick={() => {
              setActiveQuestionIdx(activeQuestionIdx - 1);
            }}
            disabled={isTimeUp}
            className={`py-2 px-5 rounded-lg bg-primary text-white font-medium w-fit ${
              isTimeUp
                ? 'opacity-50 pointer-events-none'
                : 'pointer-events-auto opacity-100'
            }`}>
            Previous
          </button>
        ) : (
          <div></div>
        )}
        <button
          onClick={() => {
            if (activeQuestionIdx < activeQuestion.length - 1) {
              setActiveQuestionIdx(activeQuestionIdx + 1);
              //   setSomethingSelected(false);
              return;
            } else if (timeLeft <= 0) {
              submitQuizResponse();
            } else {
              submitQuizResponse();
            }
          }}
          //   disabled={isTimeUp || !somethingSelected}
          className={`py-2 px-5 rounded-lg bg-primary text-white font-medium w-fit pointer-events-auto opacity-100`}
          //   className={`py-2 px-5 rounded-lg bg-primary text-white font-medium w-fit ${
          //     !isTimeUp && somethingSelected
          //       ? 'pointer-events-auto opacity-100'
          //       : 'pointer-events-none bg-stone-500'
          //   }`}
        >
          {activeQuestionIdx < activeQuestion.length - 1 ? 'Next' : 'Submit'}
        </button>
      </div>
    </div>
  );
};

export default Quiz;
