import React from 'react';

interface allProps {
  totalQuestion: any;
}

const TestBar = (props: allProps) => {
  return (
    <div className='my-2 px-8 py-2 bg-gray-50 font-semibold flex justify-between items-center'>
      <div>Capstone Quiz</div>
      <div>00:{props.totalQuestion}:00</div>
    </div>
  );
};

export default TestBar;
