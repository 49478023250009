import React from 'react';
import { useGetAptitudeQuizQuery } from './AptitudeQuizSlice';
import AptitudeInstructions from './AptitudeInstructions';
import Loading from 'components/global/layout/Loading';

const GetAptitudeQuestion = () => {
  const { isLoading, isError, data } = useGetAptitudeQuizQuery();
  return (
    <div>
      {isLoading || isError ? (
        <Loading />
      ) : (
        <AptitudeInstructions data={data} />
      )}
    </div>
  );
};

export default GetAptitudeQuestion;
