import React from 'react';

const contents = [
  {
    id: 1,
    icon: 'https://res.cloudinary.com/belong/image/upload/v1661942785/capstone/Contract_xx8sz1.svg',
    text: 'Real-world problems by global brands',
  },
  {
    id: 2,
    icon: 'https://res.cloudinary.com/belong/image/upload/v1661942785/capstone/Video_presentation_ok1dnt.svg',
    text: 'Self-paced with progress tracking',
  },
  {
    id: 3,
    icon: 'https://res.cloudinary.com/belong/image/upload/v1661942785/capstone/New_contact_lfjbox.svg',
    text: 'Industry certificate & skill proof',
  },
];
const Banner = () => {
  return (
    <div className='pt-2'>
      <div
        style={{
          backgroundImage:
            'url("https://res.cloudinary.com/belong/image/upload/v1661940517/capstone/Rectangle_829_dasck1.png")',
        }}
        className='w-full h-64 rounded-3xl  bg-cover bg-center flex items-center justify-center text-white font-semibold text-sm md:text-xl lg:text-3xl bg-gray-500'
      >
        Build proof of work with industry-led projects
      </div>
      <div className='grid grid-cols-12  my-4 lg:my-8 '>
        {contents.map((content) => {
          return (
            <div key={content.id} className='col-span-4 px-2 md:px-4 lg:px-10'>
              <div className='lg:flex space-x-2 lg:space-x-6 space-y-2 lg:space-y-0'>
                <img
                  className='w-6 md:w-10 h-6 md:h-10 object-contain mx-auto'
                  src={content.icon}
                  alt='icon'
                />
                <p className='md:font-medium  text-center  text-xs lg:text-base'>
                  {content.text}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className='border-b border-1 border-gray-400 '></div>
    </div>
  );
};

export default Banner;
