import React from 'react';
import { useGetJobsQuery } from './JobsSlice';
import { getLocation } from './utils/helper';
import type { OpportunityType } from './utils/types';
import Opportunity from './layout/Opportunity';
import Loading from 'components/global/layout/Loading';

const AllOpportunities: React.FC<{ opportunitiesSearchParameter: string }> = ({
  opportunitiesSearchParameter,
}) => {
  const { isLoading, isError, isSuccess, data } = useGetJobsQuery({
    search: opportunitiesSearchParameter,
    limit: 0,
  });

  const content = data?.map((opportunity: OpportunityType) => {
    const location = getLocation(opportunity.jobType, opportunity.jobLocation);
    const propsData: OpportunityType = {
      _id: opportunity._id,
      companyId: opportunity.companyId,
      roleDetails: opportunity.roleDetails,
      jobType: opportunity.jobType,
      jobLocation: opportunity.jobLocation,
      location: location,
      domainId: opportunity.domainId,
      payRange: opportunity.payRange,
      cutoffDate: opportunity.cutoffDate,
      slug: opportunity.slug,
    };

    return <Opportunity {...propsData} key={opportunity._id} />;
  });

  return (
    <div className='space-y-8'>
      {isLoading && <Loading />}
      {isError && 'Error'}
      {isSuccess && content}
    </div>
  );
};

export default AllOpportunities;
