import React from 'react';
import EngagementsCard from './EngagementsCard';

const UpcomingEngagements = () => {
  return (
    <div className='mt-8 hidden'>
      <div className='bg-primary w-full text-white font-semibold text-center rounded-2xl py-1'>
        Upcoming Engagements- VGA Week 2{' '}
      </div>
      <div className='border bg-primary-lightest px-4  mx-2 pb-4 rounded-bl-2xl rounded-br-2xl'>
        <EngagementsCard />
      </div>
    </div>
  );
};

export default UpcomingEngagements;
