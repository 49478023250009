import React from 'react';
import htmlparser from 'html-react-parser';

interface allProps {
  data: any;
}

const BusinessObjectives = (props: allProps) => {
  return (
    <div className='p-4 '>
      {props.data.businessObjectives && (
        <>
          <div className='font-semibold lg:text-xl'>Business Objectives</div>
          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.businessObjectives)}
          </div>
        </>
      )}
      {props.data.learningObjectives && (
        <>
          <div className='font-semibold mt-4 lg:text-xl'>Learning Goals</div>
          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.learningObjectives)}
          </div>
        </>
      )}
      {props.data.tools && (
        <>
          <div className='font-semibold mt-4 lg:text-xl'>Platforms/Tools</div>
          <div className='text-base'>{props.data.tools}</div>
        </>
      )}
    </div>
  );
};

export default BusinessObjectives;
