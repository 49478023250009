import React, { useEffect, useState } from 'react';

interface QuizContentProps {
  questionData: any[];
  quizResponses: string[][];
  isLoading: boolean;
  setQuizResponses: React.Dispatch<React.SetStateAction<string[][]>>;
  submitQuizResponses: (totalCorrect: number) => void;
  setQuizTime: React.Dispatch<
    React.SetStateAction<{
      startTime: Date;
      endTime: Date;
    }>
  >;
}

interface QuizViewProps {
  questionData: any[];
  setQuizResponses: React.Dispatch<React.SetStateAction<string[][]>>;
  forceQuizOver: boolean;
}

// For quiz with single choice questions ie; props.questionData[0].maxSelect === 1
const RadioViewQuiz = (props: QuizViewProps) => {
  return (
    <div
      className={`flex flex-col gap-3 h-auto overflow-y-auto select-none scrollbar pr-2 ${
        props.forceQuizOver && 'pointer-events-none opacity-60 '
      }`}>
      {props.questionData.map((ques, idx) => (
        <div
          key={idx}
          className='border-2 border-gray-200 rounded-lg p-5 gap-4 flex flex-col'>
          <span className='font-medium'>
            {idx + 1}. {ques.question}
          </span>
          <div className='flex flex-col gap-1'>
            {ques.options.map((opt: any, opt_idx: number) => (
              <div key={opt_idx} className='flex flex-row gap-2'>
                <input
                  className='w-5'
                  type='radio'
                  name={`${idx}`}
                  id={`${idx}_${opt_idx}`}
                  onChange={() => {
                    props.setQuizResponses((prev) => {
                      const temp = [...prev];
                      temp[idx] = [opt.option];
                      return temp;
                    });
                  }}
                />
                <label htmlFor={`${idx}_${opt_idx}`}>{opt.option}</label>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

// For quiz with single choice questions ie; props.questionData[0].maxSelect > 1
const CheckboxViewQuiz = (props: QuizViewProps) => {
  return (
    <div className='flex flex-col gap-3 h-auto overflow-y-auto'>
      Checkbox View
    </div>
  );
};

const QuizContent: React.FC<QuizContentProps> = ({
  isLoading,
  questionData,
  quizResponses,
  setQuizResponses,
  setQuizTime,
  submitQuizResponses,
}) => {
  const [allAnswered, setAllAnswered] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(questionData.length * 60);
  const [forceQuizOver, setForceQuizOver] = useState(false);

  // Array of all options for each question in the task.
  // Thus a 2d array [[option1, option2], [...]]
  // Instead of checking against all the options,
  // Extract the correct answer and just check against that
  let optionArr: string[][] = [];
  for (const ques of questionData) {
    let quesAnswers = [];

    for (const opt of ques.options) {
      if (opt.value === 1) {
        quesAnswers.push(opt.option);
      }
    }
    optionArr.push(quesAnswers);
  }

  // This takes in a string to allow for multiple selectable questions.
  // TODO: Functionality for questions with maxSelect > 1
  const isCorrect = (quesIdx: number, response: string[]): boolean => {
    // if (ques.maxSelect > 1) {
    //   // TODO: This part doesnt handle multi-select questions.
    //   return false;
    // }

    // Since for maxSelect response.length and correctAnswer.length is always 1 (for now)
    if (response[0] === optionArr[quesIdx][0]) {
      return true;
    } else {
      return false;
    }
  };

  // If any value in quizResponse is still null, it returns false else true
  const checkIfAllAnswered = (): boolean => {
    for (const val of quizResponses) {
      if (val.length === 0) {
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    setAllAnswered(checkIfAllAnswered());
    for (const resp of quizResponses) {
      if (resp.length > 0 && !timerStarted) {
        setTimerStarted(true);
        setQuizTime((prev) => ({
          ...prev,
          startTime: new Date(),
        }));
      }
    }

    // eslint-disable-next-line
  }, [quizResponses]);

  // TODO: Wrap in useCallback
  const submitFunc = (forceQuit: boolean) => {
    if ((allAnswered || forceQuizOver || forceQuit) && !isLoading) {
      setQuizTime((prev) => ({
        ...prev,
        endTime: new Date(),
      }));

      // Calculate the number of correct answers and pass them to the submit function
      let totalCorrect = 0;
      for (let idx = 0; idx < questionData.length; idx++) {
        if (isCorrect(idx, quizResponses[idx])) {
          totalCorrect += 1;
        }
      }

      submitQuizResponses(totalCorrect);
    }
  };

  useEffect(() => {
    if (timeRemaining <= 0) {
      setForceQuizOver(true);
      setTimeout(() => {
        submitFunc(true);
      }, 200);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeRemaining((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component unmounts or when timeRemaining reaches 0
    return () => clearInterval(intervalId);

    // Temp solution. Need to fix this later
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining]);

  const formatTime = (seconds: number) => {
    const mins = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // TODO: questionData is currently unmutable. Cannot update the answer field with new response. Using quizResponse for now.
  return (
    <React.Fragment>
      <div className='px-2 md:px-6 flex flex-col gap-5 md:max-h-[69vh]'>
        <div className='flex flex-row justify-between w-full gap-2'>
          <span className='font-semibold text-lg'>
            Answer the task quiz to advance forward in the project -
          </span>
          <span className='font-semibold text-lg text-primary px-4 py-1 rounded-lg border border-primary w-20 text-center'>
            {formatTime(timeRemaining)}
          </span>
        </div>

        {questionData[0]?.maxSelect === 1 ? (
          <RadioViewQuiz
            forceQuizOver={forceQuizOver}
            questionData={questionData}
            setQuizResponses={setQuizResponses}
          />
        ) : (
          <CheckboxViewQuiz
            forceQuizOver={forceQuizOver}
            questionData={questionData}
            setQuizResponses={setQuizResponses}
          />
        )}
      </div>
      <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
        <button
          onClick={() => {
            submitFunc(false);
          }}
          className={`text-white text-lg px-2 md:px-12 py-2 rounded-lg select-none ${
            allAnswered || forceQuizOver
              ? 'bg-primary pointer-events-auto'
              : 'bg-stone-500 pointer-events-none'
          }`}>
          {isLoading ? 'Loading' : 'Submit Quiz'}
        </button>
      </div>
    </React.Fragment>
  );
};

export default QuizContent;
