import TaskListElement from './TaskListElement';

const TasksList = ({ weekTasks }: { weekTasks: any }) => {
  const box = 'border p-1 min-h-[48px] rounded';
  return (
    <div className='grid grid-rows-7 gap-1'>
      {weekTasks.map((task: any) => {
        return (
          <div className={box} key={task._id}>
            <TaskListElement task={task} />
          </div>
        );
      })}
    </div>
  );
};

export default TasksList;
