import React from 'react';
import {
  AiOutlineHome,
  AiOutlineCar,
  AiOutlineDollar,
  AiOutlineStock,
} from 'react-icons/ai';
import { FiMonitor } from 'react-icons/fi';
import {
  MdLocalHospital,
  MdOutlineMapsHomeWork,
  MdOutlineSick,
  MdOutlineBabyChangingStation,
  MdOutlineFamilyRestroom,
  MdOutlineKeyboardAlt,
} from 'react-icons/md';
import { HiOutlineAcademicCap } from 'react-icons/hi';
import { TbBuildingCottage } from 'react-icons/tb';
import { RiCoupon2Line } from 'react-icons/ri';
import { BsEmojiSmile } from 'react-icons/bs';

const PerksIcon = ({ perkName }: { perkName: string }) => {
  const perksIcons: any = {
    workFromHome: {
      text: 'Work From Home',
      icon: <AiOutlineHome />,
    },
    jobTrainings: {
      text: 'Job Trainings',
      icon: <FiMonitor />,
    },
    transport: {
      text: 'Transport',
      icon: <AiOutlineCar />,
    },
    healthInsurance: {
      text: 'Health Insurance',
      icon: <MdLocalHospital />,
    },
    educationAssisstance: {
      text: 'Education Assistance',
      icon: <HiOutlineAcademicCap />,
    },
    teamOutings: {
      text: 'Team Outings',
      icon: <TbBuildingCottage />,
    },
    corporateDiscounts: {
      text: 'Corporate Discounts',
      icon: <RiCoupon2Line />,
    },
    paidVacation: {
      text: 'Paid Vacation',
      icon: <MdOutlineMapsHomeWork />,
    },
    paidSickLeaves: {
      text: 'Paid Sick Leaves',
      icon: <MdOutlineSick />,
    },
    performanceBonus: {
      text: ' Performance Bonus',
      icon: <AiOutlineDollar />,
    },
    stockOptions: {
      text: 'Stock Options',
      icon: <AiOutlineStock />,
    },
    paidParentalLeaves: {
      text: 'Paid Parental Leaves',
      icon: <MdOutlineBabyChangingStation />,
    },
    childcareFacilities: {
      text: 'Childcare Facilities',
      icon: <MdOutlineFamilyRestroom />,
    },
    officePerks: {
      text: 'Office Perks',
      icon: <MdOutlineKeyboardAlt />,
    },
    wellnessPrograms: {
      text: 'Wellness Programs',
      icon: <BsEmojiSmile />,
    },
  };
  return (
    <div>
      <div className='text-3xl text-primary flex justify-center'>
        {perksIcons[perkName].icon}
      </div>
      <p className='text-sm'>{perksIcons[perkName].text}</p>
    </div>
  );
};

export default PerksIcon;
