import React from 'react';
import classNames from 'classnames';
import Multiselect from 'multiselect-react-dropdown';
import { useGetVgaQuizSkillQuery } from '../VgaQuizSlice';

const style = {
  chips: {
    background: '#10adb91a',
    color: '#13253A',
  },
};

const SelectSkills = ({ skills, setSkills, setcurrentStep, domainId }: any) => {
  const { data, isLoading } = useGetVgaQuizSkillQuery({
    areaId: domainId,
    noOfQuestion: 8,
  });

  function onChange(selectedList: []) {
    const selectedSkills = selectedList.map((skill: any) => skill._id);
    setSkills(selectedSkills);
  }
  return (
    <>
      <p className='text-primary font-manrope lg:font-semibold font-normal lg:text-xl text-base text-center lg:mt-12 mt-6'>
        Pick your Skill
      </p>
      <div className='font-manrope font-normal text-sm text-center lg:leading-9 leading-6'>
        <p>
          Choose the domain of area of expertise that you are currently working
          in or aspire to work in.
        </p>
        <p>You can select at least three and atmost six skills.</p>
      </div>
      <div className='w-1/2 mx-auto my-8'>
        <Multiselect
          options={data}
          displayValue='title'
          placeholder='Select Skills'
          selectionLimit={6}
          loading={isLoading}
          onSelect={onChange}
          onRemove={onChange}
          style={style}
          customCloseIcon={<div className='ml-3 font-bold'>X</div>}
        />
        <div
          className={classNames('text-center', {
            hidden: skills.length < 3,
          })}
        >
          <button
            onClick={() => {
              setcurrentStep(3);
            }}
            className='font-manrope lg:mt-10 mt-4 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-14 px-6 py-2 rounded-xl '
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectSkills;
