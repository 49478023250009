import { CompanyConnectType } from '../types';
import AboutCompany from './AboutCompany';
import CompanyDataHeader from './layout/CompanyDataHeader';
import MentorConnects from './layout/MentorConnects';
import QuickTipsSection from './layout/QuickTipsSection';

import MentorEvents from './layout/MentorEvents';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';

const CompanyConnectPanel = ({
  aboutData,
  mentorData,
  eventData,
  company2Data,
  mentor2Data,
}: CompanyConnectType) => {
  const { basicDetails } = useBasicDetailsContext();
  const isFullJourney = basicDetails?.isFullJourney;
  const showBothCompany = isFullJourney && company2Data?.companyName;

  return (
    <div className=' md:border rounded-b-lg text-textColor-lighter'>
      <CompanyDataHeader eventData={eventData} />
      {showBothCompany ? (
        <div className='px-4 md:px-8 space-y-14'>
          <AboutCompany
            aboutData={company2Data}
            mentorData={mentor2Data || mentorData}
          />
          <MentorConnects />
          <QuickTipsSection />
          <MentorEvents eventData={eventData} />
          <div className='font-semibold'>
            Prior Company Engagement with {aboutData.companyName}{' '}
          </div>
          <AboutCompany aboutData={aboutData} mentorData={mentorData} />
        </div>
      ) : (
        <div className='px-4 md:px-8 space-y-14'>
          <AboutCompany aboutData={aboutData} mentorData={mentorData} />
          <MentorConnects />
          <QuickTipsSection />
          <MentorEvents eventData={eventData} />
        </div>
      )}
    </div>
  );
};

export default CompanyConnectPanel;
