import React, { useState } from 'react';
import { useAddSuperFeedbackMutation } from '../superCapstoneSlice';

interface FeedbackQuestionProps {
  index: number;
  question: string;
  inputType: string;
  options: string[];
  addResponse: (response: string, idx: number) => void;
}

const FeedbackQuestion: React.FC<FeedbackQuestionProps> = ({
  index,
  question,
  inputType,
  options,
  addResponse,
}) => {
  // Render input based on type
  const renderInput = () => {
    switch (inputType) {
      case 'Scale':
        return (
          <div className='flex gap-5'>
            {options.map((option, idx) => (
              <div key={idx} className='flex flex-row  gap-2 items-center'>
                <input
                  type='radio'
                  className='w-5 h-5'
                  name={`${index}`}
                  id={`${index}_${idx}`}
                  onClick={() => addResponse(option, index - 1)}
                />
                <label htmlFor={`${index}_${idx}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      case 'Yes/No':
        return (
          <div className='flex gap-5'>
            {['Yes', 'No'].map((option, idx) => (
              <div key={idx} className='flex flex-row gap-2 items-center'>
                <input
                  type='radio'
                  className='w-5 h-5'
                  name={`${index}`}
                  id={`${index}_${option}`}
                  onClick={() => addResponse(option, index - 1)}
                />
                <label htmlFor={`${index}_${option}`}>{option}</label>
              </div>
            ))}
          </div>
        );
      case 'Text':
        return (
          <textarea
            className='w-full p-2 border border-gray-300 rounded'
            placeholder='Enter your response'
            onChange={(e) => addResponse(e.target.value, index - 1)}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className='flex flex-col gap-7 p-5 border-2 border-gray-200 rounded-lg'>
      <span className='font-medium'>
        {index}. {question}
      </span>
      {renderInput()}
    </div>
  );
};

type FeedbackResponseType = {
  question: string;
  response: string;
};

const FeedBackView = ({ enrollmentId, setShowFeedback }: any) => {
  const [addFeedback] = useAddSuperFeedbackMutation();

  // Questions data including type
  const questionsData = [
    {
      question:
        'The capstone materials were free from spelling and grammatical errors.',
      type: 'Scale',
      options: [
        'Strongly Disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question:
        'The capstone information provided, including references, resources, and guidelines, was clear, comprehensive, and up-to-date.',
      type: 'Scale',
      options: [
        'Strongly Disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question: 'The capstone content was engaging and kept my attention.',
      type: 'Scale',
      options: [
        'Strongly disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question: 'How easy was it to navigate our platform?',
      type: 'Scale',
      options: ['Very Difficult', 'Difficult', 'Neutral', 'Easy', 'Very Easy'],
    },
    {
      question:
        'How clear and understandable was the information presented on the platform?',
      type: 'Scale',
      options: ['Very Unclear', 'Unclear', 'Neutral', 'Clear', 'Very Clear'],
    },
    {
      question:
        'How would you rate the visual design, responsiveness, and speed of our platform?',
      type: 'Scale',
      options: [
        'Very Unappealing/Slow',
        'Unappealing/Slow',
        'Neutral',
        'Appealing/Fast',
        'Very Appealing/Fast',
      ],
    },
    {
      question:
        'Did you encounter any errors or issues while using the platform?',
      type: 'Yes/No',
      options: [],
    },
    {
      question: 'Please describe the errors or issues you encountered.',
      type: 'Text',
      options: [],
    },
    {
      question:
        'How helpful were the platform features in completing your tasks?',
      type: 'Scale',
      options: [
        'Not Helpful',
        'Slightly Helpful',
        'Neutral',
        'Helpful',
        'Very Helpful',
      ],
    },
    {
      question:
        'The hands-on project was effective in enhancing my learning and was appropriately challenging.',
      type: 'Scale',
      options: [
        'Strongly disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question:
        'The upskilling provided was relevant and applicable to my current job or career goals.',
      type: 'Scale',
      options: [
        'Strongly disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question:
        'I feel confident in applying the skills I have learned and presenting my portfolio to potential employers or clients.',
      type: 'Scale',
      options: [
        'Strongly disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question:
        'The knowledge and skills gained from the capstone are valuable to me on a personal level, and I was highly motivated throughout the duration of the capstone.',
      type: 'Scale',
      options: [
        'Strongly disagree',
        'Disagree',
        'Neutral',
        'Agree',
        'Strongly Agree',
      ],
    },
    {
      question: 'What aspects of the capstone did you find most valuable?',
      type: 'Text',
      options: [],
    },
    {
      question:
        'How would you rate your overall satisfaction with the capstone?',
      type: 'Scale',
      options: [
        'Very Satisfied',
        'Satisfied',
        'Neutral',
        'Dissatisfied',
        'Very Dissatisfied',
      ],
    },
    {
      question: 'Did the capstone meet your expectations?',
      type: 'Yes/No',
      options: [],
    },
    {
      question: 'What areas do you think need improvement, and why?',
      type: 'Text',
      options: [],
    },
    {
      question: 'Would you recommend this capstone to others? Why or why not?',
      type: 'Text',
      options: [],
    },
    {
      question:
        'Do you have any additional comments or suggestions you would like to share?',
      type: 'Text',
      options: [],
    },
  ];

  const [feedbackResponse, setFeedbackResponse] = useState<
    FeedbackResponseType[]
  >(questionsData.map((q) => ({ question: q.question, response: '' })));

  const [allAnswered, setAllAnswered] = useState(false);

  const checkIfAllAnswered = (): boolean => {
    return feedbackResponse.every((res) => res.response !== '');
  };

  return (
    <>
      <style>
        {`
          .custom-scrollbar::-webkit-scrollbar {
            width: 12px;
          }

          .custom-scrollbar::-webkit-scrollbar-track {
            background: #f1f1f1;
          }

          .custom-scrollbar::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 6px;
            border: 3px solid #f1f1f1;
            min-height: 200px; /* Adjust this value to increase the minimum height of the thumb */
          }

          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background-color: #555;
          }
        `}
      </style>
      ;
      <div className='flex flex-col md:flex-row h-full md:border-t-2 text-justify'>
        <div className='md:basis-2/5 md:border-r-2 border-gray-200 h-full space-y-4 p-4'>
          <p className='font-medium text-2xl text-textColor-main'>
            Feedback and Certificate
          </p>
          <div className='p-6 bg-gray-100 rounded'>
            <p>
              Please complete a short feedback form to download your{' '}
              <br className='hidden sm:block' /> certificate
            </p>
          </div>
        </div>
        <div className='flex flex-col h-full md:basis-3/5 gap-6 justify-between'>
          <div className='px-6 py-4 flex flex-col gap-2 overflow-y-auto scrollbar md:max-h-[75vh] custom-scrollbar'>
            {questionsData.map((question, idx) => (
              <FeedbackQuestion
                key={idx}
                index={idx + 1}
                question={question.question}
                inputType={question.type}
                options={question.options}
                addResponse={(response: string, idx: number) => {
                  const tempArr = [...feedbackResponse];
                  tempArr[idx].response = response;
                  setFeedbackResponse(tempArr);
                  setAllAnswered(checkIfAllAnswered());
                }}
              />
            ))}
          </div>
          <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
            <button
              onClick={() => {
                addFeedback({
                  feedback: feedbackResponse,
                  enrollId: enrollmentId,
                })
                  .unwrap()
                  .then(() => {
                    setShowFeedback(false);
                  });
              }}
              className={`text-white text-lg px-2 md:px-5 py-2 rounded-lg select-none ${
                allAnswered
                  ? 'bg-primary pointer-events-auto'
                  : 'bg-stone-500 pointer-events-none'
              }`}>
              {false ? 'Loading' : 'Submit Feedback'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeedBackView;
