import React from 'react';

import CMSection from '../../layout/CMSection';
import Expertise from '../../layout/Expertise';

const YourCoach = (props) => {
  return (
    <div>
      <div className='bg-gray-100 p-4'>
        <div className='flex'>
          <div className='h-2 w-2 m-2 bg-primary mb-4'></div>
          <div>
            <h2>
              <b>{props.heading}</b>
            </h2>
          </div>
        </div>
        <CMSection
          imgUrl={props.coach.coachImageUrl}
          name={props.coach.name}
          designation={props.coach.designation}
          aboutCM={props.coach.sectionContent[0].contentToPublish}
        />

        <Expertise
          areas1={props.coach.areas[0]}
          areas2={props.coach.areas[1]}
        />
      </div>
    </div>
  );
};

export default YourCoach;
