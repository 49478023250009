import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { buttonStyle, inputStyle, errorStyle, linkStyle } from './authStyles';
import Logo from './Logo';
import { setToken } from './authSlice';
import { useGetPartnerQuery } from './partnerSlice';
import Loading from 'components/global/layout/Loading';

const PartnerLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { slug } = useParams();

  const { isLoading, data } = useGetPartnerQuery({ code: slug });
  const isEdRed = slug === 'edred';
  const location: any = useLocation();
  const from = location.state?.from?.pathname || '/';

  const [serverResponse, setServerResponse] = useState('');
  //If already logged in

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((user) => {
      navigate(from, { replace: true });
    });
  }, [from, navigate]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <div className='grid md:grid-cols-2 grid-cols-1'>
          <div className='md:flex md:flex-col p-4 justify-between bg-gradient-to-b from-[#193257] to-[#0A1626] md:min-h-screen'>
            <Link to='/'>
              <Logo partnerLogo={data?.logoUrl} partnerCode={slug} />
            </Link>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1661165411/b2c/loginImage_uvscv9.png'
              alt='Login'
              className='mx-auto mt-8'
            />
            {isEdRed ? (
              <p className='text-white text-center text-lg md:text-2xl font-bold mb-32 md:w-3/5 mx-auto'>
                Build proof of work with capstones and apprenticeships. Get
                recognised by 500+ global companies
              </p>
            ) : (
              <p className='text-white text-center text-lg md:text-2xl font-bold mb-32 md:w-4/5 mx-auto'>
                Build <span className='text-primary'>Belong Score </span>and get
                recognised by 500+ global companies
              </p>
            )}
          </div>

          <div className='grid place-items-center p-4  my-auto'>
            <div className='md:w-3/4 w-full'>
              <h1 className='my-8 text-lg font-semibold'>
                Login to your {isEdRed ? 'Edred' : 'Belong'} account
              </h1>
              <Formik
                initialValues={{ email: '', password: '' }}
                validationSchema={Yup.object().shape({
                  password: Yup.string()
                    .min(2, 'Too Short!')
                    .max(50, 'Too Long!')
                    .required('Required'),
                  email: Yup.string()
                    .email('Invalid email')
                    .required('Required'),
                })}
                onSubmit={(values, { setSubmitting }) => {
                  setServerResponse('');
                  Auth.signIn({
                    username: values.email,
                    password: values.password,
                  })
                    .then((res) => {
                      const token =
                        res?.signInUserSession?.accessToken?.jwtToken;
                      dispatch(setToken(token));
                      navigate(from, { replace: true });
                    })
                    .catch((err) => {
                      setServerResponse(err?.message);
                    })
                    .finally(() => {
                      setSubmitting(false);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form className='space-y-8'>
                    <div>
                      <Field
                        type='email'
                        name='email'
                        placeholder='Email'
                        className={inputStyle}
                      />
                      <ErrorMessage
                        name='email'
                        component='div'
                        className='text-red-300'
                      />
                    </div>
                    <div>
                      <Field
                        type='password'
                        name='password'
                        placeholder='Password'
                        className={inputStyle}
                      />
                      <ErrorMessage
                        name='password'
                        component='div'
                        className='text-red-300'
                      />
                    </div>
                    <div className='text-center'>
                      <button
                        type='submit'
                        disabled={isSubmitting}
                        className={buttonStyle}
                      >
                        {isSubmitting ? 'Submitting' : 'Login'}
                      </button>
                      <div className='my-4'>
                        <p className={errorStyle}>{serverResponse}</p>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className='font-semibold'>
              <Link to='/forgot-password'>Forgot Password?</Link>
            </div>
            {data && (
              <Link to={`/signup/${slug}`} className={linkStyle}>
                Create a new account
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default PartnerLogin;
