import { differenceInHours } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { getAttendanceSummary } from '../helper';

const AttendanceCard = ({ batchData }: { batchData: any }) => {
  const [summary, setSummary] = useState<any>(null);
  const [totalAttended, setTotalAttended] = useState(0);
  // get all past events
  const pastEvents: any = useMemo(() => {
    return batchData.events.filter((individualEvent: any) => {
      const isPastEvent =
        differenceInHours(
          new Date(),
          new Date(individualEvent.eventId.dateTime)
        ) >= 2;
      return isPastEvent;
    });
  }, [batchData.events]);

  //total events that the user should have attended
  const totalEvents = pastEvents.length;
  //Get the event attendance summary
  useEffect(() => {
    getAttendanceSummary(pastEvents, batchData.users[0].email).then(
      (res: any) => {
        setSummary(res);
      }
    );
  }, [batchData.users, pastEvents]);

  //Calculate total attended events once the summary is available
  useEffect(() => {
    if (summary) {
      setTotalAttended(
        summary?.masterclass?.attended +
          summary?.coachConnect?.attended +
          summary?.mentorConnect?.attended
      );
    }
  }, [summary]);

  const attendancePercentage = totalEvents
    ? (totalAttended / totalEvents) * 100
    : 100;
  const barTotal = totalAttended + summary?.notattended;

  return (
    <div className='border-2 border-dark/10 rounded-sm space-y-6 p-2 pb-6  '>
      {/* first area start*/}
      <div className='flex justify-between items-center'>
        <p className='text-primary font-semibold text-lg'>Attendance Rate</p>

        <p className='text-white font-semibold bg-primary border rounded-2xl min-w-fit px-2'>
          {attendancePercentage.toFixed(2)}%
        </p>
      </div>
      {/* first area end */}

      {/* second area start */}
      <div>
        <div className='w-full h-4 flex'>
          <div
            className={`bg-primary/80 rounded-l-2xl h-4 w-[${Math.floor(
              (summary?.masterclass?.attended / barTotal) * 100
            )}%]`}></div>
          <div
            className={`bg-primary/60 rounded-l-2xl h-4 w-[${Math.floor(
              (summary?.coachConnect?.attended / barTotal) * 100
            )}%]`}></div>
          <div
            className={`bg-primary/40 rounded-l-2xl h-4 w-[${Math.floor(
              (summary?.mentorConnect?.attended / barTotal) * 100
            )}%]`}></div>
          <div
            className={` bg-primary/10 rounded-r-2xl h-4 w-[${Math.floor(
              (summary?.notattended / barTotal) * 100
            )}%]`}></div>
        </div>
      </div>
      {/* second area end */}
      {/* third area start */}
      <div className='space-y-4 font-semibold'>
        {/* Masterclass type */}
        <div className='bg-primary/80 border  rounded-2xl flex items-center  h-8 w-full'>
          <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
            {summary?.masterclass?.attended}/{summary?.masterclass?.total}
          </p>
          <div className='flex w-full items-center justify-between'>
            <p className='ml-4'>Master Class</p>
          </div>
        </div>
        {/* Coach Connect type */}
        <div className='bg-primary/60 border  rounded-2xl flex items-center  h-8 w-full'>
          <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
            {summary?.coachConnect?.attended}/{summary?.coachConnect?.total}
          </p>
          <div className='flex w-full items-center justify-between'>
            <p className='ml-4'>Coach Discussions</p>
          </div>
        </div>
        {/* Mentor Connect type */}
        <div className='bg-primary/40 border  rounded-2xl flex items-center  h-8 w-full'>
          <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
            {summary?.mentorConnect?.attended}/{summary?.mentorConnect?.total}
          </p>
          <div className='flex w-full items-center justify-between'>
            <p className='ml-4'>Mentor Connects</p>
          </div>
        </div>
        {/* Not attended */}
        <div className='bg-primary/20 border  rounded-2xl flex items-center  h-8 w-full'>
          <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
            {summary?.notattended}
          </p>
          <div className='flex w-full items-center justify-between'>
            <p className='ml-4'>Not Attended</p>
          </div>
        </div>
      </div>
      {/* third area end */}
    </div>
  );
};

export default AttendanceCard;
