import React from 'react';
import { format } from 'date-fns';

import OverviewCard from '../../layout/OverviewCard';
import AboutSection from '../../layout/AboutSection';
import Tasks from '../../layout/Tasks';

function Assignments(props) {
  const userSpecificTasks = props.userSpecificTasks;
  const uniqueUserId = props.data.cognitoId;
  const isCareereraUser = props.isCareereraUser;

  const batchTasks = userSpecificTasks
    .map((task) => ({
      ...task,
    }))
    .filter(
      (task) =>
        task.assignmentId.type === '21st Century Skills' &&
        task.assignmentId.status === 'publish'
    );

  return (
    <div className='bg-gray-100 py-4'>
      <OverviewCard
        imageUrl='https://res.cloudinary.com/belong/image/upload/v1643360330/uploaded_resources/Upskilling_Banner_Logo_g89s91.png'
        overviewHeader={
          props.isVGE
            ? 'Your Assignments From the Entrepreneurial Sessions'
            : 'Your Assignments From The Masterclass'
        }
        list1='Practical'
        list2='Enhancing'
        list3='Growth'
      />
      <AboutSection heading='What Are These Assignments About?'>
        {props.isVGE ? (
          <p>
            Your entrepreneurial sessions come with assignments for you to
            practically put to test your learnings. You are given an assignment
            that aids you in upgrading your performance and work output with
            your startup.
          </p>
        ) : (
          <p>
            Your masterclasses come with assignments for you to practical put to
            test your learnings. You are given an assignment that aids you in
            upgrading your performance and work output with the{' '}
            {isCareereraUser ? ' internship ' : ' apprenticeship '}
            company.
          </p>
        )}
        <p>
          Our aim is to contribute positively in your overall employability
          through exposure and experience with key entrepreneurial skills that
          can be applied to every walk of life.
        </p>
      </AboutSection>

      {batchTasks.length !== 0 && (
        <div className='flex mt-2 p-4'>
          <div className='h-2 w-2 m-2 bg-primary mb-2'></div>
          <div className='text-sm'>
            <h2>
              <b>Task Schedule</b>
            </h2>
          </div>
        </div>
      )}
      {batchTasks.map((task) => {
        return (
          <Tasks
            key={task.assignmentId._id}
            taskSubmissions={props.data.taskSubmissions}
            assignmentId={task.assignmentId._id}
            uniqueUserId={uniqueUserId}
            heading={task.assignmentId.title}
            about={task.assignmentId.description}
            aboutpdf={task.assignmentId.fileName}
            fileLink={task.assignmentId.assignmentFileUrl}
            duedate={format(
              new Date(task.assignmentId.dueDateTime),
              'do LLLL p'
            )}
            publishAt={task.assignmentId.publishAt}
          />
        );
      })}
    </div>
  );
}

export default Assignments;
