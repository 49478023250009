import React from 'react';

const classNames = require('classnames');

const FiibButton = ({
  selectedScreen,
  setSelectedScreen,
  showCertificate,
  showTicket,
}) => {
  return (
    <div className='p-3 bg-gray-100 text-xs'>
      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 0,
        })}
        onClick={() => {
          setSelectedScreen(0);
        }}
      >
        Calendar
      </button>

      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 4,
        })}
        onClick={() => {
          setSelectedScreen(4);
        }}
      >
        21st Century Lab
      </button>

      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 12,
        })}
        onClick={() => {
          setSelectedScreen(12);
        }}
      >
        Aptitude Quiz
      </button>
      <button
        type='button'
        className={classNames('bg-white p-2 hover:bg-primary-light', {
          'bg-primary-light': selectedScreen === 11,
        })}
        onClick={() => {
          setSelectedScreen(11);
        }}
      >
        Domain Quiz
      </button>
      {showTicket && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 8,
          })}
          onClick={() => {
            setSelectedScreen(8);
          }}
        >
          Query
        </button>
      )}
      {showCertificate && (
        <button
          type='button'
          className={classNames('bg-white p-2 hover:bg-primary-light', {
            'bg-primary-light': selectedScreen === 6,
          })}
          onClick={() => {
            setSelectedScreen(6);
          }}
        >
          Feedback & Certification
        </button>
      )}
    </div>
  );
};

export default FiibButton;
