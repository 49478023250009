import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import React, { useEffect, useState } from 'react';
// import WelcomePage from './WelcomePage';
import Tour from 'reactour';

import OnboardingDetails from './OnboardingDetails';
import { useGetOnboardingQuery } from './panelSlice';
import { tourConfig } from './TourConfig';

const Index = () => {
  const { data: userData, isLoading: userLoading } = useGetUserQuery();
  const { data: onbordingData, isLoading: onbordingLoading } =
    useGetOnboardingQuery(undefined, { skip: !userData });

  const [isTourOpen, setIsTourOpen] = useState(false);

  useEffect(() => {
    const localDashboardTour = localStorage.getItem('localDashboardTour') || '';
    if (localDashboardTour !== 'Done') {
      setIsTourOpen(true);
      localStorage.setItem('localDashboardTour', 'Done');
    }
  }, []);

  return (
    <>
      <Tour
        steps={tourConfig}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        rounded={5}
        showButtons={true}
        // continuous={true}
      />
      {(userLoading || onbordingLoading) && <Loading />}
      {userData && onbordingData && (
        <OnboardingDetails onbordingData={onbordingData} userData={userData} />
      )}
    </>
    // <WelcomePage />
  );
};

export default Index;
