import React from 'react';
import { BannerType } from '../utils/types';

const Banner: React.FC<BannerType> = ({ logo, title, website, domain }) => {
  return (
    <div className='flex justify-center'>
      <div className='flex items-center  w-full justify-center border-2 bg-gray-100'>
        <div className='flex items-center justify-between h-24 '>
          <div className='py-2 pr-4 ml-4 md:ml-8 border-gray-500 border-r-2'>
            <img
              className='h-8 sm:h-10 md:h-12 w-24 sm:w-auto '
              alt='company'
              src={logo}
            ></img>
            <a href={website} target='_blank' rel='noreferrer noopener'>
              <p className='text-xs mt-2'>{website}</p>
            </a>
          </div>

          <div className='flex flex-col px-4'>
            <span className='text-sm md:text-base font-semibold'>{title}</span>
            <p className='text-gray-700 text-sm md:text-base'>{domain}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
