import { useCurrentCapstoneQuery } from './CapstoneQuizSlice';
import QuizFailScreen from './QuizFailScreen';
import QuizpassScreen from './QuizpassScreen';
import Loading from 'components/global/layout/Loading';

interface allProps {
  setShowQuiz: any;
  capstoneId: String;
}

const ShowScore = (props: allProps) => {
  const { data } = useCurrentCapstoneQuery({
    capstoneId: props.capstoneId,
  });

  return (
    <div>
      {data?.capstoneAssessments?.finishTime ? (
        <>
          {data?.capstoneAssessments?.percentage < 60 ? (
            <QuizFailScreen
              setShowQuiz={props.setShowQuiz}
              percentage={data?.capstoneAssessments?.percentage}
            />
          ) : (
            <QuizpassScreen
              setShowQuiz={props.setShowQuiz}
              percentage={data?.capstoneAssessments?.percentage}
            />
          )}
        </>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default ShowScore;
