import React from 'react';
const cardData = [
  {
    id: 1,
    question: 'What?',
    text: 'Interacting  with domain expert to support & guide in project research, ideation & queries',
  },
  {
    id: 2,
    question: 'Why?',
    text: 'Acts as a journey accelerator  to help navigate challenges & build proof of work',
  },
  {
    id: 3,
    question: 'How?',
    text: 'Interacting through scheduled discussions on the dashboard and written communication via the Query section',
  },
];

const CoachDiscussions = () => {
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold'>Coach Discussions</p>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4  xl:gap-12  mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2 items-center lg:items-start border rounded-lg p-2 md:p-4 '
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center justify-center my-auto  rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2 '>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoachDiscussions;
