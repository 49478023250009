import React from 'react';
import { BiUpload } from 'react-icons/bi';
import {
  AiOutlineLoading3Quarters,
  AiOutlineCheckCircle,
} from 'react-icons/ai';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkJobEligibility,
  LOADING,
  IDLE,
  trackClick,
} from '../utils/helper';
import { useApplyForJobMutation } from '../JobsSlice';
import { useGetSummaryQuery } from 'components/dashboard/panel/panelSlice';

const ApplyNow: React.FC<{
  setShowApplyBlockedPopup: Function;
  setShowApplyPopup: Function;
  applyState: string;
  setApplyState: Function;
  isLoggedIn: boolean;
  isLoading: boolean;
  alreadyApplied: boolean;
  domainId: string;
  oportunityId: string;
  setModalData: Function;
}> = ({
  setShowApplyBlockedPopup,
  setShowApplyPopup,
  setApplyState,
  applyState,
  isLoggedIn,
  isLoading,
  alreadyApplied,
  domainId,
  oportunityId,
  setModalData,
}) => {
  //   type ApplyStates = {
  //     key: {
  //       icon: JSX.Element;
  //       text: string;
  //     };
  //   };
  const navigate = useNavigate();
  const location = useLocation();

  const iconClass =
    'group-hover:text-white transition-scale ease-in-out duration-300';
  const applyStates: any = {
    idle: {
      icon: (
        <BiUpload className={classNames(iconClass, 'group-hover:scale-150')} />
      ),
      text: 'Apply Now',
    },
    loading: {
      icon: (
        <AiOutlineLoading3Quarters
          className={classNames(
            iconClass,
            'text-xl text-white animate-spin pointer-events-none'
          )}
        />
      ),
      text: 'Processing...',
    },
    applied: {
      icon: (
        <AiOutlineCheckCircle
          className={classNames(
            iconClass,
            'text-white scale-150 pointer-events-none'
          )}
        />
      ),
      text: 'Applied',
    },
  };

  const [applyForJob] = useApplyForJobMutation();
  const { isLoading: loading, data } = useGetSummaryQuery({ areaId: domainId });
  function handleClick() {
    if (!isLoggedIn) {
      //Give category and action to track using GA
      trackClick('Jobs', 'Signup/Login to Apply');
      navigate('/login', { state: { from: location }, replace: true });
    } else {
      if (!alreadyApplied && !isLoading && !loading) {
        setApplyState(LOADING);
        const eligibility = checkJobEligibility(domainId, data);
        const eligibleForJob = eligibility.every((condition) => condition);
        if (eligibleForJob) {
          //Give category and action to track using GA
          trackClick('Jobs', 'Applied with complete profile');
          // setShowApplyPopup(true);
          // setShowApplyBlockedPopup(false);
          //send oportunityId and apply
          applyForJob({ opportunityId: oportunityId });
          // setApplyState(APPLIED);
        } else {
          //Give category and action to track using GA
          trackClick('Jobs', 'Applying with no profile');
          // setShowApplyPopup(false);
          setModalData(eligibility);
          setShowApplyBlockedPopup(true);
          setApplyState(IDLE);
        }
      }
    }
  }

  const applyNowButton = (
    <button
      disabled={isLoading}
      onClick={handleClick}
      className={classNames(
        'group flex gap-4 items-center mt-8 hover:bg-primary shadow px-6 py-3 rounded-xl transition ease-in-out duration-300',
        { 'bg-primary-light': !(applyState === 'applied') },
        { 'bg-primary': applyState === 'applied' }
      )}
    >
      {applyStates[applyState].icon}
      {applyStates[applyState].text}
    </button>
  );
  return applyNowButton;
};

export default ApplyNow;
