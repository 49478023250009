import { useState } from 'react';
import TaskDetail from './TaskDetail';
import TabBar from '../TabBar';
import TaskSolution from './TaskSolution';
// import TaskSolution from './TaskSolution';
import QuizAndScoreStart from './quiz_and_score/QuizAndScoreStart';

interface allProps {
  data: any;
  setSelectedTask: Function;
  selectedTask: any;
  enrollData: any;
  count: number;
}

const Index = (props: allProps) => {
  const tabOptions = ['Text Editor', 'Quiz & Review'];

  const [activeTab, setActiveTab] = useState('Text Editor');

  // User is allowed to reattempt if the prev attempts percentage is less than some value.
  // The new attempt replaces the prev attempt in the db with the new data.
  // Storing the previous reattempts.
  let prevAttempt: any = null;
  let defaultQuizState = 'instructions';

  for (const quiz of props.enrollData?.quiz) {
    if (quiz.taskNo === props.selectedTask.taskNo) {
      prevAttempt = quiz;
    }
  }

  if (prevAttempt !== null) {
    defaultQuizState = 'score';
  }

  // State options: disabled, instructions, ongoing, review, score, error
  const [quizState, setQuizState] = useState(defaultQuizState);

  // Array of empty strings arrays [[""], [""]].
  // Keep track of user responses for each question.
  // If any element "", user has not answered something.
  const [quizResponses, setQuizResponses] = useState<string[][]>(
    new Array(props.selectedTask.questions?.length).fill([])
  );

  return (
    <div className='flex flex-col md:flex-row h-full md:border-t-2 text-justify'>
      <div className='md:w-2/5 py-2 md:p-4 flex flex-col gap-4'>
        {/* Might need to remove this Back button later and use BreadCrumbs instead */}
        {/* <button
          className='text-primary border border-primary bg-white px-4 py-0.5 rounded-xl w-fit'
          onClick={() => props.setSelectedTask(null)}>
          Back
        </button> */}
        <div className='flex items-center space-x-4'>
          <p className='pr-4 border-r border-black'>Task {props.count}</p>
          <p>{props.selectedTask.time} hours</p>
        </div>
        <TaskDetail taskData={props.selectedTask} />
      </div>
      <div className='md:w-3/5 md:border-l-2 border-gray-200 flex flex-col h-full gap-6'>
        <TabBar
          quizDisabled={quizState === 'disabled'}
          tabOptions={tabOptions}
          setActiveTab={setActiveTab}
          activeTab={activeTab}
        />
        {activeTab === 'Text Editor' && (
          <TaskSolution
            setActiveTab={setActiveTab}
            taskData={props.selectedTask}
            enrollData={props.enrollData}
            setSelectedTask={props.setSelectedTask}
            disableQuiz={(val: boolean) => {
              val ? setQuizState('disabled') : setQuizState('instructions');
            }}
          />
        )}
        {activeTab === 'Quiz & Review' && (
          <QuizAndScoreStart
            resetQuizResponses={() => {
              setQuizResponses(
                new Array(props.selectedTask.questions?.length).fill([])
              );
            }}
            isReattempt={prevAttempt !== null}
            prevAttempt={prevAttempt}
            setSelectedTask={props.setSelectedTask}
            taskData={props.selectedTask}
            quizResponses={quizResponses}
            setQuizResponses={setQuizResponses}
            quizState={quizState}
            setQuizState={setQuizState}
            enrollData={props.enrollData}
          />
        )}
      </div>
    </div>
    // <div className='p-4'>
    //   <div className='flex justify-between'>
    //     <button
    //       className='text-primary border border-primary bg-white px-4 py-0.5 rounded-xl'
    //       onClick={() => props.setSelectedTask(null)}>
    //       Back
    //     </button>
    //     <div className='flex items-center bg-white border border-primary px-4 py-0.5 rounded text-sm font-semibold'>
    //       Task {props.count}
    //     </div>
    //   </div>
    //   <TaskDetail taskData={props.selectedTask} />
    //   <TaskSolution
    //     taskData={props.selectedTask}
    //     enrollData={props.enrollData}
    //     setSelectedTask={props.setSelectedTask}
    //   />
    // </div>
  );
};

export default Index;
