import React from 'react';
import OverviewCard from '../../layout/OverviewCard';
import FetchQuestion from './FetchQuestion';
import Report from './Report';
import LockInstruction from './LockInstruction';

const Index = ({
  userDetails,
  batchId,
  currentWeek,
  totalWeek,
  isCareereraUser,
  isFullJourney,
  fullJourneyStart,
}) => {
  const isQuizUnlock = isFullJourney
    ? currentWeek === fullJourneyStart - 1
    : currentWeek === totalWeek;

  return (
    <div>
      <div className='bg-gray-100 py-4'>
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1655815969/uploaded_resources/image-removebg-preview_1_kwfjfo.png'
          overviewHeader='Your Domain Expertise Evalution'
          list1={isCareereraUser ? 'E-Score' : 'Belong Score'}
          list2='Domain Knowledge'
          list3='Industry Skillset'
        />

        {userDetails.vgaQuizData && userDetails.vgaQuizData.finishTime ? (
          <Report userDetails={userDetails} />
        ) : isQuizUnlock ? (
          <FetchQuestion
            batchId={batchId}
            userDetails={userDetails}
            isCareereraUser={isCareereraUser}
          />
        ) : (
          <LockInstruction isCareereraUser={isCareereraUser} />
        )}
      </div>
    </div>
  );
};

export default Index;
