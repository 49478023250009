import React from 'react';

const NavigationBar = () => {
  return (
    <div className='flex justify-between items-baseline'>
      <div className='flex space-x-2 sm:space-x-10 font-medium text-textColor-light'>
        <p className='underline underline-offset-8'>Guidelines</p>
        <p>Solution</p>
      </div>
    </div>
  );
};

export default NavigationBar;
