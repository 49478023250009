import React from 'react';
import useCapstoneTestResponsesContext from 'hooks/useCapstoneTestResponsesContext';
import QuestionCell from './QuestionCell';

const QuestionGrid: React.FC<{ closeModal?: any }> = ({ closeModal }) => {
  const { state } = useCapstoneTestResponsesContext();
  return (
    <div>
      <div className='text-gray-500 font-semibold mb-6'>{state.capstone}</div>
      <div className='grid lg:grid-cols-5 grid-cols-4  gap-2  md:gap-4 mr-1 sm:mr-4'>
        {state.responses.map((question: any, index: number) => {
          return (
            <QuestionCell
              num={index + 1}
              question={question}
              key={question._id}
              closeModal={closeModal}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QuestionGrid;
