export const eventAttended = (email: string, attendance: any) => {
  const res = attendance.find((attendee: any) => {
    return attendee.email === email;
  });
  return !!res?.status;
};

export const getAttendanceSummary = (pastEvents: any, userEmail: string) => {
  return new Promise((resolve, reject) => {
    const summary = {
      masterclass: { total: 0, attended: 0 },
      coachConnect: { total: 0, attended: 0 },
      mentorConnect: { total: 0, attended: 0 },
      notattended: 0,
    };

    pastEvents.forEach((event: any) => {
      if (event.eventId.type === 'Masterclass') {
        summary.masterclass.total += 1;
        if (eventAttended(userEmail, event.eventId.attendance)) {
          summary.masterclass.attended += 1;
        } else summary.notattended += 1;
      } else if (event.eventId.type === 'Coach Connect') {
        summary.coachConnect.total += 1;
        if (eventAttended(userEmail, event.eventId.attendance)) {
          summary.coachConnect.attended += 1;
        } else summary.notattended += 1;
      } else if (event.eventId.type === 'Mentor Connect') {
        summary.mentorConnect.total += 1;
        if (eventAttended(userEmail, event.eventId.attendance)) {
          summary.mentorConnect.attended += 1;
        } else summary.notattended += 1;
      }
    });
    resolve(summary);
  });
};
