import Navigation from 'components/profile/Navigation';
import { useReducer, useState, useEffect, useMemo } from 'react';
import { initialNavigation, reducer } from './helper';
import { PorfileNavigation } from './types';
import Information from 'components/profile/information';
import Education from 'components/profile/education';
import Achievements from 'components/profile/achievements';
import Portfolio from 'components/profile/portfolio';
import Others from 'components/profile/others';
import CompletedProfile from 'components/profile/completed';
import Experiences from 'components/profile/experience';
import SkillSection from 'components/profile/skills';
import { useGetUserQuery } from 'components/profile/userSlice';
import Loading from 'components/global/layout/Loading';
import { profilePercentage } from 'components/profile/helper/helperFun';

const Index = () => {
  const { isLoading, isSuccess, data } = useGetUserQuery();

  const [navigation, dispatch] = useReducer(reducer, initialNavigation);
  const [currentItem, setCurrentItem] = useState(1);
  const profileCompleted = useMemo(() => {
    return profilePercentage(data) > 50;
  }, [data]);
  const [showFullProfile, setShowFullProfile] = useState(profileCompleted);

  const handleSwitch = (id: number) => {
    dispatch({ type: 'SWITCH', id: id });
    setCurrentItem(id);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentItem]);

  const profilePanels = navigation.map((item: PorfileNavigation) => {
    return (
      <div key={item.id}>
        {item.view && item.id === 1 && (
          <Information
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 2 && (
          <Education
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 3 && (
          <Experiences
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 4 && (
          <Portfolio
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 5 && (
          <Achievements
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 6 && (
          <SkillSection
            item={item}
            handleSwitch={handleSwitch}
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
        {item.view && item.id === 7 && (
          <Others
            key={item.id}
            profileCompleted={profileCompleted}
            setShowFullProfile={setShowFullProfile}
          />
        )}
      </div>
    );
  });

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div className='pl-6 overflow-hidden'>
          <Navigation
            navigation={navigation}
            handleSwitch={handleSwitch}
            showFullProfile={showFullProfile}
            setShowFullProfile={setShowFullProfile}
          />

          {showFullProfile ? (
            <CompletedProfile
              // item={item}
              handleSwitch={handleSwitch}
              setShowFullProfile={setShowFullProfile}
            />
          ) : (
            profilePanels
          )}
        </div>
      )}
    </>
  );
};

export default Index;
