import React from 'react';

interface allProps {
  percentage: any;
  setShowQuiz: any;
}

const QuizFailScreen = (props: allProps) => {
  return (
    <div className='border rounded text-sm md:text-base font-medium md:font-semibold'>
      <div className='bg-primary-lightest p-6 text-primary '>Capstone Quiz</div>
      <div className='border rounded mx-4 md:mx-8 my-4 md:my-8 px-4 md:px-16 pt-6 pb-10 md:pb-20'>
        <p>Sorry, you did not pass the quiz. Please try again after 48 hours</p>
        <div className='border rounded  pt-4 md:pt-8 pb-16 px-2 md:px-6  my-4 bg-primary-lightest '>
          <p className='bg-primary px-2 md:px-4 py-1 text-white text-center  rounded-2xl w-52 md:w-72'>
            Overall Performance- {props.percentage} %
          </p>
        </div>
        <div className='flex justify-end'>
          <button
            onClick={() => props.setShowQuiz(false)}
            className='bg-primary px-4 py-1 rounded text-white '
          >
            End Session
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizFailScreen;
