import React, { useCallback, useEffect } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { axiosUpload } from 'config/Axios';

interface textEditorProps {
  initialtext: any;
  setUserSolution: any;
}

const TextEditorQuill = (props: textEditorProps) => {
  const initialtext = props.initialtext;
  const setUserSolution = props.setUserSolution;

  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }],
        ['clean'],
        ['image'],
      ],
    },
    formats: [
      'bold',
      'list',
      'link',
      'color',
      'clean',
      'italic',
      'size',
      'underline',
      'image',
    ],
  });
  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = initialtext;
      quill.on('text-change', () => {
        setUserSolution(quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, initialtext, setUserSolution]);

  if (Quill && !quill) {
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  const insertToEditor = useCallback(
    (imageUrl: any) => {
      const finalUrl = `${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`;
      if (quill) {
        const range = quill.getSelection();
        if (range) {
          quill.insertEmbed(range.index, 'image', finalUrl);
        }
      }
    },
    [quill]
  );

  // const insertToEditor = (imageUrl: any) => {
  //   const finalUrl = `${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`;
  //   if (quill) {
  //     const range = quill.getSelection();
  //     if (range) {
  //       quill.insertEmbed(range.index, 'image', finalUrl);
  //     }
  //   }
  // };

  //async usecallback
  const uploadFile = useCallback(
    async (files: any) => {
      if (files.size > 2200000) {
        alert('Please upload an image less than 2MB');
      } else {
        const fileExtension = files.name
          .substring(files.name.lastIndexOf('.') + 1)
          .toLowerCase();
        const formData = new FormData();
        formData.append('file', files);
        formData.append('field', 'PROFILE_IMAGE');
        formData.append('fileExtension', fileExtension);
        const result = await axiosUpload.post('/aws-upload/single', formData);
        if (result.status === 200) {
          insertToEditor(result.data.publicUrl);
        }
      }
    },
    [insertToEditor]
  );

  // async function uploadFile(files: any) {
  //   const fileExtension = files.name
  //     .substring(files.name.lastIndexOf('.') + 1)
  //     .toLowerCase();
  //   const formData = new FormData();
  //   formData.append('file', files);
  //   formData.append('field', 'PROFILE_IMAGE');
  //   formData.append('fileExtension', fileExtension);
  //   const result = await axiosUpload.post('/aws-upload/single', formData);
  //   if (result.status === 200) {
  //     insertToEditor(result.data.publicUrl);
  //   }
  // }

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      if (input.files && quill) {
        const file = input.files[0];
        uploadFile(file);
      }
    };
  }, [quill, uploadFile]);

  // const selectLocalImage = () => {
  //   const input = document.createElement('input');
  //   input.setAttribute('type', 'file');
  //   input.setAttribute('accept', 'image/*');
  //   input.click();

  //   input.onchange = () => {
  //     if (input.files && quill) {
  //       const file = input.files[0];
  //       uploadFile(file);
  //     }
  //   };
  // };

  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
    }
  }, [quill, selectLocalImage]);

  return (
    <div style={{ height: '50vh' }} className='bg-gray-50 mb-20'>
      <div ref={quillRef} className='rounded-b-lg border-none overflow-y-auto max-h-[60vh] bg-gray-50'/>
    </div>
  );
};

export default TextEditorQuill;
