import React from 'react';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

interface QuizScoreProps {
  questionData: any[];
  quizResponses: string[][];
  nextFunc: () => void;
}

const QuizReviewScreen = (props: QuizScoreProps) => {
  // If the max attempts have been used up, use the responses from the previous attempt.
  let quizResponses: string[][] = props.quizResponses;

  // Array of all options for each question in the task.
  // Thus a 2d array [[option1, option2], [...]]
  let optionArr: string[][] = [];
  for (const ques of props.questionData) {
    let quesAnswers = [];

    for (const opt of ques.options) {
      if (opt.value === 1) {
        quesAnswers.push(opt.option);
      }
    }
    optionArr.push(quesAnswers);
  }

  // This takes in a string to allow for multiple selectable questions.
  // TODO: Functionality for questions with maxSelect > 1
  const isCorrect = (quesIdx: number, response: string[]): boolean => {
    // if (ques.maxSelect > 1) {
    //   // TODO: This part doesnt handle multi-select questions.
    //   return false;
    // }

    // Since for maxSelect response.length and correctAnswer.length is always 1
    if (response[0] === optionArr[quesIdx][0]) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <div className='px-2 md:px-6 flex flex-col gap-5 overflow-y-auto lg:max-h-[67vh] max-2xl:max-h-[74vh]'>
        <span className='font-semibold text-lg'>Review your results -</span>
        <div className='flex flex-col gap-3 h-auto overflow-y-auto'>
          {props.questionData.map((ques: any, idx: number) => (
            <div
              key={idx}
              className='border-2 border-gray-200 rounded-lg p-5 gap-4 flex flex-col'>
              <div className='flex flex-row gap-4 justify-between items-start'>
                <span className='font-medium'>
                  {idx + 1}. {ques.question}
                </span>
                <div className='flex flex-row gap-2 items-center'>
                  {isCorrect(idx, quizResponses[idx]) ? (
                    <React.Fragment>
                      <FaCheckCircle className='text-green-500 font-medium' />
                      <span className='text-green-500 font-medium'>
                        Correct
                      </span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <FaTimesCircle className='text-red-500 font-medium' />
                      <span className='text-red-500 font-medium'>
                        Incorrect
                      </span>
                    </React.Fragment>
                  )}
                </div>
              </div>
              <div className='grid grid-cols-6 md:grid-cols-5'>
                <span className='col-span-3 md:col-span-1'>Your Answer</span>
                <span className='col-span-3 md:col-span-4'>
                  : {quizResponses[idx].join(', ')}
                </span>
                <span className='col-span-3 md:col-span-1'>
                  {' '}
                  Correct Answer
                </span>
                <span className='col-span-3 md:col-span-4'>
                  : {optionArr[idx].join(', ')}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
        <button
          onClick={props.nextFunc}
          className={`text-white text-lg px-2 md:px-12 py-2 rounded-lg select-none bg-primary pointer-events-auto`}>
          Finalize Score
        </button>
      </div>
    </React.Fragment>
  );
};

export default QuizReviewScreen;
