import SuperFeedbackCertificate from './SuperFeedbackCertificate';
import Task from './Task';

interface allProps {
  data: any;
  setSelectedTask: Function;
  enrollData: any;
  setCount: Function;
  isEnrolled: boolean;
  userProgress: any;
  setShowFeedback: any;
}

const Tasks = (props: allProps) => {
  // Sort the tasks according to the taskNo before displaying
  let tasks = [...props.data.tasks] || [];
  tasks.sort((a: any, b: any) => {
    return a.taskNo - b.taskNo;
  });
  const superCapstoneId = props.data._id;
  // Check if the task has been completed
  const isCompleted = (currentTaskNo: number): boolean => {
    // Find the task/quiz corresponding to the currentTaskNo.
    // Check whether the quiz has been meets the percentage requirements or the totalAttempts have been used up.
    let currentTaskDone = false;
    let currentQuizDone = false;

    for (const task of props.enrollData?.tasks) {
      if (task.taskNo === currentTaskNo) {
        currentTaskDone = true;
        break;
      }
    }
    for (const quiz of props.enrollData?.quiz) {
      if (quiz.taskNo === currentTaskNo) {
        // If quiz completed or attempts used up
        if (quiz.percentage >= 40 || quiz.totalAttempt > 2) {
          currentQuizDone = true;
        }
        break;
      }
    }

    return currentTaskDone && currentQuizDone;
  };

  return (
    <div className=''>
      {tasks.length > 0 && (
        <table className='w-full'>
          <thead className=''>
            <tr className='bg-slate-100 '>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4 '>
                Task Name
              </th>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4'>
                Hours
              </th>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4'>
                Skills
              </th>
              <th className='border border-slate-300 font-normal text-left p-2 md:px-4'>
                Status
              </th>
            </tr>
          </thead>

          <tbody>
            {tasks.map((task: any, index: number) => (
              <Task
                // If not enrolled, lock everything
                // if first task, dont lock
                // If prevTasks and quiz done dont lock
                isLocked={
                  props.isEnrolled
                    ? task.taskNo === 1
                      ? false
                      : !isCompleted(task.taskNo - 1!)
                    : true
                }
                isCompleted={
                  props.isEnrolled ? isCompleted(task.taskNo) : false
                }
                taskDetail={task}
                key={index}
                setSelectedTask={props.setSelectedTask}
                setCount={() => {
                  props.setCount(index + 1);
                }}
                superCapstoneId={superCapstoneId}
              />
            ))}
            {/* feedback and certificate */}
            <SuperFeedbackCertificate
              enrollData={props.enrollData}
              capstoneData={props.data}
              userProgress={props.userProgress}
              setShowFeedback={props.setShowFeedback}
            />
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Tasks;
