import React from 'react';

const Progress = (props) => {
  return (
    <div>
      <p className='mb-4  text-red-400'>
        Please complete the feedback form to download your certificate
      </p>
      <div className='flex justify-between text-center items-center'>
        <p className='font-semibold text-lg'>Feedback</p>
        <p className='w-32 text-sm border p-2 rounded'>
          Progress: <span className='font-bold'>{props.currentQuestion}</span> /{' '}
          {props.database.length}{' '}
        </p>
      </div>
    </div>
  );
};

export default Progress;
