import { format } from 'date-fns';
import { isEventLive } from '../../helper';

const EventListElement = ({ event }: { event: any }) => {
  const isLive = isEventLive(event.dateTime);
  return (
    <div className='flex justify-between items-center'>
      <div className='flex w-2/3'>
        <p className='shrink-0 mr-2 mt-2 h-2 w-2 rounded-full bg-gray-400'></p>
        <div>
          <p>{event.title}</p>
          <p className='text-xs text-gray-400'>{event.type}</p>
        </div>
      </div>
      <div className='text-xs ml-2 mr-4 shrink-0'>
        <a
          href={event.link}
          rel='noreferrer noopener'
          target='_blank'
          className='cursor-pointer'
        >
          {isLive && (
            <>
              <p className='bg-primary text-white rounded-md px-2'>
                Live
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                  strokeWidth='1.5'
                  stroke='currentColor'
                  className='inline ml-2 w-4 h-4'
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    d='M12.75 19.5v-.75a7.5 7.5 0 00-7.5-7.5H4.5m0-6.75h.75c7.87 0 14.25 6.38 14.25 14.25v.75M6 18.75a.75.75 0 11-1.5 0 .75.75 0 011.5 0z'
                  />
                </svg>
              </p>
              <p className='text-primary underline'>Join Now</p>
            </>
          )}
        </a>
      </div>
      <div className='text-xs text-primary shrink-0'>
        <p>{format(new Date(event.dateTime), 'd LLL')}</p>
        <p>{format(new Date(event.dateTime), 'p')}</p>
      </div>
    </div>
  );
};

export default EventListElement;
