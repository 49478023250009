import React, { useState } from 'react';
import TicketForm from './TicketForm';
import TicketType from './TicketType';

const NewTicket = (props) => {
  const [ticketType, setTicketType] = useState(null);
  const [ticketAssignee, setTicketAssignee] = useState(null);

  return (
    <div>
      {ticketType ? (
        <TicketForm
          ticketType={ticketType}
          ticketAssignee={ticketAssignee}
          userId={props.userId}
          batchId={props.batchId}
          cognitoId={props.cognitoId}
          setTicketType={setTicketType}
          setShowTicket={props.setShowTicket}
          setUpdated={props.setUpdated}
          isCareereraUser={props.isCareereraUser}
        />
      ) : (
        <TicketType
          setShowTicket={props.setShowTicket}
          setTicketType={setTicketType}
          setTicketAssignee={setTicketAssignee}
          userDetails={props.userDetails}
          partner={props.partner}
          isVGE={props.isVGE}
          isFiibBatch={props.isFiibBatch}
          isCareereraUser={props.isCareereraUser}
        />
      )}
    </div>
  );
};

export default NewTicket;
