import React, { useState } from 'react';
import { EventCardType } from '../types';
import { format } from 'date-fns';
import { Dialog } from '@headlessui/react';
import Registeration from './Registeration';
import Modal from 'react-modal';
import EngagementPopup from 'components/dashboard/panel/EngagementPopup';
import classNames from 'classnames';
import EventPopUp from './EventPopUp';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '70%',
    overflowY: 'auto' as 'auto',
    zIndex: 1000,
  },
  overlay: {
    zIndex: 1000,
  },
};

const EventCard = ({ event }: { event: EventCardType }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  const [eventModalIsOpen, setEventModalIsOpen] = useState(false);

  function openEventModal() {
    setEventModalIsOpen(true);
  }

  function closeEventModal() {
    setEventModalIsOpen(false);
  }

  const [isAlreadyRegistered, setisAlreadyRegistered] = useState(
    event.isAlreadyRegistered
  );
  const [showResources, setShowResources] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const eventTime: any = new Date(event.dateTime);
  const nowTime: any = new Date();
  const timediff = (nowTime - eventTime) / 3600000;
  const isEventLive = timediff < 2 && timediff > -1;
  const isPolling =
    event.pollingEvent && timediff < -24 && !isAlreadyRegistered;
  const [isFeedbackRequired, setIsFeedbackRequired] = useState(
    event.isFeedbackRequired
  );
  const recordingUrl = event.recordingUrl;
  const isPastEvent = timediff > 2;

  return (
    <div
      className='bg-white border-2 hover:border-primary hover:bg-primary-lightest pb-4 mb-6 min-h-[300px] -z-10'
      key={event.id}>
      <img
        src={event.imageUrl}
        alt='banner'
        className={classNames(
          'object-cover w-full h-32 border-l-4 border-red-300',
          { grayscale: isPastEvent }
        )}
      />
      <div className=' p-4 grid grid-cols-12 gap-x-1'>
        <div className='col-span-8'>
          <p
            className={classNames('text-primary font-semibold', {
              grayscale: isPastEvent,
            })}>
            {' '}
            {event.dateTime &&
              format(new Date(event.dateTime), 'do LLLL yyyy p')}
          </p>
        </div>
        <div className='col-span-4  text-primary space-y-4 text-center text-xs font-semibold mt-1'>
          {recordingUrl ? (
            <a
              href={recordingUrl}
              target='_blank'
              rel='noreferrer noopener'
              className='border border-primary text-white bg-primary rounded-lg px-6 py-2 w-1/2 md:w-full mx-auto'>
              Recording
            </a>
          ) : isPolling ? (
            <>
              <button
                onClick={() => setIsOpen(true)}
                className={classNames(
                  'border  border-primary rounded-lg px-2 py-2 w-1/2 md:w-3/4 lg:w-full mx-auto',
                  {
                    grayscale: isPastEvent,
                  }
                )}>
                Register Now
              </button>
              <p>Limited Seats Left</p>
            </>
          ) : isPastEvent ? null : (
            <>
              <button
                onClick={openEventModal}
                className='border border-primary bg-white rounded-lg px-6 py-2 w-1/2 md:w-full mx-auto'>
                Join Now
              </button>
              {isEventLive && (
                <div className='text-white bg-primary rounded-lg px-2 py-1 flex space-x-2 justify-center w-1/4 sm:w-1/2 lg:w-3/4  mx-auto'>
                  <p>Live</p>
                  <img
                    src='https://res.cloudinary.com/belong/image/upload/v1670568688/new%20b2b/pajamas_live-stream_vac5rf.svg'
                    alt='wifi-logo'
                  />
                </div>
              )}
            </>
          )}
          {isFeedbackRequired && (
            <button
              onClick={() => openModal()}
              className={classNames(
                'border border-primary bg-white rounded-lg px-2 py-2 w-1/2 md:w-3/4 lg:w-full mx-auto'
              )}>
              Add Event Feedback
            </button>
          )}
        </div>
      </div>
      <div
        className={classNames('md:flex md:space-x-4 items-baseline mx-4', {
          grayscale: isPastEvent,
        })}>
        <div className='flex items-center space-x-2  mt-2'>
          <div className='h-3 w-3 bg-primary rounded-full '></div>
          <p className='text-sm  font-medium'> {event.title}</p>
        </div>
        {/* <div className='flex space-x-1 mt-2 md:mt-0 items-center cursor-pointer text-primary'>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1670569049/new%20b2b/akar-icons_eye_kun9w1.svg'
                alt='eye-logo'
              />
              <p className='text-xs'>View Profile</p>
            </div> */}
      </div>
      <p
        className={classNames('mx-4 text-xs pb-2 text-textColor-lightest', {
          truncate: !showResources,
          grayscale: isPastEvent,
        })}>
        {event.description}
      </p>
      {event.resources && event.resources.length > 0 && (
        <>
          <p
            onClick={() => setShowResources(true)}
            className={classNames(
              'cursor-pointer text-primary font-semibold text-sm pl-4',
              { hidden: showResources, grayscale: isPastEvent }
            )}>
            Show More....
          </p>
          {showResources && (
            <div className={classNames('p-4', { grayscale: isPastEvent })}>
              <div className='flex items-center space-x-2 my-2'>
                <div className='h-3 w-3 bg-primary rounded-full '></div>
                <p className='text-sm  font-medium text-black'>Resources</p>
              </div>

              <div className='flex flex-wrap  gap-x-2'>
                {event.resources?.map((resource, index) => {
                  return (
                    <a
                      key={index}
                      href={resource.link}
                      target='_blank'
                      rel='noreferrer noopener'
                      className='hover:text-blue-400 text-xs underline'>
                      {resource.title}
                    </a>
                  );
                })}
              </div>
              <p
                onClick={() => setShowResources(false)}
                className='cursor-pointer text-primary font-semibold text-sm pl-2 pt-2'>
                Show Less....
              </p>
            </div>
          )}
        </>
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        as='div'
        className='relative z-10'>
        <div className='fixed inset-0 overflow-y-auto overflow-x-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 bg-gray-100 bg-opacity-70'>
            <div>
              <Dialog.Panel className='rounded bg-white m-4 p-4'>
                <Registeration
                  eventData={event}
                  setisAlreadyRegistered={setisAlreadyRegistered}
                  setIsOpen={setIsOpen}
                />
              </Dialog.Panel>
            </div>
          </div>
        </div>
      </Dialog>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}>
        <div>
          <EngagementPopup
            closeModal={closeModal}
            event={event}
            setIsFeedbackRequired={setIsFeedbackRequired}
          />
        </div>
      </Modal>
      <Modal
        isOpen={eventModalIsOpen}
        onRequestClose={closeEventModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel='Example Modal'
        data={{ background: 'gray' }}>
        <div>
          <EventPopUp eventLink={event.link} />
        </div>
      </Modal>
    </div>
  );
};

export default EventCard;
