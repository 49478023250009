import { Fragment, useMemo, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  Bars3Icon,
  HomeIcon,
  UsersIcon,
  FolderIcon,
  XMarkIcon,
  BriefcaseIcon,
  LockClosedIcon,
} from '@heroicons/react/24/outline';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import { Auth } from 'aws-amplify';
import { Link, useNavigate, Outlet, useLocation } from 'react-router-dom';
import { useGetUserQuery } from 'components/profile/userSlice';
import Loading from 'components/global/layout/Loading';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { useGetOnboardingQuery } from 'components/dashboard/panel/panelSlice';
import { allBatches, partnerIds } from 'config/common';

import Breadcrumb from './Breadcrumb';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export default function SuperDashboardLayout() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { data, isLoading, isSuccess } = useGetUserQuery();
  const onboardingData = useGetOnboardingQuery();

  const isTeamUser = data?.roles?.find((el: any) => {
    return ['Admin', 'Team'].includes(el);
  });
  const isNirmanUser = data?.partnerId?._id === partnerIds.nirman;
  const isBelongUser = data?.partnerId?._id === partnerIds.belong;
  const isGsapUser = data?.partnerId?._id === partnerIds.gsap;
  const isEntreprenuship = data?.partnerId?._id === partnerIds.entreprenuship;

  const hideBelongLogo = isGsapUser || isBelongUser;

  const profileImage = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1658898215/uploaded_resources/933-9332131_profile-picture-default-png_hmyowh.jpg';
  const sidebartabs = data?.partnerId?.sidebarTab;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const userBatches = data?.batches || [];
  const aptitudeGiven = onboardingData.data?.aptitude?.totalAttempts > 0;
  const domainGiven = onboardingData.data?.domain?.totalAttempts > 0;
  const profileFilled = onboardingData.data?.profileStrength >= 5; // change it 5
  const isOldConsoleflareUser =
    data?.partnerId?._id === partnerIds.consoleflare &&
    new Date('2023-06-02T00:30:00.000Z') > new Date(data?.createdAt);
  const openCapstones =
    (aptitudeGiven &&
      profileFilled &&
      (domainGiven || isOldConsoleflareUser)) ||
    userBatches.includes(allBatches.deviareMICT) ||
    isEntreprenuship;

  const openSuperCapstones = aptitudeGiven && profileFilled && domainGiven;

  const desktopLogo =
    data?.partnerId?.logoUrl ||
    'https://res.cloudinary.com/belong/image/upload/v1720510170/test/belong-logo_1_rpe9rj.webp';

  const mobileLogo =
    data?.partnerId?.logoUrl ||
    'https://res.cloudinary.com/belong/image/upload/v1716289845/new%20b2b/belong-logo-small_qskofc.png';

  const isEnrolled = Boolean(data?.batches.length);
  const navigation = useMemo(() => {
    const tabs = [
      {
        name: 'Dashboard',
        href: '/',
        icon: HomeIcon,
        current: pathname === '/',
        disabled: false,
        image: '',
      },
    ];

    (sidebartabs?.capstone || userBatches.includes(allBatches.deviareMICT)) &&
      tabs.push({
        name: 'Capstones',
        href: '/capstone',
        icon: UsersIcon,
        current: pathname === '/capstone',
        disabled: !openCapstones,
        image: '',
      });
    // change capstone to supercasptone in the line below
    (isTeamUser || isNirmanUser) &&
      tabs.push({
        name: 'Capstones',
        href: '/supercapstone',
        icon: FolderIcon,
        current: pathname === '/supercapstone',
        disabled: !openSuperCapstones,
        image: '',
      });

    sidebartabs?.vga &&
      tabs.push({
        name: 'Apprenticeship',
        href: '/dashboard',
        icon: XMarkIcon,
        image:
          'https://res.cloudinary.com/belong/image/upload/v1723796635/uploaded_resources/pencil-ruler-2-line_wyqjz0.png',
        current: pathname === '/dashboard',
        disabled: !isEnrolled,
      });

    sidebartabs?.job &&
      tabs?.push({
        name: 'Jobs',
        href: '/jobs',
        icon: BriefcaseIcon,
        current: pathname === '/jobs',
        disabled: false,
        image: '',
      });

    return tabs;
  }, [
    pathname,
    sidebartabs?.capstone,
    sidebartabs?.vga,
    sidebartabs?.job,
    userBatches,
    openCapstones,
    isTeamUser,
    isNirmanUser,
    openSuperCapstones,
    isEnrolled,
  ]);
  //   const url = window.location.hostname;
  //   const isEdRed = url === 'capstone.edred.in';
  // const loginPath = isEdRed ? '/login/edred' : '/login';

  const [extendSidebar, setExtendSidebar] = useState(false);
  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          {/* mobile sidebar */}
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as='div'
              className='relative z-40 lg:hidden '
              onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter='transition-opacity ease-linear duration-300'
                enterFrom='opacity-0'
                enterTo='opacity-100'
                leave='transition-opacity ease-linear duration-300'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'>
                <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
              </Transition.Child>

              <div className='fixed inset-0 z-40 flex'>
                <Transition.Child
                  as={Fragment}
                  enter='transition ease-in-out duration-300 transform'
                  enterFrom='-translate-x-full'
                  enterTo='translate-x-0'
                  leave='transition ease-in-out duration-300 transform'
                  leaveFrom='translate-x-0'
                  leaveTo='-translate-x-full'>
                  <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-white'>
                    <Transition.Child
                      as={Fragment}
                      enter='ease-in-out duration-300'
                      enterFrom='opacity-0'
                      enterTo='opacity-100'
                      leave='ease-in-out duration-300'
                      leaveFrom='opacity-100'
                      leaveTo='opacity-0'>
                      <div className='absolute top-0 right-0 -mr-12 pt-2'>
                        <button
                          type='button'
                          className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                          onClick={() => setSidebarOpen(false)}>
                          <span className='sr-only'>Close sidebar</span>
                          <XMarkIcon
                            className='h-6 w-6 text-white'
                            aria-hidden='true'
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className='h-0 flex-1 overflow-y-auto pt-5 pb-4'>
                      <div className='flex flex-shrink-0 items-center px-4'>
                        <Link to='/'>
                          <img
                            src={desktopLogo}
                            alt='Belong'
                            className='hidden md:block w-20 object-contain'
                          />
                          <img
                            src={mobileLogo}
                            alt='Belong'
                            className='md:hidden w-16 object-contain'
                          />
                        </Link>
                      </div>
                      <nav className='mt-5 space-y-1 px-2'>
                        {navigation.map((item) => (
                          <Link
                            key={item.name}
                            to={item.href}
                            className={classNames(
                              item.disabled
                                ? 'pointer-events-none bg-gray-50 text-gray-400'
                                : item.current
                                ? 'bg-primary-lighter text-gray-900'
                                : 'text-gray-600 hover:bg-primary-lightest hover:text-gray-900',
                              'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                            )}>
                            {item.disabled ? (
                              <LockClosedIcon
                                className={classNames(
                                  'text-gray-300   mr-3 h-6 w-6 flex-shrink-0'
                                )}
                                aria-hidden='true'
                              />
                            ) : item.image ? (
                              <img
                                src={item.image}
                                alt='..'
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 h-6 w-6 flex-shrink-0'
                                )}
                              />
                            ) : (
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? 'text-gray-500'
                                    : 'text-gray-400 group-hover:text-gray-500',
                                  'mr-3 h-6 w-6 flex-shrink-0'
                                )}
                                aria-hidden='true'
                              />
                            )}
                            {item.name}
                          </Link>
                        ))}
                      </nav>
                    </div>
                    <div className='flex flex-shrink-0 border-t border-gray-200 p-4'>
                      <span className='group block flex-shrink-0'>
                        <div className='flex items-center'>
                          <div>
                            <img
                              src={profileImage}
                              alt='profile'
                              className='h-12 w-12 rounded-full'
                            />
                          </div>
                          <div className='mx-3'>
                            <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                              {data?.firstName} {data?.lastName}
                            </p>
                          </div>
                          {/* <FaArrowRightFromBracket
                            onClick={async () => {
                              await Auth.signOut({ global: true });
                              navigate(loginPath, { replace: true });
                            }}
                            className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
                          /> */}
                        </div>
                      </span>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className='w-14 flex-shrink-0'>
                  {/* Force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className='hidden sm:fixed sm:inset-y-0 sm:flex sm:w-fit items-center sm:flex-col border-r border-gray-200'>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            {/* non-extended sidebar */}
            {!extendSidebar && (
              <div className='flex min-h-0 flex-1 flex-col bg-white '>
                <div className='flex  flex-1 flex-col overflow-y-auto overflow-hidden   pb-5 justify-between'>
                  <nav className='mt-3 flex-1  space-y-8 bg-white px-5'>
                    {/* 3 line icon */}
                    <div className='sm:relative'>
                      <button
                        type='button'
                        onClick={() => setExtendSidebar(true)}>
                        <Bars3Icon className='pb-2 mt-2 h-10 ml-1 object-contain' />
                      </button>

                      <div className='sm:absolute -left-12 top-full pt-2 w-[calc(300%-0rem)] border-b border-gray-200'></div>
                    </div>

                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.disabled
                            ? 'pointer-events-none bg-gray-50 text-gray-400'
                            : item.current
                            ? 'bg-primary'
                            : 'text-gray-600 hover:bg-primary-lightest hover:text-gray-900',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                        )}>
                        {item.disabled ? (
                          <LockClosedIcon
                            className={classNames(
                              'text-gray-300 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden='true'
                          />
                        ) : item.image ? (
                          <img
                            src={item.image}
                            alt='..'
                            className={classNames(
                              item.current
                                ? 'text-white'
                                : 'text-black group-hover:text-gray-500',
                              ' h-6 w-6 flex-shrink-0'
                            )}
                          />
                        ) : (
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-white'
                                : 'text-black group-hover:text-gray-500',
                              ' h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden='true'
                          />
                        )}
                        {/* {item.name} */}
                      </Link>
                    ))}
                  </nav>
                  {/* Profile Logo and Logout button here */}
                  <div className='space-y-6 mx-auto'>
                    <div className='relative group ml-2'>
                      <RiLogoutBoxRLine
                        data-tut='logout-icon'
                        onClick={async () => {
                          await Auth.signOut({ global: true });
                          navigate('/login', { replace: true });
                        }}
                        className='text-2xl cursor-pointer font-bold'
                      />
                      <span className='absolute left-1/2 transform -translate-x-1/2 translate-y-3 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded py-1 px-2 transition-opacity duration-300'>
                        Logout
                      </span>
                    </div>
                    <Link
                      data-tut='profile-icon'
                      to='/profile'
                      className='flex items-center gap-2'>
                      <div>
                        <img
                          className='inline-block h-9 w-9 rounded-full'
                          src={profileImage}
                          alt='profile'
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {/* extended sidebar */}
            {extendSidebar && (
              <div className=' flex w-full max-w-xs flex-1 flex-col bg-gray-100 '>
                <div className=' flex-1 overflow-y-auto pt-5 pb-4 '>
                  <button
                    type='button'
                    className='font-semibold pl-5  w-full text-left'
                    onClick={() => setExtendSidebar(false)}>
                    <div className='flex space-x-4 font-semibold'>
                      <p>X</p>
                      <p>Menu</p>
                    </div>
                  </button>
                  <nav className='mt-5 space-y-4 px-2 border-t  pt-4 '>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.disabled
                            ? 'pointer-events-none bg-gray-50 text-gray-400'
                            : item.current
                            ? 'bg-primary text-white'
                            : 'text-black hover:bg-primary-lightest hover:text-gray-900',
                          'group flex items-center rounded-md px-2 py-2 text-sm font-medium'
                        )}>
                        {item.disabled ? (
                          <LockClosedIcon
                            className={classNames(
                              'text-gray-300   mr-3 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden='true'
                          />
                        ) : item.image ? (
                          <img
                            src={item.image}
                            alt='..'
                            className={classNames(
                              item.current
                                ? 'text-gray-500'
                                : 'text-gray-400 group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0'
                            )}
                          />
                        ) : (
                          <item.icon
                            className={classNames(
                              item.current
                                ? 'text-white'
                                : 'text-black group-hover:text-gray-500',
                              'mr-3 h-6 w-6 flex-shrink-0'
                            )}
                            aria-hidden='true'
                          />
                        )}
                        {item.name}
                      </Link>
                    ))}
                  </nav>
                </div>
                <div className='flex flex-col gap-4 flex-shrink-0 border-t border-gray-200 p-4'>
                  {/* logout */}
                  <div
                    onClick={async () => {
                      await Auth.signOut({ global: true });
                      navigate('/login', { replace: true });
                    }}
                    className='flex space-x-2 ml-2 cursor-pointer'>
                    <RiLogoutBoxRLine
                      data-tut='logout-icon'
                      className='text-2xl  font-bold'
                    />
                    <p>Logout</p>
                  </div>
                  <span className='group block flex-shrink-0'>
                    <div className='flex items-center border-t pt-3'>
                      <div>
                        <img
                          src={profileImage}
                          alt='profile'
                          className='h-12 w-12 rounded-full'
                        />
                      </div>
                      <div className='mx-3'>
                        <p className='text-base font-medium text-gray-700 group-hover:text-gray-900'>
                          {data?.firstName} {data?.lastName}
                        </p>
                      </div>
                      {/* <FaArrowRightFromBracket
                            onClick={async () => {
                              await Auth.signOut({ global: true });
                              navigate(loginPath, { replace: true });
                            }}
                            className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
                          /> */}
                    </div>
                  </span>
                </div>
              </div>
            )}
          </div>
          {/* right side from sidebar */}
          <div className='flex flex-1 flex-col '>
            <div className='sticky top-0  bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 sm:hidden'>
              <button
                type='button'
                className='-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500'
                onClick={() => setSidebarOpen(true)}>
                <span className='sr-only'>Open sidebar</span>
                <Bars3Icon className='h-6 w-6' aria-hidden='true' />
              </button>
            </div>
            <main className='flex flex-col h-screen max-h-screen'>
              <Breadcrumb pathname={pathname} extendSidebar={extendSidebar}>
                <div className='flex flex-row gap-4'>
                  <Link to='/' className='leading-none'>
                    <img
                      src={desktopLogo}
                      alt='Belong'
                      className='hidden md:block w-20 object-contain'
                    />
                  </Link>
                  <Link to='/' className='leading-none'>
                    <img
                      src={mobileLogo}
                      alt='Belong'
                      className='md:hidden w-20 object-contain'
                    />
                  </Link>
                  {!hideBelongLogo && (
                    <Link to='/' className='leading-none'>
                      <img
                        src={
                          'https://res.cloudinary.com/belong/image/upload/v1720510170/test/belong-logo_1_rpe9rj.webp'
                        }
                        alt='Belong'
                        className='w-10 object-contain'
                      />
                    </Link>
                  )}
                </div>
              </Breadcrumb>
              <div
                // className={classNames('flex flex-col flex-1 sm:pl-20', {
                //   'sm:pl-56 flex-none': extendSidebar,
                // })}
                className={
                  extendSidebar
                    ? 'flex flex-col flex-1 sm:pl-56'
                    : 'flex flex-col flex-1 sm:pl-20'
                }>
                <Outlet />
              </div>
            </main>
          </div>
        </div>
      )}
    </>
  );
}
