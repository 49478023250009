import React from 'react';

const NewTicketButton = (props) => {
  const buttonStyle =
    ' font-semibold text-sm px-4 py-2 hover:bg-primary-light text-gray-600';
  return (
    <div className='px-4'>
      <button
        className={`${
          props.showNewTicket ? 'bg-primary-light' : 'bg-white '
        } ${buttonStyle}`}
        onClick={() => props.setShowTicket(true)}
      >
        New Query
      </button>
      <button
        className={`${
          !props.showNewTicket ? 'bg-primary-light' : 'bg-white'
        } ${buttonStyle} border-r border-gray-700`}
        onClick={() => props.setShowTicket(false)}
      >
        All Queries
      </button>
    </div>
  );
};

export default NewTicketButton;
