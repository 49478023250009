import htmlparser from 'html-react-parser';

interface ProblemStatementProps {
  data: any;
}

const ProblemStatement = (props: ProblemStatementProps) => {
  return (
    <div>
      {props.data.problemStatement ? (
        <div className=' flex flex-col gap-3'>
          <div className='font-medium'>Problem statement</div>

          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.problemStatement)}
          </div>
        </div>
      ) : (
        <span className=' bg-opacity-60 font-medium rounded-lg'>
          Dataset is currently empty for this Capstone.
        </span>
      )}
    </div>
  );
};

export default ProblemStatement;
