import React from 'react';
import MilestoneCard from './MilestoneCard';

const Milestones = ({
  userData,
  onbordingData,
}: {
  userData: any;
  onbordingData: any;
}) => {
  // let totalMilestones = 2;

  // if (userData?.partnerId?.sidebarTab?.vga) totalMilestones += 1;
  // if (userData?.partnerId?.sidebarTab?.job) totalMilestones += 1;
  // if (userData?.partnerId?.sidebarTab?.capstone) totalMilestones += 1;

  return (
    <div data-tut='Milestones' className='border mr-2 pb-4 px-1 rounded-b-xl'>
      <div className='bg-primary w-full text-white font-semibold text-center rounded-2xl py-1'>
        {/* Milestones To Completion- 1/{totalMilestones} */}
        Milestones To Completion
      </div>
      <div className='border bg-gradient-to-b from-primary-lightest/10 to-white  px-4 md:px-8 mx-2 pb-4 rounded-bl-2xl rounded-br-2xl'>
        <MilestoneCard userData={userData} onbordingData={onbordingData} />
      </div>
    </div>
  );
};

export default Milestones;
