import React from 'react';

interface QuizErrorScreenProps {
  message: string;
  goBackFunc: () => void;
}

const QuizErrorScreen = (props: QuizErrorScreenProps) => {
  return (
    <React.Fragment>
      <div className='w-full px-5'>
        <div className='flex flex-col text-center bg-red-50 px-5 py-6 rounded-lg'>
          <span className='font-medium text-lg'>Something went wrong</span>
          <span className='text-2xl text-red-500'>{props.message}</span>
        </div>
      </div>
      <div className='border-t-2 border-gray-200 py-5 px-6 flex justify-end'>
        <button
          onClick={props.goBackFunc}
          className={`bg-primary text-white text-lg px-12 md:px-12 py-2 rounded-lg`}>
          Go Back
        </button>
      </div>
    </React.Fragment>
  );
};

export default QuizErrorScreen;
