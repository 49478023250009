import React from 'react';
import ReactCountryFlag from 'react-country-flag';

const LeftSection = ({
  batchDetails,
  userDetails,
  isFullJourney,
  isVGE,
  isHeroVired,
  isFiibBatch,
}) => {
  return (
    <div>
      <div className='p-2'>
        <div className='mb-8'>
          <h2 className='text-sm font-bold'>
            {batchDetails.programTitle} with {batchDetails.name}
          </h2>
        </div>
        {isFullJourney && userDetails.company2 ? (
          <>
            <div className='bg-cover bg-no-repeat bg-center mb-8 md:h-auto'>
              <img
                src={userDetails.company2.companyImageUrl}
                alt='Company'
                className='rounded shadow'
              />
            </div>
            <div className='mb-2'>
              <div className='flex justify-between'>
                <h1 className='font-bold'>
                  Full-Time Internship With {userDetails.company2.name}
                </h1>
                <img
                  src={userDetails.company2.logoUrl}
                  alt='icon'
                  className='sm:block h-10 border rounded-lg shadow'
                />
              </div>
            </div>

            <div>
              <div className='flex justify-between'>
                <p className='text-gray-500 italic text-xs'>
                  {userDetails.company2.slogan}
                </p>
                <div className='-mt-4 h-10 border rounded-lg hidden'>
                  <ReactCountryFlag
                    countryCode={userDetails.company2.country.toLowerCase()}
                    svg
                    style={{
                      width: '2em',
                      height: '2em',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className='my-2'>
              <div className='flex justify-between'>
                <h1 className='font-bold'>
                  Prior Apprentice at {userDetails.company.name}
                </h1>
                <img
                  src={userDetails.company.logoUrl}
                  alt='icon'
                  className='sm:block h-10 border rounded-lg shadow'
                />
              </div>
            </div>
            <div className='mb-4'>
              <p className='inline-block p-2 text-xs bg-gray-200 border rounded-full'>
                {userDetails.area.title}
              </p>
            </div>
          </>
        ) : (
          <>
            <div className='bg-cover bg-no-repeat bg-center mb-8 md:h-auto w-3/4'>
              <img
                src={userDetails.company.companyImageUrl}
                alt='Company'
                className='rounded shadow'
              />
            </div>
            {!(isVGE || isHeroVired || isFiibBatch) && (
              <>
                <div className='mb-2'>
                  <div className='flex justify-between'>
                    <h1 className='font-bold'>
                      {batchDetails.programTitle} With{' '}
                      {userDetails.company.name}
                    </h1>
                    <img
                      src={userDetails.company.logoUrl}
                      alt='icon'
                      className='sm:block h-10 border rounded-lg shadow'
                    />
                  </div>
                </div>
                <div className='mb-4'>
                  <p className='inline-block p-2 text-xs bg-gray-200 border rounded-full'>
                    {userDetails.area.title}
                  </p>
                </div>
                <div>
                  <div className='flex justify-between'>
                    <p className='text-gray-500 italic text-xs'>
                      {userDetails.company.slogan}
                    </p>
                    <div className='-mt-4 h-10 border rounded-lg hidden'>
                      <ReactCountryFlag
                        countryCode={userDetails.company.country.toLowerCase()}
                        svg
                        style={{
                          width: '2em',
                          height: '2em',
                        }}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            {isFiibBatch && (
              <div className='mb-4'>
                <p className='inline-block p-2 text-xs bg-gray-200 border rounded-full'>
                  {userDetails.area.title}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LeftSection;
