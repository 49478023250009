import Loading from 'components/global/layout/Loading';
import React from 'react';
import { useVgaTestScoreQuery } from '../VgaQuizSlice';

const Report = ({ batchId }: any) => {
  const { data, isLoading, isSuccess } = useVgaTestScoreQuery({ batchId });

  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
  ];

  function showImage(percentage: number) {
    let imageToShow = images[0];
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1];
    }
    if (percentage < 65) {
      imageToShow = images[2];
    }
    return imageToShow;
  }
  return (
    <div>
      {isLoading && <Loading />}
      {isSuccess && data && (
        <div>
          <div className='m-2 sm:ml-3 rounded'>
            <div className='flex'>
              <div className='bg-primary px-6 py-1 text-white rounded-xl'>
                {' '}
                Quiz Performance - {data?.percentage}%
              </div>
            </div>
            <div className='text-primary font-semibold my-4'>
              {data.domain?.title} Skill Expertise Level
            </div>
            <div className='grid grid-cols-2 gap-2'>
              {data.finalScore.map((skill: any, index: number) => {
                const percentage = Math.round(
                  (skill.obtained * 100) / skill.max
                );
                return (
                  <div
                    key={index}
                    className='my-2 flex items-center bg-primary-lightest col-span-2 sm:col-span-1 rounded-3xl'
                  >
                    <div className='bg-primary rounded-full text-center flex items-center w-12 h-12 px-3 py-1 text-white'>
                      {percentage}%
                    </div>
                    <div className='flex justify-between w-full '>
                      <div className='ml-1 text-sm mt-1'>
                        {skill.skillId?.title}{' '}
                      </div>
                      {percentage > 45 && (
                        <div>
                          <img
                            alt='skill'
                            className='mr-6'
                            src={showImage(percentage)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='my-4'>
              <b>
                *Please note this score is used to compute the VGA Score and
                further your Belong Score
              </b>{' '}
              The consolidated view of the VGA Score will be visible in the
              {' "'}Score & Certification{'" '} Section, while the Belong Score
              will be visible in the profile section post journey completion
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Report;
