import React from 'react';
import CMSection from '../../layout/CMSection';

const Mentor = (props) => {
  return (
    <div>
      <div className='bg-gray-100 p-4'>
        <div className='flex'>
          <div className='h-2 w-2 m-2 bg-primary mb-4'></div>
          <div>
            <h2>
              <b>{props.heading}</b>
            </h2>
          </div>
        </div>
        <CMSection
          imgUrl={props.mentor.imageUrl}
          name={props.mentor.name}
          designation={props.mentor.designation}
          aboutCM={props.mentor.sectionContent[0].contentToPublish}
        />
      </div>
    </div>
  );
};

export default Mentor;
