import { apiSlice } from '../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder)=> ({
    getJobs: builder.query<any,any>({
      query: (arg) => {
        return {
            url: '/opportunities/search?'+arg.search,
            params:{limit:arg.limit}       
          };
      },
      transformResponse:(res:any)=>res.data,
    }),
    getJobBySlug: builder.query<any,any>({
      query: (arg) => {
        return {
            url: '/opportunities',
            params:arg
          };
      },
      transformResponse:(res:any)=>res.data[0],
    }),
    getFeaturedJobs:builder.query({
        query: () => {
            return {
                url: '/opportunities',
                params: {featured:true,limit:0},
              };
          },    transformResponse:(res:any)=>res.data,
    providesTags: ['FeaturedJobs'],
    }),
    getFilters: builder.query<any,any>({
      query: (arg) => {
        return {
            url: '/opportunities/search-count?'+arg,
          };
      },
      transformResponse:(res:any)=>res.data,
    }),
    getApplicationCount: builder.query<any,any>({
      query: (arg) => {
        return {
            url: '/application/applicant-count',
            params: arg,
          };
      },
      transformResponse:(res:any)=>res.data,
    }),
    getUserJobApplications: builder.query<any,void>({
      query: () => '/application',
      transformResponse:(res:any)=>res.data,
    providesTags: ['UserApplications'],
    }),
    applyForJob: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/application`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserApplications'],
    })
  }),
});

export const { useGetJobsQuery,useGetJobBySlugQuery,useGetFeaturedJobsQuery,useGetApplicationCountQuery,useGetFiltersQuery,useGetUserJobApplicationsQuery,useApplyForJobMutation } = extendedApiSlice;