import StartScreenCard from 'components/dashboard/quiz/layout/StartScreenCard';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

const AptStartTest = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  const aptitudeCardProps = {
    stepNo: 'STEP 1',
    stepColor: 'text-black',
    bgColor: 'bg-secondary',
    heading: 'Aptitude Test',
    score: '0 / 40',
    time: ' : 20 Minutes',
    text: 'Communication, Analytical Ability, Reasoning and Self-Awareness & Learnability',
    color: 'to-bgIndigo',
  };

  const content = (
    <>
      <div className='grid grid-cols-12'>
        <div className='lg:col-span-5 col-span-12 -mx-2'>
          <div className='p-2 bg-gradient-to-b from-[#193257] to-[#0A1626] '>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1661327845/b2c/Aptitude_Test_eoqbq4.png'
                alt='belong'
                className='mx-auto mt-8 object-contain h-96'
              />
            </div>
            <p className='text-white text-center text-lg md:text-2xl font-bold mb-12 md:mb-16 md:w-3/5 mx-auto mt-10 md:mt-14'>
              Get Your
              <br />
              <span className='text-primary'> Belong Score </span>
            </p>
          </div>
        </div>
        <div className='lg:col-span-7 col-span-12 mx-auto'>
          <div className=''>
            <StartScreenCard {...aptitudeCardProps} />
          </div>
          <div className='text-center'>
            <button
              onClick={() => {
                openModal();
              }}
              // onClick={() => {
              //   navigate('aptitude-quiz');
              // }}
              className='font-manrope lg:mt-10 mt-6 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-10 px-6 py-2 rounded-xl '>
              Start Now
            </button>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <div className='overflow-hidden rounded-lg shadow-[0px_8px_15px_rgba(158,158,158,0.2)] ring-1 ring-black ring-opacity-5'>
                    <div className='relative bg-white space-y-4 p-4 '>
                      {/* disclaimer */}
                      <p
                        onClick={closeModal}
                        className='absolute top-2 right-4 cursor-pointer font-bold '>
                        X
                      </p>
                      <div className='font-semibold space-y-2'>
                        <p>Disclaimer:</p>
                        <p>
                          Ensure that your internet connection and device are
                          functioning properly, and find a calm environment free
                          from any disturbance for the next 30-40 minutes for
                          the test.
                        </p>
                        <p>
                          Please note that we will not consider any requests to
                          retake the Aptitude Test.
                        </p>
                      </div>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            navigate('aptitude-quiz');
                          }}
                          className='font-manrope lg:mt-10 mt-6 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-10 px-6 py-2 rounded-xl '>
                          Start Aptitude Test
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );

  return content;
};

export default AptStartTest;
