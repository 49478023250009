import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import React from 'react';

const MasterclassDiscussions = () => {
  const { basicDetails } = useBasicDetailsContext();
  const isVge = basicDetails?.isVge;
  const vgeText = isVge ? 'VGE' : 'VGA';
  const cardData = [
    {
      id: 1,
      question: 'What?',
      text: 'Live sessions aimed at enhancing various entrepreneurial and employability skills',
    },
    {
      id: 2,
      question: 'Why?',
      text: `Provides industry insights, tools & knowledge to excel in ${vgeText} and network with guest experts`,
    },
    {
      id: 3,
      question: 'How?',
      text: 'Scheduled live sessions like conceptual and tech sessions, as well as career cafes with industry experts ',
    },
  ];
  return (
    <div className='mt-4 md:mt-6'>
      <p className='font-semibold'>Live Sessions & Schedule</p>
      <div className='grid grid-cols-1 xl:grid-cols-3 gap-4  xl:gap-12  mt-6'>
        {cardData.map((data) => (
          <div
            key={data.id}
            className='flex space-x-2 items-center lg:items-start border rounded-lg p-2 md:p-4 '
          >
            <div className='flex-none font-semibold bg-primary-light  text-white h-12 md:h-14 w-12 md:w-14 text-sm flex items-center justify-center my-auto  rounded-full'>
              {data.question}
            </div>
            <p className='text-sm pl-2'>{data.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MasterclassDiscussions;
