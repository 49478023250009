import React, { useRef, useState } from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../../../../config/API_LINKS';

const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <div className='flex items-center'>
      <input
        className='rounded-full border focus:outline-none w-full px-4 py-1 text-gray-600 text-sm font-semibold'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-red-500 text-xs ml-2'>{meta.error}</div>
      ) : null}
    </div>
  );
};

const Reply = (props) => {
  const [serverResponse, setServerResponse] = useState('');
  const formikRef = useRef();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          ticketId: props.ticketId,
          comment: '',
          owner: 'user',
          status: props.status,
        }}
        validationSchema={Yup.object({
          comment: Yup.string()
            .min(2, 'Must be 3 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a comment'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/ticket/comment',
            data: values,
          })
            .then(function (response) {
              setServerResponse(response.data);
              setSubmitting(false);
              response.status === 200 && resetForm();
              props.setUpdated((prev) => !prev);
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className='py-4 px-2 md:px-6 rounded-xl mt-4'>
            <div className=''>
              <div className='grid grid-cols-12 flex items-center'>
                <div className='col-span-12 md:col-span-10 w-full '>
                  <TextInput name='comment' type='text' placeholder='Comment' />
                </div>
                <div className='col-span-12 md:col-span-2 mt-3 md:mt-0 md:mx-0 text-center mx-auto md:ml-4'>
                  <button
                    type='submit'
                    className='bg-primary font-semibold text-sm px-4 py-1 rounded text-gray-50 hover:text-gray-700 hover:bg-primary-light'
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? 'Processing...' : 'Comment'}
                  </button>
                </div>
                {props.status === 'closed' && (
                  <div className='flex items-center mt-3 col-span-6'>
                    <p className='text-xs'>
                      Do you want to reopen your ticket?
                    </p>
                    <button
                      type='button'
                      onClick={() => setFieldValue('status', 'open')}
                      className={`${
                        values.status === 'open'
                          ? 'bg-primary text-gray-50'
                          : 'bg-white text-gray-600'
                      } ml-6 px-4 py-1 border-1 rounded text-xs`}
                    >
                      Yes
                    </button>
                    <button
                      type='button'
                      onClick={() => setFieldValue('status', 'closed')}
                      className={`${
                        values.status === 'closed'
                          ? 'bg-primary text-gray-50'
                          : 'bg-white text-gray-600'
                      } ml-6 px-4 py-1 border-1 rounded text-xs`}
                    >
                      No
                    </button>
                  </div>
                )}
              </div>
            </div>

            {serverResponse && (
              <div className='m-4 flex text-sm justify-center'>
                {serverResponse}
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Reply;
