import React from 'react';

export const EventCard = (props) => {
  const leftCardStyle = 'ml-0 px-4';
  const rightCardStyle = 'ml-auto pl-4';

  const commonStyle = 'w-1/2 mb-8 pt-0';
  return (
    <div
      className={`${
        props.position === 'left' ? leftCardStyle : rightCardStyle
      } ${commonStyle}`}
    >
      <div className='bg-white p-2 rounded-md shadow-md'>
        <div
          className={`${
            props.position === 'left'
              ? 'flex-col-reverse md:flex-row'
              : 'flex-col-reverse md:flex-row-reverse'
          } flex  md:justify-between text-xs text-center items-center`}
        >
          <h1>{props.heading}</h1>
          <div className='flex flex-col'>
            <h1 className='text-primary'>{props.date}</h1>
            <h1 className='text-primary'>{props.time}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};
