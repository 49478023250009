import { Dialog } from '@headlessui/react';
import NotificationBody from './NotificationBody';

const AllNotifications = ({
  isOpen,
  setIsOpen,
  data,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  data: any;
}) => {
  const content = data.map((notification: any) => {
    return (
      <NotificationBody notification={notification} key={notification._id} />
    );
  });
  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className='relative z-50'
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className='fixed inset-0 bg-black/30' aria-hidden='true' />

      {/* Full-screen scrollable container */}
      <div className='fixed inset-0 overflow-y-auto'>
        {/* Container to center the panel */}
        <div className='flex min-h-full items-center justify-center p-4'>
          {/* The actual dialog panel  */}
          <Dialog.Panel className='mx-auto max-w-lg w-4/5 rounded bg-white p-4 md:p-8'>
            <Dialog.Title className='text-primary font-semibold'>
              All Notifications
            </Dialog.Title>
            <div className='mt-4'>{content}</div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default AllNotifications;
