import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
} from '@heroicons/react/20/solid';
import classNames from 'classnames';

interface allProps {
  data: any;
  setPage: any;
  isFetching: any;
}

const Pagination = (props: allProps) => {
  const totalPages = props.data?.totalPages;
  const currentPage = props.data?.page;

  // Calculate first and last page numbers to display
  let firstPage = Math.max(currentPage - 5, 0);
  // Above code sets the value of firstPage to the maximum of the current page number minus 5 and 0.
  //This ensures that the first page number displayed is at least 0(the first page),
  //and if the current page is close to the beginning of the pagination list,
  //it shows the previous 5 pages.For example, if the current page is 7,
  // firstPage would be set to 2(7 - 5).

  let lastPage = Math.min(currentPage + 4, totalPages - 1);
  //Above code sets the value of lastPage to the minimum of the current page number plus 4 and
  //the total number of pages minus 1. This ensures that the last page number displayed is within
  // the range of available pages, and if the current page is close to the end of the pagination list,
  // it shows the next 4 pages.For example, if the current page is 7, lastPage would be set to 11(7 + 4),
  // but if the total number of pages is 9, lastPage would be set to 8(9 - 1).

  //In above example,where currentPage=7 and number of pages is greater than 10, we have pages from
  //index 2-11 i.e 10 pages. But in case we have totalPages=7 and currentPage=6 ie. last page
  //we will get firstPage=1 and lastPage=6. To make this firstPage=0, we have used the If condition below.

  // Adjust firstPage and lastPage to display 10 pages if possible
  const numPages = lastPage - firstPage + 1;
  if (numPages < 10) {
    if (firstPage === 0) {
      //This If condition keeps the range for 0-9 or 0 to  totalPages - 1, whichever is minimum
      lastPage = Math.min(9, totalPages - 1);
    } else {
      //This else part will set firstPage=0
      firstPage = Math.max(totalPages - 10, 0);
    }
  }

  const pageNumbers = Array.from(Array(totalPages).keys()).slice(
    firstPage,
    lastPage + 1
  );
  return (
    <div
      className={classNames(
        'col-span-1 md:col-span-3',
        {
          hidden: pageNumbers.length < 2,
        },
        {
          'pointer-events-none': props.isFetching,
        }
      )}
    >
      <nav className='flex items-center justify-between border-t border-gray-200 px-4 sm:px-0'>
        <div className='-mt-px flex w-0 flex-1'>
          <p
            onClick={() => {
              props.setPage(0);
            }}
            className={classNames(
              'hidden md:inline-flex cursor-pointer items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
              { 'pointer-events-none': currentPage === 0 }
            )}
          >
            <ArrowLongLeftIcon
              className='mr-3 h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
            First
          </p>
        </div>
        <div className='-mt-px flex'>
          {pageNumbers.map((number) => (
            <p
              key={number}
              onClick={() => {
                props.setPage(number);
              }}
              className={classNames(
                'inline-flex cursor-pointer items-center border-t-2 border-transparent px-2 md:px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                { 'text-primary': currentPage === number }
              )}
            >
              {number + 1}
            </p>
          ))}
        </div>

        <div className='-mt-px flex w-0 flex-1 justify-end'>
          <p
            onClick={() => {
              props.setPage(totalPages - 1);
            }}
            className={classNames(
              'hidden md:inline-flex cursor-pointer items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
              { 'pointer-events-none': currentPage === totalPages - 1 }
            )}
          >
            Last
            <ArrowLongRightIcon
              className='ml-3 h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </p>
        </div>
      </nav>
    </div>
  );
};

export default Pagination;
