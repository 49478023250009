import React from 'react';
import classNames from 'classnames';

const RightSection = ({
  guidebook,
  videoLink,
  partnerId,
  fullTimeGuidebook,
  isVGE,
  isHeroVired,
}) => {
  return (
    <div>
      <div
        className={classNames('p-2 space-y-2 bg-gray-100  md:mt-10', {
          hidden: partnerId !== '610a7ca8d3c51a1458d005ce',
        })}
      >
        <div className='p-2'>
          <div className='flex'>
            <p className='text-primary pt-5 pr-3 md:pr-2 text-sm font-bold'>
              Belong Assistance
            </p>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1643354497/uploaded_resources/Assisstance-Icon_kwxlhe.png'
              className=' m-2 w-10'
              alt='Assistance'
            />
          </div>

          <p className='text-sm pt-2 md:text-xs text-gray-800'>
            For any queries or doubts about your apprenticeship reach out to
            Belong at
          </p>

          <a href='mailto:customersupport@deviare.africa?subject=[VGA]%20Query&cc=connect@belong.education;'>
            <span className='text-primary text-xs md:tracking-tighter'>
              customersupport@deviare.africa
            </span>
          </a>
        </div>
        {fullTimeGuidebook && (
          <div className='flex'>
            <div>
              <img
                src='https://res.cloudinary.com/belong/image/upload/v1643354441/uploaded_resources/Guidebook-Icon_zijhvy.png'
                className=' m-2  w-12'
                alt='Guidebook'
              />
            </div>
            <div className='p-2 ml-3'>
              <p className='text-xs font-bold mb-2'>
                Full-Time Internship Guideline
              </p>
              <p className='text-xs'>
                Lists the best practices to immerse and engage.
                <a
                  href={fullTimeGuidebook}
                  rel='noreferrer noopener'
                  target='_blank'
                >
                  <button
                    type='button'
                    className=' text-primary p-1 hover:bg-primary-light text-xs'
                  >
                    <u>View</u>
                  </button>
                </a>
              </p>
            </div>
          </div>
        )}
        {!isVGE && (
          <>
            <div className='flex'>
              <div>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643354441/uploaded_resources/Guidebook-Icon_zijhvy.png'
                  className=' m-2  w-12'
                  alt='Guidebook'
                />
              </div>
              <div className='p-2 ml-3'>
                <p className='text-xs font-bold mb-2'>
                  Apprenticeship Guidebook
                </p>
                <p className='text-xs'>
                  Provides key features and highlights.
                  <a href={guidebook} rel='noreferrer noopener' target='_blank'>
                    <button
                      type='button'
                      className=' text-primary p-1 hover:bg-primary-light text-xs'
                    >
                      <u>View</u>
                    </button>
                  </a>
                </p>
              </div>
            </div>

            <div className='flex'>
              <div>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643354406/uploaded_resources/Tutorial-Icon_g8tkgi.png'
                  className=' m-2  w-12'
                  alt='Tutorial'
                />
              </div>
              <div className='p-2 ml-2'>
                <p className='text-xs font-bold mb-2'>
                  Dashboard Video Tutorial
                </p>
                <p className='text-xs'>
                  Helps you understand how to use this dashboard
                  <a href={videoLink} rel='noreferrer noopener' target='_blank'>
                    <button
                      type='button'
                      className=' text-primary p-1 hover:bg-primary-light text-xs'
                    >
                      <u>View</u>
                    </button>
                  </a>
                </p>
              </div>
            </div>
            <div className='flex'>
              <div>
                <img
                  src='https://res.cloudinary.com/belong/image/upload/v1643354367/uploaded_resources/FAQ_icon_ip3czl.png'
                  className=' m-2  w-12'
                  alt='Tutorial'
                />
              </div>
              <div className='p-2 ml-2'>
                <p className='text-xs font-bold mb-2'>
                  Frequently Asked Questions
                </p>
                <p className='text-xs'>
                  You can find answers to common dashboard and apprenticeship
                  related queries
                  <a
                    href='/VGA_FAQs.pdf'
                    rel='noreferrer noopener'
                    target='_blank'
                  >
                    <button
                      type='button'
                      className=' text-primary p-1 hover:bg-primary-light text-xs'
                    >
                      <u>View</u>
                    </button>
                  </a>
                </p>
              </div>
            </div>
          </>
        )}
      </div>
      {isHeroVired && (
        <div className='p-2 space-y-2 bg-gray-100  md:mt-10'>
          <div className='p-2'>
            <div className='flex'>
              <p className='text-primary pt-5 pr-3 md:pr-2 text-sm font-bold'>
                Belong Assistance
              </p>
              <img
                src='/Assisstance-Icon.png'
                className=' m-2 w-10'
                alt='Assistance'
              />
            </div>

            <p className='text-sm pt-2 md:text-xs text-gray-800'>
              For any queries or doubts about your journey reach out to Belong
              at
            </p>

            <a href='mailto:connect@belong.education;'>
              <span className='text-primary text-xs md:tracking-tighter'>
                connect@belong.education
              </span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightSection;
