import React from 'react';
import htmlparser from 'html-react-parser';

const UserSolution = ({ solution }: { solution: string }) => {
  return (
    <div className='my-6 px-4'>
      <p className='font-bold mb-4'>Solution</p>
      <div className='prose prose-sm max-w-none text-base bg-primary-lightest -mx-4 p-4 rounded shadow'>
        {htmlparser(solution)}
      </div>
    </div>
  );
};

export default UserSolution;
