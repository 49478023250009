import { Auth } from 'aws-amplify';
import { UPLOAD_OR_DOWNLOAD_URL } from 'config/API_LINKS';
import { AiOutlineLogout } from 'react-icons/ai';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useGetUserQuery } from '../../profile/userSlice';
import Loading from './Loading';

const NoSidebarLayout = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = useGetUserQuery();

  const profileImage = data?.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + data?.imageUrl
    : 'https://res.cloudinary.com/belong/image/upload/v1658898215/uploaded_resources/933-9332131_profile-picture-default-png_hmyowh.jpg';

  const url = window.location.hostname;
  const isEdRed = url === 'capstone.edred.in';
  const loginPath = isEdRed ? '/login/edred' : '/login';
  const edredLogo =
    'https://res.cloudinary.com/belong/image/upload/v1693893154/uploaded_resources/EDRED_LOGO_for_center_with_patch-09_ov7ocr.png';

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && (
        <div>
          <div className='flex justify-between items-center pt-5 pb-4 px-4'>
            <div>
              <Link to='/'>
                <img
                  src={
                    isEdRed
                      ? edredLogo
                      : 'https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
                  }
                  alt='Belong'
                  className='hidden md:block w-40'
                />
                <img
                  src={
                    isEdRed
                      ? edredLogo
                      : 'https://res.cloudinary.com/belong/image/upload/v1643351815/uploaded_resources/logo-small_mtbkf0.png'
                  }
                  alt='Belong'
                  className='md:hidden w-16'
                />
              </Link>
            </div>
            <div className='flex items-center gap-4'>
              <Link to='/profile' className='flex items-center gap-2'>
                <div>
                  <img
                    className='inline-block h-9 w-9 rounded-full'
                    src={profileImage}
                    alt='profile'
                  />
                </div>
                <p className='text-sm font-medium text-gray-700 group-hover:text-gray-900'>
                  {data?.firstName} {data?.lastName}
                </p>
              </Link>
              <AiOutlineLogout
                onClick={async () => {
                  await Auth.signOut({ global: true });
                  navigate(loginPath, { replace: true });
                }}
                className='text-2xl cursor-pointer font-bold text-primary -rotate-90'
              />
            </div>
          </div>
          <div className='h-screen'>
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
};

export default NoSidebarLayout;
