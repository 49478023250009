import React from 'react';
import { formatDistance } from 'date-fns';
import Reply from './Reply';
import IndividualTicket from './IndividualTicket';
import AssigneeComment from './AssigneeComment';
import UserComment from './UserComment';

const TicketInfo = ({
  ticketInfo,
  setTicketDetails,
  setUpdated,
  ticketDetails,
  isCareereraUser,
}) => {
  const ticketInfoDate = formatDistance(
    new Date(ticketInfo.createdAt),
    new Date(),
    {
      addSuffix: true,
    }
  );
  const comment = ticketInfo.activity.filter((act) => {
    return act.comment;
  });
  return (
    <div className='px-2 md:px-4 mt-6'>
      <IndividualTicket
        ticket={ticketInfo}
        comment={comment}
        ticketDate={ticketInfoDate}
        setTicketDetails={setTicketDetails}
        ticketDetails={ticketDetails}
        isCareereraUser={isCareereraUser}
      />
      {comment.length > 0 && (
        <div className='shadow border w-full p-4 rounded-xl mb-3 bg-white'>
          <p className='text-gray-600 font-bold mb-6 text-sm'>Response</p>
          {comment.map((com) => {
            let commentTitle = '';
            if (com.owner === 'user') {
              commentTitle = ticketInfo.user.name
                .split(/\s/)
                .reduce((response, word) => (response += word.slice(0, 1)), '');
            } else {
              commentTitle = ticketInfo.assigneeRef
                ? ticketInfo.assigneeRef
                    .split(/\s/)
                    .reduce(
                      (response, word) => (response += word.slice(0, 1)),
                      ''
                    )
                : '';
            }
            return (
              <>
                {com.owner !== 'user' ? (
                  <AssigneeComment
                    com={com}
                    commentTitle={commentTitle}
                    assigneeRef={ticketInfo.assigneeRef}
                  />
                ) : (
                  <UserComment com={com} commentTitle={commentTitle} />
                )}
              </>
            );
          })}
          <div className=''>
            <Reply
              ticketId={ticketInfo._id}
              status={ticketInfo.status}
              setUpdated={setUpdated}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TicketInfo;
