import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
// import { LoadingSpinner } from '../../../layout/LoadingSpinner';
import AssessmentTimer from './AssessmentTimer';
import Loading from 'components/global/layout/Loading';
import { API_URL } from '../../../../config/API_LINKS';

const FetchQuestion = ({ userDetails, batchId, isCareereraUser }) => {
  const fetchAssessment = async () => {
    const res = await axios.get(API_URL + '/v2/question/random', {
      params: {
        areaId: userDetails.area._id,
      },
    });
    return res;
  };

  const { status, data } = useQuery('fetchDomainAssessment', fetchAssessment);

  return (
    <div>
      {status === 'loading' && <Loading />}
      {status === 'error' && <div>Error....</div>}
      {status === 'success' && (
        <div className='mx-2 md:mx-6'>
          {data.data.data.length < 20 ? (
            <div className='text-center'>
              There is an issue with fetching Questions, Please contact the
              administration.
            </div>
          ) : (
            <AssessmentTimer
              assessmentQuestion={data.data.data}
              batchId={batchId}
              isCareereraUser={isCareereraUser}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default FetchQuestion;
