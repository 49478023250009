import React from 'react';

const AdditionalFeedback = ({ coachNote }: { coachNote: string }) => {
  return (
    <div>
      <p className='font-semibold'>Additional Feedback</p>
      <div className='px-4 sm:px-8  mt-2  text-primary '>
        <ul className='list-disc'>
          <li>{coachNote}</li>
        </ul>
      </div>
    </div>
  );
};

export default AdditionalFeedback;
