import React from 'react';
import { format } from 'date-fns';

import { EventCard } from '../../layout/EventCard';

const Timeline = (props) => {
  const timelineData = props._getTimelineData();
  return (
    <div>
      <div className='mt-8'>
        <div className='relative flex flex-col space-y-4'>
          {timelineData.length !== 0 && (
            <>
              <div className='z-0 absolute w-1 h-full bg-gray-200 mx-auto  left-1/2'></div>
              <div className='flex justify-center items-center'>
                <div className='flex justify-around bg-primary-light border rounded-full w-40 py-3 font-semibold  text-xs'>
                  <p>Events</p>
                  <p>Tasks</p>
                </div>
              </div>
            </>
          )}

          {timelineData.length === 0 && 'Space for your events and tasks'}
          {timelineData.map((event) =>
            event.assignmentId ? (
              <EventCard
                key={event.assignmentId._id}
                position='right'
                heading={event.assignmentId.title}
                date={format(new Date(event.dateTime), 'do LLLL')}
                time={format(new Date(event.dateTime), 'p')}
              />
            ) : (
              <EventCard
                key={event.eventId._id}
                position='left'
                heading={event.eventId.title}
                date={format(new Date(event.dateTime), 'do LLLL')}
                time={format(new Date(event.dateTime), 'p')}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
