import React from 'react';
import IndividualQuery from './IndividualQuery';

const QueryCard = ({
  SetIsReplySelected,
  data,
}: {
  SetIsReplySelected: any;
  data: any;
}) => {
  return (
    <div className='my-6 md:my-10'>
      <div className='mt-6'>
        {data?.map((ticket: any) => {
          return (
            <IndividualQuery
              key={ticket._id}
              SetIsReplySelected={SetIsReplySelected}
              ticket={ticket}
            />
          );
        })}
      </div>
    </div>
  );
};

export default QueryCard;
