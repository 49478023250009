import React from 'react';

const CheckLink = () => {
  return (
    <div className='h-screen grid place-items-center'>
      <p>Incorrect URL. Please check the link and try again.</p>
    </div>
  );
};

export default CheckLink;
