import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addVgaFeedback: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/batch/vga-feedback',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
    }),
  }),
});

export const { useAddVgaFeedbackMutation } = extendedApiSlice;
