import React from 'react';
import InstructionsText from './layout/InstructionsText';
import { useStartAptitudeTestMutation } from './AptitudeQuizSlice';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

type instructionsProps = {
  data: any;
};

const AptitudeInstructions = (props: instructionsProps) => {
  const [loading, setLoading] = useState(false);
  const [startAptitudeTest] = useStartAptitudeTestMutation();
  function startAptitude() {
    setLoading(true);
    startAptitudeTest({ responses: props.data });
  }
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  return (
    <>
      <div className='mb-8'>
        <InstructionsText data={props.data} />
        <div className='flex justify-center mt-4 sm:mt-12'>
          <button
            disabled={loading}
            onClick={() => {
              openModal();
            }}
            // onClick={() => {
            //   startAptitude();
            // }}
            className='px-12 py-2 font-semibold text-xl text-primary border border-gray-400 rounded-xl shadow'>
            {loading ? 'Please Wait' : 'Start Test'}
          </button>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'>
                <Dialog.Panel className='w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all'>
                  <div className='overflow-hidden rounded-lg shadow-[0px_8px_15px_rgba(158,158,158,0.2)] ring-1 ring-black ring-opacity-5'>
                    <div className='relative bg-white space-y-4 p-4 '>
                      {/* disclaimer */}
                      <p
                        onClick={closeModal}
                        className='absolute top-2 right-4 cursor-pointer font-bold '>
                        X
                      </p>
                      <div className=' space-y-2'>
                        <p className='font-semibold'>Note:</p>
                        <p>
                          Ensure your internet connection and device are
                          functioning properly.
                        </p>
                        <p>
                          Find a calm, disturbance-free environment for the next
                          30-40 minutes during the test.
                        </p>
                        <p className='font-medium'>
                          Please be aware that requests to retake the Aptitude
                          Test will not be considered.
                        </p>
                      </div>
                      <div className='text-center'>
                        <button
                          onClick={() => {
                            startAptitude();
                          }}
                          // onClick={() => {
                          //   navigate('aptitude-quiz');
                          // }}
                          className='font-manrope lg:mt-10 mt-6 text-primary font-bold text-xl border-2 border-[#878787]/50 lg:px-10 px-6 py-2 rounded-xl '>
                          Start Aptitude Test
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AptitudeInstructions;
