import React from 'react';

const Report = ({ userDetails }) => {
  const reportData = userDetails.vgaQuizData;
  return (
    <div className='bg-white p-2 md:p-10'>
      <div className='bg-gray-100'>
        <div className='text-center font-semibold py-6'>
          You have successfully submited your domain specific quiz assessment
        </div>
        <div className='w-80 mx-auto mt-4'>
          <div className='grid grid-cols-4 gap-2 pb-6'>
            <div className='col-span-3'>Number of correct answer </div>
            <div>
              : <span className='ml-3 text-primary'>{reportData.score}/20</span>{' '}
            </div>
            <div className='col-span-3'>Number of incorrect answer </div>
            <div>
              :{' '}
              <span className='ml-3 text-red-400'>
                {20 - reportData.score}/20
              </span>{' '}
            </div>
            <div className='col-span-3'>Score Percentage </div>
            <div>
              :{' '}
              <span className='ml-3 text-primary'>{reportData.score * 5}%</span>{' '}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
