import React from 'react';
import htmlparser from 'html-react-parser';

const TemplateText = ({ template }: { template: any }) => {
  return (
    template?.description &&
    template?.link && (
      <>
        <div className='flex justify-between '>
          <p className='font-semibold text-lg'>Template</p>
          <a
            href={template?.link}
            target='_blank'
            rel='noreferrer noopener'
            className='flex space-x-2 items-center'
          >
            <p className='text-primary font-medium underline'>Download</p>
            <img
              src='https://res.cloudinary.com/belong/image/upload/v1670306846/new%20b2b/Group_d1dx9s.svg'
              alt='download-logo'
            />
          </a>
        </div>
        <div className='px-4 prose prose-sm max-w-none text-base'>
          {htmlparser(template?.description)}
        </div>
      </>
    )
  );
};

export default TemplateText;
