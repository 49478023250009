import Modal from 'react-modal';
import { customStyles } from '../modalStyles';

const ApplyPopup: React.FC<{
  showApplyPopup: boolean;
  setShowApplyPopup: Function;
}> = ({ showApplyPopup, setShowApplyPopup }) => {
  //Modal should open if canApplyToJob is false

  function closeModal() {
    setShowApplyPopup(false);
  }

  return (
    <Modal
      isOpen={showApplyPopup}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
      data={{ background: 'gray' }}
    >
      <div>text</div>
    </Modal>
  );
};

export default ApplyPopup;
