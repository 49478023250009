import { useImmerReducer } from 'use-immer';
import { AptitudeTestResponsesContext } from 'hooks/useAptitudeTestResponseContext';
import Assessments from './assessments';
import { initialState } from './utils/types';
import { reducer } from './utils/helper';

type startProps = {
  data: any;
};

const StartAptitudeTestScreen = (props: startProps) => {
  const aptitudeResponsesInitialState: initialState = {
    overallTimeLeft: { minutes: 0, seconds: 0 },
    categoryTimeLeft: { minutes: 0, seconds: 0 },
    currentCategoryNo: 0,
    currentQuestion: 0,
    responses: props.data.responses,
    totalQuestions: props.data.responses[0].questions.length,
  };
  const [state, dispatch] = useImmerReducer(
    reducer,
    aptitudeResponsesInitialState
  );
  return (
    <AptitudeTestResponsesContext.Provider value={{ state, dispatch }}>
      <Assessments data={props.data} />
    </AptitudeTestResponsesContext.Provider>
  );
};

export default StartAptitudeTestScreen;
