import classNames from 'classnames';
import React from 'react';
import useVgaTestResponsesContext from 'hooks/useVgaTestResponsesContent';
import { useFinishVgaTestMutation } from '../../VgaQuizSlice';

const ShowQuestion: React.FC = () => {
  const { state, dispatch } = useVgaTestResponsesContext();
  const questionToShow = state.responses[state.currentQuestion];
  const [finishTest, { isLoading }] = useFinishVgaTestMutation();
  const batchId = state.batchId;

  function finishTestFn() {
    finishTest({ batchId, source: 'B2B' })
      .unwrap()
      .then((res: any) => {
        window.location.reload();
      });
  }
  return (
    <div>
      <p className='font-semibold p-4'>
        <span className='text-secondary'>{`${
          state.currentQuestion + 1
        }. `}</span>
        {`${questionToShow.question}`}
      </p>
      <div className='grid grid-flow-col auto-cols-fr gap-4'>
        {questionToShow.image?.map((imageName: any) => {
          return (
            <img
              key={imageName}
              className='rounded shadow h-full'
              src={imageName}
              alt='Question'
            />
          );
        })}
      </div>
      {questionToShow.options.map((option: any) => {
        return (
          <div
            onClick={() => {
              dispatch({
                type: 'UPDATE_OPTIONS',
                payload: { option: option._id, value: !option.selected },
              });
            }}
            className={classNames(
              'm-4  rounded py-2 px-4',
              {
                'bg-secondary': option.selected,
              },
              {
                'bg-gray-100': !option.selected,
              }
            )}
            key={option._id}
          >
            {option.option}
          </div>
        );
      })}
      <div className='flex justify-between mx-4 mt-6 text-primary font-semibold'>
        <div>
          <button
            onClick={() => {
              dispatch({
                type: 'UPDATE_CURRENT_QUESTION',
                payload: state.currentQuestion - 1,
              });
            }}
            className={classNames('text-secondary font-semibold', {
              hidden: !state.currentQuestion,
            })}
          >
            Previous
          </button>
        </div>

        <button
          onClick={() => {
            dispatch({
              type: 'UPDATE_CURRENT_QUESTION',
              payload: state.currentQuestion + 1,
            });
          }}
          className={classNames(
            'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
            { hidden: state.currentQuestion + 1 === state.totalQuestions }
          )}
        >
          Next
        </button>

        <button
          onClick={() => {
            finishTestFn();
          }}
          disabled={isLoading}
          className={classNames(
            'border border-gray-400 px-8 py-1 rounded-xl shadow bg-white',
            { hidden: state.currentQuestion + 1 < state.totalQuestions }
          )}
        >
          {isLoading ? 'Submitting' : 'Finish'}
        </button>
      </div>
    </div>
  );
};

export default ShowQuestion;
