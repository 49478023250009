import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { TextInput } from '../../layout/FormElements';
import moment from 'moment';
import Deadline from './Deadline';
import NotSubmitted from '../../layout/NotSubmitted';
import { API_URL } from '../../../../config/API_LINKS';

const buttonStyle =
  'shadow bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600';
const userResponseStyle =
  'p-4 bg-gray-500 text-white text-center shadow rounded';

const AddTimeTable = ({
  batchDetails,
  userDetails,
  weekDates,
  selectedWeek,
  setDataloading,
  cognitoId,
  userId,
}) => {
  const batchId = batchDetails._id;

  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  const [loading, setLoading] = useState(false);
  const fieldToUpdate = 'TIMESHEET_SUBMISSION';

  const catagory = [
    ' Mentor Meeting',
    'Coach Connect',
    'Internship Work',
    'Self Learning',
    'Career Cafe',
  ];

  const mytimeValue = weekDates.map((myDate) => {
    return {
      date: moment(myDate.date, 'DD/MM/YYYY'),
      hours: '',
    };
  });

  const myReport = catagory.map((cat) => {
    return {
      eventCode: cat,
      description: '',
      timeValues: mytimeValue,
    };
  });

  const totalAmount = function (data, day) {
    const mydata = data.map((cat) => {
      return cat.timeValues[day].hours;
    });
    var totalSum = 0;
    for (var i = 0; i < mydata.length; i++) {
      if (mydata[i] !== '') {
        totalSum += mydata[i];
      }
    }
    return totalSum;
  };

  const myFun = () => {
    setServerResponse('file Uploaded ');
    setDataloading((data) => !data);
  };

  const checkForDaily = (report) => {
    for (let i = 0; i < 5; i++) {
      if (totalAmount(report, i) > 8) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <Deadline deadline={weekDates[4]} batchDetails={batchDetails} />
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          batchId: batchId,
          userId: userId,
          week: selectedWeek,
          report: myReport,
        }}
        validationSchema={Yup.object({
          report: Yup.array().of(
            Yup.object().shape({
              timeValues: Yup.array().of(
                Yup.object().shape({
                  hours: Yup.number()
                    .min(0, 'daily hours 0-8')
                    .max(8, 'daily hours 0-8'),
                })
              ),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          if (checkForDaily(values.report)) {
            alert('Daily Working hours Not more then 8');
            setSubmitting(false);
          } else {
            axios({
              method: 'POST',
              url: API_URL + '/timesheet',
              data: values,
            })
              .then(function (response) {
                setServerResponse(response.data.msg);
                setSubmitting(false);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form className='space-y-12 bg-gray-50 p-4 rounded-xl'>
            <div>
              <table className='table-fixed border-collapse border border-gray-400'>
                <thead>
                  <tr className='bg-primary-light'>
                    <th className='w-3/12 border-collapse border border-gray-300'>
                      Category
                    </th>
                    <th className='w-4/12  border-collapse border border-gray-300'>
                      Description
                    </th>
                    {weekDates.map((myDate) => {
                      return (
                        <th
                          key={myDate.day}
                          className='w-1/12 p-2  border-collapse border border-gray-300'
                        >
                          <p>{myDate.date}</p>
                          <p>{myDate.day}</p>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {values.report.map((cat, index) => {
                    return (
                      <tr className='text-center' key={index}>
                        <td className='py-3 border-collapse border border-gray-300'>
                          {cat.eventCode}
                        </td>
                        <td className='py-3 border-collapse border border-gray-300'>
                          <TextInput
                            name={`report[${index}].description`}
                            type='text'
                            placeholder='Description'
                            className='w-full p-2'
                          />
                        </td>

                        {cat.timeValues.map((date, idx) => {
                          return (
                            <td
                              key={idx}
                              className='py-3 border-collapse border border-gray-300'
                            >
                              <TextInput
                                name={`report[${index}].timeValues[${idx}].hours`}
                                type='number'
                                placeholder='Hours'
                                className='w-full p-2'
                              />
                              {totalAmount(values.report, idx) > 8 && (
                                <div className='text-sm text-red-400'>
                                  Daily hour must be less then 8
                                </div>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}

                  <tr className='bg-primary-light'>
                    <td></td>
                    <td>
                      Total Hours ={' '}
                      {totalAmount(values.report, 0) +
                        totalAmount(values.report, 1) +
                        totalAmount(values.report, 2) +
                        totalAmount(values.report, 3) +
                        totalAmount(values.report, 4)}
                      /40
                    </td>
                    <td className='py-3 text-center border-collapse border border-gray-300'>
                      {totalAmount(values.report, 0)}
                    </td>
                    <td className='py-3 text-center border-collapse border border-gray-300'>
                      {totalAmount(values.report, 1)}
                    </td>
                    <td className='py-3 text-center border-collapse border border-gray-300'>
                      {totalAmount(values.report, 2)}
                    </td>
                    <td className='py-3 text-center border-collapse border border-gray-300'>
                      {totalAmount(values.report, 3)}
                    </td>
                    <td className='py-3 text-center border-collapse border border-gray-300'>
                      {totalAmount(values.report, 4)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='border border-gray-300 text-center grid grid-cols-3'>
              <div className='border border-gray-300 p-4'>
                Weekly Work Report
              </div>

              <div className='text-center text-xs'>
                <NotSubmitted
                  fieldToUpdate={fieldToUpdate}
                  fileFormat='pdf'
                  refetch={myFun}
                  uniqueUserId={cognitoId}
                  loading={loading}
                  setLoading={setLoading}
                  week={selectedWeek}
                  batchId={batchId}
                  userId={userId}
                />
              </div>
              <div className='text-center p-4'>
                <button
                  type='submit'
                  className={buttonStyle}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? 'Processing...' : 'Save'}
                </button>
              </div>
            </div>

            {serverResponse && (
              <div className={userResponseStyle}>{serverResponse}</div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTimeTable;
