import { useState, useEffect } from 'react';
import { format } from 'date-fns';
import AlreadySubmitted from './AlreadySubmitted';
import NotSubmitted from './NotSubmitted';
import TaskDetails from './TaskDetails';
import { useGetUserBatchQuery } from 'components/dashboard/vga/userBatchSlice';
import { UPLOAD_OR_DOWNLOAD_URL } from '../../../config/API_LINKS';

const Tasks = (props) => {
  const { data, refetch, isFetching } = useGetUserBatchQuery({});
  const uniqueUserId = data?._id;
  const [submittedFile, setSubmittedFile] = useState(false);
  const [submittedFileName, setSubmittedFileName] = useState('');
  const [loading, setLoading] = useState(false);
  //To identify action at the endpoint
  const fieldToUpdate = 'TASK_SUBMISSION';
  let isPublish = true;
  if (props.publishAt) {
    const publishAtTime = new Date(props.publishAt);
    const nowTime = new Date();
    if (publishAtTime > nowTime) {
      isPublish = false;
    }
  }
  useEffect(() => {
    const result = props.taskSubmissions.find(
      (submission) => submission.assignmentId === props.assignmentId
    );
    if (result) {
      setSubmittedFile(true);
      setSubmittedFileName(result.submittedFile);
    } else {
      setSubmittedFile(false);
      setSubmittedFileName('');
    }
  }, [props.taskSubmissions, props.assignmentId]);
  return (
    <div className='bg-gray-100 p-4 relative'>
      <div className='grid grid-cols-1 md:grid-cols-4 mb-10'>
        <TaskDetails
          heading={props.heading}
          about={props.about}
          fileLink={props.fileLink}
          aboutpdf={props.aboutpdf}
          duedate={props.duedate}
        />
        {submittedFile ? (
          <AlreadySubmitted
            fileName={submittedFileName}
            fieldToUpdate={fieldToUpdate}
            UPLOAD_OR_DOWNLOAD_URL={UPLOAD_OR_DOWNLOAD_URL}
            refetch={refetch}
            uniqueUserId={uniqueUserId}
            loading={loading || isFetching}
            setLoading={setLoading}
          />
        ) : (
          <NotSubmitted
            assignmentId={props.assignmentId}
            fieldToUpdate={fieldToUpdate}
            fileFormat='pdf'
            refetch={refetch}
            uniqueUserId={uniqueUserId}
            loading={loading || isFetching}
            setLoading={setLoading}
          />
        )}
      </div>
      {!isPublish && (
        <div className='absolute h-full w-full top-0 left-0 bg-black opacity-40 flex justify-center items-center border shadow rounded text-lg'>
          <p className='text-white font-bold'>
            Open On :{format(new Date(props.publishAt), 'do LLLL p')}
          </p>
        </div>
      )}
    </div>
  );
};

export default Tasks;
