import React from 'react';

const UngradedSection = () => {
  const criterias = [
    {
      id: 1,
      heading: 'Problem Statement Comprehension and Analysis',
      text: 'Ability to construct the problem statement, identify the various challenges and structure the holistic view',
      Required: 'Analytical and Critical Thinking',
    },
    {
      id: 2,
      heading: 'Potential Solutions Evaluation',
      text: 'Ability to conduct research, identify approaches and evaluate potential solutions with insightful explanation',
      Required: 'Research and Creative Problem Solving',
    },
    {
      id: 3,
      heading: 'Solution Startegy and Implementation',
      text: 'Ability to review logic/reasoning, examines feasibility of solution and weigh impacts of solution',
      Required: 'Domain Knowledge and Skill Applicability',
    },
    {
      id: 4,
      heading: 'Challenge Addressal',
      text: 'Ability to proactively identify the challenge in the implementation and suggest & revise the solution ',
      Required: 'Commitment',
    },
    {
      id: 5,
      heading: 'Outcome Evaluation',
      text: 'Ability to conclude results relative to the problem defined with thorough considerations on need for further work',
      Required: 'Domain Expertise',
    },
  ];
  return (
    <div className='mt-4 md:mt-6'>
      <div className='sm:flex justify-between '>
        <p className='font-semibold text--light text-lg'>Score & Feedback</p>
      </div>
      <div className='my-4 lg:my-8 '>
        {criterias.map((criteria) => {
          return (
            <div
              key={criteria.id}
              className='grid grid-cols-12 md:grid-flow-col gap-2 mb-4 sm:mb-2   text-textColor-lighter'
            >
              <div className='col-span-12 md:col-span-6 border rounded-lg  p-2 md:p-4 '>
                <p className='font-medium mb-2 text-black'>
                  {criteria.heading}
                </p>
                <p className='text-sm'>{criteria.text}</p>
              </div>
              <div className='col-span-10 md:col-span-4  border rounded-lg  p-2 md:p-4'>
                <div className='flex items-center h-full '>
                  <p className='text-sm sm:text-base'>{criteria.Required}</p>
                </div>
              </div>
              <div className='col-span-2  border rounded-lg  p-2 '>
                <div className='flex items-center justify-center h-full '>
                  <p className='font-medium text-primary'>Yet to be graded</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UngradedSection;
