import React from 'react';
import { BiFilter } from 'react-icons/bi';

const Header: React.FC<{ showFilters: boolean; setShowFilters: Function }> = ({
  showFilters,
  setShowFilters,
}) => {
  return (
    <div className='grid sm:hidden'>
      <div className='flex justify-end text-gray-400'>
        <div>
          <BiFilter
            onClick={() => {
              setShowFilters(!showFilters);
            }}
            className='cursor-pointer hover:text-black text-3xl'
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
