import React from 'react';
import { MasterclassConnectType } from '../types';
import MasterclassCard from './layout/MasterclassCard';
import MasterclassDiscussions from './layout/MasterclassDiscussions';
import MasterclassHeader from './layout/MasterclassHeader';
import QuickTipsSection from './layout/QuickTipsSection';

const MasterclassPanel = ({ eventData }: MasterclassConnectType) => {
  return (
    <div className='md:border rounded-b-lg text-textColor-lighter '>
      <MasterclassHeader eventData={eventData} />
      <div className='px-4 md:px-8 space-y-14'>
        <MasterclassDiscussions />
        <QuickTipsSection />
        <MasterclassCard eventData={eventData} />
      </div>
    </div>
  );
};

export default MasterclassPanel;
