import { apiSlice } from '../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserBatch: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/user/user-batch',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['UserBatch'],
    }),
    getUserProject: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/vga-submission',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['UserSubmission'],
    }),
    submitTask: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/vga-submission',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserSubmission'],
    }),
    addPolling: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/event/polling',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserBatch'],
    }),
    addEventFeedback: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/event/add-feedback',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserBatch'],
    }),
    getCountries: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/countrytimezones/countries',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
    }),
    addViewDataset: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/vga-project/view-dataset',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserBatch'],
    }),
    getUserTimeSheet: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/timesheet/user',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['UserTimeSheet'],
    }),
    addTimeSheet: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/timesheet',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['UserTimeSheet'],
    }),
    editTimeSheet: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: '/timesheet',
          method: 'PUT',
          body,
        };
      },
      invalidatesTags: ['UserTimeSheet'],
    }),
  }),
});

export const {
  useGetUserBatchQuery,
  useGetUserProjectQuery,
  useSubmitTaskMutation,
  useAddPollingMutation,
  useAddEventFeedbackMutation,
  useGetCountriesQuery,
  useAddViewDatasetMutation,
  useGetUserTimeSheetQuery,
  useAddTimeSheetMutation,
  useEditTimeSheetMutation
} = extendedApiSlice;
