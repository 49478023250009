import classNames from 'classnames';

const ToggleBar: React.FC<{
  setIsAllCapstone: Function;
  isAllCapstone: Boolean;
}> = ({ setIsAllCapstone, isAllCapstone }) => {
  return (
    <div className='flex justify-between items-baseline '>
      <div className='space-x-4 md:space-x-8'>
        <button
          onClick={() => {
            setIsAllCapstone(true);
          }}
          className={classNames(
            'text-sm md:text-base px-2 md:px-6 py-1 rounded shadow',
            {
              'bg-primary-light': isAllCapstone,
            },
            {
              'bg-white': !isAllCapstone,
            }
          )}
        >
          All Capstones
        </button>
        <button
          onClick={() => {
            setIsAllCapstone(false);
          }}
          className={classNames(
            'text-sm md:text-base px-2 md:px-6 py-1 rounded shadow',
            {
              'bg-primary-light': !isAllCapstone,
            },
            {
              'bg-white': isAllCapstone,
            }
          )}
        >
          My Capstones
        </button>
      </div>
      <div className='hidden'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1661951537/capstone/image_238_eoehqs.png'
          alt='burger-icon'
        />
      </div>
    </div>
  );
};

export default ToggleBar;
