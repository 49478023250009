import React, { useEffect, useState } from 'react';
import { CoachConnectType } from '../types';
import AboutCoach from './layout/AboutCoach';
import CoachCard from './layout/CoachCard';
import CoachDiscussions from './layout/CoachDiscussions';
import CoachDataHeader from './layout/CoachDataHeader';
import QuickTipsSection from './layout/QuickTipsSection';
import NotificationBanner from './layout/notifications/NotificationBanner';
import AllNotifications from './layout/notifications/AllNotifications';
import { useGetAnnouncementsQuery } from './notificationsSlice';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';

const CoachSupportPanel = ({
  aboutData,
  eventData,
  batchId,
}: CoachConnectType & { batchId: string }) => {
  let [isOpen, setIsOpen] = useState(false);
  const [notificationToShow, setNotificationToShow] = useState(null);

  const { dispatch } = useBasicDetailsContext();

  const { isSuccess, data } = useGetAnnouncementsQuery({ batchId: batchId });

  useEffect(() => {
    const newNotifications = data?.filter((notification: any) => {
      return !notification.viewStatus;
    });
    dispatch({
      type: 'UPDATE_COACH_NOTIFICATIONS',
      payload: newNotifications?.length || 0,
    });
    if (newNotifications?.length) {
      //Set only the first notification to alert
      setNotificationToShow(newNotifications[0]);
    } else {
      setNotificationToShow(null);
    }
  }, [data, dispatch]);

  return (
    <div className=' md:border rounded-b-lg text-textColor-lighter '>
      <CoachDataHeader eventData={eventData} />
      <div className='px-4 md:px-8 space-y-14'>
        {isSuccess && notificationToShow && (
          <NotificationBanner
            setIsOpen={setIsOpen}
            notificationToShow={notificationToShow}
          />
        )}
        <AboutCoach
          {...aboutData}
          setIsOpen={setIsOpen}
          notificationsExist={data?.length}
        />
        <CoachDiscussions />
        <QuickTipsSection />
        <CoachCard eventData={eventData} />
        {isSuccess && (
          <AllNotifications isOpen={isOpen} setIsOpen={setIsOpen} data={data} />
        )}
      </div>
    </div>
  );
};

export default CoachSupportPanel;
