export const allQuestions = [
  {
    domainId: '60bf4d0963dbccf5133ea70e', 
    questions: [
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Confirm that the selected VPN Gateway SKU is compatible with the target Virtual Network and supports required protocols (e.g., IKEv2, IPsec).',
          },
          {
            value: 0,
            option: 'Modify Network Security Group (NSG) rules by adjusting inbound and outbound traffic policies.',
          },
          {
            value: 0,
            option: 'Analyze subnet and IP addressing configurations for any overlapping or restrictive address ranges that may interfere.',
          },
          {
            value: 0,
            option: 'Restart Virtual Machines associated with the VPN and inspect the route tables for misconfigurations.',
          },
        ],
        question: 'A VPN Gateway is set up for securely connecting remote networks, yet connection issues persist. What initial troubleshooting step is most effective in isolating the cause of the problem?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Inspect subnet IP address ranges to identify if any NAT configurations are blocking external traffic.',
          },
          {
            value: 1,
            option: 'Evaluate NSG priority settings to identify conflicting rules that may override HTTP permissions.',
          },
          {
            value: 0,
            option: 'Review user permissions in Active Directory for network access inconsistencies.',
          },
          {
            value: 0,
            option: 'Check VPN Gateway settings for misconfigured routing rules impacting HTTP traffic.',
          },
        ],
        question: 'A web application has HTTP traffic allowed through its Network Security Group (NSG), yet access is still restricted. What should be the next step in troubleshooting this issue?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Network peering configuration',
          },
          {
            value: 0,
            option: 'IP forwarding on Virtual Machines',
          },
          {
            value: 1,
            option: 'VPN Gateway connection type as IPsec',
          },
          {
            value: 0,
            option: 'Private IP configuration',
          },
        ],
        question: 'A network team is configuring a VPN Gateway for secure remote access. Which parameter should be set to ensure encryption between on-premises and cloud networks?',
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Use multiple VMs within an Availability Set',
          },
          {
            value: 0,
            option: 'Assign a single public IP to each VM',
          },
          {
            value: 0,
            option: 'Increase the storage capacity of individual VMs',
          },
          {
            value: 0,
            option: 'Configure VMs with a static IP address',
          },
        ],
        question: 'A cloud administrator needs to set up VMs for high availability. Which approach should be used to minimize downtime during maintenance events?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Apply individual Network Security Groups (NSGs) to each VM for custom rule sets.',
          },
          {
            value: 1,
            option: 'Attach NSGs directly to the subnet, allowing centralized traffic control for all VMs within it.',
          },
          {
            value: 0,
            option: 'Implement route tables for each VM with isolated configurations for traffic flow management.',
          },
          {
            value: 0,
            option: 'Enable logging and monitoring across VMs to track and analyze traffic patterns.',
          },
        ],
        question: 'An organization requires fine-grained control over traffic directed at VMs within a specific subnet to improve security and management. Which configuration approach is most effective?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Enable MFA for all users',
          },
          {
            value: 1,
            option: 'Set up Conditional Access policies targeting specific user groups',
          },
          {
            value: 0,
            option: 'Apply Identity Protection at a high-risk level',
          },
          {
            value: 0,
            option: 'Use Azure AD B2C for MFA requirements',
          },
        ],
        question: 'A new policy requires multi-factor authentication (MFA) for certain users. Which Azure AD configuration best applies this policy selectively?',
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Dynamic membership in user groups',
          },
          {
            value: 0,
            option: 'Privileged Identity Management (PIM)',
          },
          {
            value: 0,
            option: 'Single sign-on (SSO)',
          },
          {
            value: 0,
            option: 'Resource groups',
          },
        ],
        question: 'If an application requires users to be divided by a job function with different access rights, which Azure AD feature should be used?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Azure Diagnostics',
          },
          {
            value: 1,
            option: 'Alerts in Azure Monitor',
          },
          {
            value: 0,
            option: 'Role-Based Access Control',
          },
          {
            value: 0,
            option: 'Azure AD Access Reviews',
          },
        ],
        question: 'A company needs to receive immediate notifications when resource usage exceeds a threshold. Which Azure feature should be configured?',
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Azure Policy to define and audit rule sets across subscriptions.',
          },
          {
            value: 0,
            option: 'Azure Blueprint to set up pre-configured environments with compliance controls.',
          },
          {
            value: 0,
            option: 'Use DevTest Labs for sandbox testing of policies before deployment.',
          },
          {
            value: 0,
            option: 'Configure Application Gateway to manage and monitor resource compliance.',
          },
        ],
        question: 'A cloud administrator aims to enforce compliance across multiple Azure resources by applying organizational policies. Which tool provides a centralized solution for consistent policy enforcement?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS CloudTrail',
          },
          {
            value: 1,
            option: 'AWS Migration Hub',
          },
          {
            value: 0,
            option: 'AWS CloudFormation',
          },
          {
            value: 0,
            option: 'AWS IAM',
          },
        ],
        question: 'A cloud architect is planning a migration for an on-premises application with multiple dependencies on different sub-systems. Which AWS service would provide a comprehensive overview to assess application dependencies and facilitate the migration process?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Create a single public subnet and use an Internet Gateway for all traffic routing.',
          },
          {
            value: 1,
            option: 'Implement a multi-tier VPC with both public and private subnets, placing application servers in the private subnet.',
          },
          {
            value: 0,
            option: 'Use a VPC with only public subnets for ease of access to resources.',
          },
          {
            value: 0,
            option: 'Deploy all resources in a default VPC and use security groups to control access.',
          },
        ],
        question: 'A development team is setting up an application on AWS with multiple tiers that require isolation of internal resources. Which VPC architecture best supports secure separation?',
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Amazon S3',
          },
          {
            value: 0,
            option: 'Amazon EC2 instance store',
          },
          {
            value: 0,
            option: 'Amazon EBS',
          },
          {
            value: 0,
            option: 'AWS Lambda',
          },
        ],
        question: 'While setting up storage on AWS, a database instance needs access to static files regularly. Which storage solution is best suited to deliver high availability and low-latency access to the application?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS IAM Roles',
          },
          {
            value: 1,
            option: 'AWS Organizations',
          },
          {
            value: 0,
            option: 'AWS IAM Policies',
          },
          {
            value: 0,
            option: 'AWS CloudFormation',
          },
        ],
        question: 'During migration, it is necessary to assign permissions for specific teams handling different AWS services. To manage permissions dynamically across accounts without adding new policies, which AWS service would best manage this task?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Attach a security group that allows access to DynamoDB',
          },
          {
            value: 0,
            option: 'Use IAM user credentials in the application code',
          },
          {
            value: 1,
            option: 'Assign an IAM role to the EC2 instance with DynamoDB access permissions',
          },
          {
            value: 0,
            option: 'Store the access key in S3 and retrieve it during application startup',
          },
        ],
        question: 'An application running on EC2 instances requires access to data stored in DynamoDB. Which approach would you use to securely grant permissions without embedding keys in the application code?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS CloudTrail',
          },
          {
            value: 1,
            option: 'AWS CloudWatch',
          },
          {
            value: 0,
            option: 'AWS Config',
          },
          {
            value: 0,
            option: 'AWS Trusted Advisor',
          },
        ],
        question: 'In a scenario where a migration process requires continuous monitoring and tracking of resource utilization, which AWS service offers insights into performance metrics and resource health?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS Budgets',
          },
          {
            value: 1,
            option: 'AWS Cost Explorer',
          },
          {
            value: 0,
            option: 'AWS CloudTrail',
          },
          {
            value: 0,
            option: 'AWS IAM',
          },
        ],
        question: 'An AWS environment is experiencing unexpected cost increases due to data transfer charges. Which feature in AWS can help to analyze and identify the source of these charges?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS Elastic Load Balancer',
          },
          {
            value: 1,
            option: 'AWS Auto Scaling',
          },
          {
            value: 0,
            option: 'AWS S3',
          },
          {
            value: 0,
            option: 'AWS RDS Multi-AZ',
          },
        ],
        question: 'Your web application hosted on EC2 has experienced increased traffic. Which AWS feature can be implemented to automatically manage scaling requirements based on traffic?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Attach AdministratorAccess policy directly to each user',
          },
          {
            value: 1,
            option: 'Use an IAM role with AdministratorAccess and assign it to users as needed',
          },
          {
            value: 0,
            option: 'Create custom policies with limited access for each service',
          },
          {
            value: 0,
            option: 'Allow root account access for flexibility',
          },
        ],
        question: 'While configuring IAM for a team working on data migration, certain users need administrative access to all AWS resources. What\'s the best practice for assigning these permissions?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use only Amazon S3 Standard for all data',
          },
          {
            value: 1,
            option: 'Combine Amazon S3 Standard with S3 Intelligent-Tiering',
          },
          {
            value: 0,
            option: 'Use S3 Glacier for quick access',
          },
          {
            value: 0,
            option: 'Store files in Amazon RDS',
          },
        ],
        question: 'To enable secure storage and quick retrieval of frequently accessed files in AWS, which combination of storage classes would provide cost-efficiency and accessibility?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Deploy resources manually before using CloudFormation',
          },
          {
            value: 1,
            option: 'Include IAM role definitions in the CloudFormation template',
          },
          {
            value: 0,
            option: 'Use default IAM roles provided by AWS',
          },
          {
            value: 0,
            option: 'Run the application directly on local servers',
          },
        ],
        question: 'A team is preparing to deploy an application using CloudFormation templates. To ensure the application can access all necessary AWS resources, which strategy should be followed?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Store sessions in EC2 instance storage',
          },
          {
            value: 0,
            option: 'Use Amazon S3 to store session data',
          },
          {
            value: 1,
            option: 'Store sessions in DynamoDB with a caching layer',
          },
          {
            value: 0,
            option: 'Use AWS Lambda for session persistence',
          },
        ],
        question: 'A web application hosted on AWS requires session state management for scaling. Which AWS service or configuration supports session persistence without affecting scalability?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'AWS DataSync',
          },
          {
            value: 0,
            option: 'Amazon CloudFront',
          },
          {
            value: 0,
            option: 'AWS CLI',
          },
          {
            value: 1,
            option: 'AWS Snowball',
          },
        ],
        question: 'During a migration, a data team needs to upload large datasets to AWS. Which method will offer the most reliable and cost-effective way to transfer these large data files?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Add a route for 0.0.0.0/0 in the private subnet',
          },
          {
            value: 1,
            option: 'Attach an Elastic IP to the EC2 instance and add it to a public subnet',
          },
          {
            value: 0,
            option: 'Update the application code to enable internet access',
          },
          {
            value: 0,
            option: 'Enable AWS CloudTrail logging',
          },
        ],
        question: 'A developer is testing a newly deployed application on EC2 and notices it\'s not accessible over the internet. What configuration change is likely required?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Reserved Instances',
          },
          {
            value: 0,
            option: 'On-Demand Instances',
          },
          {
            value: 1,
            option: 'Spot Instances',
          },
          {
            value: 0,
            option: 'Savings Plans',
          },
        ],
        question: 'To optimize costs for fluctuating workloads with occasional high traffic, which EC2 pricing model offers the best balance between cost and availability?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Hard-code region-specific values',
          },
          {
            value: 1,
            option: 'Use parameters and mappings for region-specific configurations',
          },
          {
            value: 0,
            option: 'Configure IAM policies for each region manually',
          },
          {
            value: 0,
            option: 'Use direct resource IDs from each region',
          },
        ],
        question: 'A team is setting up CloudFormation templates to automate resource creation. What should they include to ensure the templates can be easily reused across multiple AWS regions?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use S3 bucket policies for public access',
          },
          {
            value: 1,
            option: 'Attach an IAM role to the EC2 instance with S3 access permissions',
          },
          {
            value: 0,
            option: 'Store access keys on the EC2 instance',
          },
          {
            value: 0,
            option: 'Enable public access to the S3 bucket',
          },
        ],
        question: 'You need to ensure that an EC2 instance can securely access an S3 bucket without exposing access keys. What\'s the best approach?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Assign the AdministratorAccess policy to the EC2 instance role',
          },
          {
            value: 1,
            option: 'Create a custom IAM role with permissions only for necessary services',
          },
          {
            value: 0,
            option: 'Use the root account for access control',
          },
          {
            value: 0,
            option: 'Disable all access control policies for easier testing',
          },
        ],
        question: 'An EC2 instance needs to access several AWS services, but you want to limit permissions to what\'s strictly necessary. What\'s the recommended approach to manage this?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use Azure VM with a static number of instances',
          },
          {
            value: 1,
            option: 'Configure autoscale settings in Azure App Services based on CPU and memory thresholds',
          },
          {
            value: 0,
            option: 'Set up a fixed App Service Plan with high-tier pricing',
          },
          {
            value: 0,
            option: 'Deploy Azure Functions to handle all requests',
          },
        ],
        question: 'A team is setting up an e-learning platform on Azure and requires automatic scaling during peak hours. Which approach should they take to ensure scalability and minimize costs?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use a flat storage structure with randomized file names',
          },
          {
            value: 1,
            option: 'Create separate Blob containers for each category',
          },
          {
            value: 0,
            option: 'Store all content in a single container and use metadata for categorization',
          },
          {
            value: 0,
            option: 'Use Azure Table Storage for storing metadata and Blob Storage for files',
          },
        ],
        question: 'To efficiently organize content in Azure Blob Storage by categories like "videos," "documents," and "images," which design practice would best support structured storage and retrieval?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Azure Analysis Services with offline processing',
          },
          {
            value: 1,
            option: 'Azure Stream Analytics connected to real-time event sources',
          },
          {
            value: 0,
            option: 'Azure SQL Database with scheduled jobs',
          },
          {
            value: 0,
            option: 'Azure Synapse Analytics for batch processing',
          },
        ],
        question: 'In creating a performance tracking dashboard for students, which Azure service allows real-time data processing to calculate metrics like "time spent on course"?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Direct Query',
          },
          {
            value: 0,
            option: 'Import Mode',
          },
          {
            value: 1,
            option: 'Partitioning',
          },
          {
            value: 0,
            option: 'Elastic Pools',
          },
        ],
        question: 'In an e-learning dashboard, an educator needs to analyze student progress dynamically. Which feature in Azure Analysis Services provides high performance and fast loading for this purpose?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Create separate user accounts in SQL Database for each role',
          },
          {
            value: 1,
            option: 'Use Azure Active Directory to implement Role-Based Access Control (RBAC)',
          },
          {
            value: 0,
            option: 'Configure Shared Access Signatures on Blob Storage for each role',
          },
          {
            value: 0,
            option: 'Use Network Security Groups to restrict access',
          },
        ],
        question: 'A platform has multiple user roles: student, educator, and administrator. Which Azure service feature ensures each role has appropriate access?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use SQL Server Authentication with firewalls enabled',
          },
          {
            value: 1,
            option: 'Set up a VNet Service Endpoint for Azure SQL Database',
          },
          {
            value: 0,
            option: 'Use a publicly accessible SQL Server',
          },
          {
            value: 0,
            option: 'Enable Geo-Redundancy on Azure SQL',
          },
        ],
        question: 'An application requires secure access to Azure SQL Database only from Azure App Services. What is the best practice to achieve this?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use Azure Blob Storage to store the deployment files',
          },
          {
            value: 1,
            option: 'Configure deployment slots in Azure App Service',
          },
          {
            value: 0,
            option: 'Transfer database contents using Azure File Storage',
          },
          {
            value: 0,
            option: 'Use Azure AD to manage deployment groups',
          },
        ],
        question: 'When moving an application from development to production, which Azure tool provides a quick and consistent method for deployment?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Conditional Access Policies',
          },
          {
            value: 0,
            option: 'Azure AD Identity Protection',
          },
          {
            value: 1,
            option: 'Self-Service Password Reset (SSPR)',
          },
          {
            value: 0,
            option: 'Just-in-Time Access',
          },
        ],
        question: 'To efficiently manage authentication for students and educators with minimal administrative overhead, which Azure AD feature would be most effective?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Locally Redundant Storage (LRS)',
          },
          {
            value: 1,
            option: 'Geo-Zone Redundant Storage (GZRS)',
          },
          {
            value: 0,
            option: 'Zone Redundant Storage (ZRS)',
          },
          {
            value: 0,
            option: 'Geo-Redundant Storage (GRS)',
          },
        ],
        question: 'An educational platform processes large sets of multimedia data. What Azure Blob Storage replication option provides both high availability and protection against data loss?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Enable Always Encrypted feature in Azure SQL',
          },
          {
            value: 1,
            option: 'Enable Transparent Data Encryption (TDE)',
          },
          {
            value: 0,
            option: 'Enable storage account encryption',
          },
          {
            value: 0,
            option: 'Apply Virtual Network Service Endpoints',
          },
        ],
        question: 'To maintain compliance with data privacy regulations, how should personal data be encrypted in Azure SQL Database?',
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Implement a single high-performance SQL Database in one region',
          },
          {
            value: 1,
            option: 'Use Azure Front Door with global load balancing',
          },
          {
            value: 0,
            option: 'Increase the storage tier of Blob Storage',
          },
          {
            value: 0,
            option: 'Configure Point-in-Time Restore for databases',
          },
        ],
        question: 'To reduce latency for a geographically diverse user base accessing the e-learning platform, which configuration is most effective?',
      },
    ],
  },
  {
    domainId: '60bf4d6763dbccf5133ea710', //Data Science
    questions: [
      {
        image: '',
        options: [
          {
            value: 0,
            option: `ColumnTransformer([
     ("num", StandardScaler(), make_column_selector(dtype_include="number")),
     ("cat", OneHotEncoder(), make_column_selector(dtype_include="object"))
   ]) | LogisticRegression()`
          },
          {
            value: 1,
            option: `Pipeline([
     ("preprocessor", ColumnTransformer([
       ("num", StandardScaler(), make_column_selector(dtype_include="number")),
       ("cat", OneHotEncoder(), make_column_selector(dtype_include="object"))
     ])),
     ("classifier", LogisticRegression())
   ])`
          },
          {
            value: 0,
            option: 'ColumnTransformer([StandardScaler(), OneHotEncoder()]).fit_transform(df)'
          },
          {
            value: 0,
            option: 'make_pipeline(StandardScaler(), OneHotEncoder(), LogisticRegression())'
          }
        ],
        question: 'Given a DataFrame df with both categorical and numerical features, construct a preprocessing pipeline that standardizes numerical features, applies one-hot encoding to categorical features, and then fits a Logistic Regression model.'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'return pd.concat([df.shift(i) for i in range(1, lags+1)], axis=1)'
          },
          {
            value: 1,
            option: 'return pd.concat([df[col].shift(i).rename(f"{col}_lag_{i}") for i in range(1, lags+1)], axis=1)'
          },
          {
            value: 0,
            option: 'return df.shift(lags)'
          },
          {
            value: 0,
            option: 'return [df.shift(i) for i in range(1, lags+1)]'
          }
        ],
        question: 'You are working with a time-series dataset in df, and you need to create lag features for a forecasting model. Construct a function to create lag features for a specified number of lags.'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: `Pipeline([
     ("scaler", StandardScaler()),
     ("poly", PolynomialFeatures(degree=3)),
     ("model", RidgeCV())
   ])`
          },
          {
            value: 0,
            option: 'make_pipeline(PolynomialFeatures(3), StandardScaler(), RidgeCV())'
          },
          {
            value: 0,
            option: `Pipeline([
     ("poly", PolynomialFeatures(degree=3)),
     ("scaler", StandardScaler()),
     ("model", RidgeCV())
   ])`
          },
          {
            value: 0,
            option: `Pipeline([
     ("scaler", PolynomialFeatures()),
     ("poly", StandardScaler(degree=3)),
     ("model", RidgeCV())
   ])`
          }
        ],
        question: 'Construct a Pipeline that performs feature scaling, polynomial feature generation up to the 3rd degree, and finally fits a Ridge Regression model with cross-validation.'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'GridSearchCV(RandomForestClassifier(), {"n_estimators": [50, 100], "max_depth": [None, 10]}, cv=5).fit(X, y).best_params_'
          },
          {
            value: 0,
            option: 'GridSearchCV(estimator=RandomForestClassifier(), param_grid={"n_estimators": [50, 100], "max_depth": [None, 10]}, cv=5).fit(X, y).best_estimator_'
          },
          {
            value: 0,
            option: 'GridSearchCV(estimator=RandomForestClassifier(), param_grid={"n_estimators": [50, 100], "max_depth": [None, 10]}, cv=5).fit(X, y).best_score_'
          },
          {
            value: 0,
            option: 'GridSearchCV(RandomForestClassifier(), {"n_estimators": [50, 100], "max_depth": [None, 10]}).fit(X, y)'
          }
        ],
        question: 'To evaluate model performance on a cross-validated grid search for RandomForestClassifier, select the code snippet that correctly implements a grid search on n_estimators and max_depth, and extracts the best parameters.'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: `Pipeline([
     ("smote", SMOTE()),
     ("scaler", StandardScaler()),
     ("classifier", DecisionTreeClassifier())
   ])`
          },
          {
            value: 0,
            option: 'make_pipeline(SMOTE(), StandardScaler(), DecisionTreeClassifier())'
          },
          {
            value: 0,
            option: `Pipeline([
     ("preprocessing", ColumnTransformer([("smote", SMOTE(), []), ("scaler", StandardScaler(), make_column_selector(dtype_include="number"))])),
     ("classifier", DecisionTreeClassifier())
   ])`
          },
          {
            value: 0,
            option: `Pipeline([
     ("scaler", StandardScaler()),
     ("smote", SMOTE()),
     ("classifier", DecisionTreeClassifier())
   ])`
          }
        ],
        question: 'For a dataset with imbalanced classes, implement a pipeline that applies SMOTE for oversampling followed by scaling and fitting a DecisionTreeClassifier.'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'plt.plot([param["max_depth"] for param in grid.cv_results_["params"]], grid.cv_results_["mean_test_score"])'
          },
          {
            value: 1,
            option: 'sns.lineplot(x=grid.cv_results_["param_max_depth"], y=grid.cv_results_["mean_test_score"], hue=grid.cv_results_["param_n_estimators"])'
          },
          {
            value: 0,
            option: 'plt.scatter(grid.cv_results_["param_max_depth"], grid.cv_results_["mean_test_score"])'
          },
          {
            value: 0,
            option: 'sns.scatterplot(x=grid.cv_results_["param_n_estimators"], y=grid.cv_results_["param_max_depth"], size=grid.cv_results_["mean_test_score"])'
          }
        ],
        question: 'Given a GridSearchCV instance called grid, which code snippet will plot the model\'s accuracy for each value of max_depth and n_estimators tested?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'sns.barplot(x=model.feature_importances_.sort_values()[-5:], y=range(5))'
          },
          {
            value: 0,
            option: 'sns.barplot(x=sorted(model.feature_importances_[:5]), y=model.feature_names[:5])'
          },
          {
            value: 0,
            option: 'sns.barplot(x=sorted(model.feature_importances_[-5:]), y=model.feature_names[-5:])'
          },
          {
            value: 1,
            option: 'sns.barplot(x=model.feature_importances_, y=np.array(model.feature_names_)[model.feature_importances_.argsort()[-5:]])'
          }
        ],
        question: 'Write code that will extract and plot the top 5 features from RandomForestClassifier by importance, with each bar representing the relative importance.'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'TimeSeriesSplit(n_splits=3).split(X, y)'
          },
          {
            value: 0,
            option: 'KFold(n_splits=3).split(X, y)'
          },
          {
            value: 1,
            option: 'cross_val_score(model, X, y, cv=TimeSeriesSplit(n_splits=3))'
          },
          {
            value: 0,
            option: 'TimeSeriesSplit().fit(model, X, y)'
          }
        ],
        question: 'In the context of time-series cross-validation, select the correct implementation of TimeSeriesSplit for evaluating a regression model with 3 splits on X and y.'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'df["above_mean"] = df["amount"] > df["amount"].mean()'
          },
          {
            value: 1,
            option: 'df["above_mean"] = (df["amount"] > df["amount"].mean()).astype(int)'
          },
          {
            value: 0,
            option: 'df["above_mean"] = np.where(df["amount"] > df.mean(), 1, 0)'
          },
          {
            value: 0,
            option: 'df["above_mean"] = df.apply(lambda x: 1 if x["amount"] > x["amount"].mean() else 0)'
          }
        ],
        question: 'For a given DataFrame df, create a column indicating if the values in the "amount" column are above or below the mean, and add it as a binary column "above_mean".'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'df[\'Event_Count\'] = df.groupby(\'ID\')[\'Event_Flag\'].apply(lambda x: x.cumsum().shift())'
          },
          {
            value: 1,
            option: 'df[\'Event_Count\'] = df.groupby(\'ID\')[\'Event_Flag\'].transform(lambda x: (x == 1).cumsum().shift().fillna(0))'
          },
          {
            value: 0,
            option: 'df[\'Event_Count\'] = df.groupby(\'ID\')[\'Date\'].diff().dt.days.fillna(0)'
          },
          {
            value: 0,
            option: 'df[\'Event_Count\'] = (df[\'Event_Flag\'].cumsum() - df.groupby(\'ID\')[\'Event_Flag\'].cumsum())'
          }
        ],
        question: 'You are engineering a feature that tracks the number of occurrences of a particular event for each unique identifier in your data. Which of the following code snippets efficiently creates a column called Event_Count, assuming the data is already sorted by ID and Date?'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: `predictions = model.predict(X_test)
print(confusion_matrix(y_test, predictions))
print(classification_report(y_test, predictions))`
          },
          {
            value: 0,
            option: `predictions = model.predict(X_test)
print(ConfusionMatrix(y_test, predictions))
print(PrecisionRecall(y_test, predictions))`
          },
          {
            value: 0,
            option: `predictions = model.predict(X_test)
print(confusion_matrix(predictions, y_test))
print(metrics(y_test, predictions))`
          },
          {
            value: 0,
            option: `predictions = model.predict_proba(X_test)[:, 1]
print(confusion_matrix(predictions, y_test))
print(classification_report(y_test, predictions))`
          }
        ],
        question: 'After training a classification model, you want to evaluate it using a confusion matrix and calculate metrics like precision and recall. Which code correctly generates these evaluation metrics?'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'RandomizedSearchCV(estimator=LogisticRegression(class_weight="balanced"), param_distributions=param_dist, n_iter=10, cv=5).fit(X, y)'
          },
          {
            value: 0,
            option: 'RandomizedSearchCV(LogisticRegression(class_weight="balanced"), param_dist=param_dist, n_iter=10, cv=5).fit(X, y)'
          },
          {
            value: 0,
            option: 'RandomizedSearchCV(LogisticRegression(), param_distributions=param_dist, n_iter=10, cv=5).fit(X, y)'
          },
          {
            value: 0,
            option: 'RandomizedSearchCV(estimator=LogisticRegression(), param_distributions=param_dist, n_iter=10, cv=5).fit(X, y)'
          }
        ],
        question: 'You have a list of hyperparameters and need to train a LogisticRegression model on a balanced dataset with RandomizedSearchCV. Select the correct code snippet.'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Dropping all features with null values'
          },
          {
            value: 0,
            option: 'Using a low-variance threshold to remove features'
          },
          {
            value: 1,
            option: 'Applying Recursive Feature Elimination (RFE) with a Random Forest model'
          },
          {
            value: 0,
            option: 'Removing features based solely on correlation analysis'
          }
        ],
        question: 'You\'re analyzing a dataset with a high number of features, many of which may not be significant for a predictive model. To reduce dimensionality, which approach would best help retain the most informative features?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Scaling the data using Min-Max scaling'
          },
          {
            value: 1,
            option: 'Applying a log transformation'
          },
          {
            value: 0,
            option: 'Replacing outliers with the median value of the column'
          },
          {
            value: 0,
            option: 'Normalizing with StandardScaler'
          }
        ],
        question: 'You notice that a continuous variable has extreme outliers. However, these outliers might be legitimate values important to the analysis. Which transformation method is most suitable to reduce the effect of outliers without removing them?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Decreasing the threshold for the positive class'
          },
          {
            value: 1,
            option: 'Using a model evaluation metric like F1 score or AUC-ROC instead of accuracy'
          },
          {
            value: 0,
            option: 'Oversampling only the majority class'
          },
          {
            value: 0,
            option: 'Applying cross-validation only to the minority class'
          }
        ],
        question: 'You need to classify customers who are likely to buy a product, but the target class is highly imbalanced. Which of the following strategies is most effective for improving model performance?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Dropping both features'
          },
          {
            value: 0,
            option: 'Applying dimensionality reduction techniques like PCA'
          },
          {
            value: 1,
            option: 'Selecting the feature with the highest correlation with the target variable'
          },
          {
            value: 0,
            option: 'Removing the feature that contributes least to the mean of the dataset'
          }
        ],
        question: 'After performing feature engineering, you discover that two engineered features are highly correlated. Including both may lead to multicollinearity. Which method can best help in selecting the more predictive feature?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Histogram'
          },
          {
            value: 0,
            option: 'Pair plot'
          },
          {
            value: 1,
            option: 'Box plot with hue set for categories'
          },
          {
            value: 0,
            option: 'Heatmap of correlations'
          }
        ],
        question: 'In an EDA, you find a strong relationship between two continuous variables and the target variable. Which visualization would best show if this relationship changes across different categories?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Exhaustive Grid Search'
          },
          {
            value: 1,
            option: 'Randomized Search with a limited number of iterations'
          },
          {
            value: 0,
            option: 'K-Fold Cross-Validation without tuning'
          },
          {
            value: 0,
            option: 'Exhaustive Grid Search with cross-validation on all features'
          }
        ],
        question: 'During model tuning, you want to optimize hyperparameters for a Random Forest model but have computational constraints. Which technique is most efficient for finding the optimal hyperparameters?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Directly analyzing feature importances from the Random Forest model'
          },
          {
            value: 1,
            option: 'Calculating SHAP (Shapley Additive Explanations) values'
          },
          {
            value: 0,
            option: 'Using Principal Component Analysis (PCA)'
          },
          {
            value: 0,
            option: 'Selecting features based on their correlation with the target'
          }
        ],
        question: 'You\'re tasked with interpreting the feature importance from a tree-based model to better understand what drives predictions. Which of the following approaches provides the most reliable insights?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Normalizing all features'
          },
          {
            value: 0,
            option: 'Using polynomial features'
          },
          {
            value: 1,
            option: 'Applying Ridge regularization'
          },
          {
            value: 0,
            option: 'Increasing the learning rate'
          }
        ],
        question: 'You\'re building a logistic regression model, but multicollinearity among features is impacting model performance. What technique can help resolve this?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Using first-order differencing'
          },
          {
            value: 0,
            option: 'Applying a simple moving average'
          },
          {
            value: 1,
            option: 'Using seasonal decomposition of time series (STL)'
          },
          {
            value: 0,
            option: 'Normalizing data with Min-Max scaling'
          }
        ],
        question: 'In a time-series dataset, seasonal trends vary across different intervals. Which preprocessing technique best prepares the data for a forecasting model?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Increasing the classification threshold'
          },
          {
            value: 1,
            option: 'Decreasing the classification threshold'
          },
          {
            value: 0,
            option: 'Using accuracy as the primary evaluation metric'
          },
          {
            value: 0,
            option: 'Reducing feature dimensionality'
          }
        ],
        question: 'You\'re evaluating a classification model and notice a high precision but low recall. Which adjustment will likely help increase recall without significantly affecting model performance?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use one-hot encoding for all categorical variables.'
          },
          {
            value: 0,
            option: 'Use label encoding and scale each label between 0 and 1.'
          },
          {
            value: 1,
            option: 'Apply target encoding for high cardinality categories and one-hot encoding for lower cardinality ones.'
          },
          {
            value: 0,
            option: 'Drop categorical columns with high cardinality to reduce feature space.'
          }
        ],
        question: 'You are tasked with building a predictive model, but your dataset contains multiple categorical variables with a high number of unique values, leading to substantial dimensionality when encoded. How can you efficiently handle this situation while preserving the model\'s accuracy?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Training and test scores converge at a low value.'
          },
          {
            value: 1,
            option: 'Training scores remain high, while test scores flatten at a low value with increased data.'
          },
          {
            value: 0,
            option: 'Both training and test scores converge at a high value.'
          },
          {
            value: 0,
            option: 'Training scores decline steadily with more data, while test scores increase.'
          }
        ],
        question: 'You observe that your trained model\'s accuracy is consistently high on training data but significantly lower on test data, even after adding regularization. To diagnose the model, you generate learning curves. Which of the following observations would best support the model\'s overfitting?'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'Adjust the classification threshold based on the ROC curve.'
          },
          {
            value: 0,
            option: 'Remove feature scaling as it may have distorted the data.'
          },
          {
            value: 0,
            option: 'Introduce polynomial features to enhance model capacity.'
          },
          {
            value: 0,
            option: 'Increase regularization to prevent overfitting.'
          }
        ],
        question: 'After applying feature scaling on your data, you notice that a logistic regression model\'s AUC-ROC improved, but precision-recall scores remain low. What could be the next step to potentially improve model performance on precision-recall?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use cross-validation to evaluate at each forecast horizon.'
          },
          {
            value: 1,
            option: 'Measure performance on rolling windows at different horizon intervals.'
          },
          {
            value: 0,
            option: 'Calculate the RMSE for the entire series without regard to forecast horizon.'
          },
          {
            value: 0,
            option: 'Split the dataset into train and test sets and evaluate each horizon independently.'
          }
        ],
        question: 'While working with time-series data, you want to assess the model\'s accuracy at various forecast horizons (short-term vs. long-term predictions). What would be the best approach for evaluating these forecasts?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Use a larger grid of hyperparameter values to explore further.'
          },
          {
            value: 0,
            option: 'Apply nested cross-validation with an expanded parameter search space.'
          },
          {
            value: 0,
            option: 'Increase the number of trees significantly without tuning other parameters.'
          },
          {
            value: 1,
            option: 'Apply random search and then refine the search using Bayesian optimization.'
          }
        ],
        question: 'You are tuning hyperparameters of a random forest model but observe that the cross-validation score improves only marginally even with substantial changes to the hyperparameter values. Which of the following strategies would be most effective for finding better results?'
      },
      {
        image: '',
        options: [
          {
            value: 1,
            option: 'The batch size might be too small; try increasing it.'
          },
          {
            value: 0,
            option: 'Increase the dropout rate to regularize the model further.'
          },
          {
            value: 0,
            option: 'The optimizer could be stuck in a local minimum; try increasing the learning rate.'
          },
          {
            value: 0,
            option: 'The model architecture may be too complex; reduce the number of layers.'
          }
        ],
        question: 'You are experimenting with a neural network and observe an unusual pattern where the model\'s loss fluctuates significantly during training without consistent decrease, even with a low learning rate. What could be the potential cause, and what would you adjust?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Adjust the regularization parameters to minimize bias across all predictions.'
          },
          {
            value: 1,
            option: 'Add more training data that emphasizes the range with high residuals.'
          },
          {
            value: 0,
            option: 'Remove data points within this range to reduce their impact.'
          },
          {
            value: 0,
            option: 'Apply ensemble methods to smooth out residuals across ranges.'
          }
        ],
        question: 'While analyzing your model\'s residuals, you observe systematic errors across a specific range of predictions, indicating a bias in that segment. What action would you take to address this?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Implement a static threshold on prediction confidence to flag uncertain predictions.'
          },
          {
            value: 1,
            option: 'Set up periodic model retraining on the latest available data to account for distributional shifts.'
          },
          {
            value: 0,
            option: 'Increase regularization to mitigate the impact of data distribution changes.'
          },
          {
            value: 0,
            option: 'Conduct feature scaling adjustments on new data before feeding it to the model.'
          }
        ],
        question: 'After model deployment, you want to ensure the model\'s predictions are reliable over time, given that incoming data might vary in distribution from the training data. Which approach would best help maintain prediction reliability?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Reduce the number of features used in each tree of the model.'
          },
          {
            value: 1,
            option: 'Reduce the number of boosting rounds and increase learning rate proportionally.'
          },
          {
            value: 0,
            option: 'Replace gradient boosting with a simpler model like logistic regression.'
          },
          {
            value: 0,
            option: 'Increase the maximum depth of each tree to reduce overall training time.'
          }
        ],
        question: 'You develop a gradient boosting model and find that its predictive performance is satisfactory, but the model takes significant time to make predictions on new data. Which of the following methods would best help reduce prediction latency without a major sacrifice in accuracy?'
      },
      {
        image: '',
        options: [
          {
            value: 0,
            option: 'Apply one-hot encoding to the numerical features to reduce skewness.'
          },
          {
            value: 1,
            option: 'Use logarithmic or Box-Cox transformation to normalize distributions.'
          },
          {
            value: 0,
            option: 'Normalize data by subtracting the mean and dividing by the range.'
          },
          {
            value: 0,
            option: 'Increase the regularization strength in the neural network to counter skewness.'
          }
        ],
        question: 'You\'re applying feature engineering on data that includes numerical features with skewed distributions. Before feeding these features into a neural network, what transformation would be most suitable to improve model performance?'
      }
    ],
  },
];
