import { Auth } from 'aws-amplify';
import Loading from 'components/global/layout/Loading';
import { useGetUserQuery } from 'components/profile/userSlice';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga4';
import {
  useGetJobBySlugQuery,
  useGetUserJobApplicationsQuery,
} from '../JobsSlice';
import ApplyNow from '../layout/ApplyNow';
import ApplyPopup from '../layout/ApplyPopup';
import CantApplyYet from '../layout/CantApplyYet';
import { LOADING, APPLIED, IDLE } from '../utils/helper';
import { BannerType, BasicDetailsType } from '../utils/types';
import Banner from './Banner';
import BasicDetails from './BasicDetails';
import EditorContent from './EditorContent';
import { setToken } from 'components/auth/authSlice';

const Index: React.FC<any> = (props) => {
  const dispatch = useDispatch();

  Auth.currentAuthenticatedUser({
    bypassCache: false, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then((res) => {
    const token = res?.signInUserSession?.accessToken?.jwtToken;
    dispatch(setToken(token));
  });

  const user = useGetUserQuery();
  let { slug } = useParams();
  const [applyState, setApplyState] = useState(LOADING);
  const [showApplyPopup, setShowApplyPopup] = useState(false);
  const [showApplyBlockedPopup, setShowApplyBlockedPopup] = useState(false);
  const [alreadyApplied, setAlreadyApplied] = useState(false);
  const { isLoading, isError, isSuccess, data } = useGetJobBySlugQuery({
    slug,
  });
  const [modalData, setModalData] = useState([]);
  const userApplications = useGetUserJobApplicationsQuery();

  const bannerProps: BannerType = {
    logo: data?.companyId.logoUrl,
    title: data?.roleDetails.roleTitle,
    website: data?.companyId.website,
    domain: data?.domainId.title,
  };

  const basicDetailsProps: BasicDetailsType = {
    jobType: data?.jobType,
    roleType: data?.roleDetails.roleType,
    jobDescriptionDoc: data?.jobDescriptionDoc,
    selectionProcess: data?.selectionProcess,
    openPositionsCount: data?.openPositionsCount,
    jobLocation: {
      country: data?.jobLocation.country,
      city: data?.jobLocation.city,
    },
    payRange: {
      currency: data?.payRange.currency,
      minimum: data?.payRange.minimum,
      maximum: data?.payRange.maximum,
      disclosed: data?.payRange.disclosed,
    },
    perks: data?.perks,
  };
  const jobContent = data?.jobDescription?.blocks || [];

  ReactGA.send({ hitType: 'pageview', page: '/' + slug });

  useEffect(() => {
    const found = userApplications?.data?.find((application: any) => {
      return application.opportunityId._id === data?._id;
    });
    if (found) {
      setAlreadyApplied(true);
      setApplyState(APPLIED);
    } else {
      setApplyState(IDLE);
    }
  }, [data?._id, userApplications?.data]);
  const content = (
    <>
      <Banner {...bannerProps} />
      <BasicDetails {...basicDetailsProps} />
      <ApplyNow
        setShowApplyBlockedPopup={setShowApplyBlockedPopup}
        setShowApplyPopup={setShowApplyPopup}
        applyState={applyState}
        setApplyState={setApplyState}
        isLoggedIn={user.isSuccess}
        isLoading={user.isLoading || userApplications.isLoading}
        alreadyApplied={alreadyApplied}
        domainId={data?.domainId?._id}
        oportunityId={data?._id}
        setModalData={setModalData}
      />
      <EditorContent content={jobContent} />
      <ApplyPopup
        showApplyPopup={showApplyPopup}
        setShowApplyPopup={setShowApplyPopup}
      />
      <CantApplyYet
        open={showApplyBlockedPopup}
        setShowApplyBlockedPopup={setShowApplyBlockedPopup}
        data={modalData}
      />
    </>
  );

  return (
    <div>
      {isLoading && <Loading />}
      {isError && <p>Error</p>}
      {isSuccess && content}
    </div>
  );
};

export default Index;
