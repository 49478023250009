import { createContext, useContext } from 'react';

//TO DO: Find less hacky solution
import { initialState } from 'components/dashboard/vga/domain-quiz/assessment/utils/types';

const vgaResponsesInitialState: initialState = {
  domain: '',
  minutesLeft: 0,
  secondsLeft: 0,
  currentQuestion: 0,
  responses: {},
  totalQuestions: 0,
  allSkills: '',
  batchId: '',
};
const VgaTestResponsesContextObject: {
  state: initialState;
  dispatch: Function;
} = {
  state: vgaResponsesInitialState,
  dispatch: () => {},
};

export const VgaTestResponsesContext = createContext(
  VgaTestResponsesContextObject
);

const useVgaTestResponsesContext = () => {
  return useContext(VgaTestResponsesContext);
};

export default useVgaTestResponsesContext;
