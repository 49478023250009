import { createContext, useContext } from 'react';

//TO DO: Find less hacky solution
import { initialState } from 'components/capstone/capstoneView/capstoneQuiz/assessment/utils/types';

const capstoneResponsesInitialState: initialState = {
  capstone: '',
  minutesLeft: 0,
  secondsLeft: 0,
  currentQuestion: 0,
  responses: {},
  totalQuestions: 0,
  enrollmentId: '',
};
const CapstoneTestResponsesContextObject: {
  state: initialState;
  dispatch: Function;
} = {
  state: capstoneResponsesInitialState,
  dispatch: () => {},
};

export const CapstoneTestResponsesContext = createContext(
  CapstoneTestResponsesContextObject
);

const useCapstoneTestResponsesContext = () => {
  return useContext(CapstoneTestResponsesContext);
};

export default useCapstoneTestResponsesContext;
