import { addMinutes, differenceInSeconds } from 'date-fns';
import { apiSlice } from '../../../../api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCapstoneQuiz: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/question/capstone/random',
          params: arg,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['NewCapstoneQuiz'],
    }),
    currentCapstone: builder.query<any, any>({
      query: (arg) => {
        return {
          url: '/enrollments/capstone',
          params: arg,
        };
      },
      transformResponse: (res: any) => {
        //Start Time
        //Total minutes = total questions
        //End time = current time + total questions (mins)
        //Remaining time = End time - current time
        if (!res.data) {
          return null;
        }
        const totalQuestions = res.data?.capstoneAssessments?.responses?.length;
        const startTime = res.data?.capstoneAssessments?.startTime;
        const endTime = addMinutes(new Date(startTime), totalQuestions);
        const currentTime = new Date();
        const timeLeft = differenceInSeconds(endTime, currentTime);
        const responses = res.data?.capstoneAssessments?.responses;

        // const groupedResponses = res.data.responses.map((skillSpecific:any)=> {return skillSpecific.questions.map((question:any) => {num++;return {...question,num}})}).flat()
        return { totalQuestions, ...res.data, timeLeft, responses };
      },
      providesTags: ['CurrentCapstone'],
    }),

    startCapstoneTest: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/enrollments/capstone/start-test`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CurrentCapstoneQuiz'],
    }),
    updateCapstoneResponses: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/enrollments/capstone/update-responses`,
          method: 'POST',
          body,
        };
      },
    }),
    finishCapstoneTest: builder.mutation({
      query(data: any) {
        const { ...body } = data;
        return {
          url: `/enrollments/capstone/finish`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['CurrentCapstone', 'UserCapstoneSkill'],
    }),
    getUserCapstoneSkill: builder.query<any, void>({
      query: () => {
        return {
          url: '/enrollments/capstone/user-skills',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['UserCapstoneSkill'],
    }),
  }),
});

export const {
  useGetCapstoneQuizQuery,
  useCurrentCapstoneQuery,
  useStartCapstoneTestMutation,
  useUpdateCapstoneResponsesMutation,
  useFinishCapstoneTestMutation,
  useGetUserCapstoneSkillQuery,
} = extendedApiSlice;
