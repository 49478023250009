import React from 'react';

const LeftSection = ({ batchLogo }) => {
  return (
    <div className='col-span-6 lg:col-span-2 flex flex-col items-center px-4 py-12 md:px-10 border-2 border-b-0'>
      <p className='text-lg md:text-xl  text-primary text-center'>
        We're eager to hear about your experience on the Virtual Global
        Apprenticeship
      </p>
      <div className='flex flex-col items-center mt-4'>
        <p className='mb-8 text-center text-sm md:text-normal'>
          Don't forget your certificate is waiting at the end of this!
        </p>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1643353821/uploaded_resources/Illustration_l4nkpv.png'
          alt='Illustration'
          className='h-40'
        />
      </div>
      <div className='flex items-center justify-center mt-6 '>
        <img src={batchLogo} alt='Deviare-Logo' className='h-12' />
        <p className='font-black text-gray-600 text-center mx-4 mt-1'>X</p>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1643351865/uploaded_resources/logo_orzfoc.png'
          alt='Logo'
          className='h-8  mt-1'
        />
      </div>
    </div>
  );
};

export default LeftSection;
