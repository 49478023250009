// import classNames from 'classnames';
// import React, { useState } from 'react';
// import { FaAngleDown } from 'react-icons/fa';

interface filterOptionType {
  sortBy: string;
  icon: JSX.Element;
  options: string[];
}

const SuperToggleBar = ({
  sortbyOptions,
  filterOptions,
  setActiveFilter,
  activeFilter,
}: {
  sortbyOptions: filterOptionType[];
  filterOptions: string[];
  setActiveFilter: React.Dispatch<
    React.SetStateAction<{
      filter: string;
      Domain: string;
      Industry: string;
      Level: string;
      Time: string;
    }>
  >;
  activeFilter: {
    filter: string;
    Domain: string;
    Industry: string;
    Level: string;
    Time: string;
  };
}) => {
  return (
    <div className='flex gap-2 w-max md:w-full flex-col md:flex-row justify-between'>
      <div className='rounded-lg w-full md:w-fit border border-gray-200 p-1 flex flex-wrap'>
        {filterOptions.map((val, idx) => (
          <button
            onClick={() => {
              setActiveFilter((prev) => ({
                ...prev,
                filter: val,
              }));
            }}
            key={idx}
            className={`w-fit px-3 md:px-0 md:w-32 text-center rounded-lg py-2 ${
              activeFilter.filter === val
                ? ' bg-primary text-white font-medium '
                : ' bg-white text-black font-normal '
            }`}>
            {val}
          </button>
        ))}
      </div>
      {/* filters till line 70*/}
      {/* <div className='flex flex-row flex-wrap md:flex-nowrap gap-2 md:gap-4'>
        {sortbyOptions.map((opt, idx) => (
          <div
            key={idx}
            className='flex flex-row px-1 py-3 justify-between border-2 border-gray-200 rounded-lg items-center w-32 h-fit'>
            <div className='flex flex-row gap-2'>
              {opt.icon}
              {opt.sortBy}
            </div>
            <FaAngleDown className='mt-1 mr-1' />
          </div>
        ))}
      </div> */}
      {/* <button
        onClick={() => {
          setCapstoneCategory('all');
        }}
        className={classNames(
          'text-sm md:text-base px-2 md:px-6 py-1 rounded shadow ',
          {
            'bg-primary text-white': capstoneCategory === 'all',
          },
          {
            'bg-white border-none': capstoneCategory !== 'all',
          }
        )}>
        All
      </button>
      <button
        onClick={() => {
          setCapstoneCategory('ongoing');
        }}
        className={classNames(
          'text-sm md:text-base px-2 md:px-6 py-1 rounded shadow ',
          {
            'bg-primary text-white': capstoneCategory === 'ongoing',
          },
          {
            'bg-white': capstoneCategory !== 'ongoing',
          }
        )}>
        Ongoing
      </button>
      <button
        onClick={() => {
          setCapstoneCategory('completed');
        }}
        className={classNames(
          'text-sm md:text-base px-2 md:px-6 py-1 rounded shadow ',
          {
            'bg-primary text-white': capstoneCategory === 'completed',
          },
          {
            'bg-white ': capstoneCategory !== 'completed',
          }
        )}>
        Completed
      </button> */}
    </div>
  );
};

export default SuperToggleBar;
