import React from 'react';

interface allProps {
  data: any;
}

const UserSkill = (props: allProps) => {
  const skillData = props.data;
  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
  ];

  function showImage(percentage: number) {
    let imageToShow = images[0];
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1];
    }
    if (percentage < 65) {
      imageToShow = images[2];
    }
    return imageToShow;
  }
  return (
    <div className='m-2 sm:ml-3 rounded'>
      <div className='text-primary font-semibold text-center'>Skills</div>
      <div className='grid grid-cols-2 gap-2'>
        {skillData.map((skill: any, index: number) => {
          return (
            <div
              key={index}
              className='my-2 flex items-center bg-primary-lightest col-span-2 sm:col-span-1 rounded-3xl'
            >
              <div className='bg-primary rounded-full text-center flex items-center w-12 h-12 px-3 py-1 text-white'>
                {Math.round(skill.percentage)}%
              </div>
              <div className='flex justify-between w-full '>
                <div className='ml-1 text-sm mt-1'>{skill.title} </div>
                <div>
                  <img
                    alt='skill'
                    className='mr-6'
                    src={showImage(skill.percentage)}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserSkill;
