import React from 'react';
import { GoDotFill } from 'react-icons/go';
import { differenceInCalendarDays, format } from 'date-fns';
import { CapData } from './types';
import { Link } from 'react-router-dom';

function diff_hours(dt2: any, dt1: any) {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60 * 60;
  return Math.round(diff);
}

const CapstoneData = (props: CapData) => {
  const dt1 = new Date(props?.capstoneAssessments?.finishTime);
  const dt2 = new Date(Date.now());
  let timeLeft = 0;
  if (props?.capstoneAssessments?.finishTime) {
    timeLeft = diff_hours(dt1, dt2) + 48;
  }
  const enrollmentDate = differenceInCalendarDays(
    new Date(),
    new Date(props.createdAt)
  );
  const totalTasks = props?.tasks?.length;
  const submittedTasks = props?.capstoneSubmissions?.length;
  const progressPercentage = (submittedTasks * 100) / totalTasks;

  const tryAgain =
    props?.capstoneAssessments?.finishTime &&
    props?.capstoneAssessments?.percentage < 50;

  const allTaskDone =
    props.capstoneSubmissions.length === props.capstone.tasks.length;

  return (
    <div>
      <div className='md:px-8 border-t md:border-t-0 md:border-l border-primary'>
        <div className='sm:flex justify-between'>
          <div className='mt-2 md:mt-0'>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                <GoDotFill />
              </div>
              <p className='text-sm'>
                Capstone Progress-{' '}
                <span className='text-primary font-semibold'>
                  {progressPercentage}%
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                <GoDotFill />
              </div>
              <p className='text-sm'>
                Milestones Achieved-{' '}
                <span className='text-primary font-semibold'>
                  {submittedTasks}/{totalTasks}
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                <GoDotFill />
              </div>
              <p className='text-sm'>
                Capstone Performance-{' '}
                <span className='text-primary font-semibold'>
                  {props.capstoneAssessments?.finishTime
                    ? `${props?.capstoneAssessments?.percentage}%`
                    : 'NA'}
                </span>
              </p>
            </div>
            <div className='flex items-center'>
              <div className='w-6  text-primary'>
                <GoDotFill />
              </div>
              <p className='text-sm'>
                Current Milestone-{' '}
                <span className='text-primary font-semibold'>
                  Task {props?.capstoneSubmissions?.length}
                </span>
              </p>
            </div>
          </div>
          <div className='flex items-center  md:block'>
            <div
              className='text-primary font-semibold text-center  pt-12 h-20 md:h-24 w-20 md:w-24 bg-top md:-mt-7 flex place-items-center justify-center'
              style={{
                backgroundImage:
                  'url(https://res.cloudinary.com/belong/image/upload/v1666187316/capstone/ant-design_calendar-filled_ytsqip.png)',
              }}
            >
              {'Day ' + enrollmentDate}
            </div>
            <p className='ml-4 md:mt-2 md:ml-0 text-xs text-gray-400'>
              Start Date- {format(new Date(props?.createdAt), 'do LLLL yyyy')}
            </p>
          </div>
        </div>
        {tryAgain ? (
          <>
            {timeLeft < 1 ? (
              <Link to={'/capstone/' + props._id}>
                <button className='text-white bg-primary px-8 py-1 my-4 rounded'>
                  Try Again
                </button>
              </Link>
            ) : (
              <button className='text-white bg-primary px-8 py-1 my-4 rounded'>
                Start Quiz in {timeLeft} hours
              </button>
            )}
          </>
        ) : allTaskDone ? (
          <Link to={'/capstone/' + props._id}>
            <button className='text-white bg-primary px-8 py-1 my-4 rounded'>
              Start Quiz
            </button>
          </Link>
        ) : (
          <Link to={'/capstone/' + props._id}>
            <button className='text-white bg-primary px-8 py-1 my-4 rounded'>
              Continue Task
            </button>
          </Link>
        )}

        {/* <p className='font-medium md:font-semibold text-sm '>
          *Capstone quiz performance criteria to unlock the certificate is 50%
          and above
        </p> */}
      </div>
    </div>
  );
};

export default CapstoneData;
//Continue
//tasks submitted < tasks.length
//Click goes to slug

//Try quiz in x hours
//If quiz given
//Check quiz status file to get value of x, no action

//Start Quiz
//If no quiz given
//All tasks done
//Click goes to slug

//***completed***//
//Show end date based on finish time of quiz
