import { useDomainTestScoreQuery } from 'components/dashboard/quiz/domain/DomainQuizSlice';

const DomainCard = () => {
  const { isSuccess, data } = useDomainTestScoreQuery();

  //percentage of each individual skill
  const skillPercentage = (id: string) => {
    const res = data[0]?.finalScore.find((singleFinalScore: any) => {
      return singleFinalScore.skillId === id;
    });
    return Math.ceil((res.obtained / res.max) * 100);
  };

  //to show gold,silver and bronze trophy icons
  const images = [
    'https://res.cloudinary.com/belong/image/upload/v1658136571/uploaded_resources/image-removebg-preview_2_2_rrntmf.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136617/uploaded_resources/image-removebg-preview_3_2_a6j2ji.png',
    'https://res.cloudinary.com/belong/image/upload/v1658136603/uploaded_resources/image-removebg-preview_4_2_o5xmps.png',
  ];
  function showImage(percentage: number) {
    let imageToShow = images[0];
    if (percentage < 80 && percentage >= 65) {
      imageToShow = images[1];
    }
    if (percentage < 65) {
      imageToShow = images[2];
    }
    return imageToShow;
  }

  return (
    <>
      {isSuccess && (
        <div className='border-2 border-dark/10 rounded-sm space-y-6 p-2 pb-6 '>
          {/* first area start  */}
          <div className='flex justify-between items-center mb-14'>
            <p className='text-primary font-semibold text-lg'>Domain Quiz</p>
            <div>
              <p className='text-white font-semibold bg-primary border rounded-2xl min-w-fit px-2 py-1 text-center'>
                {data[0]?.percentage.toFixed(2)}%
              </p>
            </div>
          </div>

          {/* <div>
            <p className='text-white font-semibold bg-primary border rounded-2xl min-w-fit px-2 py-1 text-center'>
              Quiz Performance - {data[0]?.percentage}%
            </p>
          </div> */}
          {/* second area end */}
          {/* third area start */}
          <div className='space-y-4 font-semibold'>
            {data[0]?.skills.map((skill: any) => {
              return (
                <div
                  key={skill._id}
                  className='bg-primary-light border  rounded-2xl flex items-center  h-8 w-full'>
                  <p className='bg-white  border-2  border-primary rounded-full h-11 w-11 p-1 text-primary flex flex-none items-center justify-center'>
                    {skillPercentage(skill._id)}%
                  </p>
                  <div className='flex w-full items-center justify-between'>
                    <p className='ml-4'>{skill.title}</p>
                    {skillPercentage(skill._id) > 45 && (
                      <div>
                        <img
                          alt='skill'
                          className='mr-4 h-5'
                          src={showImage(skillPercentage(skill._id))}
                        />
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          {/* third area end */}
        </div>
      )}
    </>
  );
};

export default DomainCard;
