import { isSameDay, isSameMonth } from 'date-fns';

export const getCurrentWeekEvents = (events: any, currentWeek: number) => {
  return events
    .filter((event: any) => {
      return event.eventId.week === currentWeek;
    })
    .sort((a: any, b: any) => {
      return (
        new Date(a.eventId.dateTime).valueOf() -
        new Date(b.eventId.dateTime).valueOf()
      );
    });
};

export const getAllTasks = (projects: any) => {
  return projects
    .map((project: any) => {
      return project.projectId.tasks.map((task: any) => {
        return {
          ...task,
          title: project.projectId.title,
          type: project.projectId.type,
        };
      });
    })
    .flat();
};

export const getWeekTasks = (allTasks: any, currentWeek: number) => {
  return allTasks
    .filter((project: any) => {
      return project.week === currentWeek;
    })
    .sort((a: any, b: any) => {
      return (
        new Date(a.dueDateTime).valueOf() - new Date(b.dueDateTime).valueOf()
      );
    });
};

export const getMonthEvents = (
  allEvents: any,
  firstDayOfCurrentMonth: Date
) => {
  return allEvents.filter((event: any) => {
    return isSameMonth(
      new Date(event.eventId.dateTime),
      firstDayOfCurrentMonth
    );
  });
};

export const getMonthTasks = (allTasks: any, firstDayOfCurrentMonth: Date) => {
  return allTasks.filter((task: any) => {
    return isSameMonth(new Date(task.dueDateTime), firstDayOfCurrentMonth);
  });
};

export const isEventOnSameDay = (eventsOfMonth: any, day: any) => {
  return eventsOfMonth.some((event: any) => {
    return isSameDay(new Date(event.eventId.dateTime), day);
  });
};

export const isTaskOnSameDay = (tasksOfMonth: any, day: any) => {
  return tasksOfMonth.some((task: any) => {
    return isSameDay(new Date(task.dueDateTime), day);
  });
};
