import { useGetOnboardingQuery } from 'components/dashboard/panel/panelSlice';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import { BsFillCalendarCheckFill } from 'react-icons/bs';

const DataHeader = ({ batchId }: { batchId: string }) => {
  const { data } = useGetOnboardingQuery();
  const batch = data?.vga?.find((batch: any) => batch._id === batchId);
  const { basicDetails } = useBasicDetailsContext();
  const isVge = basicDetails?.isVge;
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <BsFillCalendarCheckFill className='text-lg' />
        <p className='text-sm font-semibold'>
          Your Apprenticeship Schedule At A Glance
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-4 rounded-full bg-primary'></span>
          {isVge ? 'VGE' : 'VGA'} Performance -{' '}
          <span className='text-primary'>{batch?.vgaScore}%</span>
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-4 rounded-full bg-primary'></span>
          Sessions Attended - {/* Round number to two decimals */}
          <span className='text-primary'>
            {' '}
            {batch?.attendanceRate &&
              Math.round(batch?.attendanceRate * 100) / 100}
            %
          </span>
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-4 rounded-full bg-primary'></span>
          Tasks Completed -{' '}
          <span className='text-primary'>{batch?.submissionRate}%</span>
        </p>
      </div>
    </div>
  );
};

export default DataHeader;
