import EventListElement from './EventListElement';

const EventsList = ({ weekEvents }: { weekEvents: any }) => {
  const box = 'border p-1 min-h-[48px] rounded';
  return (
    <div className='grid grid-rows-7 gap-1'>
      {weekEvents.map((event: any) => {
        return (
          <div className={box} key={event.eventId._id}>
            <EventListElement event={event.eventId} />
          </div>
        );
      })}
    </div>
  );
};

export default EventsList;
