import React from 'react';
import { format } from 'date-fns';

const StudentEducation = ({ data }: { data: any }) => {
  const educations = data?.education || [];
  return (
    <div className='py-4  px-6 lg:pr-16  '>
      <div className='flex space-x-2 items-center'>
        <div className='border-r-2 pr-4 font-semibold text-xl'>Education</div>
        <p className='text-sm'>Graduation</p>
      </div>
      {/* college info and batch year */}
      {educations.map((education: any, index: number) => {
        return (
          <div key={index}>
            <div className='md:flex justify-between items-center'>
              {/* college info */}
              <div className='flex md:basis-3/5 lg:basis-1/2 space-x-3 items-center mt-8 mb-6'>
                <div className='bg-gray-200 p-4 rounded-lg shadow'>
                  <svg
                    width='48'
                    height='40'
                    viewBox='0 0 48 40'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M24 0.5L0 13.5L24 26.5L43.6364 15.8617V30.8333H48V13.5M8.72727 22.5567V31.2233L24 39.5L39.2727 31.2233V22.5567L24 30.8333L8.72727 22.5567Z'
                      fill='#7D7D7D'
                    />
                  </svg>
                </div>

                <div>
                  <p className=' font-semibold text-lg mb-2'>
                    {education.oganizationName}
                  </p>
                  <p className='text-sm mb-1'>
                    {education.degree} {education.fieldOfStudy}
                  </p>
                  <p className='text-sm text-primary'>
                    Grade Point {education.grade}{' '}
                  </p>
                </div>
              </div>
              <div className='flex md:basis-2/5 lg:basis-1/2 justify-between mb-4 md:mb-0'>
                <p className='font-semibold text-base'>
                  {education.startDate &&
                    format(new Date(education.startDate), 'MMM u')}{' '}
                  -{' '}
                  {education.endDate &&
                    format(new Date(education.endDate), 'MMM u')}
                </p>
              </div>
            </div>
            {/* text area */}
            {education.backlogs > 0 && (
              <p className='font-semibold text-sm'>
                {education.backlogs} Backlogs - Expected Date of clearance{' '}
                <span className='text-primary'>
                  {education.backlogClearanceDate
                    ? format(new Date(education.backlogClearanceDate), 'MMM u')
                    : 'No Idea'}
                </span>
              </p>
            )}
            <p className='my-6'>{education.description}</p>
          </div>
        );
      })}
    </div>
  );
};

export default StudentEducation;
