import React from 'react';

const Result = ({ userData }: any) => {
  const vgaQuizData = userData?.vgaQuizData;
  console.log(vgaQuizData);
  return (
    <div className='flex flex-col items-center justify-center  p-8'>
      <div className='text-center'>
        {/* <h1 className='text-3xl font-bold text-green-600 mb-4'>
          Congratulations! 🎉
        </h1> */}
        <p className='text-xl font-semibold  text-lg mb-8'>
          You have successfully completed the test.
        </p>
        <div className='bg-gray-50 rounded-xl p-6 shadow-inner'>
          <p className='text-xl font-semibold text-gray-800'>
            Your Score:
            <span className='ml-2 text-2xl text-blue-600'>
              {vgaQuizData?.score.percentage.toFixed(2)}%
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Result;
