import React from 'react';
import AttendanceCard from './layout/AttendanceCard';
import DomainCard from './layout/DomainCard';
import ProjectScoreCard from './layout/ProjectScoreCard';
import SubmissionCard from './layout/SubmissionCard';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';

const Performance = ({
  batchData,
  userSubmissionRateData,
}: {
  batchData: any;
  userSubmissionRateData: any;
}) => {
  const { basicDetails } = useBasicDetailsContext();
  const isVge = basicDetails?.isVge;
  return (
    <div>
      <p className='fold-bold text-lg'>{isVge ? 'VGE' : 'VGA'} Performance</p>
      <p className='text-primary font-semibold text-lg my-4'>
        Your submission rate needs to be 100% to access the certificate
      </p>
      {/* individual performance card start */}
      <div className='mt-6 grid grid-cols-1 lg:grid-cols-4 gap-4'>
        <AttendanceCard batchData={batchData} />
        <SubmissionCard userSubmissionRateData={userSubmissionRateData} />
        <DomainCard />
        <ProjectScoreCard batchId={batchData._id} />
      </div>

      {/* individual performance card ends */}
    </div>
  );
};

export default Performance;
