import { apiSlice } from 'api/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // Get all supercapstones
    getSuperCapstones: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone/user',
          params: params,
        };
      },
      // transformResponse: (res: any) => res.data,
      providesTags: ['AllSuperCapstones'],
    }),
    getSuperCapstone: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone/user',
          params: params,
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['SuperCapstone'],
    }),
    getFilter: builder.query<any, void>({
      query: () => {
        return {
          url: '/super-capstone/filter-data',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['SuperCapstoneFilter'],
    }),
    getUserSuperCapstones: builder.query<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission',
        };
      },
      transformResponse: (res: any) => res.data,
      providesTags: ['MySuperCapstone'],
    }),
    enrollCapstone: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission/',
          method: 'POST',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: [
        'AllSuperCapstones',
        'MySuperCapstone',
        'CurrentSuperCapstone',
      ],
    }),
    addTaskSolution: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission/task',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['MySuperCapstone'],
    }),
    addQuiz: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission/quiz',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['MySuperCapstone'],
    }),
    addSuperFeedback: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission/feedback',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['MySuperCapstone'],
    }),
    addCompleted: builder.mutation<any, any>({
      query: (params) => {
        return {
          url: '/super-capstone-submission/completed',
          method: 'PUT',
          body: params,
        };
      },
      transformResponse: (res: any) => res.data,
      invalidatesTags: ['MySuperCapstone'],
    }),
  }),
});

export const {
  useGetSuperCapstoneQuery,
  useGetSuperCapstonesQuery,
  useGetFilterQuery,
  useGetUserSuperCapstonesQuery,
  useEnrollCapstoneMutation,
  useAddTaskSolutionMutation,
  useAddQuizMutation,
  useAddSuperFeedbackMutation,
  useAddCompletedMutation,
} = extendedApiSlice;
