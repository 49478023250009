import React from 'react';
import DatePicker from 'react-datepicker';
import { useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';

// import {
//   labelStyle,
//   inputStyle,
//   textAreaStyle,
//   errorMessageStyle,
// } from '../../styles/panel';

const inputStyle = 'shadow p-2 rounded mx-2 w-3/5';
const labelStyle = 'w-1/4 inline-block';
const errorMessageStyle = 'text-sm text-red-500';
const textAreaStyle = 'shadow p-2 rounded mx-2 w-3/4 h-40';

export const TextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <>
      <label className={labelStyle} htmlFor={props.id || props.name}>
        {label}
      </label>
      <input className={inputStyle} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </>
  );
};

export const TextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input>. We can use field meta to show an error
  // message if the field is invalid and it has been touched (i.e. visited)
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className={textAreaStyle} {...field} {...props}></textarea>
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </>
  );
};

export const Checkbox = ({ children, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'checkbox' });
  return (
    <div>
      <label>
        <input className='mx-2' type='checkbox' {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className='error'>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const Radio = ({ children, ...props }) => {
  // React treats radios and checkbox inputs differently other input types, select, and textarea.
  // Formik does this too! When you specify `type` to useField(), it will
  // return the correct bag of props for you -- a `checked` prop will be included
  // in `field` alongside `name`, `value`, `onChange`, and `onBlur`
  const [field, meta] = useField({ ...props, type: 'radio' });
  return (
    <div>
      <label className='checkbox-input'>
        <input type='radio' {...field} {...props} />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const Select = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className='space-x-4'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <select {...field} {...props} className='p-4' />
      {meta.touched && meta.error ? (
        <div className={errorMessageStyle}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export const DatePickerField = ({ name, value, onChange, label, ...props }) => {
  return (
    <div className='space-x-4'>
      <label htmlFor={props.id || props.name}>{label}</label>
      <DatePicker
        selected={(value && new Date(value)) || null}
        {...props}
        onChange={(val) => {
          onChange(name, val);
        }}
      />
    </div>
  );
};
