import { useGetCountriesQuery } from 'components/dashboard/vga/userBatchSlice';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import htmlparser from 'html-react-parser';

import {
  RiToolsFill,
  RiPencilRuler2Line,
  RiStarLine,
  RiUser3Line,
  RiTimeLine,
} from 'react-icons/ri';

interface allProps {
  data: any;
}

interface SectionContentType {
  contentToPublish: string;
  contentType: string;
  sectionNumber: number;
}

const CapstoneInfo: React.FC<allProps> = ({ data }) => {
  const totalTime =
    data?.tasks && data?.tasks.length
      ? data?.tasks?.map((i: any) => i.time).reduce((a: any, b: any) => a + b)
      : 0;

  const skillIds = new Set(
    data?.tasks.flatMap((task: any) =>
      task.skillId.map((skill: any) => skill._id)
    )
  );

  let uniqueSkills = new Set<string[]>();

  for (const task of data.tasks) {
    for (const taskSkill of task.skillId) {
      uniqueSkills.add(taskSkill.title);
    }
  }

  const totalSkill = skillIds.size;

  // for flag
  let countryCode = '';
  const { data: flagData } = useGetCountriesQuery({});
  if (flagData) {
    countryCode = Object.keys(flagData).filter((el: any) => {
      return flagData[el]?.name === data.companyId?.country;
    })[0];
  }

  return (
    <div className='py-8 px-6 md:px-10 flex flex-col gap-7'>
      <div className='flex flex-col gap-2 md:flex-row justify-between items-start md:items-center'>
        <div>
          <img
            className='w-auto h-16 object-contain'
            src={data.companyId?.logoUrl}
            alt={`${data.companyId?.name} logo`}
          />
        </div>
        <div className='flex gap-2 items-center justify-center'>
          {data.companyId?.industryId?.title ? (
            <React.Fragment>
              <span className='flex text-center justify-start'>
                {data.companyId?.industryId.title}
              </span>
              <span>|</span>
            </React.Fragment>
          ) : (
            ''
          )}

          {/* show flag here */}
          {countryCode && (
            <ReactCountryFlag
              countryCode={countryCode}
              svg
              style={{
                width: '3em',
                height: '2em',
                border: '1px solid grey',
                padding: '0.1em',
              }}
            />
          )}
          {/* <span className='text-center'>{data.companyId?.country}</span> */}
          <span>|</span>
          <a
            className='text-primary'
            rel='noreferrer'
            target='_blank'
            href={data.companyId?.website}>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M10 3V5H5V19H19V14H21V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3H10ZM17.5858 5H13V3H21V11H19V6.41421L12 13.4142L10.5858 12L17.5858 5Z'
                fill='#10ABB7'
              />
            </svg>
          </a>
        </div>
      </div>
      <span className='font-semibold text-2xl'>{data.title}</span>
      <div className='flex flex-col gap-1.5'>
        <div className='flex flex-row gap-2 cursor-default'>
          <RiTimeLine className='text-primary font-semibold mt-[3px] text-xl' />
          {totalTime} hours
        </div>
        <div className='flex flex-row gap-2 cursor-default'>
          <RiPencilRuler2Line className='text-primary font-semibold mt-[3px] text-xl' />
          {data.tasks.length} tasks
        </div>
        <div className='flex flex-row gap-2 relative group cursor-pointer'>
          <span className='absolute transform translate-x-20 translate-y-0 opacity-0 group-hover:opacity-100 bg-gray-800 text-white text-xs rounded py-1 px-2 transition-opacity duration-300'>
            {Array.from(uniqueSkills).join(', ')}
          </span>
          <RiStarLine className='text-primary font-semibold mt-[3px] text-xl' />
          {totalSkill} skills
        </div>
        <div className='flex flex-row gap-2 cursor-default'>
          <RiUser3Line className='text-primary font-semibold mt-[3px] text-xl' />
          {data.level}
        </div>
        <div className='flex flex-row gap-2 cursor-default'>
          <RiToolsFill className='text-primary font-semibold mt-[3px] text-xl' />
          {data.domainId.title}
        </div>
      </div>
      <div className='border-t-2 border-gray-200'></div>
      <div className='flex flex-col gap-4'>
        <span className='font-medium'>About Company</span>
        <div className='flex flex-col gap-3'>
          {data.companyId?.sectionContent?.map(
            (content: SectionContentType, idx: number) => {
              return (
                content.contentType === 'Text' && (
                  <span key={idx}>{htmlparser(content.contentToPublish)}</span>
                )
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default CapstoneInfo;
