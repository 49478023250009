// import { Link } from 'react-router-dom';
import { FaMapMarkerAlt } from 'react-icons/fa';
import classNames from 'classnames';

import type { OpportunityType } from '../utils/types';
import { experienceToTextMapping } from '../utils/helper';
import OpportunityStat from '../OpportunityStat';
import { Link } from 'react-router-dom';

import SaveAndShareRole from './SaveAndShareRole';

const Opportunity: React.FC<OpportunityType> = (props: OpportunityType) => {
  const pillStyle =
    'px-1 h-8 border text-xs text-gray-600 border-1 rounded-full inline-flex items-center justify-center flex-1';
  return (
    <div className='p-2 lg:p-6 bg-white shadow-md rounded-2xl'>
      <div className='mt-2 mb-4 grid grid-cols-12 gap-2'>
        <div className='col-span-4 md:col-span-2'>
          <img className='max-h-10' src={props.companyId.logoUrl} alt='logo' />
        </div>
        <div className='col-span-8 md:col-span-5'>
          <p className='text-lg md: text-gray-600 font-bold text-primary break-words'>
            {props.roleDetails.roleTitle}
          </p>
          <div className='flex mt-1 text-gray-400 gap-1 md:gap-4'>
            <p className='text-sm'>{props.companyId.name}</p>
            <div className='flex items-center gap-1 break-words'>
              <FaMapMarkerAlt className='font-bold text-sm' />
              <p className='text-sm'>
                {props.jobType}
                {props.location}
              </p>
            </div>
          </div>
        </div>
        <div className='col-span-8 md:col-span-3 flex justify-center gap-2 text-center'>
          <span
            className={classNames(
              'bg-gray-200 font-semibold',
              pillStyle,
              experienceToTextMapping(props.roleDetails.requiredExperience)
                ?.color
            )}
          >
            {
              experienceToTextMapping(props.roleDetails.requiredExperience)
                ?.text
            }
          </span>
          <span className={classNames('bg-primary-lightest', pillStyle)}>
            {props.roleDetails.roleType}
          </span>
        </div>
        <div className='col-span-4 md:col-span-2'>
          <SaveAndShareRole slug={props.slug} />
        </div>
      </div>
      <div className='my-2'>
        <p className={classNames('bg-primary-light px-2', pillStyle)}>
          {props.domainId.title}
        </p>
      </div>
      <OpportunityStat
        payRange={props.payRange}
        cutOffDate={props.cutoffDate}
        opportunityId={props._id}
      />
      <div className='my-3 text-xs text-right'>
        <Link
          to={props.slug}
          className='text-white text-center bg-primary shadow  hover:text-primary hover:bg-white hover:border-1 hover:border hover:border-primary rounded-full py-2 px-6 font-semibold cursor-pointer'
        >
          Apply
        </Link>
      </div>
    </div>
  );
};

export default Opportunity;
