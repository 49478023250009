import React from 'react';
import { useNavigate } from 'react-router-dom';

const ScorePercentCard = ({ onbordingData }: any) => {
  const navigate = useNavigate();
  const aptitudeScore = onbordingData?.aptitude?.percentage;
  const testGiven = onbordingData?.aptitude?.totalAttempts > 0;
  return (
    <div className='relative  mt-4 md:mx-2  pb-4 text-center border rounded bg-primary-lightest'>
      <div className='absolute text-xs bg-primary rounded text-white px-4 py-0.5  text-center right-0 top-0'>
        1/1
      </div>
      <div className='flex justify-center pt-4 mt-4'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1667818455/new%20b2b/fluent_brain-circuit-20-regular_qezubn.png'
          alt='brain'
        />
      </div>
      <p className='my-2 font-semibold'>Aptitude</p>
      <p className='font-semibold text-sm'>Quiz Performance</p>
      <p className='text-primary font-bold text-sm mt-1'>{aptitudeScore} %</p>
      <button
        className='text-sm  mt-1 text-white bg-primary rounded-2xl  px-3 py-1'
        onClick={() => {
          testGiven
            ? navigate('/quiz/aptitude/scorecard')
            : navigate('/quiz/aptitude/aptitude-quiz');
        }}>
        {testGiven ? 'Score Card' : 'Start Test'}
      </button>
    </div>
  );
};

export default ScorePercentCard;
