import React from 'react';
import htmlparser from 'html-react-parser';

const TextSection = ({ taskData }: { taskData: any }) => {
  return (
    <div className='mt-6 px-4'>
      <div className='flex flex-wrap items-center justify-between'>
        <p className='font-semibold'>Task -{taskData.title} </p>
        <div className='flex flex-wrap mt-2 sm:mt-0 gap-x-1'>
          {taskData.skillId.map((skill: any) => {
            return (
              <p
                key={skill._id}
                className='bg-primary/10 max-w-fit text-center px-4 py-1 rounded-2xl font-semibold text-xs sm:text-sm'
              >
                {skill.title}
              </p>
            );
          })}
        </div>
      </div>
      <div className='space-y-4 text-sm mt-4'>
        {taskData.backgroundDescription && (
          <div className='prose prose-sm max-w-none text-base'>
            {' '}
            {htmlparser(taskData.backgroundDescription)}
          </div>
        )}
        {taskData.taskInstructions && (
          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(taskData.taskInstructions)}
          </div>
        )}
        {taskData.resources && (
          <>
            <p className='font-semibold text-base'>Resources</p>
            <div className='prose prose-sm max-w-none text-base'>
              {htmlparser(taskData.resources)}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default TextSection;
