import { BasicDetailsContext } from 'hooks/useBasicDetailsContext';
import SuperBanner from './SuperBanner';
import { getBannerData } from 'components/dashboard/vga/helper';
import { BannerType, InitialTab } from 'components/dashboard/vga/types';
import SuperTabsBar from './super-layout/SuperTabsBar';
// import TabsBar from './layout/TabsBar';
// import useTabs from './layout/useTabs';
import useSuperTabs from './super-layout/useSuperTabs';

const SuperDashboard: React.FC<any> = ({ data }) => {
  const bannerProps: BannerType = getBannerData(data?.batches[0]);
  const [state, dispatch] = useSuperTabs(data);
  const [activeTab] = state.tabs.filter(
    (tab: InitialTab) => tab.status === 'ACTIVE'
  );

  const coachNotifications = state?.basicDetails?.coachNotifications;

  const userData = data?.batches[0]?.users[0];

  const userStatus = userData?.status;

  const isCertificationTabActive =
    state.tabs.find((el: any) => {
      return el.id === 9;
    })?.status === 'ACTIVE';
  return (
    <>
      {userStatus === 'Disabled' ? (
        <div className='h-screen grid place-items-center'>
          <p>You no longer have access to this Batch</p>
        </div>
      ) : (
        <div>
          <BasicDetailsContext.Provider
            value={{ basicDetails: state?.basicDetails, dispatch }}>
            <SuperBanner {...bannerProps} />
            <div
              className={
                isCertificationTabActive ? 'sticky top-0 ' : 'sticky top-0 z-50'
              }>
              <SuperTabsBar
                tabs={state.tabs}
                dispatch={dispatch}
                coachNotifications={coachNotifications}
              />
            </div>
            {activeTab.component}
          </BasicDetailsContext.Provider>
        </div>
      )}
    </>
  );
};

export default SuperDashboard;
