import React from 'react';
import htmlparser from 'html-react-parser';

const IntroText = ({ projectDetails }: { projectDetails: any }) => {
  return (
    <div className='space-y-4 text-sm mt-4 md:mt-10'>
      <div className='md:flex md:justify-between  font-semibold '>
        <p className=' text-lg'>{projectDetails?.title} </p>
        <div className='text-primary flex flex-wrap md:space-x-1 '>
          <p> 21st Century Skillset: </p>
          <div className='flex flex-wrap space-x-2'>
            {projectDetails.tasks[0]?.skillId.map((skill: any) => {
              return <p key={skill._id}>{skill.title} </p>;
            })}
          </div>
        </div>
      </div>
      <div className='px-4 prose prose-sm max-w-none text-base'>
        {projectDetails.introduction && htmlparser(projectDetails.introduction)}
      </div>
    </div>
  );
};

export default IntroText;
