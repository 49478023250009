import classNames from 'classnames';

const ScoreCard = ({ onbordingData, setShowScreen, showScreen }: any) => {
  const { belongScore } = onbordingData;
  const cognitiveAcumen = belongScore?.cognitiveAcumen || 0;
  const profileStrength = belongScore?.profileStrength || 0;
  const skillCompetency = belongScore?.skillCompetency || 0;

  const staticClasses =
    'flex flex-col justify-between border rounded my-4 space-y-2 bg-gradient-to-b  text-center  pt-2 pb-4 cursor-pointer';

  return (
    <>
      <div
        data-tut='Cognitive-Acumen'
        onClick={() => setShowScreen(1)}
        className={classNames(
          staticClasses,
          { 'from-primary/5 to-primary/30': showScreen === 1 },
          { 'from-primary/5 to-dark/5': !(showScreen === 1) }
        )}
      >
        <p className='text-primary font-bold '>Cognitive Acumen</p>
        <p className='font-semibold text-sm'>Aptitude Quiz</p>
        <div className='flex justify-center'>
          <p className='bg-white text-primary md:w-18 px-4 py-1 text-center rounded-2xl font-semibold '>
            {cognitiveAcumen}/40
          </p>
        </div>
      </div>
      <div
        data-tut='Skill-Competency'
        onClick={() => setShowScreen(2)}
        className={classNames(
          staticClasses,
          { 'from-primary/5 to-primary/30': showScreen === 2 },
          { 'from-primary/5 to-dark/5': !(showScreen === 2) }
        )}
      >
        <p className='text-primary font-bold '>Skill Competency</p>
        <p className='font-semibold text-sm'>Capstone & VGA Performance</p>
        <div className='flex justify-center'>
          <p className='bg-white text-primary md:w-18 px-4 py-1 text-center rounded-2xl font-semibold '>
            {skillCompetency}/45
          </p>
        </div>
      </div>
      <div
        data-tut='Profile-Strength'
        onClick={() => setShowScreen(3)}
        className={classNames(
          staticClasses,
          { 'from-primary/5 to-primary/30': showScreen === 3 },
          { 'from-primary/5 to-dark/5': !(showScreen === 3) }
        )}
      >
        <p className='text-primary font-bold '>Profile Strength</p>
        <p className='font-semibold text-sm'>Employment Profile</p>
        <div className='flex justify-center'>
          <p className='bg-white text-primary md:w-18 px-4 py-1 text-center rounded-2xl font-semibold '>
            {profileStrength?.toFixed(1)}/15
          </p>
        </div>
      </div>
    </>
  );
};

export default ScoreCard;
