import React from 'react';
import { format } from 'date-fns';

import AboutSection from '../../layout/AboutSection';
import OverviewCard from '../../layout/OverviewCard';
import Schedules from '../../layout/Schedules';
import YourCoach from './YourCoach';

function Coach(props) {
  const isFullJourney = props.isFullJourney;
  const userSpecificEvents = props.userSpecificEvents;
  const batchDetails = props.data.batches[0];
  const isCareereraUser = props.isCareereraUser;

  const userDetails = batchDetails.users.filter(
    (user) => user.email === props.data.email
  )[0];
  const coachDetails = userDetails.coach;
  const coachDetails2 = userDetails.coach2;

  const fullJourneyStart =
    batchDetails.timeSheet && batchDetails.timeSheet.startsAt;
  const coachEvents = userSpecificEvents
    .map((event) => ({
      ...event,
    }))
    .filter(
      (event) =>
        event.eventId.type === 'Coach Connect' &&
        event.eventId.status === 'publish'
    )
    .sort(
      (a, b) => new Date(b.eventId.dateTime) - new Date(a.eventId.dateTime)
    );

  const coachEvent1 = coachEvents.filter((event) => {
    return event.eventId.week < fullJourneyStart;
  });
  const coachEvent2 = coachEvents.filter((event) => {
    return event.eventId.week >= fullJourneyStart;
  });

  return (
    <div>
      <div className='bg-gray-100 py-4'>
        <OverviewCard
          imageUrl='https://res.cloudinary.com/belong/image/upload/v1643359130/uploaded_resources/Coaching-Banner-Logo_byqkzp.png'
          overviewHeader='Your Journey Guidance & Support'
          list1='Knowledge'
          list2='Feedback'
          list3='Ideas & Tools'
        />
        <AboutSection heading='How does Coaching Help?'>
          {props.isVGE ? (
            <>
              <p>
                While you apply your skills and knowledge to transform your idea
                into business, we provide a seasoned entrepreneur to direct your
                journey with guidance and support.
              </p>
              <p>
                The expert coach can help you in research and ideation as well
                as answer your queries related to the entrepreneurial concepts.
              </p>
              <p>
                Utilize this connection to seek guidance and support wherever
                needed. No question is too big or small. Get your work reviewed,
                ideate on actionable steps and find your way around roadblocks
                and challenges.
              </p>
            </>
          ) : (
            <>
              <p>
                While you apply your technical knowledge and skills in your
                tasks from the{' '}
                {isCareereraUser ? ' Internship ' : ' apprenticeship '} company,
                we provide a tech peer to support you in delivering great
                output. The teach peer can help you research and ideation as
                well as answer your queries related to the tech tasks assigned
                to you.
              </p>
              <p>
                Utilise this connect to seek guidance and support wherever
                needed. No question is too big or small.
              </p>
              <p>
                Get your work reviewed, ideate on actionable steps and find your
                way around roadblocks and challenges.
              </p>
            </>
          )}
        </AboutSection>

        {coachDetails2 && isFullJourney ? (
          <>
            <YourCoach
              heading='Your Full-time Internship Coach'
              coach={coachDetails2}
            />
            {coachEvent2.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-8'></div>
                <h2>
                  <b>Coach Schedule</b>
                </h2>
              </div>
            )}
            {coachEvent2.map((event) => {
              return (
                <Schedules
                  key={event.eventId._id}
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
            <YourCoach
              heading='Your Previous Coach Engagement'
              coach={coachDetails}
            />
            {coachEvent1.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-8'></div>
                <h2>
                  <b>Coach Schedule</b>
                </h2>
              </div>
            )}
            {coachEvent1.map((event) => {
              return (
                <Schedules
                  key={event.eventId._id}
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
          </>
        ) : (
          <>
            <YourCoach heading='Your Coach' coach={coachDetails} />
            {coachEvents.length !== 0 && (
              <div className='flex p-4'>
                <div className='h-2 w-2 m-2 bg-primary mb-8'></div>
                <h2>
                  <b>Coach Schedule</b>
                </h2>
              </div>
            )}
            {coachEvents.map((event) => {
              return (
                <Schedules
                  key={event.eventId._id}
                  imgsrc={event.eventId.imageUrl}
                  heading={event.eventId.title}
                  about={event.eventId.description}
                  dateTime={event.eventId.dateTime}
                  date={format(
                    new Date(event.eventId.dateTime),
                    'do LLLL yyyy'
                  )}
                  time={format(new Date(event.eventId.dateTime), 'p')}
                  joinlink={event.eventId.link}
                  recordingUrl={event.eventId.recordingUrl}
                />
              );
            })}
          </>
        )}
      </div>
    </div>
  );
}

export default Coach;
