import { stringify } from 'query-string';
import ReactGA from 'react-ga4';

import availableDomains from '../domainData.json';
export function experienceToTextMapping(experience: string) {
  const expToText: any = {
    '0-0.5 Year': { text: 'Entry Level', color: 'text-orange-400' },
    '0.5-3 Year': { text: 'Beginner', color: 'text-yellow-400' },
    '3-6 Year': { text: 'Intermediate', color: 'text-green-500' },
    '6+ Year': { text: 'Senior', color: 'text-green-800' },
  };
  return expToText[experience];
}

export function getLocation(jobType: String, jobLocation: any) {
  const jobCity = jobLocation.city;
  const jobCountry = jobLocation.country;
  let location = '';
  if (jobType !== 'Remote') {
    location = `-${jobCity}, ${jobCountry}`;
  }
  return location;
}

export const fieldMap: any = {
  'Role Type': 'jobType',
  'Work Mode': 'roleType',
  Expertise: 'expertise',
  Experience: 'experience',
  City: 'city',
  Country: 'country',
  Industries: 'industryId',
  Companies: 'companyId',
};

export const makeSearchQuery = (appliedFilters: any) => {
  const selectedSectionsData = {
    selectedSections: appliedFilters['selectedSections'],
  };
  const sections = stringify(selectedSectionsData, { arrayFormat: 'index' });
  //Exclude selectedSections key only and make query from others
  const { selectedSections, ...filters } = appliedFilters;
  const filterParams = stringify(filters, { arrayFormat: 'index' });
  return [filterParams, sections];
};

export const IDLE = 'idle';
export const LOADING = 'loading';
export const APPLIED = 'applied';

export function checkJobEligibility(domainId: string, data: any) {
  //Check if aptitude test is givem
  //Check of domain test is given (If job domain matches the current domains available with quizzes)
  //Check if < 3 applications are present already (NOT PUTTING THIS IN PLACE FOR NOW)
  let aptitudeDone = false;
  let domainDone = false;
  let profileDone = false;
  if (data) {
    const domainAvailable = availableDomains.find((domain) => {
      return domain.id === domainId;
    });
    aptitudeDone = data.aptitude?.totalAttempts > 0;
    domainDone = domainAvailable ? data.domain?.totalAttempts > 0 : true;
    profileDone = data.profileStrength >= 5;
  }
  return [aptitudeDone, domainDone, profileDone];
}

export function trackClick(category: any, action: any) {
  ReactGA.event({
    category: category,
    action: action,
  });
}
