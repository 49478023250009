import React from 'react';
import type { OpportunityType } from './utils/types';
import { useGetFeaturedJobsQuery } from './JobsSlice';
import Opportunity from './layout/Opportunity';
import { getLocation } from './utils/helper';
import Loading from 'components/global/layout/Loading';
import classNames from 'classnames';

const FeaturedOpportunities: React.FC<{
  browseAll: boolean;
  setbrowseAll: Function;
}> = ({ browseAll, setbrowseAll }) => {
  const { isLoading, isError, isSuccess, data } = useGetFeaturedJobsQuery({});

  const content = data?.map((opportunity: OpportunityType) => {
    //
    const location = getLocation(opportunity.jobType, opportunity.jobLocation);
    const propsData: OpportunityType = {
      _id: opportunity._id,
      companyId: opportunity.companyId,
      roleDetails: opportunity.roleDetails,
      jobType: opportunity.jobType,
      jobLocation: opportunity.jobLocation,
      location: location,
      domainId: opportunity.domainId,
      payRange: opportunity.payRange,
      cutoffDate: opportunity.cutoffDate,
      slug: opportunity.slug,
    };

    return <Opportunity {...propsData} key={opportunity._id} />;
  });

  return (
    <div className='space-y-8'>
      {isLoading && <Loading />}
      {isError && 'Error'}
      {isSuccess && content}
      {isSuccess && (
        <button
          className={classNames('font-extralight cursor-pointer', {
            hidden: browseAll,
          })}
          onClick={() => {
            setbrowseAll(true);
          }}
        >
          Browse All
        </button>
      )}
    </div>
  );
};

export default FeaturedOpportunities;
