import { useState } from 'react';
import Logo from '../Logo';
import ForgotEmailEnter from './ForgotEmailEnter';
import ForgotPasswordReset from './ForgotPasswordReset';
const ForgotPassword = () => {
  const [sendCode, setSendCode] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  return (
    <div className='grid md:grid-cols-2 grid-cols-1 font-manrope'>
      <div className='md:flex md:flex-col p-4 justify-between bg-gradient-to-b from-[#193257] to-[#0A1626] md:h-screen'>
        <Logo />
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1660130444/b2c/quiz-removebg-preview_1_pok1oh.png'
          alt='Login'
          className='mx-auto'
        />
        <p className='text-white text-center text-lg md:text-2xl font-bold mb-32'>
          Boost Your Employment Profile
          <br />
          <span className='text-primary'>For Better Work Opportunities</span>
        </p>
      </div>
      {sendCode ? (
        <div>
          <ForgotPasswordReset userEmail={userEmail} />
        </div>
      ) : (
        <div>
          <ForgotEmailEnter
            setSendCode={setSendCode}
            setUserEmail={setUserEmail}
          />
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
