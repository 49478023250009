import { BellIcon } from '@heroicons/react/20/solid';
import HTMLReactParser from 'html-react-parser';
import { useMarkViewedMutation } from '../../notificationsSlice';

const NotificationBanner = ({
  setIsOpen,
  notificationToShow,
}: {
  setIsOpen: (value: boolean) => void;
  notificationToShow: any;
}) => {
  const [setViewed] = useMarkViewedMutation();
  return (
    <div className='rounded-md bg-red-50 p-4'>
      <div className='flex justify-between'>
        <div className='flex-shrink-0 flex gap-4'>
          <BellIcon className='h-5 w-5 text-red-400' aria-hidden='true' />
          <h3 className='text-lg font-medium text-red-800'>
            {notificationToShow.title}
          </h3>
        </div>
        <div className='ml-3 flex justify-between'>
          <div className='flex shrink-0'>
            <div className='space-x-4'>
              <button
                onClick={() => {
                  setViewed({ announcementId: notificationToShow._id });
                }}
                type='button'
                className='rounded text-primary bg-white py-2 px-4 text-sm shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
              >
                Mark as Read
              </button>

              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                type='button'
                className='rounded text-primary bg-white py-2 px-4 text-sm   shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
              >
                View All
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='ml-3'>
        <div className='mt-2 text-sm text-red-700'>
          <ul className='list-disc space-y-1 pl-5'>
            <div className='prose prose-sm max-w-none text-base'>
              <li>{HTMLReactParser(notificationToShow.message)}</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NotificationBanner;
