import PageTitle from 'components/layout/PageTitle';
import Routes from './routes';

function App() {
  const url = window.location.hostname;
  const isEdRed = url === 'capstone.edred.in';
  const favIcon = isEdRed ? 'https://www.edred.in/favicon.ico' : '';
  const title = isEdRed ? 'Edred' : 'Belong';
  return (
    <div>
      <PageTitle favIcon={favIcon} title={title} />
      <Routes />
    </div>
  );
}

export default App;
