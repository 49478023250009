import React from 'react';

interface FilterCategoryProps {
  field: string;
  label: string;
  data: any;
  appliedFilters: any;
  setAppliedFilters: Function;
}
const FilterCategory: React.FC<FilterCategoryProps> = ({
  field,
  label,
  data,
  appliedFilters,
  setAppliedFilters,
}) => {
  function handleChange(
    checked: Boolean,
    docId: string,
    fieldThatIsChanged: string
  ) {
    let currentFiltersOfCategory = appliedFilters[field];
    let currentSelectedSections = appliedFilters.selectedSections;
    const fieldToCheck = docId ? docId : fieldThatIsChanged;
    if (checked && !currentFiltersOfCategory.includes(fieldToCheck)) {
      currentFiltersOfCategory.push(fieldToCheck);
    }
    if (!checked && currentFiltersOfCategory.includes(fieldToCheck)) {
      currentFiltersOfCategory = currentFiltersOfCategory.filter(
        (_: string) => {
          return _ !== fieldToCheck;
        }
      );
    }
    if (
      currentFiltersOfCategory.length &&
      !currentSelectedSections.includes(label)
    ) {
      currentSelectedSections.push(label);
    }
    if (
      !currentFiltersOfCategory.length &&
      currentSelectedSections.includes(label)
    ) {
      currentSelectedSections = currentSelectedSections.filter(
        (section: string) => {
          return section !== label;
        }
      );
    }
    const _ = { ...appliedFilters };
    _[field] = currentFiltersOfCategory;
    _.selectedSections = currentSelectedSections;
    setAppliedFilters(_);
  }

  return (
    <div>
      <p className='font-semibold mb-2  text-primary'>{label}</p>
      <div className='space-y-1 font-extralight'>
        {data?.map((_: any) => {
          return (
            <div
              className={`${_.count ? 'flex gap-4 md:gap-8' : 'hidden'}`}
              key={_.name}
            >
              <input
                id={_.name}
                type='checkbox'
                className='w-4 h-4 checked:bg-primary rounded'
                checked={_.selected}
                onChange={(e) => {
                  handleChange(e.target.checked, _.docId, _.name);
                }}
              />
              <p className='text-left'>{_.name}</p>
              {/* <p>{_.count}</p> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FilterCategory;
