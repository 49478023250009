import { HiOutlineShare } from 'react-icons/hi';

const SaveAndShareRole = ({ slug }: { slug: string }) => {
  return (
    <div className='flex gap-2 text-primary justify-end text-2xl'>
      <div>
        <HiOutlineShare
          className='cursor-pointer'
          onClick={async () => {
            await navigator.clipboard.writeText(
              'https://belong.education/jobs/' + slug
            );
            alert('Link Copied');
          }}
        />
      </div>
    </div>
  );
};

export default SaveAndShareRole;
