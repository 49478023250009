import React from 'react';
import Chart from 'react-apexcharts';

const QuizScoreChart = (props) => {
  const series = [100 - props.percentage, props.percentage];
  const options = {
    colors: ['#FFFFFF', props.color || '#10ABB77A'],
    chart: {
      type: 'donut',
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        customScale: 1.1,
        donut: {
          size: '75%',
          labels: {
            show: true,
            total: {
              label: `${props.percentage} %`,
              fontSize: '14px',
              showAlways: true,
              show: true,
              formatter: function () {
                return '';
              },
            },
          },
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };
  return (
    <div>
      <Chart options={options} series={series} type='donut' />
    </div>
  );
};

export default QuizScoreChart;
