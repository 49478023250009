import { Outlet } from 'react-router-dom';
import Loading from 'components/global/layout/Loading';
import { useCheckIncompleteQuery } from './DomainQuizSlice';
import Assessment from '../assessment';

const Index = () => {
  //Checks if a test is already in progress or fresh test
  const { isLoading, isSuccess, data } = useCheckIncompleteQuery();

  const condition = isSuccess && Boolean(data);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : condition ? (
        <Assessment data={data} />
      ) : (
        <Outlet />
      )}
    </>
  );
};

export default Index;
