import React from 'react';
import { EventCardType } from '../../types';
import EventCard from '../../layout/EventCard';
import { differenceInHours } from 'date-fns';

type CoachEventCardData = {
  eventData: EventCardType[];
};
const CoachCard = (props: CoachEventCardData) => {
  const pastEvents = props.eventData.filter((event) => {
    const isPastEvent =
      differenceInHours(new Date(), new Date(event.dateTime)) >= 2;
    return isPastEvent;
  });
  const upComingEvents = props.eventData.filter((event) => {
    const isUpComingEvent =
      differenceInHours(new Date(), new Date(event.dateTime)) < 2;
    return isUpComingEvent;
  });
  return (
    <div className='mt-4 md:mt-6'>
      <div className='grid grid-cols-12 gap-8'>
        <div className='col-span-12 xl:col-span-6'>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Upcoming</p>
          </div>
          <div className='space-y-8'>
            {upComingEvents.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
        </div>
        <div className='col-span-12 xl:col-span-6 '>
          <div className='p-1 border-r-2 text-center text-primary font-semibold border rounded mb-2'>
            <p>Concluded</p>
          </div>
          <div className='space-y-8'>
            {pastEvents.map((event) => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoachCard;
