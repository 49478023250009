import htmlparser from 'html-react-parser';

interface ObjectivesProps {
  data: any;
}

const Objectives = (props: ObjectivesProps) => {
  return (
    <div className=' flex flex-col gap-3'>
      {props.data.learningObjectives && (
        <div className='flex flex-col gap-2   bg-opacity-60 rounded-lg'>
          <span className='font-medium'>Learning Objectives</span>

          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.learningObjectives)}
          </div>
        </div>
      )}
      {props.data.businessObjectives && (
        <div className='flex flex-col gap-2   bg-opacity-60 rounded-lg'>
          <span className='font-medium'>Business Objectives</span>

          <div className='prose prose-sm max-w-none text-base'>
            {htmlparser(props.data.businessObjectives)}
          </div>
        </div>
      )}
      {!props.data.learningObjectives && !props.data.businessObjectives && (
        <span className='  bg-opacity-60 font-medium rounded-lg'>
          Dataset is currently empty for this Capstone.
        </span>
      )}
    </div>
  );
};

export default Objectives;
