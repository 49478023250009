import useBasicDetailsContext from 'hooks/useBasicDetailsContext';

const QueryHeader = ({ numQueries }: { numQueries: number }) => {
  const { basicDetails } = useBasicDetailsContext();
  const isVge = basicDetails?.isVge;
  return (
    <div className='md:flex flex-wrap md:justify-between bg-gradient-to-b from-primary/10 to-white p-4 md:px-8 md:pt-6'>
      <div className='flex space-x-2 md:space-x-4 items-center text-primary'>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1670581252/new%20b2b/bi_chat-square-text_akm5d5.svg'
          alt='icon'
        />
        <p className='text-sm font-semibold'>
          Your {isVge ? 'VGE' : 'VGA'} Query Resolution Portal{' '}
        </p>
      </div>

      <div className='mt-2 md:mt-0'>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Journey & Tech Support{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Task & Assignment Doubts{' '}
        </p>
        <p className='text-sm'>
          <span className='w-2 h-2 inline-block mr-1 md:mr-4 rounded-full bg-primary'></span>
          Ongoing Queries- <span className='text-primary'> {numQueries}</span>
        </p>
      </div>
    </div>
  );
};

export default QueryHeader;
